<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle"      title="무료차량 관리">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist"   title="정기권 주차장 관리" class="active">정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement"    title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist"        title="팝업 관리">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>정기권 주차장 관리</h4>
			</article>
			<article>
				<h3>정기권 주차장 관리</h3>
			</article>
			<article class="prepayment-inner">
				<table class="vertical-table">
					<caption class="sr-only">정기권 주차장 관리</caption>
					<colgroup>
						<col style="width:13%" />
						<col style="width:87%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" rowspan="2">주차장</th>
							<td>
								<ul class="input-style small">
									<li>
										<input type="text" placeholder="주차장(키워드) 검색" class="place-hide" :value="searchKeyword" @input="searchParkingList">
									</li>
								</ul>
								<div class="prepayment">
									<date-picker @emitSetDate="setDate"></date-picker>
								</div>
								<div>
									<button class="table-btn" @click="setPeriodParking">적용</button>
									<button class="table-border-btn" @click="insertParkingInfo">주차장 업데이트</button>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<p v-if="nodata">조회된 주차장이 없습니다.</p>
								<p v-else>조회된 주차장은 {{listData.length}}개입니다. *주차장을 선택하고 신청기간을 설정한 뒤 적용 버튼을 눌러주세요.</p>
								<table class="table-inner">
									<caption class="sr-only">정기권 주차장관리 리스트</caption>
									<colgroup>
										<col style="width: 5%;" />
										<col style="width: 5%;" />
										<col style="width: 45%;" />
										<col style="width: 45%;" />
									</colgroup>
									<tbody>
										<tr v-for="(item, index) in listData" :key="index">
											<td>{{ index + 1 }}</td>
											<td>
												<input type="checkbox" :id="'itemCheck_' + item.SECTN_ID" v-model="checkedValues" :value="item.SECTN_ID">
												<label :for="'itemCheck_' + item.SECTN_ID"></label>
											</td>
											<td>{{ item.PARKING_NM }}</td>
											<td>{{ item.START_DTTM }} - {{ item.END_DTTM }}</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import DatePicker from '../../../components/DatePicker2.vue';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return{
			nodata: false,
			listData: [],
			checkedValues: [],
			startDate: new Date().toISOString().substr(0, 10),
			endDate: new Date().toISOString().substr(0, 10),
			searchKeyword: ''
		}
	},

	mounted() {
		this.searchBtn();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		searchBtn() {
			instance.get('/api/admin/period/manager/selectPeriodParkingList.json')
			.then((response => {
				var data = response.data;

				if(data.value && data.value.length > 0){
					this.listData = data.value;
					this.nodata = false;
				} else {
					this.listData = [];
					this.nodata = true;
				}

			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		searchParkingList(e) {
			this.searchKeyword = e.target.value;
			instance.get('/api/admin/period/manager/selectPeriodParkingListByKeyword.json', {
				params: {
					searchKeyword: this.searchKeyword
				}
			})
			.then((response => {
				var data = response.data;

				if(data.value && data.value.length > 0){
					this.listData = [];
					this.listData = data.value;
					this.nodata = false;
				} else {
					this.listData = [];
					this.nodata = true;
				}

			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		existCheckBox() {
			if(this.checkedValues.length < 1){
				alert('선택된 체크박스가 없습니다.');
				return false;
			} else{
				return true;
			}
		},

		setDate(sd, ed){
			this.startDate = sd;
			this.endDate   = ed;
		},

		setPeriodParking() {
			if(this.existCheckBox()){
				let apiParams = new FormData();

				apiParams.append("sectnIds", this.checkedValues);
				apiParams.append("startDttm", this.startDate);
				apiParams.append("endDttm", this.endDate);
	
				instance.post('/api/admin/period/manager/setPeriodParking.json', apiParams)
				.then((response => {
					let message = response.data.message;
					alert(message);
					this.searchBtn();
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		insertParkingInfo(){
			instance.post('/api/admin/period/manager/insertParkingInfo.json')
			.then((response => {
				let message = response.data.message;

				alert(message);
				this.searchBtn();
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		}
	},

	components : {
		DatePicker,	
	}
};
</script>

<style scope>
	.vertical-table tr:first-child td{ background: #F7F7F7; }
	.vertical-table tr:first-child td input[type="text"]{background-color: white;}
	.vertical-table tr:last-child > td{padding: 20px 10px; border-top: 1px solid orange;}
</style>