<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">열린공간</div>
			<ul>
				<li><router-link to="noticelist"    title="공지사항" class="active">공지사항</router-link></li>
				<li><router-link to="questionslist" title="자주하는 질문">자주하는 질문</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>열린공간</h2>
				<h4>공지사항</h4>
			</article>
			<article>
				<h3>공지사항</h3>
				<!-- Search Area -->
				<div class="search-box board">
					<search-box @emitSearch="searchBtn" @emitPerChange="changePerPage">
						<template slot="searchCondition">
							<option value="1">제목</option>
							<option value="2">내용</option>
							<option value="3">제목+내용</option>
						</template>
					</search-box>
				</div>
			</article>
			<article>
				<!-- Table List Area -->
				<h5 v-if="nodata">Total: 0건</h5>
				<h5 v-else>Total: {{listData.length}}건</h5>
				<table class="default-table notice-board">
					<caption class="sr-only">공지사항 테이블</caption>
					<colgroup>
						<col style="width:70px" />
						<col style="width:90%" />
						<col style="width:10%" />
						<col style="width:100px" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">번호</th>
							<th scope="col">제목</th>
							<th scope="col">작성자</th>
							<th scope="col">작성일</th>
						</tr>
					</thead>
					<tbody>
					<tr class="nodate" v-if="nodata">
						<td colspan="4">조회된 데이터가 없습니다.</td>
					</tr>
					<tr v-for="(item, index) in calData" :key="index" @click="view(item.atclId)" >
						<td>{{ listData.length - index - startOffset}}</td>
						<td style="text-align: left;">
							<a href="#" @keyup.enter="view(item.atclId)" title="제목">
								{{ item.title }}
							</a>
							<span v-if="item.atchCnt > 0" class="glyphicon glyphicon-paperclip" aria-hidden="true"><i class="hide">첨부파일 아이콘</i></span>
						</td>
						<td>{{ item.regNm }}</td>
						<td>{{ item.regDttm | ptDateFormat }}</td>
					</tr>
					</tbody>
				</table>
			</article>
			<article>
				<v-pagination v-model="curPageNum" :length="numOfPages"></v-pagination>
			</article>
		</section>
	</main>
</template>

<script>
import axios     from 'axios';
import SearchBox from '../../../components/SearchBox.vue';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			nodata      : false,
			listData    : [],
			curPageNum  : 1,
			dataPerPage : 10,
			bbsCd       : 'notice'
		}
	},

	mounted() {
		this.searchBtn();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		searchBtn(searchCondition, searchKeyword) {
			instance.get('/api/board/' + this.bbsCd + '/list.json', {
				params: {
					searchCondition : searchCondition,
					searchKeyword   : searchKeyword,
					bbsCd           : this.bbsCd
				}
			})
			.then((response => {
				var data = response.data;

				if(data.boardList && data.boardList.length > 0){
					this.listData = data.boardList;
					this.nodata   = false;
				} else {
					this.listData = [];
					this.nodata   = true;
				}

				this.curPageNum = 1;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		changePerPage(dataPerPage) {
			this.dataPerPage = dataPerPage;
			this.curPageNum  = 1;
		},

		view(atclId) {
			this.$router.push({
				path  : 'noticeview',
				query : { atclId : atclId }
			});
		},
	},

	computed: {
		startOffset() {
			return ((this.curPageNum - 1) * this.dataPerPage);
		},
		endOffset() {
			return (this.startOffset + this.dataPerPage);
		},
		numOfPages() {
			return Math.ceil(this.listData.length / this.dataPerPage);
		},
		calData() {
			if(this.listData.length) {
				return this.listData.slice(this.startOffset, this.endOffset);
			}else{
				return null;
			}
		}
	},
	
	components:{
		SearchBox,
	}
}
</script>

<style>

</style>