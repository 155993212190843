<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo"        title="안내">안내</router-link></li>
				<li><router-link to="residentsearch"      title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup"      title="조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 신청 결제</h3>
			</article>
			<article>
				<table class="default-table-view">
					<caption class="sr-only">거주자 우선 주차장 신청결제 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>{{ r.appcntNm }}</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>({{ r.zipcode }}) {{ r.addr}} {{ r.addrDetail }}</td>
						</tr>
						<tr>
							<th>연락처</th>
							<td>{{ r.mobile | phoneFormat }}</td>
						</tr>
						<tr>
							<th>신청 주차장</th>
							<td>
								<span class="emphasis-view">{{ r.dongNm }}</span>
								{{ r.sectnNm }}
								<span class="emphasis" v-if="r.cellNm != '' && r.cellNm != null">
									{{ r.cellNm }}
								</span>
							</td>
						</tr>
						<tr>
							<th>차량 소유주</th>
							<td>{{ r.carOwnerNm }}</td>
						</tr>
						<tr>
							<th>차량번호</th>
							<td>{{ r.carNo }}</td>
						</tr>
						<tr>
							<th>할인</th>
							<td>{{ r.discountNm }}</td>
						</tr>
						<tr>
							<th>이용 기간</th>
							<td>
								<span class="emphasis-view">{{ r.useDivNm }}</span>
								{{ r.startDt | longToDate }} ~ {{ r.endDt | longToDate }}
							</td>
						</tr>
						<tr>
							<th>결제 기간</th>
							<td>
								<span class="orange bold" v-if="r.billDt == '' || r.limitDt == ''">
									결제 기간이 없습니다. 관리자에게 문의해주세요.
								</span>
								<span class="orange" v-else>{{ r.billDt | longToDate }} ~ {{ r.limitDt | longToDate }}</span>
							</td>
						</tr>
						<tr>
							<th>결제 금액</th>
							<td>{{ r.billAmt | comma }} 원</td>
						</tr>
					</tbody>
				</table>
			</article>

			<article class="message">
				<p v-if="r.allotStsCd == 'ASS-001'">
					<!-- 요금미납 -->
					<span v-if="r.limitDt < nowDate">결제기간이 만료되어 결제를 진행할 수 없습니다.</span>
					<span v-else>결제가 가능합니다. 결제를 진행하면 배정 완납처리 됩니다.</span>
				</p>
				<p v-else-if="r.allotStsCd == 'ASS-002'">
					<!-- 배정확정 -->
					<span>결제가 완료되었습니다.</span>
				</p>
				<p v-else>
					<!-- 배정취소 -->
					<span>배정이 취소되었습니다.</span>
				</p>
			</article>
			<article class="message">
				<p>결제 실패시 처음(차량번호 조회)부터 다시 시도 해주시기 바랍니다.</p>
			</article>

			<article class="btn-list" v-if="r.allotStsCd == 'ASS-001' && r.expiredYn == 'Y'">
				<button class="btn"        @click="paymentBtn()">결제하기</button>
				<button class="btn-border" @click="cancelBtn(carNo)">취소</button>
			</article>
			<article class="btn-list" v-else>
				<button class="btn-border" @click="cancelBtn(carNo)">확인</button>
			</article>

		</section>
	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return{
			carNo         : '',
			allotId       : '',
			r             : '',
			easypayParams : '',
			nowDate       : this.getNowDate()
		}
	},
	filters : {
		// 숫자 #,### 포맷팅
		comma : function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
		// 날짜 포맷팅
		dateFormat : function(x){
			if(x != null){
				var d = x.toString().replace(/[^0-9]/g, '');

				var year  = d.toString().substr(0, 4);
				var month = d.toString().substr(4, 2);
				var day   = d.toString().substr(6, 2);

				return year + "-" + month + "-" + day;
			}
		},
		longToDate : function(x){
			if(x != null){
				var d = new Date(x);

				var year  = d.getFullYear();
				var month = d.getMonth() + 1;
				month     = (month < 10) ? "0" + month.toString() : month.toString();
				var day   = d.getDate();
				day       = (day < 10) ? "0" + day.toString() : day.toString();

				return year + "-" + month + "-" + day;
			}
		},
		// 전화번호 포맷팅
		phoneFormat : function(x){
			if(x != null){
				var p = x.toString().replace(/[^0-9]/g, '');

				let tmp = "";

				if(p.length < 4){

					return p;

				}else if(p.length < 7){

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3);
					return tmp;

				}else if(p.length == 8){

					tmp += p.substr(0, 4);
					tmp += "-";
					tmp += p.substr(4);
					return tmp;

				}else if(p.length < 10){

					tmp += p.substr(0, 2);
					tmp += "-";
					tmp += p.substr(2, 3);
					tmp += "-";
					tmp += p.substr(5);
					return tmp;

				}else if(p.length < 11){

					if(p.substr(0, 2) == "02"){

						tmp += p.substr(0, 2);
						tmp += "-";
						tmp += p.substr(2, 4);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}else{

						tmp += p.substr(0, 3);
						tmp += "-";
						tmp += p.substr(3, 3);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}
					

				}else{

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3, 4);
					tmp += "-";
					tmp += p.substr(7);
					return tmp;

				}

			}
		}
	},
	created(){
		
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("data"));
		}else{
			localStorage.setItem("data", JSON.stringify(data));
		}

		this.carNo   = data.carNo;
		this.allotId = data.allotId;

	},
	mounted(){
		let apiParams = new Map();
		apiParams.set("allotId" , this.allotId);
		apiParams.set("isMobile", this.$isMobile());

		instance.get("/api/resident/view.json", {
			params : Object.fromEntries(apiParams)
		})
		.then((res => {

			var resData = res.data;

			this.r             = resData.resultMap.result;
			this.easypayParams = resData.resultMap.easypayParams;

		}));

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		// 지금 시간 가져오기
		getNowDate(){
			var d = new Date();

			var year  = d.getFullYear();
			var month = d.getMonth() + 1;
			month     = (month < 10) ? "0" + month.toString() : month.toString();
			var day   = d.getDate();
			day       = (day < 10) ? "0" + day.toString() : day.toString();

			return year + "-" + month + "-" + day;
		},
		// 취소 버튼
		cancelBtn(carNo){
			this.$router.push({
				name  : 'residentlookup',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},
		// 결제 버튼
		paymentBtn(){

			var e = this.easypayParams;

			let easypayParams = new Map();
			easypayParams.set("easypayRequestUrl"	, e.easypayRequestUrl);
			easypayParams.set("mallId"				, e.mallId);
			easypayParams.set("payMethodTypeCode"	, e.payMethodTypeCode);
			easypayParams.set("currency"			, e.currency);
			easypayParams.set("amount"				, e.amount);
			easypayParams.set("clientTypeCode"		, e.clientTypeCode);
			easypayParams.set("returnUrl"			, e.easypayReturnUrl);
			easypayParams.set("shopOrderNo"			, e.shopOrderNo);
			easypayParams.set("deviceTypeCode"		, e.deviceTypeCode);
			easypayParams.set("mallName"			, encodeURIComponent(e.mallName));
			easypayParams.set("goodsName"			, encodeURIComponent(e.goodsName));
			easypayParams.set("customerId"			, encodeURI(this.$store.state.empNo));				// e.customerId
			easypayParams.set("customerName"		, encodeURI(this.$store.state.empNm));				// e.customerName
			easypayParams.set("customerMail"		, encodeURIComponent(this.$store.state.empNo));		// e.customerMail
			easypayParams.set("customerContactNo"	, this.$store.state.empMobile);						// e.customerContactNo
			easypayParams.set("value1"				, e.value1);										// payCategory (사전정산 PREPAY)
			easypayParams.set("value2"				, e.value2);										// sectnId
			easypayParams.set("value3"				, e.value3);										// iNervPayId
			easypayParams.set("value4"				, e.value4);										// carNo
			easypayParams.set("value5"				, e.cellId);										// cellId

			instance.get("/api/easypay/easypaystart.json", {
				
				params : Object.fromEntries(easypayParams)

			}).then(function(res){
				
				//console.log("SUCESS");
				//console.dir(res);
				var resData = res.data;
				
				// Mobile, PC Check & ios, android Check
				var mobileOS = "";
				if(e.deviceTypeCode == "mobile"){
					var mobileOSCheck = navigator.userAgent.toLowerCase();
					if(mobileOSCheck.indexOf('iphone') > -1 || mobileOSCheck.indexOf('ipad') > -1 || mobileOSCheck.indexOf('ipod') > -1){
						mobileOS = "ios";
					}else{
						mobileOS = "android";
					}
				}

				if(mobileOS == "ios"){
					window.location.href = resData.result.authPageUrl;
				}else{
					// 결제 모듈 - 팝업 오픈
					var webpay = window.open(resData.result.authPageUrl, "", "_blank");

					// 결제 모듈 - JSP 파일 팝업 종료 감지
					var interval = window.setInterval(function(){
						if(webpay == null || webpay.closed){
							window.clearInterval(interval);
						}
					});

				}

			})
			.catch(function(err){
				console.log("ERROR");
				//console.dir(err);
			});

		}
	}
}

</script>

<style>

</style>