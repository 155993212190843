<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">주차요금 사전결제</div>
			<ul>
				<li><router-link to="prepaymentinfo" title="사전결제 안내" >안내</router-link></li>
				<li><router-link to="lookup" title="사전결제 조회/결제" class="active">조회 &#47; 결제</router-link></li>
				${result}
			</ul>
		</section>
	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			resCd           : '',
			resMsg          : '',
			shopOrderNo     : '',
			authorizationId : '',
			shopValue1      : '',
			shopValue2      : '',
			shopValue3      : '',
			shopValue4      : '',
			shopValue5      : '',
			shopValue6      : '',
			shopValue7      : ''
		}
	},
	create(){
		var data = this.$route;
		//console.dir(data);
	},
	mounted(){
		var data = this.$route;
		//console.dir(data);
	}
}

</script>