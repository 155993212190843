<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차장</div>
			<ul>
				<li><router-link to="/introduce" title="주차장 소개">주차장 소개</router-link></li>
				<li><router-link to="/map" title="지도 검색">지도 검색</router-link></li>
				<li><router-link to="/list" title="주차장 검색">주차장 검색</router-link></li>
        <li><router-link to="/feeinformation" title="주차장 요금안내">주차장 요금안내</router-link></li>
        <li><router-link to="/realtimeparking" title="실시간 주차정보" class="active">실시간 주차정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차장</h2>
				<h4>실시간 주차정보</h4>
			</article>
      <section>        
        <article>
          <div class="flexRow">
            <h3 data-v-235fe3c6="">실시간 주차정보</h3>
            <button type="button" class="update-btn" @click="updateOperating()">실시간 업데이트</button>
          </div>
        </article>
        <article>
          <table class="default-table-list-view exception">
            <caption class="sr-only">실시간 주차정보</caption>
            <colgroup>
              <col style="width:35%;" />
              <col style="width:22%;" />
              <col style="width:22%;" />
              <col style="width:auto;" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">주차장명</th>
                <th scope="col">주차면수</th>
                <th scope="col">주차대수</th>
                <th scope="col">가용대수</th>
              </tr>
            </thead>
            <tbody>                   
              <tr v-for="operate in this.datosPaginados">
                <td>{{operate.PARKING_NM}}</td>
                <td>{{operate.CELL_CNT}}</td>
                <td>{{operate.USE_CNT}}</td>
                <td>{{operate.CURRENT_CNT}}</td>
              </tr>
            </tbody>
          </table>
        </article>
        <!-- Pagenation Area -->
			  <article>
          <nav>
            <ul class="pagination">
              <li class="first" @click="getDataPagina(1)" :style="isFirst()"><a class="" href="#" title="처음">&lt;&lt;</a></li>
              <li class="prev" @click="getPreviousBlock()" :style="isFirst()"><a class="" href="#" title="이전">&lt;</a></li>
              <li v-for="pagina in pageNum" :key="pagina" @click="getDataPagina(pagina)" :class="isActive(pagina)"><a href="#">{{ pagina }}</a></li>
              <li class="next" @click="getNextBlock()" :style="isLast()"><a class="" href="#" title="다음">&gt;</a></li>
              <li class="last" @click="getDataPagina(totalPage())" :style="isLast()"><a class="" href="#" title="마지막">&gt;&gt;</a></li>
            </ul>
          </nav>
        </article>
      </section>
    </section>
	</main>
</template>
<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
    return{
      nodata      : false,
      parkingName : "",
      territories : "",
      operating   : [],
      /**
			 * Pagination
			 */
			pageSize        : 10,
			datosPaginados  : [],
			paginaActual    : 1,
			on              : false,
			blockSize		: 5,
			pageNum			: [], 
			startBlock		: 1,
			endBlock		: 1,
    }
  },
  
  mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
    this.getOperating();
	},

  methods:{
		getOperating(){
      var jsonList = [];
			instance.get("/api/parking/allOperating.json").then((res => {
        if(res.data.operating.length > 0){
          this.operating = res.data.operating;
					this.datosPaginados = jsonList.slice(0, 5);
          this.nodata = false;
        }else{
          this.operating    = [];
					this.datosPaginados = null;
					this.nodata         = true;
        }
        this.getDataPagina(1);
          
			}));
		},

		getDataPagina(noPagina){
			
			this.datosPaginados = [];
			this.paginaActual = noPagina;
			let ini = (noPagina * this.pageSize) - this.pageSize;
			let fin = (noPagina * this.pageSize);
			this.datosPaginados = this.operating.slice(ini, fin);

			var pageNumlist = [];
			var nowBlock = Math.ceil(this.paginaActual / this.blockSize);
			this.startBlock = ((nowBlock - 1) * this.blockSize) + 1;
			this.endBlock = this.startBlock + this.blockSize - 1
			var i = this.startBlock;

			if(this.endBlock > this.totalPage()) {
				this.endBlock = this.totalPage();
			}

			for (i; i <= this.endBlock; i++) {
				pageNumlist.push(i);
			}

			this.pageNum = pageNumlist;
		},

		getPreviousBlock(){
			this.getDataPagina(this.startBlock - 1);
		},

		getNextBlock(){
			this.getDataPagina(this.endBlock + 1);
		},
    
    isActive(noPagina){
      return noPagina == this.paginaActual ? 'active' : '';

    },

    isFirst(){
      return this.startBlock == 1 ? { display : 'none' } : "";
    },

    isLast(){
      return this.endBlock == this.totalPage() ? { display : 'none' } : "";
    },

    totalPage(){
			return Math.ceil(this.operating.length / this.pageSize);
		},

    updateOperating(){
      this.$router.go();
    }
  }

}
</script>

<style lang="scss" scoped>
	
</style>