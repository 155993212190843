<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">비대면 자격확인</div>
			<ul>
				<li><router-link to="reductioninfo"      title="비대면 자격확인 안내" class="active">안내</router-link></li>
				<li><router-link to="reductionagreement" title="비대면 자격확인 조회">비대면 자격확인 조회</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>비대면 자격확인</h2>
				<h4>안내</h4>
			</article>
			<article>
				<h3>비대면 자격확인이란?</h3>
				<ul>
					<li class="hyphen">
						화성시 주차장 조례 및 화성시 공공시설 부설주차장 운영 조례상 주차요금 감면(면제) 대상 중 행정안전부 공공시설 이용요금 감면 서비스를 활용하여 주차요금 할인을 위한 자격을 확인하는 서비스
					</li>
					<li class="hyphen">
						요금감면 자격확인 대상은 경차, 저공해차량, 장애인차량, 국가유공자차량 입니다.
					</li>
					<li class="hyphen">
						기타 할인 관련 별도 문의 (화성도시공사 주차운영부 (031)8059-6538)
					</li>
				</ul> 
			</article>
			<article>
				<h3>비대면 자격확인 절차 및 감면 항목</h3>
				<p class="circle">자격확인 절차</p>
				<ul>
					<li class="hyphen">요금감면 자격확인  &#9655; 차량번호 입력  &#9655; 확인 </li>
				</ul>
				<p class="circle">감면항목</p>
				<ul>
					<li class="hyphen">
						경형차량, 저공해차량, 장애인차량, 국가유공자차량
					</li>
				</ul>
			</article>
			<article class="btn-list">
				<button class="one-btn" @click="pageList">신청하기</button>
			</article>
			<article class="responsive-align">
				<p class="orange">* 신청 정보가 일치하지 않으면 자격확인이 되지않습니다. 신청정보를 정확히 입력하여 주세요.</p>
			</article>	
		</section>
	</main>
</template>

<script>
export default {
	methods:{
		pageList(){
			this.$router.push('/reductionagreement')
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>