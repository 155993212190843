<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle"      title="무료차량 관리">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist"   title="정기권 주차장 관리" >정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement"    title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist"        title="팝업 관리" class="active">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>팝업 관리</h4>
			</article>
			<article>
				<h3>팝업 관리</h3>
			</article>
			<article>
				<!-- Table List Area -->
				<table class="vertical-table">
					<caption class="sr-only">팝업관리 상세보기 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:30%" />
						<col style="width:20%" />
						<col style="width:30%" />
					</colgroup>
					<tbody>
						<tr>
							<th class="asterisk-after">제목</th>
							<td colspan="3">{{ popupObj.title }}</td>
						</tr>
						<tr>
							<th class="asterisk-after">게시기간</th>
							<td colspan="3">{{ popupObj.openDate | ptDateFormat }} ~ {{ popupObj.closeDate | ptDateFormat }}</td>
						</tr>
						<tr>
							<th class="asterisk-after">사용여부</th>
							<td v-if="popupObj.useYn === 'Y'">사용함</td>
							<td v-else>사용안함</td>
							<th class="asterisk-after">위치</th>
							<td>
								<ul class="input-style location">
									<li>X : </li>
									<li>{{ popupObj.posY }}</li>
									<li>Y : </li>
									<li>{{ popupObj.posX }}</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">크기</th>
							<td colspan="3">
								<ul class="input-style location">
									<li>가로 : </li>
									<li>{{ popupObj.sizeY }}</li>
									<li>세로 : </li>
									<li>{{ popupObj.sizeX }}</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">링크주소</th>
							<td colspan="3">{{ popupObj.linkUrl }}</td>
						</tr>
						<tr>
							<th class="asterisk-after">팝업내용</th>
							<td colspan="3">
								<!-- {{ popupObj.popupContents }} -->
								<div v-html="cts"></div>
							</td>
						</tr>
						<tr>
							<th>첨부파일</th>
							<td colspan="3">
								<ul class="input-style button">
									<li class="attach" v-for="(item, index) in attachList" :key="index">
										<div class="file-name">
											<span class="cursor" @click="downloadAttachFile(item.filePath, item.saveNm, item.viewNm, item.fileExt, item.repoId)">
												{{ item.viewNm }} ({{ item.fileSize | comma }})kb
											</span>
										</div>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list right view">
				<button class="btn"        @click="$router.push('popuplist')">목록</button>
				<button class="btn black"  @click="updateBtn(popupObj)">수정</button>
				<button class="btn-border" @click="deleteConfirm()">삭제</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return {
			popupId    : this.$route.query.popupId,
			popupObj   : '',
			attachList : [],
			menuUrl    : "/api/admin/popup",
			cts        : "",
		}
	},

	mounted() {
		this.initView();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		initView(){
			instance.get('/api/admin/popup/view.json', {
				params: {
					popupId : this.popupId
				}
			})
			.then((response => {
				var data        = response.data;
				this.popupObj   = data.popupView;
				if(this.popupObj.popupContents != "" || this.popupObj.popupContents != null){
					this.cts = this.popupObj.popupContents;
				}
				this.attachList = data.attachList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		updateBtn(popupObj){
			let params = {
				popupId       : popupObj.popupId,
				title         : popupObj.title,
				openDate      : this.$options.filters.ptDateFormat(popupObj.openDate),
				closeDate     : this.$options.filters.ptDateFormat(popupObj.closeDate),
				useYn         : popupObj.useYn,
				posX          : popupObj.posX,
				posY          : popupObj.posY,
				sizeX         : popupObj.sizeX,
				sizeY         : popupObj.sizeY,
				linkUrl       : popupObj.linkUrl,
				popupContents : popupObj.popupContents
			}

			this.$router.push({
				name   : 'popupupdate',
				params : { data : params }
			})
		},

		downloadAttachFile(filePath, saveNm, viewNm, fileExt, repoId) {
			location.href = this.menuUrl + "/download.json?filePath=" + filePath + "&saveNm=" + saveNm + "&viewNm=" + viewNm + "&fileExt=" + fileExt + "&repoId=" + repoId;
		},

		deleteBtn(){
			let apiParams = new FormData();
			apiParams.append("popupId", this.popupId);

			instance.post('/api/admin/popup/delete.json', apiParams)
			.then((response => {
				let message = response.data.message;
				alert(message);
				this.$router.push('popuplist');
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		deleteConfirm(){
			this.$confirm(
				{
					message: `삭제하시겠습니까?`,
					button: {
						no: '아니오',
						yes: '예'
					},
					callback: confirm => {
						if (confirm) {
						this.deleteBtn();
						}
					}
				}
			)
		}
	}

};
</script>

<style>

</style>