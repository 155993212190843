<template>
	<main id="subTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article>
				<h3>02. 차량 정보</h3>
				<table class="vertical-table mo">
					<caption class="sr-only">차량  정보</caption>
					<colgroup>
						<col style="width:20%; min-width: 100px;"/>
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">차량번호</th>
							<td>
								<ul class="input-style small">
									<li>
										<!-- <input type="text" placeholder="차량번호" class="place-hide" v-model="carNumber">
										<div class="error" v-if="!$v.carNumber.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.carNumber.carNoCheck">차량번호 형식에 맞지 않습니다.</div> -->
										{{ carNumber }}
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">소유주명</th>
							<td>
								<ul class="input-style small">
									<li>
										<label for="proPrietor" class="hide">소유주명</label>
										<input type="text" placeholder="소유주명" class="place-hide" v-model="carOwnerNm" id="proPrietor">
										<div class="error" v-if="!$v.carOwnerNm.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<!-- <tr>
							<th class="asterisk-after">주민등록번호</th>
							<td>
                				<ul class="input-style security-number">
									<li>
										<input type="text" placeholder="123456" class="place-hide" v-model="registerNumber1">
									</li>
									<li>-</li>
									<li>
										<input type="password" placeholder="*******" class="place-hide" v-model="registerNumber2">
									</li>
              					</ul>
								<div class="error" v-if="!$v.registerNumber1.required || !$v.registerNumber2.required">필수 입력란입니다.</div>
								<div class="error" v-if="!$v.registerNumber1.minLength || !$v.registerNumber1.maxLength || !$v.registerNumber2.minLength || !$v.registerNumber2.maxLength">주민등록번호를 올바르게 입력하세요.</div>
							</td>
						</tr> -->
						<tr>
							<th>차종</th>
							<td>
								자동조회
							</td>
						</tr>
						<tr>
							<th>배기량</th>
							<td>
								자동조회
							</td>
						</tr>
						<tr>
							<th>할인 선택</th>
							<td class="select-area">
								<label for="discountSelect" class="hide">할인선택</label>
								<select name="" v-model="discountCd" id="discountSelect">
									<option v-for="(item, index) in discountList" :key="index" :value="{id: item.code, text: item.codeNm}">{{item.codeNm}}</option>
								</select>
								<div class="error" v-if="!$v.discountCd.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th>공동소유 &#47; 리스 &#47; <br>법인차량 여부</th>
							<td>
								<ul class="input-radio">
									<li>
										<input type="radio" id="false" v-model="renYnCheckBox" value="false">
										<label for="false">아니오</label>
									</li>
									<li>
										<input type="radio" id="true" v-model="renYnCheckBox" value="true">
										<label for="true">예</label>
									</li>
								</ul>
								<div class="error" v-if="!$v.renYnCheckBox.required">필수 입력란입니다.</div>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list">
				<button class="btn" @click="previousBtn">이전</button>
				<button class="btn-border" @click="nextBtn">다음</button>
		</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import { required, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'

const carNoRegex     = helpers.regex('carNoRegex', /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi);
const carNoRegexDipl = helpers.regex('carNoRegexDipl', /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi); //외교차량 정규식

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return {
			userNm: '',
			userAddress: '',
			useHpPhone: '',
			useTelPhone: '',
			carNumber: '',
			carOwnerNm: '',
			// registerNumber1: '',
			// registerNumber2: '',
			discountCd: '',
			renYnCheckBox: 'false',
			discountList: [],
			submitStatus: ''
		}
	},

	validations: {
		// carNumber: {
		// 	required,
		// 	carNoCheck: or(carNoRegex, carNoRegexDipl)
		// },
		carOwnerNm: {
			required
		},
		// registerNumber1: {
		// 	required,
		// 	minLength: minLength(6),
		// 	maxLength: maxLength(6)
		// },
		// registerNumber2: {
		// 	required,
		// 	minLength: minLength(7),
		// 	maxLength: maxLength(7)
		// },
		discountCd: {
			required
		},
		renYnCheckBox: {
			required
		}
	},

	created() {
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("residentApplData"));
		}else{
			localStorage.setItem("residentApplData", JSON.stringify(data));
		}

		this.userNm = data.userNm;
		this.userAddress = data.userAddress;
		this.useHpPhone = data.useHpPhone;
		this.useTelPhone = data.useTelPhone;
		this.carNumber = this.$store.state.empCarNo;
		this.carOwnerNm = data.carOwnerNm;
		// this.registerNumber1 = data.registerNumber1;
		// this.registerNumber2 = data.registerNumber2;
		this.discountCd = {id: data.discountCd, text: data.discountNm};
		// this.discountNm = data.discountNm;
		this.renYnCheckBox = data.renYnCheckBox;
	},

	mounted() {
		this.getDiscountList();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		nextBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR"
			}else {
				this.submitStatus = "OK";
				
				let params = {
					userNm : this.userNm,
					userAddress : this.userAddress,
					useHpPhone : this.useHpPhone,
					useTelPhone : this.useTelPhone,
					carNo : this.carNumber,
					carOwnerNm : this.carOwnerNm,
					// registerNumber1 : this.registerNumber1,
					// registerNumber2 : this.registerNumber2,
					discountCd : this.discountCd.id,
					discountNm : this.discountCd.text,
					renYnCheckBox : this.renYnCheckBox
				}

				this.$router.push({
					name : 'residentregistration',
					params: {data : params}
				})
			}
		},

		// previousBtn() {
		// 	let params = {
		// 		userNm : this.userNm,
		// 		userAddress : this.userAddress,
		// 		useHpPhone : this.useHpPhone,
		// 		useTelPhone : this.useTelPhone,
		// 		carNo : this.carNumber,
		// 		carOwnerNm : this.carOwnerNm,
		// 		registerNumber1 : this.registerNumber1,
		// 		registerNumber2 : this.registerNumber2,
		// 		discountCd : this.discountCd.id,
		// 		discountNm : this.discountCd.text,
		// 		renYnCheckBox : this.renYnCheckBox
		// 	}

		// 	this.$router.push({
		// 		name : 'applicantinfo',
		// 		params: {data : params}
		// 	})
		// },

		previousBtn() {
			this.$router.push('applicantinfo');
		},

		getDiscountList() {
			instance.get('/api/resident/getDiscountList.json')
			.then((response => {
				var data = response.data;

				this.discountList = data.discountList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		}
	}
};
</script>

<style>

</style>