<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">마이페이지</div>
			<ul>
				<li><router-link to="/mypagemain" title="회원정보변경" class="active">회원정보 변경</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>마이페이지</h2>
        <h4>회원정보</h4>
			</article>
			<article>
				<table class="vertical-table">
          <caption class="sr-only">회원정보 상세보기</caption>
          <colgroup>
            <col style="width:20%; min-width: 80px;" />
            <col style="width:80%" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">사용자 이름</th>
              <td>{{ empNm }}</td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>{{ empNo }}</td>
            </tr>
            <tr>
              <th class="asterisk-after">비밀번호</th>
              <td>
                <ul class="input-style mypage">
                  <li>
                    <ul v-show="inputPassword">
                      <li>
                        <input type="password" placeholder="현재 비밀번호 입력" class="place-hide" id="prePassword" v-model="prePassword">
                        <label for="prePassword" tabindex="1"><span class="hide">현재 비밀번호 입력</span></label>
                      </li>
                      <li>
                        <p class="asterisk red" tabindex="1">비밀번호는 숫자와 영문자 특수문자 조합으로 8~20자리를 사용해야 합니다.</p>
                      </li>
                      <li>
                        <input type="password" placeholder="변경 비밀번호 입력" class="place-hide" id="changePassword" v-model="changePassword">
                        <label for="changePassword"><span class="hide">변경 비밀번호 입력</span></label>
										    <div class="error" v-if="!$v.changePassword.minLength">8~20자리 입력란입니다.</div>
										    <div class="error" v-if="!$v.changePassword.maxLength">8~20자리 입력란입니다.</div>
    										<div class="error" v-if="!$v.changePassword.passRegex">조건에 맞지 않습니다.</div>  
                      </li>
                      <li>
                        <input type="password" placeholder="변경 비밀번호 확인" class="place-hide" v-model="changePasswordCron" id="changePasswordCron" v-on:keyup.enter="updatePassword">
                        <label for="changePasswordCron"><span class="hide">변경 비밀번호 확인</span></label>
										    <div class="error" v-if="!$v.changePasswordCron.sameAsPassword">일치하지 않는 비밀번호입니다.</div>
										    <div class="error" v-if="!$v.changePasswordCron.minLength">8~20자리 입력란입니다.</div>
										    <div class="error" v-if="!$v.changePasswordCron.maxLength">8~20자리 입력란입니다.</div>
                      </li>
                    </ul>
                  </li>
                  <li>
                      <div v-if="changeBtn === false">
                        <button class="table-in-btn" @click="changepassword">수정</button>
                      </div>
                      <div v-else>
                        <button class="table-in-btn" @click="updatePassword">수정</button>
                        <button class="table-in-btn border" @click="canclePassword"> 취소</button>
                      </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>휴대폰번호</th>
              <td>{{empMobile}}</td>
            </tr>
            <tr>
              <th class="asterisk-after">주소</th>
              <td>
                <ul class="input-style mypage">
                  <li>
                    <div v-if="inputAddress === false">
                        <li>{{empZipcode}}</li>
                        <li>{{empAdress}} {{empAdressDetail}}</li>
                    </div>
                    <div v-else>
                      <ul>
                        <li>
                          <ul class="input-style button">
                            <li>
                              <label for="zipcode" class="hide">우편번호 입력</label>
                              <input type="text" v-model="zipcode" readonly="true" placeholder="우편번호" class="place-hide" id="zipcode">
                              <button class="table-btn black" @click="zipCode">우편번호</button>
                            </li>
                            <li>
                              <label for="address" class="hide">주소 입력</label>
                              <input type="text" v-model="addr" placeholder="주소" class="place-hide" id="address">
                            </li>
                            <li>
                              <label for="detailAddress" class="hide">상세주소 입력</label>
                              <input type="text" v-model="addrDetail" ref="addrDetail" placeholder="상세주소" class="place-hide" id="detailAddress">  
                            </li>
                          </ul>
                          <div class="error" v-if="!$v.zipcode.required || !$v.addr.required || !$v.addrDetail.required">필수 입력란입니다.</div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div v-if="addressBtn === false">
                      <button class="table-in-btn" @click="changeAddress">수정</button>
                    </div>
                    <div v-else>
                      <button class="table-in-btn" @click="updateAddress">수정</button>
                      <button class="table-in-btn border" @click="cancleAddress"> 취소</button>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th class="asterisk-after">차량번호</th>
              <td>
                <ul class="input-style mypage">
                  <li>
                    {{ carNo }}
                  </li>
                </ul>     
              </td>
            </tr>
            <tr>
              <th class="asterisk-after">감면적용</th>
              <td>
                <ul class="input-style mypage">
                  <li>
                    <button class="table-in-btn" @click="submitBtn">증빙자료제출</button>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
			</article>	
		</section>

    <!-- Popup Area -->
    <change-password v-show="passwordModel">
      <template #info>
        <li>안내</li>
      </template>

      <template #content-notice>
        <p v-html="passwordMsg"></p>
      </template>

      <template #button>
        <button class="popup-btn" @click="closepassword">닫기</button>
      </template>
    </change-password>

    <carNumber v-show="carNumberModel">
      <template #info>
        <li>안내</li>
      </template>

      <template #content-notice>
        <p v-html="carnumberMsg"></p>
      </template>

      <template #button>
        <button class="popup-btn" @click="closecarNumber">닫기</button>
      </template>
    </carNumber>

		<!-- 우편 번호 -->
		<template>
			<div id="zipCodePopup" class="post-code" v-show="zipcodePopup">
				<section class="modal-manager zip-code">
					<article class="content">
						<div ref="embed"></div>
					</article>
				</section>
				<section class="dim"></section>
			</div>
		</template>

    <guidance v-show="guidanceModal">
      <template #info>
        <li>안내</li>
      </template>

      <template #content-notice>
        <p v-html="addressMsg"></p>
      </template>

      <template #button>
        <button class="popup-btn" @click="closeGuidance">닫기</button>
      </template>
    </guidance>

    <situation v-show="situationModel">
      <template #info>
        증빙자료 제출현황
      </template>

      <template #content>
        <article class="btn-list right">
          <button class="btn" @click="submissionBtn">증빙자료 제출</button>
        </article>
      <div class="table-board">
        <table class="default-table-board-content">
          <caption class="sr-only">증빙자료 제출현황</caption>
          <colgroup>
            <col style="width:30%" />
            <col style="width:40%" />
            <col style="width:15%" />
            <col style="width:15%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col">할인유형</th>
              <th scope="col">파일</th>
              <th scope="col">제출일시</th>
              <th scope="col">승인상태</th>
            </tr>
          </thead>
          <tbody> 
            <tr v-for="(item, index) in existFiles" :key="index">
              <!-- <button type="button" @click="deleteAttachFile(item.filePath, item.saveNm, item.atchFileId, item.fileExt, item.repoId)">파일 삭제</button> -->
							<td v-if="item.discountCd =='DC002'">장애인 차량(1~3급)</td>
              <td v-if="item.discountCd =='DC003'">장애인 차량(4~6급)</td>
              <td v-if="item.discountCd =='DC004'">국가유공자</td>
              <td v-if="item.discountCd =='DC005'">경차</td>
              <td v-if="item.discountCd =='DC006'">충전시설이용차량</td>
              <td v-if="item.discountCd =='DC007'">저공해</td>
              <td v-if="item.discountCd =='DC008'">모범납세자</td>
              <td v-if="item.discountCd =='DC013'">다자녀</td>
              <td v-if="item.discountCd =='DC016'">5.18민주유공자</td>
              <td v-if="item.discountCd =='DC017'">우수자원봉사자</td>
              <td v-if="item.discountCd =='DC021'">재래시장</td>
              <td v-if="item.discountCd =='DC023'">병역명문가</td>
              <td v-if="item.discountCd =='DC026'">환승차량</td>
              <td v-if="item.discountCd =='DC001'">일반</td>
              <td><span class="cursor"  @click="downloadAttachFile(item.filePath, item.saveNm, item.viewNm, item.fileExt, item.repoId)"><!--<p class="attach-file"></p>-->{{item.viewNm}}<!--({{item.fileSize | comma}})kb--></span></td>
              <td>{{item.submitDttm | ptDateFormat}}</td>
              <td v-if="item.status == 'W'">대기</td>
              <td v-if="item.status == 'Y'">승인</td>
              <td v-if="item.status == 'N'">취소</td>
            </tr>
          </tbody>
        </table>
      </div>
      </template>

      <template #button>
        <button class="table-btn" @click="closeSubmissionBtn">닫기</button>
      </template>
    </situation>

    <submission v-show="submissionModal">
      <template #info>
        <li>증빙자료 업로드</li>
      </template>
      
      <template #content>
        <table class="vertical-table">
          <caption class="sr-only">증빙자료 업로드</caption>
          <colgroup>
            <col style="width:20%" />
            <col style="width:80%" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">감면유형</th>
              <td>
                <ul class="input-style upload">
                  <li>
                    <label for="discountCd"></label>
								    <select name="discountCd" id="discountCd" v-model="discountCd">
									    <option v-for="(item, index) in discountList" :key="index" :value="{ id : item.code, text : item.codeNm }">{{item.codeNm}}</option>
								    </select>
                  </li>
                  <li class="file">
                    <input type="file" id="file" class="place-hide" ref="files" multiple @change="changeFile" @click="resetFile">
                    <label for="file" >파일선택</label>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <template #content-notice>
        <p class="upload-name" v-for="(item, index) in eFiles" :key="index" style="height: 20px; line-height: 20px;">{{ item.name }}({{item.size | bytes('MB')}})</p>
        <ul v-html="msg+submissionMsg" style="line-height: 20px;"></ul>
      </template>

      <template #button>
        <button class="popup-btn" @click="uploadBtn" style="z-index: 10000;">파일 업로드</button>
        <button class="popup-btn-border" @click="closeSubmission" style="z-index: 10000;">닫기</button>
      </template>
    </submission>
	</main>
</template>

<script>
import guidance from '../../../components/PopupType9.vue';
import carNumber from '../../../components/PopupType9.vue';

import situation from '../../../components/PopupType10.vue';
import submission from '../../../components/PopupType9.vue';
import changePassword from '../../../components/PopupType9.vue';
import { required, sameAs, helpers, minLength, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios';

const passRegex      = helpers.regex('passRegex', /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);

const instance = axios.create({
  timeout: 30000,
});

export default {
  data(){
		return{

      empNo           :  '',
      empNm           :  '',
      carNo           :  '',
      empZipcode      :  '',
      empAdress       :  '',
      empAdressDetail :  '',
      empMobile       :  '',

      prePassword     :   '',
      changePassword    :   '',
      changePasswordCron  :   '',
      
      zipcode: '',                  //기입 우편번호
		  addr: '',                     //기입 주소
		  addrDetail: '',               //기입 상세주소

      discountList : [],            //할인코드 리스트
      discountCd: {                 //선택 할인코드 default 세팅
				id: 'DC001',
				text: '일반'
			},

      inputPassword   : false,
      inputAddress    : false,
      changeBtn       : false,
      addressBtn      : false,
      carNumberBtn    : false,
      showModal       : false,
      menuUrl         : "/api/mypage",
      passwordModel   : false,
      guidanceModal   : false,
      situationModel  : false,
      submissionModal : false,
      carNumberModel  : false,
      zipcodePopup    : false,
      
      eFiles          : '',         //기입 file
      existFiles      : '',         //DB files

      submissionMsg   : '<li class="reference">첨부파일(최대용량: 20M)(*실행파일은 업로드가 불가능합니다.)</li>',

      passwordMsg     : '<span>새로운 비밀번호</span> 로 변경이 완료되었습니다.',
      carnumberMsg    : '<span>새로운 차량번호</span> 로 변경이 완료되었습니다.',
      addressMsg      : '<span>새로운 주소</span> 로 변경이 완료되었습니다.',

      fileName        : "파일을 선택하세요.",
      msg             : '<li class="reference red">선택하신 할인유형은 요금감면 대상이 아닙니다. </li>'
		}
  },

  
validations: {
		changePassword: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(20),
			passRegex: passRegex
		},
		changePasswordCron: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(20),
			sameAsPassword: sameAs('changePassword')
		},
    zipcode: {
			required,
		},
		addr: {
			required,
		},
		addrDetail: {
			required,
		},
	},
watch: {
  'discountCd.id'() {

      if(this.discountCd.id == "DC002"){
        this.msg = '<li class="reference red">심한장애(1,2,3급) 필요자료 : 복지카드, 자동차등록증 </li>';
      }else if(this.discountCd.id == "DC003"){
        this.msg = '<li class="reference red">심하지 않은 장애(4,5,6급) 필요자료 : 복지카드, 자동차등록증 </li>';
      }else if(this.discountCd.id == "DC004"){
        this.msg = '<li class="reference red">국가유공자 필요자료 : 국가유공자증, 자동차등록증</li>';
      }else if(this.discountCd.id == "DC005"){
        this.msg = '<li class="reference red">경차 필요자료 : 자동차등록증</li>';
      }else if(this.discountCd.id == "DC007"){
        this.msg = '<li class="reference red">저공해자동차 필요자료 : 스티커부착 전면사진 , 자동차등록증</li>';
      }else if(this.discountCd.id == "DC008"){
        this.msg = '<li class="reference red">모범납세자 필요자료 : 모범납세자증 , 자동차등록증</li>';
      }else if(this.discountCd.id == "DC012"){
        this.msg = '<li class="reference red">다자녀(2인 이상) 필요자료 : 가족관계증명서, 주민등록등본, 경기아이플러스카드, 화성시 맘애좋은 다자녀카드 중 택 1 및 자동차 등록증</li>';
      }else if(this.discountCd.id == "DC013"){
        this.msg = '<li class="reference red">다자녀(2인) 필요자료 : 가족관계증명서 또는 주민등록등본, 경기아이플러스카드, 자동차등록증</li>';
      }else if(this.discountCd.id == "DC016"){
        this.msg = '<li class="reference red">5·18 민주화운동 부상자 필요자료 : 5·18 민주화운동 부상증서, 자동차등록증</li>';
      }else if(this.discountCd.id == "DC017"){
        this.msg = '<li class="reference red">우수자원봉사자 필요자료 : 화성시 우수자원봉사자증</li>';
      }else if(this.discountCd.id == "DC021"){
        this.msg = '<li class="reference red">재래시장은 필요서류가 없습니다.</li>';
      }else if(this.discountCd.id == "DC023"){
        this.msg = '<li class="reference red">병역명문가 필요자료 : 병역명문가증, 주소가 기입된 신분증, 자동차등록증</li>';
      }else if(this.discountCd.id == "DC026"){
        this.msg = '<li class="reference red">환승차량 필요자료 : 재직증명서 또는 재학증명서, 자동차등록증</li>';
      }else if(this.discountCd.id == "DC029"){
        this.msg = '<li class="reference red">임산부 필요자료 : 임산부 주차증 부착 전면사진, 자동차등록증</li>';
      }else{
        this.msg = '<li class="reference red">선택하신 할인유형은 요금감면 대상이 아닙니다. </li>'
      }
      
  }
},
methods:{
    changeFile(e){
			let files = e.target.files;

			//if(file['type']==='image/jpeg' || file['type']==='image/png' || file['type']==='image/gif' ){
				//this.eFiles = files;
				this.fileName = this.eFiles.name;
			//}else{
			//	alert('PNG/JPEG/GIF 형식만 가능합니다.');
			//	this.$refs.files.value = '';
			//}

			let totalSize = 0;

			for(let i = 0 ; i < files.length ; i++){
				totalSize += files[i].size;
			}

			if(files.length > 5){
				alert('최대 5개까지 업로드할 수 있습니다.');
				this.resetFile();
				return null;
			}else if(totalSize > 20971520){
				alert('최대 20mb까지 업로드할 수 있습니다.');
				this.resetFile();
				return null;
			}

			for(let i = 0 ; i < files.length ; i++){
				this.eFiles.push(files[i]);
			}
		},

    resetFile(){
			this.$refs.files.value = '';
			this.eFiles = [];
		},

		downloadAttachFile(filePath, saveNm, viewNm, fileExt, repoId) {
			location.href = this.menuUrl + "/download.json?filePath=" + filePath + "&saveNm=" + saveNm + "&viewNm=" + viewNm + "&fileExt=" + fileExt + "&repoId=" + repoId;
		},


    //삭제 요청시 해당기능 구현해야함.
		// deleteAttachFile(filePath, saveNm, atchFileId, fileExt, repoId){
		// 	this.submitStatus = "OK";
		// 	let apiParams = new FormData();
		// 	apiParams.append("filePath", filePath);
		// 	apiParams.append("saveNm", saveNm);
		// 	apiParams.append("atchFileId", atchFileId);
		// 	apiParams.append("fileExt", fileExt);
		// 	apiParams.append("repoId", repoId);

		// 	instance.post('/api/mypage/deleteAttachFile.json', apiParams)
		// 	.then((response => {
		// 		let message = response.data.message;
		// 		alert(message);
		// 		this.initFileList();
		// 	}))
		// 	.catch(function (error) {
		// 		alert("서버와 통신중 오류가 발생하였습니다.")
		// 	})
		// },
    

    async initFileList(){
			await instance.get('/api/mypage/getFiles.json', {
				params: {
					empNo: this.empNo
				}
			})
			.then((response => {
				var data = response.data;
				this.existFiles = data.result;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})

		},

    searchedAddress(data) {
			this.zipcode = data.zonecode;
			// 도로명을 선택하는 경우
			if(data.userSelectedType == 'R'){
				this.addr = data.address;
			}else {
				this.addr = data.jibunAddress;
			}
			this.$refs.addrDetail.focus();
			this.showModal = false;
		},

    changepassword(){
      this.inputPassword = !this.inputPassword
      this.changeBtn     = !this.changeBtn
    },

    updatePassword(){
      if(!(this.$v.changePassword.$invalid && this.$v.changePasswordCron.$invalid)){
        let apiParams = new Map();
        apiParams.set("empNo",this.empNo);
        apiParams.set("prePassword",this.prePassword);
        apiParams.set("changePassword",this.changePassword);
        apiParams.set("changePasswordCron",this.changePasswordCron);

        instance.post("/api/mypage/password.json", null, {
        params : Object.fromEntries(apiParams)
        }).then((res => {
          var resData = res.data;
          if(resData.result == 'Y'){
            this.passwordModel = true
          }else{
            alert(resData.msg);
          }
        }));
      }      
    },

    canclePassword(){
      this.inputPassword = !this.inputPassword
      this.changeBtn     = !this.changeBtn
    },

    closepassword(){
      this.$store.dispatch("logout", null);
      this.$router.push("/main");
    },

    // 주소
     zipCode(){
       this.zipcodePopup = !this.zipcodePopup;
       new window.daum.Postcode({
        oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
            let extraRoadAddr = ''; // 도로명 조합형 주소 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
              extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = ' (' + extraRoadAddr + ')';
            }
            // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
            if(fullRoadAddr !== ''){
                fullRoadAddr += extraRoadAddr;
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.zipcode = data.zonecode; //5자리 새우편번호 사용
            if(data.userSelectedType == 'R'){
              this.addr = fullRoadAddr;
            }
            else{
              this.addr = data.jibunAddress;
            }
			      this.addrDetail = "";

						this.zipcodePopup = false;
						document.getElementById("zipcode").focus();
        }
      }).embed(this.$refs.embed)
     },

    changeAddress(){
      this.inputAddress  = !this.inputAddress
      this.addressBtn    = !this.addressBtn
    },

    fileSelect(e){
      this.fileName = e.target.files[0].name;
    },

    initEFiles(){
			if(this.eFiles.length == 0){
				this.eFiles = '';
			}
		},

    updateAddress(){
      //주소변경은 비번과 다르게 메인화면으로 push안함.
      console.log("updateAddress push!");
      if(!(this.$v.zipcode.$invalid && this.$v.addr.$invalid && this.$v.addrDetail.$invalid)){
        console.log("updateAddress vaild chk true!");
        let apiParams = new Map();
        apiParams.set("empNo",this.empNo);
        apiParams.set("zipCode",this.zipcode);
        apiParams.set("addr",this.addr);
        apiParams.set("addrDetail",this.addrDetail);

        instance.post("/api/mypage/address.json", null, {
        params : Object.fromEntries(apiParams)
        }).then((res => {
          var resData = res.data;
          if(resData.result == 'Y'){
            this.inputAddress  = !this.inputAddress //false
            this.addressBtn    = !this.addressBtn   //true

              this.empZipcode = this.zipcode;
              this.empAdress = this.addr;
              this.empAdressDetail = this.addrDetail;

          }else{
            alert(resData.msg);
          }
        }));
      } 
      this.guidanceModal = true
    },

    getDiscountList() {
			instance.get('/api/mypage/MypageDiscountList.json')
			.then((response => {
				var data = response.data;

				this.discountList = data.discountList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

    cancleAddress(){
      this.inputAddress  = !this.inputAddress
      this.addressBtn    = !this.addressBtn
    },

    // 차량번호
    changeCarnumber(){
      this.carNumberBtn  = !this.carNumberBtn
    },

    updateCarnumber(){
      this.carNumberBtn   = !this.carNumberBtn
      this.carNumberModel = !this.carNumberModel
    },

    cancleCarnumber(){
      this.carNumberBtn = !this.carNumberBtn
    },

    closecarNumber(){
      this.carNumberModel = false
    },
    
    //할인적용
    submitBtn(){
      this.situationModel = true
    },

    submissionBtn(){
      this.submissionModal = true
      this.situationModel = !this.situationModel
    },

    closeSubmissionBtn(){
      this.situationModel = !this.situationModel
    },
    
    closeSubmission(){
      this.submissionModal = !this.submissionModal
    },

    uploadBtn(){

      // 셀렉트박스 기본옵션 빈칸(DC001)인데 선택안했을경우
      if(this.discountCd.id == "DC001"){
        alert("감면유형이 선택되지 않았습니다.");
      }else if(this.eFiles.length == 0){
        alert("첨부된 파일이 없습니다.")
      }else{
      this.initEFiles();
      
      let apiParams = new FormData();
      if(this.eFiles !== '') {
					this.eFiles.forEach(e => {
						apiParams.append("files", e);
            apiParams.append("empNo", this.empNo);
					});
          apiParams.append("discountCd", this.discountCd.id);
				}

      instance.post('/api/mypage/updateAction.json', apiParams)
      .then((response => {
        let message = response.data.message;

        this.initFileList();

        alert(message);
        //this.$router.push('popuplist');
      }))
      .catch(function (error) {
        //console.log(error);
        alert("서버와 통신중 오류가 발생하였습니다.")
      })
      this.submissionModal = !this.submissionModal
      }      
    },

    closeGuidance(){
      this.guidanceModal = !this.guidanceModal
    }
  },

  components:{
    guidance,
    carNumber,
    situation,
    submission,
    changePassword,
  },
created(){
				
		this.empNo = this.$route.params.empNo;

    if(this.empNo == null){
      this.$router.push('mypagemain');

    }

	},
	mounted() {
    this.initFileList();
    this.getDiscountList();

    let apiParams = new Map();
    apiParams.set("empNo",this.empNo);

		instance.get("/api/mypage/view.json", {
			params : Object.fromEntries(apiParams)
		})
		.then((res => {
        var resData = res.data.result;
        
        this.empNo  = resData.empNo;
        this.empNm  = resData.empNm;
        this.carNo  = resData.empCarNo;
        this.empZipcode = resData.zipcode;
        this.empAdress = resData.address;
        this.empAdressDetail = resData.addressDetail;
        this.empMobile = resData.empMobile;

		}));

    let category = this.$route.meta.category;
		// eslint-disable-next-line no-undef
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style scoped>
  .table-board{
    margin-top : 10px;
    height : 210px;
    overflow: auto;
  }

  .default-table-board-content tbody tr td{
    white-space: nowrap;
  }
</style>
