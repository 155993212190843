<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">사업자</div>
			<ul>
				<li><router-link to="" title="선불(포인트) 조회/적용" class="active">선불(포인트) 조회/적용</router-link></li>
				<li><router-link to="" title="포인트 결제">포인트 결제</router-link></li>
				<li><router-link to="" title="포인트 사용 내역">포인트 사용 내역</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>사업자</h2>
				<h4>선불(포인트) 조회/적용</h4>
			</article>
			<article>
				<table class="lookup-table">
					<caption class="sr-only">조회 테이블</caption>
					<colgroup>
						<col style="width:40%" />
						<col style="width:60%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">사업자 [0000000000]</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<input type="text" v-model="inputText" placeholder="0000" class="place-hide">
										</li>
										<li>
											<button class="table-btn">포인트 결제</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<h3>선불(포인트) 차량번호 조회</h3>
				<table class="lookup-table">
					<caption class="sr-only">조회 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">차량 번호</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<input type="text" v-model="inputText" placeholder="0000" class="place-hide">
										</li>
										<li>
											<button class="table-btn">Test</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
				<ul>
					<li>실시간 주차요금을 조회하여 선불(포인트) 적용을 하실 수 있습니다.</li>
					<li>차량 번호 뒷자리 4자리를 입력하여 주세요. (예:<span class="orange">0000</span>)</li>
				</ul>
			</article>
			<article class="mt30">
				<table class="default-table-content">
					<caption class="sr-only">선불(포인트) 차량번호 리스트 </caption>
					<colgroup>
						<col style="width:70px" />
						<col style="width:25%" />
						<col style="width:50%" />
						<col style="width:25%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">번호</th>
							<th scope="col">차량번호</th>
							<th scope="col">주차장</th>
							<th scope="col">입차시간</th>
						</tr>
					</thead>
					<tbody> 
						<tr>
							<td scope="row">1</td>
							<td>00가0000</td>
							<td>[노외]화성시청]</td>
							<td>2021-12-12 00:00:00</td>
						</tr>
					</tbody>
				</table>	
			</article>	
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style>

</style>