<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리" class="active">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>사용자 관리</h4>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">사용자 관리 등록페이지</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">회원 아이디</th>
							<td>
								<ul class="input-style small">
									<li>
										<input type="text" placeholder="test@dhicc.com" class="place-hide" v-model="empNo">
										<div class="error" v-if="!$v.empNo.isUnique">중복된 아이디입니다.</div>
										<div class="error" v-if="!$v.empNo.email">이메일 형식이 아닙니다.</div>
										<div class="error" v-if="!$v.empNo.required">필수 입력란입니다.</div>
										<!-- <button class="table-btn">중복검색</button> -->
									</li>
								</ul>
								<p class="reference">이메일을 아이디로 사용합니다.</p>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호</th>
							<td>
								<ul class="input-style small-text">
								<li>
									<input type="password" placeholder="비밀번호" class="place-hide" v-model="password">
									<div class="error" v-if="!$v.password.required">필수 입력란입니다.</div>
									<div class="error" v-if="!$v.password.minLength">8~20자리 입력란입니다.</div>
									<div class="error" v-if="!$v.password.maxLength">8~20자리 입력란입니다.</div>
									<div class="error" v-if="!$v.password.passRegex">조건에 맞지 않습니다.</div>
								</li>
								<li>
									<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
								</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호 확인</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<input type="password" placeholder="비밀번호 확인" class="place-hide" v-model="passwordCon">
										<div class="error" v-if="!$v.passwordCon.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.sameAsPassword">일치하지 않는 비밀번호입니다.</div>
										<div class="error" v-if="!$v.passwordCon.minLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.maxLength">8~20자리 입력란입니다.</div>
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">차량번호</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<input type="text" placeholder="차량번호" class="place-hide" v-model="empCarNo">
										<div class="error" v-if="!$v.empCarNo.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.empCarNo.carNoCheck">차량번호 형식에 맞지 않습니다.</div>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list right">
				<button class="btn"  @click="$router.push('usermanagement')">목록</button>
				<button class="btn-border" @click="insertBtn">확인</button>
			</article>	
		</section>
		<!-- 빠른 작업을 위한 아이디 중복 확인 팝업 임시 제거 -->
		<!-- <section class="modal-manager">
			<article class="title">
				<ul>
					<li>아이디 중복 확인</li>
					<li>닫기</li>
				</ul>
			</article>
			<article class="content">
				<section>
					<article>
						<p class="reference">이메일을 아이디로 사용합니다.</p>
					</article>
					<article>
						<table class="vertical-table">
							<caption class="sr-only">아이디 중복 확인</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">사용자 ID</th>
									<td>
										<ul class="input-style button">
											<li>
												<input type="text" placeholder="사용자 ID" class="place-hide">
												<button class="table-btn ml10">검색하기</button>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</article>
					<article>
						<ul>
							<li><p class="default">중복확인을 실행 하십시오.</p></li>
						</ul>
					</article>
					<article class="btn-list">
						<button class="popup-btn">닫기</button>
						<button class="popup-btn-border">확인</button>
					</article>
				</section>
			</article>
		</section> -->
	</main>
</template>

<script>
import axios from 'axios';
import { required, isUnique, email, sameAs, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'

const carNoRegex     = helpers.regex('carNoRegex', /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi);
const carNoRegexDipl = helpers.regex('carNoRegexDipl', /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi); //외교차량 정규식
const passRegex      = helpers.regex('passRegex', /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi);

export default {
	data(){
		return {
			empNo: "",
			password: "",
			passwordCon: "",
			empCarNo: "",
			submitStatus: "",
			usedCnt: ""
		}
	},

	validations: {
		empNo: {
			required,
			email,
			async isUnique (value) {
				if(value === '') {
					return true;
				}
			 	await axios.get('/api/member/idDplctCnfirmAjax.json', {
			 		params: {
			 			checkId: value
			 		}
			 	})
			 	.then((response => {
					 this.usedCnt = response.data.usedCnt;
			 	}))
			 	.catch(function (error) {
			 		//console.log(error);
			 		alert("서버와 통신중 오류가 발생하였습니다.")
				 })

				if(this.usedCnt > 0) {
					return false;
				} else {
					return true;
				}
			}
		},
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(20),
			passRegex: passRegex
		},
		passwordCon: {
			required,
			sameAsPassword: sameAs('password'),
			minLength: minLength(8),
			maxLength: maxLength(20)
		},
		empCarNo: {
			required,
			carNoCheck: or(carNoRegex, carNoRegexDipl)
		}
	},

	methods: {
		insertBtn(){

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("empNo", this.empNo);
				apiParams.append("password", this.password);
				apiParams.append("passwordCon", this.passwordCon);
				apiParams.append("empCarNo", this.empCarNo);
	
				axios.post('/api/member/insertAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('usermanagement');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>