<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리" class="active">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>사용자 관리</h4>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">사용자 관리 등록페이지</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">아이디</th>
							<td>
								{{ empNo }}
							</td>
						</tr>
						<tr>
							<th scope="row">연락처</th>
							<td>
								{{ empMobile }}
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">차량번호</th>
							<td>
								<ul class="input-style small">
									<li>
										<input type="text" placeholder="차량번호" class="place-hide" v-model="empCarNo">
									</li>
								</ul>
								<div class="error" v-if="!$v.empCarNo.required">필수 입력란입니다.</div>
								<div class="error" v-if="!$v.empCarNo.carNoCheck">차량번호 형식에 맞지 않습니다.</div>
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">가입상태</th>
							<td class="select-area">
								<label for=""></label>
								<select v-model="empSttus">
									<option value="A">신청</option>
									<option value="P">승인</option>
									<!-- <option v-for="(item, index) in codeTyList" :key="index" :value="item.codeTy">{{item.nm}}</option> -->
								</select>
								<div class="error" v-if="!$v.empSttus.required">필수 입력란입니다.</div>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list right">
				<button class="btn-border" @click="updatePassword">비밀번호 변경</button>
				<button class="btn"        @click="cancelBtn">목록</button>
				<button class="btn black" @click="updateBtn">수정</button>
				<button class="btn-border" @click="deleteConfirm()">삭제</button>
			</article>	
		</section>
	
	</main>
</template>

<script>
import axios from 'axios';
import { required, isUnique, email, sameAs, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'

const carNoRegex     = helpers.regex('carNoRegex', /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi);
const carNoRegexDipl = helpers.regex('carNoRegexDipl', /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi); //외교차량 정규식
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			empEsntlId: this.$route.query.empEsntlId,
			empObj: "",
			empNo: "",
			empSttus: "",
			empCarNo: "",
			empMobile: "",
			submitStatus: "",
		}
	},

	validations: {
		empNo: {
			required
		},
		empCarNo: {
			required,
			carNoCheck: or(carNoRegex, carNoRegexDipl)
		},
		empSttus: {
			required
		}
	},

	mounted() {
		this.initView();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		initView(){
			instance.get('/api/member/view.json', {
				params: {
					empEsntlId: this.empEsntlId
				}
			})
			.then((response => {
				var data = response.data;
				this.empObj = data.resultVo;

				this.empNo = this.empObj.empNo;
				this.empCarNo = this.empObj.empCarNo;
				this.empSttus = this.empObj.empSttus;
				this.empMobile = this.empObj.empMobile;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		updateBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("empNo", this.empNo);
				apiParams.append("empCarNo", this.empCarNo);
				apiParams.append("empEsntlId", this.empEsntlId);
				apiParams.append("empSttus", this.empSttus);

				instance.post('/api/member/updateAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('usermanagement');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		cancelBtn(){
			this.$router.push('usermanagement');
			localStorage.clear();
		},

		deleteBtn() {
			let apiParams = new FormData();
			apiParams.append("empEsntlId", this.empEsntlId);

			instance.post('/api/member/mberDelete.json', apiParams)
			.then((response => {
				let message = response.data.message;
	
				alert(message);
				this.$router.push('usermanagement');
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		deleteConfirm(){
			this.$confirm(
				{
					message: `삭제하시겠습니까?`,
					button: {
						no: '아니오',
						yes: '예'
					},
					callback: confirm => {
						if (confirm) {
						this.deleteBtn();
						}
					}
				}
			)
		},

		updatePassword(){
			this.$router.push({
				path  : 'userpassupdate',
				query : { empEsntlId : this.empEsntlId,
						  empNo : this.empNo }
			});
		}
	},

}
</script>

<style>

</style>