<template>
	<footer>
		<section>
			<h1 class="logo-footer"><router-link to="Main" title="화성시 로고"><span class="hide">화성시 주차포탈</span></router-link></h1>
			<article>
				<div>
					<ul class="top">
						<li><button type="button" @click="goExternalSite('https://www.hscity.go.kr/agree/member_agree.jsp');" class="router-replace-a" title="개인정보처리방침-새창열림">개인정보처리방침</button></li>
						<li><router-link to="/emailguide" title="이메일무단수집거부">이메일무단수집거부</router-link></li>
						<li><button type="button" @click="goExternalSite('https://www.hscity.go.kr/agree/copyright_policy.jsp');" class="router-replace-a" title="저작권 정책-새창열림">저작권 정책</button></li>
						<li><router-link to="/sitemap" title="사이트 맵">사이트 맵</router-link></li>
					</ul>
				</div>
				<address>
					<ul>
						<li>화성시 오산동967-1009 동탄여울공원주차장 내 통합관제센터</li>
						<li>대표전화 : 031-8059-6538     팩스 : 031-8059-6539</li>
					</ul>
				</address>
				<p class="copyright">COPYRIGHT &copy; 2021.화성도시공사 ALL RIGHTS RESERVED</p>
			</article>
			<article>
				<label for="familySite"><span class="hide">화성시 패밀리 사이트</span></label>
				<select 
					id="familySite" 
					class="footer-select" 
					@change="goFamilySite($event)"
					@keydown.up="eventStem($event)"  
					@keydown.right="eventStem($event)"
					@keydown.down="eventStem($event)"
					@keydown.left="eventStem($event)"
					@keydown.esc="eventStem($event)"
				>
					<option value="" selected>화성시 패밀리 사이트</option>

					<option value="https://www.hscity.go.kr/">화성시청</option>
					<option value="https://www.hsuco.or.kr/main.do">화성도시공사</option>
					<option value="http://ebook.hscity.go.kr">E-BOOK</option>
					<option value="http://klis.gg.go.kr/sis/main.do">개별공시지가열람</option>
					<option value="http://www.familynet.or.kr/fn_areacenter/main/index.do?ci_domain=hsfc">건강가정지원센터</option>
					<option value="http://contract.hscity.go.kr">계약정보공개시스템</option>
					<option value="http://hscity.giupsos.or.kr">기업SOS넷</option>

					<option value="https://www.hssenior.or.kr/index/">남부노인복지관</option>

					<option value="http://www.liveinkorea.kr/center/main/main.do?centerId=hwaseongsi">다문화가족지원센터</option>
					<option value="http://waste.hscity.go.kr">대형폐기물</option>
					<option value="http://www.dongtan1.co.kr/cms/main.do">동탄1동 주민자치센터</option>
					<option value="http://www.동탄2동문화센터.com/cms/main.do">동탄2동 주민자치센터</option>
					<option value="http://www.dongtan3.or.kr/cms/main.do">동탄3동 주민자치센터</option>
					<option value="http://www.dongtan4.or.kr/main/index.do">동탄4동 주민자치센터</option>
					<option value="http://hscity.dreamstart.go.kr/">드림스타트센터</option>

					<option value="http://chronic.hscity.go.kr">만성질환관리센터</option>
					<option value="http://modunurim.hswf.or.kr/">모두누림센터</option>

					<option value="http://www.hb2.or.kr/">병점2동 주민자치센터</option>
					<option value="http://www.hsicare.or.kr/">보육정보센터</option>
					<option value="http://www.narewul.or.kr/">복합복지타운 나래울</option>
					<option value="http://rtms.hscity.go.kr/rtmsweb/home.do">부동산 거래관리시스템</option>

					<option value="http://www.hssesc.or.kr/">사회적 경제지원센터</option>
					<option value="http://sc.hscity.go.kr/">사회적공동체</option>
					<option value="http://waste.hscity.go.kr/User/part_waste/list.jsp">수거차량 위치추적 시스템</option>
					<option value="http://water.hscity.go.kr/ncoe/index.do">수도요금 납부 시스템</option>
					<option value="http://tongli.hscity.go.kr/">스마트 통리장넷</option>
					<option value="https://hscitytour.co.kr/main/main.php">시티투어 하루</option>

					<option value="https://www.hs-ecocenter.or.kr/">에코센터</option>
					<option value="http://womanjob.hswf.or.kr/">여성 새로일하기센터</option>
					<option value="http://www.prism.go.kr/">온나라 정책연구</option>
					<option value="http://www.passport.go.kr/index.php">외교통상부 여권안내</option>
					<option value="http://hsbluebird.co.kr/">외국인복지센터</option>
					<option value="http://botanic.hscity.go.kr/">우리꽃식물원</option>
					<option value="http://www.hstree.org/">인재육성재단</option>

					<option value="http://www.hsvolunteer.or.kr/">자원봉사센터</option>
					<option value="http://jeam.or.kr/">제암리 3.1운동 순국기념관</option>
					<option value="http://money.hscity.go.kr/">주민참여 예산방</option>
					<option value="https://parkingsms.hscity.go.kr/">주정차단속 문자알림서비스</option>
					<option value="https://parking.hscity.go.kr">주정차위반 민원서비스</option>
					<option value="http://www.hsag21.or.kr/">지속가능발전협의회</option>

					<option value="http://www.hsccie.com/">창의지성 교육지원센터</option>

					<option value="http://kras.gg.go.kr/land_info/info/baseInfo/baseInfo.do">토지이용계획확인원</option>
					<option value="http://photo.hscity.go.kr/photo">포토갤러리</option>
					<option value="http://www.hsfoodcenter.or.kr/gnu/">푸드통합지원센터</option>

					<option value="http://sports.ibk.co.kr/sports/index.ibk">화성 IBK기업은행 배구단</option>
					<option value="http://data.hscity.go.kr/">화성 데이터 로</option>
					<option value="http://www.hscc.or.kr">화성 문화원</option>
					<option value="https://hipa.hscity.go.kr">화성 산업진흥원</option>
					<option value="http://www.hscitylib.or.kr">화성 시립도서관</option>
					<option value="http://ebook.hscitylib.or.kr">화성 시립전자도서관</option>

					<option value="http://www.hcf.or.kr/">화성시 문화재단</option>

					<option value="http://www.hscity.go.kr/hsedu">화성시 시민정보화교육</option>
					<option value="https://ccfsm.foodnara.go.kr/hwaseong/">화성시 어린이급식지원센터</option>
					<option value="http://www.hswf.or.kr/">화성시 여성가족청소년재단</option>
					<option value="http://hsmuseum.hscity.go.kr">화성시 역사박물관</option>
					<option value="http://www.hsicare.or.kr">화성시 육아종합지원센터</option>
					<option value="http://council.hscity.go.kr">화성시의회</option>
					
					<option value="https://tour.hscity.go.kr/Water/">화성시 저수조 관리시스템</option>
					<option value="https://parking.hscity.go.kr/index.jsp">화성시 주정차 민원서비스</option>

					<option value="https://www.mars1318.or.kr">화성시 청소년상담복지센터</option>
					<option value="http://sports.hscity.go.kr">화성시 체육시설 예약시스템</option>
					<option value="http://hssports.hscity.go.kr/">화성시 체육회</option>
					<option value="http://u-life.hscity.go.kr">화성시 평생학습관</option>
					<option value="http://www.hsef.or.kr">화성시 환경재단</option>

					<option value="http://www.ihbs.go.kr">화성에서 온 TV</option>

				</select>
			</article>
		</section>
	</footer>
</template>

<script>
export default {
	methods : {

		// 외부사이트 오픈
		goExternalSite(url){
			window.open(url, "_blank");
		},

		eventStem(e){
			switch(e.keyCode) {
				case 37:
					e.preventDefault();
					break;
				case 38:
					e.preventDefault();
					break;
				case 39:
					e.preventDefault();
					break;
				case 40:
					e.preventDefault();
					break;	
				case 27:
					e.preventDefault();
					break;
			}
		},

		goFamilySite(e){
			if(e.target.value != "" && e.target.value != null){
				this.goExternalSite(e.target.value);
			}
		},
	}
}
</script>

<style scoped>
.router-replace-a:hover{
	cursor: pointer;
}
</style>