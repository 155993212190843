<template>
	<div>
		<!-- Mobile Area -->
		<header id="mobile">
			<section class="utill">
				<article @click="menubtn" class="mo-top-menu"><span class="hide">메뉴</span></article>
				<article class="mo-logo-top"><router-link to="Main"><span class="hide">화성시 주차포탈</span></router-link></article>
				<article v-if="isLogin">
					<article  class="mo-icon-profile" @click="myPageMove">
						<div class="hide">회원정보</div>
					</article>
					<article  class="logout on mo-icon-logout" @click="actionLogout">
						<div class="hide">로그아웃</div>
					</article> 
				</article>
				<article v-else class="login on mo-icon-login">
					<router-link to="/loginmain">
						<span class="hide">로그인</span>
					</router-link>
				</article>
			</section>
			<gnb-mobile v-show="btnmenu" @closeResult = "closeResult"></gnb-mobile>
		</header>
		
		<!-- Web Area -->
		<header id="web">
			<section class="clear">
			<article class="accessibility">
				<a href="#mainPage" title="본문바로가기"  v-if="$route.name === 'main'" @keydown.enter="shortCuts">본문바로가기</a>
				<a href="#content" title="본문바로가기" id="onCuts" @keydown.enter="shortCuts" v-else>본문바로가기</a>
			</article>
				<article>
					<h1 class="logo-top"><router-link to="Main"><span class="hide">화성시 로고</span></router-link></h1>
					<h2 class="hide">상단메뉴</h2>
					<nav class="utill">
						<!-- Logout -->
						<ul v-if="isLogin" class="logout on">
							<!-- if (on)-->
							<li class="welcome">"<span>{{ empNm }}</span>" 님 환영합니다.</li>
							<template v-if="authorCode === 'ROLE_ADMIN'">
								<li class="separation-btn on"><router-link to="/freevehicle" title="관리자">관리자</router-link></li>
								<!-- else -->
								<li class="separation-btn"><router-link to="/prepaymentpaper" title="사업자">사업자</router-link></li>
								<li class="system-btn on"><router-link to="/usermanagement" title="시스템 관리">시스템관리</router-link></li>
							</template>
							<!-- <li><router-link to="/loginmain" title="로그아웃">로그아웃</router-link></li> -->
							<li class="profile"><router-link to="/mypagemain" title="회원정보">회원정보</router-link></li>
							<li class="logout" @click="actionLogout"><router-link to="/loginmain" title="로그아웃">로그아웃</router-link></li>
						</ul>

						<!-- Login -->
						<ul v-else class="login on">
							<li class="log"><router-link to="/loginmain"               title="로그인">로그인</router-link></li>
							<li class="join"><router-link to="/membershipcertification" title="회원가입" @keydown.enter="leave">회원가입</router-link></li>
						</ul>
					</nav>
				</article>
			</section>
			<div class="line">
				<section>
					<article>
						<h2 class="hide">상단 메뉴리스트</h2>
						<nav class="header" @mouseover="over" @mouseleave="leave" @keydown.tab ="clickTab" @click="leave" @keydown.esc="leave">
							<ul class="gnb">
								<li>
									<router-link to="introduce" title="공영주차장">공영주차장</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li @keyup.shift="leave"><router-link to="introduce" title="주차장소개" >주차장소개</router-link></li>
												<li><router-link to="map"             title="지도검색">지도검색</router-link></li>
												<li><router-link to="list"            title="주차장검색">주차장검색</router-link></li>
												<li><router-link to="/feeinformation" title="주차장 요금안내">주차장 요금안내</router-link></li>
												<li><router-link to="/realtimeparking" title="실시간 주차정보">실시간 주차정보</router-link></li>
											</ul>
										</div>
									</article>
								</li>
								<li>
									<router-link to="prepaymentinfo" title="주차요금 사전결제">주차요금 사전결제</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li><router-link to="prepaymentinfo" title="사전결제 안내">안내</router-link></li>
												<li><router-link to="lookup"         title="사전결제 조회결제">조회/결제</router-link></li>
											</ul>
										</div>
									</article>
								</li>
								<li>
									<router-link to="unpaidinfo" title="미납주차요금">미납주차요금</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li><router-link to="unpaidinfo"   title="미납주차요금 안내" class="active">안내</router-link></li>
												<li><router-link to="lookupunpaid" title="미납주차요금 조회/결제">조회/결제</router-link></li>
											</ul>
										</div>
									</article>
								</li>
								<li>
									<router-link to="reductioninfo" title="비대면 자격확인">비대면 자격확인</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li><router-link to="reductioninfo"      title="비대면 안내">안내</router-link></li>
												<li><router-link to="reductionagreement" title="비대면 자격확인">비대면 자격확인</router-link></li>
											</ul>
										</div>
									</article>
								</li>
								<li>
									<router-link to="residentinfo" title="거주자 우선 주차장">거주자 우선 주차장</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li><router-link to="residentinfo"      title="거주자 우선 주차장 안내">안내</router-link></li>
												<li><router-link to="residentsearch"    title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
												<li><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
												<li><router-link to="residentlookup"    title="거주자 우선 주차장 조회/결제">조회/결제</router-link></li>
											</ul>
										</div>
									</article>
								</li>
								<li>
									<router-link to="periodinfo" title="공영주차장 정기권">공영주차장 정기권</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li><router-link to="periodinfo"      title=" 공영주차장 정기권 안내">안내</router-link></li>
												<li><router-link to="periodagreement" title="정기권 신청">정기권 신청</router-link></li>
												<li><router-link to="periodlookup"    title="조회/결제">조회/결제</router-link></li>
											</ul>
										</div>
									</article>
								</li>
								<li>
									<router-link to="nonstopinfo" title="무정차 신청">무정차 신청</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li><router-link to="nonstopinfo" title="무정차 신청">무정차 신청</router-link></li>
											</ul>
										</div>
									</article>
								</li>
								<li>
									<router-link to="noticelist" title="열린공간">열린공간</router-link>
									<article v-show="active">
										<div>
											<ul>
												<li><router-link to="noticelist"    title="공지사항">공지사항</router-link></li>
												<li><router-link to="questionslist" title="자주하는 질문">자주하는 질문</router-link></li>
												<li @keyup.tab="leave" @keypress="leave"><a href=""><span class="hide">hide</span></a></li>
											</ul>
										</div>									
									</article>
								</li>
							</ul>
							<div class="bg-cnt" v-show="active" @keyup.tab ="leave"></div>
						</nav> 
					</article>
				</section>
			</div>
		</header>
	</div>
</template>

<script>
import axios     from 'axios';
import GnbMobile from './GnbMobile.vue';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			active  : false,
			btnmenu : false,
			main    : true
		}
	},
	mounted(){
		document.getElementById("onCuts").focus();
	},
	methods: {
		over(){
			this.active = true;
		},

		leave(){
			this.active = false;
		},

		menubtn(){
			this.btnmenu = !this.btnmenu;
		},

		closeResult(){
			this.btnmenu = false;
		},

		actionLogout(){
			instance.post('/api/actionLogout.json')

			.then((response => {
				const data = response.data;
				// if(data.result){
				// 	this.$store.dispatch("logout", null);
				// 	alert(data.msg);
				// }
				alert("로그아웃 하였습니다.");

				this.$store.dispatch("logout", null);
				this.$router.push("/loginmain");
			}))
			.catch(function (error) {	
				//console.log(error);
			})
		},

		myPageMove(){
			this.$router.push("/mypagemain");
		},

		clickTab(e){
			if(e.code === "Tab"){
				if(e.shiftKey){	
					this.active = false;
					console.log("1");
				}else{
					this.active = true;
					console.log("2");
				}
			}
		},

		leaveTab(){
			this.active = false;
		},

		shortCuts(){
			this.active = false;
		}
	},

	computed: {
		isLogin(){
			return this.$store.state.isLogin;
		},

		authorCode(){
			return this.$store.state.authorCode;
		},

		empNm(){
			return this.$store.state.empNm;
		}
	},

	components:{
		GnbMobile
	},
}
</script>

<style>

</style>