<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차 정기권</div>
			<ul>
				<li><router-link to="periodinfo" title="정기권신청 안내">안내</router-link></li>
				<li><router-link to="periodagreement" title="정기권 신청">정기권 신청</router-link></li>
				<li><router-link to="periodlookup" title="정기권 조회 &#47; 결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 정기권</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>정기권 결제</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">정기권 결제내역 확인</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">차량번호</th>
						<td>{{ r.carNo }}</td>
					</tr>
					<tr>
						<th>신청자</th>
						<td>{{ r.waitingNm }}</td>
					</tr>
					<tr>
						<th>차량소유주</th>
						<td>{{ r.carOwnerNm }}</td>
					</tr>
					<!--<tr>
						<th>주소</th>
						<td>{{ r.zipcode }} {{ r.addr }} {{ r.addrDetail }}</td>
					</tr> -->
					<tr>
						<th>연락처</th>
						<td>{{ r.mobile | phoneFormat }}</td>
					</tr>
					<tr>
						<th>신청주차장</th>
						<td>
							<span class="emphasis-view">{{ r.parkingDivCdNm }}</span>
							{{ r.parkingNm }}
						</td>
					</tr>
					<tr>
						<th>할인</th>
						<td>{{ discountCd.discountNm }}</td>
					</tr>
					<tr>
						<th>할증</th>
						<td>{{ extraCd.discountNm }}</td>
					</tr>
					<tr>
						<th>이용기간</th>
						<td v-if="r.useStartDt != '' && r.useEndDt != ''">
							{{ r.useStartDt | dateFormat }} ~ {{ r.useEndDt | dateFormat }}
						</td>
						<td v-else>이용기간이 존재하지 않습니다. 관리자에게 문의해주세요.</td>
					</tr>
					<tr>
						<th>납부기간</th>
						<td v-if="r.billDt != '' && r.limitDt != ''">
							{{ r.billDt | dateFormat }} ~ {{ r.limitDt | dateFormat }}
						</td>
						<td v-else>납부기간이 존재하지 않습니다. 관리자에게 문의해주세요.</td>
					</tr>
					<tr>
						<th>월 정기권 금액</th>
						<td>
							<span class="emphasis-view">{{ useDivCd.name }}</span>
							{{ r.useAmt | comma }} 원
						</td>
					</tr>
				</tbody>
			</table>

			<article>
			</article>
			<table class="default-table-view" v-if="!isMobile">
				<caption class="sr-only">정기권 상세보기 테이블</caption>
				<colgroup>
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td colspan="3">{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>
			<table class="default-table-view" v-else>
				<caption class="sr-only">정기권 결제 상태 테이블</caption>
				<colgroup>
					<col style="width:30%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
					</tr>
					<tr>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat}}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td>{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>

			<article class="message">
				<p v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">결제가 완료 되었습니다.</p>
			</article>

			<article class="btn-list">
				<button class="one-btn" @click="cancelBtn(carNo)">완료</button>
			</article>
		</section>
	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return{
			fResCd     : '',
			resCd      : '',
			iNervPayId : '',
			carNo      : '',
			pgCno      : '',
			approvalNo : '',
			payResult  : '',
			r          : '',
			discountCd : '',
			extraCd    : '',
			useDivCd   : '',
			procStsCd  : '',
			isMobile   : this.$isMobile()
		}
	},
	filters : {
		comma : function(value){
			if(value != null){
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
		dateFormat : function(x){
			if(x != null){
				var d = x.toString().replace(/[^0-9]/g, '');

				var year  = d.toString().substr(0, 4);
				var month = d.toString().substr(4, 2);
				var day   = d.toString().substr(6, 2);

				return year + "-" + month + "-" + day;
			}
		},
		// 전화번호 포맷팅
		phoneFormat : function(x){
			if(x != null){
				var p = x.toString().replace(/[^0-9]/g, '');

				let tmp = "";

				if(p.length < 4){

					return p;

				}else if(p.length < 7){

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3);
					return tmp;

				}else if(p.length == 8){

					tmp += p.substr(0, 4);
					tmp += "-";
					tmp += p.substr(4);
					return tmp;

				}else if(p.length < 10){

					tmp += p.substr(0, 2);
					tmp += "-";
					tmp += p.substr(2, 3);
					tmp += "-";
					tmp += p.substr(5);
					return tmp;

				}else if(p.length < 11){

					if(p.substr(0, 2) == "02"){

						tmp += p.substr(0, 2);
						tmp += "-";
						tmp += p.substr(2, 4);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}else{

						tmp += p.substr(0, 3);
						tmp += "-";
						tmp += p.substr(3, 3);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}
					

				}else{

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3, 4);
					tmp += "-";
					tmp += p.substr(7);
					return tmp;

				}

			}
		}
	},
	created(){
		var data = this.$route.query;
		this.fResCd     = data.a;
		this.resCd      = data.b;
		this.iNervPayId = data.c;
		this.carNo      = data.d;
		this.pgCno      = data.e;
		this.approvalNo = data.f;
	},
	mounted(){

		let apiParams = new Map();
		apiParams.set("fResCd"    , this.fResCd);
		apiParams.set("resCd"     , this.resCd);
		apiParams.set("iNervPayId", this.iNervPayId);
		apiParams.set("carNo"     , this.carNo);
		apiParams.set("pgCno"     , this.pgCno);
		apiParams.set("approvalNo", this.approvalNo);

		instance.get("/api/period/result.json", {
			params : Object.fromEntries(apiParams)
		})
		.then((res => {

			this.payResult  = res.data.payResult;
			this.r          = res.data.period.result;
			this.discountCd = this.r.discountCd;
			this.extraCd    = this.r.extraCd;
			this.useDivCd   = this.r.useDivCd;
			this.procStsCd  = this.r.procStsCd;

		}));
		
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		cancelBtn(carNo){
			this.$router.push({
				name  : 'periodlookup',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},
	},
}

</script>

<style>

</style>