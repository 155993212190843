<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">주차요금 사전결제</div>
			<ul>
				<li><router-link to="prepaymentinfo" title="안내" >안내</router-link></li>
				<li><router-link to="lookup" title="조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>주차요금 사전결제</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>차량번호 조회</h3>
				<p>
					차량번호를 <span class="bold">조회 &#47; 사전결제</span> 하실 수 있습니다.
					<span class="bold">전체 차량번호</span>를 입력하여 주세요.(예:<span class="orange">00가0000</span>)
				</p>
				<table class="lookup-table">
					<caption class="sr-only">차량번호 조회 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">차량 번호</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<label for="carNumber" class="hide">차량번호 입력 예)00가0000</label>
											<input type="text" ref="car" v-model="carNo" placeholder="00가0000" class="place-hide" id="carNumber">
										</li>
										<li>
											<button class="table-btn" @click="searchCarNo(carNo)">조회</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
				<p class="orange">결제 후 10분 안에 출차를 하지 않으면 주차요금이 추가 발생, 추가요금은 출차 시 추가 결제</p>
			</article>
			<article>
				<h3>차량번호 선택</h3>
				<p>
					자신의 차량번호를 선택하여 주세요.<span class="orange">타인의 차량 결제 시 환불이 되지 않으니 확인하여 주십시오.</span>	
				</p>
				<table class="default-table">
					<caption class="sr-only">차량번호 상세내역</caption>
					<colgroup>
						<col style="width:25%" />
						<col style="width:25%" />
						<col style="width:25%" />
						<col style="width:25%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">차량번호</th>
							<th scope="col">주차장</th>
							<th scope="col">입차시간</th>
							<th scope="col">상세내역</th>
						</tr>
					</thead>
					<tbody>
						<tr class="nodate" v-if="nodata">
							<td colspan="4">조회된 데이터가 없습니다.</td>
						</tr>
						<tr v-for="(item, index) in item" :key="index" @click="prepayView(item)" >
							<td>
								<a href="#" @keyup.enter="prepayView(item)" title="차량번호">
									{{ item.carNo }}
								</a>
							</td>
							<td>
								<span class="emphasis-view">{{ item.divNm }}</span>
								{{ item.parkingNm }}
							</td>
							<td>{{ item.inDttm }}</td>
							<td v-if="item.outDttm == null">
								<span class="green bold">사전결제 가능</span>
							</td>
							<td v-else>
								<span class="red bold">사전결제 완료</span>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
		</section>
		<process-dimmed v-if="show"></process-dimmed>
	</main>
</template>

<script>

import axios from 'axios';
import ProcessDimmed from '../../../components/ProcessDimmed.vue';

const instance = axios.create({
  timeout: 30000,
});

export default {

	components: {
		ProcessDimmed
	},

	data(){
		return{
			carNo  : "",
			item   : {},
			nodata : true,
			show   : false
		}
	},
	mounted(){

		this.carNo = this.$route.query.carNo;

		if(this.carNo != null && this.carNo != ""){
			this.searchCarNo(this.carNo);
		}
		
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		onSubmit(e){
			e.preventDefault();
		},
		searchCarNo: function(carNo){
			
			var jsonList = [];

			this.carNo   = this.carNo.replace(/(\s*)/g, "");

			var carNoRegex     = /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi;
			var carNoRegexDipl = /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi; //외교차량 정규식

			if(!carNoRegex.test(this.carNo) && !carNoRegexDipl.test(this.carNo)){

				alert("* 차량번호 형식에 맞지 않습니다. 예:00가0000");
				this.$refs.car.focus();

			}else{
				this.show = true;
				instance.get('/api/prepay/searchPrepayList.json', {
					params: {
						carNo : this.carNo
					}
				})
				.then((response => {
					this.show = false;

					var data = response.data.value.result;

					if(data == 'undefined' || data == null){

						this.item   = null;
						this.nodata = true;

					}else{

						for(var i = 0 ; i < data.length ; i++){
							
							if(data[i].inTy != "PERIOD"){
								jsonList.push(data[i]);
							}
						}
						
						if(jsonList.length > 0){
							this.item   = jsonList;
							this.nodata = false;

						}else{
							
							this.item   = null;
							this.nodata = true;
						}
					}
				}))
				.catch(function (err) {
					//console.dir(err);
					this.show = false;
					alert("서버와 통신중 오류가 발생하였습니다.");
				})
			}
		},
		// 사전결제 상세보기
		prepayView: function(item){
			
			var params = {
				inOutId    : item.inOutId,
				sectnId    : item.sectnId,
				carNo      : item.carNo,
				inDttm     : item.inDttm,
				discountCd : item.discountCd.discountCd,
				extraCd    : item.extraCd.discountCd
			}

			this.$router.push({
				name   : 'paymentview',
				params : { data : params }
			});

		}
	}
}
</script>

<style>

</style>