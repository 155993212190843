<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">무정차 신청</div>
			<ul>
				<li><router-link to="/nonstopinfo" title="무정차 신청" class="active">무정차 신청</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>무정차 신청</h2>
				<h4>신청 안내</h4>
			</article>
			<article>
				<div id="tabs">
					<ul class="tab-menu-nonstop">
						<li class="active">신청 안내</li>
						<li>서비스 신청</li>
						<li>결제카드 등록</li>
						<li>차량 등록</li>
					</ul>
					<div class="tab-content">

						<!-- 1. 신청 안내 -->
						<article>
							<ul id="title-next">
								<li><h3>신청자격</h3></li>
								<!--
								<li>
									<button class="one-btn-border" @click="showModal=true">
										<span>동영상</span> 메뉴얼 보기
									</button>
								</li> 
								-->
							</ul>
							<ul>
								<li class="hyphen">주차장 이용고객 누구나 신청가능(이용자의 주소지와 무관)</li>
								<!--<li class="hyphen">사업자(법인, 개인)는 회원가입 및 로그인 후 해당 <span class="orange">차량번호</span> 등을 입력하여 신청가능</li>-->
							</ul>
						</article>
						<article>
							<h3>이용기간</h3>
							<ul>
								<li class="hyphen">이용고객 해지시까지</li>
							</ul>
						</article>
						<article>
							<h3>신청절차</h3>
							<ul>
								<li class="hyphen">회원가입 및 로그인  &#9655; 무정차 신청  &#9655; 신청안내 및 서비스 신청  &#9655; 결제카드 등록  &#9655; 차량번호 등록  &#9655; 완료</li>
							</ul>
						</article>
						<article>
							<h3>이용제한</h3>
							<ul>
								<li class="hyphen">신용카드 미소유자</li>
								<li class="hyphen">공영주차장 주차요금 체납 차량</li>
								<!--<li class="hyphen">65세 이상 자가운전자(즉시 감면서비스 대상이 아니므로 자동감면 및 확인 불가)</li>-->
							</ul>
						</article>
						<article>
							<h3>유의사항</h3>
							<ul>
								<li class="hyphen">
									선결제 카드 등록은 1개만 가능하며, 결제카드 변경시 카드결제 등록 창에서 삭제 및 변경등록 가능
								</li>
								<li class="hyphen">
									등록된 신용카드의 오류 (유효기한, 잔액부족 등)에 따른 미정산 내역이 있는 경우 무정차 정산 시스템 이용정지
								</li>
								<li class="hyphen">미납요금 수납 완료시 자동으로 무정차정산 정상 운행</li>
							</ul>
							<ul id="reference">
								<li class="reference">
									행정안전부 감면정책: 주차장 이용시 장애인, 경차, 저공해, 국가유공자의 경우 증빙서류 없이 즉시감면 가능
								</li>
								<li class="reference-pc">
									화성시감면정책: 모범납세자, 성실납세자, 명예시민, 우수자원봉사자의 경우 화성시 관련부서에서 즉시감면 서비스를 등록한 경우 증빙서류 없이 즉시감면 가능
								</li>
								<li class="reference-mo">
									화성시감면정책: 모범납세자, 성실납세자, 명예시민, 우수자원봉사자의 경우 화성시 관련부서에서 즉시감면 서비스를 등록한 경우 증빙서류 없이 즉시감면 가능
								</li>
							</ul>
							<p class="reference orange">
								해당자가 즉시감면 서비스 미등록으로 인해 감면 받지 못한 주차요금은 환불되지 않으며, 화성시 즉시감면 서비스 신청 후 무정차 정산 서비스를 신청하여 주시기 바랍니다.
							</p>
						</article>
						<article class="btn-list">
							<button class="btn" @click="goNext()" title="페이지 이동">서비스 신청</button>
							<button class="btn-border" @click="nonstopMapAll();">전체 지도 검색</button>
						</article>
						<article class="nonstop-pc">
							<div class="flex">
								<h3>무정차 정산 가능 주차장</h3> <a href="/feeinformation" class="table-in-btn black reference"> 요금정보 확인하기</a>
							</div>
							<label for="hiddenText" class="hide">무정산 가능 주차장</label>
							<input type="text" id="hiddenText" ref="focusItem2">						
							<div class="overflow">
								<table class="default-table-board-content">
									<caption class="sr-only">무정차 정산 가능 주차장</caption>
									<colgroup>
										<col style="width:70%" />
										<col style="width:30%" />
										<!--
										<col style="width:30%" />
										<col style="width:30%" />
										-->
									</colgroup>
									<thead>
										<tr>
											<th scope="col">주차장명</th>
											<th scope="col">주차장 위치</th>
										</tr>
									</thead>
									<tbody>
										<tr class="nodate" v-if="nodata">
											<td colspan="4">조회된 데이터가 없습니다.</td>
										</tr>
										<tr v-for="(item, index) in parkingList" :key="index">
											<td>{{ item.parkingNm }}</td>
											<!-- 
											<td>{{ item.parkingAmtNm }}</td>
											<td>{{ item.parkingUseTmNm }}</td>
											-->
											<td>
												<button :id="'parking'+ index" class="table-in-btn black" @click="nonstopMap(item)" @keyup="popupFocus($event)">주차장 위치</button>
											</td>
										</tr>
									</tbody>	
								</table>
							</div>
						</article>
						
						<article class="nonstop-mo">
							<div class="flex">
								<h3>무정차 정산 가능 주차장</h3> <a href="/feeinformation" class="table-in-btn black reference"> 요금정보 확인하기</a>
							</div>
							<div class="overflow">
								<table class="default-table-board-content">
									<caption class="sr-only">무정차 정산 가능 주차장</caption>
									<colgroup>
										<col style="width:30%" />
										<col style="width:70%" />
									</colgroup>
									<tbody>
										<tr class="nodate" v-if="nodata">
											<td colspan="2">조회된 데이터가 없습니다.</td>
										</tr>
										<tr v-for="(item, index) in parkingList" :key="index">
											<th scope="row">{{ item.parkingNm }}</th>
											<td>
												<!-- 
												<p>{{ item.parkingAmtNm }}</p>
												<p>{{ item.parkingUseTmNm }}</p> 
												-->
												<button class="table-in-btn black" type="button"  @click="nonstopMap(item)" >주차장 위치</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</article>
					</div>
				</div>
			</article>
		</section>
		
		<!-- 사용자 메뉴얼 -->
		<!--
		<pop-up v-if="showModal" @cancel="showModal=!showModal" class="videos" tabindex="1">
			<template #info>
				<li>무정차 정산 신청 메뉴얼</li>
			</template>
			<template #content>
				<p v-html="msg"></p>
			</template>
			<template #button>
				<button class="popup-btn" @click="showModal=!showModal">확인</button>
			</template>
		</pop-up> 
		-->

		<!-- 모달 지도 -->
		<template>
			<div v-show="mapModal" class="kakaoMap" id="kakao">
				<section class="modal-kakaoMap">
					<article class="title" >
						<ul>
							<li id="modalTit" tabindex="5000">주차장 위치<span class="hide">팝업창 (스페이스 키를 누르면 닫기 버튼으로 이동이 가능합니다. )</span></li>
							<li id="modalMap" class="popup-close" @click="mapController" @keyup.enter="mapController" tabindex="5000">
								<span class="hide">닫기 아이콘 (엔터키를 누르시면 팝업창이 닫아집니다.)</span>
							</li>
						</ul>
					</article>
					<article class="content parking-inner">
						<div class="content-inner" id="container">

							<!-- Map 화면 들어갈 곳 -->
							<div id="mapWrapper">
								<div id="map" class="mapWH100"></div>
							</div>

						</div>
					</article>
				</section>
				<section class="dim"></section>
			</div>
		</template>

	</main>
</template>

<script>
import axios from 'axios';
const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return {
			showModal   : false,
			msg         : '',
			nodata      : false,
			parkingList : [],
			mapModal    : false,

			overlayOn             : false,
			container             : null,
			map                   : null,
			userLat               : 37.19952770562244,
			userLng               : 126.83145146431667,
			parkingNm             : '',
			parkingAmt            : '',
			parkingAmtNm          : '',
			parkingUseTm          : '',
			parkingUseTmNm        : '',
			mapContainer          : null,

			imageSrc              : null,
			imageSize             : null,
			imageOption           : null,
			clickImageOption      : null,
			marker                : null,
			active                : false,
			parkingName           : null,
			defaultParkingMapList : [],	// 주차장저장리스트
			markers               : [],	// 마커최소 저장리스트
			positions             : [],
			customOverlay         : [],
			mapGeocoder           : null,
			clickCustomOverlay    : [],
			clickStatus           : -1,	// 1 누른상태 , -1 취소상태
			cMarkerIndex          : -1,
			geoLocationState      : -1,
			userCustomOverlay     : null,
			userLocCustomOverlay  : null,
			distance              : "",
			message               : "<div style='padding:5px;'>화성시청</div>",
			park                  : "",
			def                   : "",
			defFlag               : false,
			ckImg                 : null,
		}
	},

	mounted(){
		this.selectParkingList();

		(window.kakao && window.kakao.maps) ? this.initMap() : this.createScript();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		const layer = document.querySelector("#kakao"),
		layerArrow = layer.querySelectorAll("li#modalMap, li#modalTit"),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = evt => {
			if (evt.shiftKey && evt.key === "Tab") {
					evt.preventDefault();
					layerLast.focus();
			}
		};

		const lastKeydown = evt => {
				if (!evt.shiftKey && evt.key === "Tab") {
						evt.preventDefault();
						layerFirst.focus();
				}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);
	},
	methods : {
		// 무정차 주차장 목록 조회
		selectParkingList(){
			instance.get("/api/nonstop/selectNonstopParkingList.json")
			.then(res => {
				var resData      = res.data.result.result;
				this.parkingList = resData;
			})
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});
		},

		mapClickReset(){
			// 만약 클릭한 상태라면, 기존의 오버레이를 지운다.
			if(this.clickStatus == 1){

				for(var i = 0 ; i < this.positions.length ; i++){
					this.customOverlay[i].setMap(null);
				}

			}

			this.map.relayout();
		},

		// 무정차 전체 주차장 [지도보기] 클릭
		nonstopMapAll(){
			this.mapModal = true;

			this.mapClickReset();

			this.positions = [];

			for(var i = 0 ; i < this.parkingList.length ; i++){
				var parkingLot = {};
				parkingLot.title  = this.parkingList[i].parkingNm;
				parkingLot.amt    = this.parkingList[i].parkingAmt;
				parkingLot.amtNm  = this.parkingList[i].parkingAmtNm;
				parkingLot.tm     = this.parkingList[i].parkingUseTm;
				parkingLot.tmNm   = this.parkingList[i].parkingUseTmNm;
				parkingLot.latlng = new kakao.maps.LatLng(this.parkingList[i].lat, this.parkingList[i].lng);

				this.positions.push(parkingLot);
			}

			// 화성 시청
			this.userLat = 37.19952770562244;
			this.userLng = 126.83145146431667;

			// HTML5의 geolocation으로 사용할 수 있는지 확인합니다 
			if (navigator.geolocation) {

				// GeoLocation을 이용해서 접속 위치 가져오기
				navigator.geolocation.getCurrentPosition(position => {
					
					this.userLat = position.coords.latitude;	// 위도
					this.userLng = position.coords.longitude;	// 경도

					// 마커가 표시될 위치를 geolocation으로 얻어온 좌표로 생성
					var locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);

					// 사용자 위치정보와 화성시 위치 판단 및 커스텀오버레이 생성
					this.changeAddressArea();
					this.userCusOverlay();

					this.geoLocationState = 1;
				});

			}else{

				// HTML5의 GeoLocation을 사용할 수 없을때 마커 표시 위치와 인포윈도우 내용을 설정합니다
				// 기본적으로 평택시청 위치값을 넣어둡니다.
				this.userLat = 37.19952770562244;
				this.userLng = 126.83145146431667;
				this.displayMarker(this.userLat, this.userLng, this.message);

			}

			setTimeout(() => {
				this.map.relayout();
				this.setMarkers(this.positions);
				this.map.setLevel(6);
				this.map.setCenter(new kakao.maps.LatLng(this.userLat, this.userLng));
			}, 1);

		},

		// 사용자 gps 마커 오버레이 제거
		userCusOverlay(){
			var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:200px;">'
								+     '<dl style="margin-bottom: 0px;"><dt>' + this.message + '</dt></dl>'
								+ '</div>'
			;

			var cusOverLat = this.userLat;
			var cusOverLng = this.userLng;

			var cusOverLocPosition = new kakao.maps.LatLng(cusOverLat, cusOverLng);
			this.userCustomOverlay = new kakao.maps.CustomOverlay({
				position : cusOverLocPosition,
				content  : customContent,
				xAnchor  : 0.017,
				yAnchor  : 0.5
			});
		},

		// userLat, userLng 위치값으로부터 화성시 영역 여부를 반환 (좌표위치값을 주소로 변환하여 판단)
		changeAddressArea(){

			if(this.mapGeocoder == null){
				this.mapGeocoder = new kakao.maps.services.Geocoder();
			}

			this.mapGeocoder.coord2Address(this.userLng, this.userLat, this.checkUserLoc);

		},

		checkUserLoc(result, status){

			if(status === kakao.maps.services.Status.OK){
				var userAddr = result[0].address.address_name;	// 전체 지번 주소

				if((this.nullChk(userAddr)).indexOf("화성") == -1){
					// 지번주소에 화성시 있는지 판단
					this.userLat = 37.19952770562244;
					this.userLng = 126.83145146431667;
				}

				this.message = "<div style='padding:5px;'>내 위치</div>";
				this.displayMarker(this.userLat, this.userLng, this.message);
				this.map.setCenter(new kakao.maps.LatLng(this.userLat, this.userLng));

			}

		},

		nullChk(x){
			return (x == null || x == undefined) ? "" : x;
		},

		// 무정차 해당 주차장 [지도보기] 클릭
		nonstopMap(p){
			this.mapModal = true;
			this.mapClickReset();

			this.userLat        = p.lat;
			this.userLng        = p.lng;
			this.parkingNm      = p.parkingNm;
			this.parkingAmt     = p.parkingAmt;
			this.parkingAmtNm   = p.parkingAmtNm;
			this.parkingUseTm   = p.parkingUseTm;
			this.parkingUseTmNm = p.parkingUseTmNm;

			var parkingLot = {};
			parkingLot.title  = this.parkingNm;
			parkingLot.amt    = this.parkingAmt;
			parkingLot.amtNm  = this.parkingAmtNm;
			parkingLot.tm     = this.parkingUseTm;
			parkingLot.tmNm   = this.parkingUseTmNm;
			parkingLot.latlng = new kakao.maps.LatLng(this.userLat, this.userLng);

			this.positions = [];
			this.positions.push(parkingLot);

			this.setNonstopMapInit(this.positions);

			document.querySelector("#modalTit").focus();
		},

		setNonstopMapInit(positions){
			setTimeout(() => {
				this.map.relayout();
				this.setMarkers(positions);
				this.map.setLevel(5);
				this.map.setCenter(positions[0].latlng);
			}, 1);
		},

		// 다음 페이지
		goNext(){
			this.$router.push('/nonstopagree');
		},

		mapMarker(){
			return require("../../../assets/images/map/step2.png");
		},

		mapPcMarker(){
			return require("../../../assets/images/map/map_pc.png");
		},

		createScript(){
			const script  = document.createElement("script");
			script.onload = () => kakao.maps.load(this.initMap);
			script.src    = "//dapi.kakao.com/v2/maps/sdk.js?appkey=5130af43da731f05b3be59b4730e2676&libraries=services&autoload=false";
			document.head.appendChild(script);
		},

		initMap(){

			this.container = document.getElementById('container');		// 지도와 로드뷰를 감싸고 있는 div

			const mapContainer = document.getElementById('map');		// 지도를 표시할 div

			var mapCenter = new kakao.maps.LatLng(this.userLat, this.userLng);

			// 지도를 생성할 때 필요한 기본 옵션
			const mapOptions = {
				center : mapCenter,	// 지도의 중심좌표
				level  : 4			// 지도의 레벨(확대, 축소 정도)
			};

			// 지도를 표시할 div와 지도 옵션으로 지도를 생성
			this.map = new kakao.maps.Map(mapContainer, mapOptions);

			//일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
			var mapTypeControl = new kakao.maps.MapTypeControl();

			// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
			this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);	// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
			// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다

			this.imageSrc    = this.mapMarker();							// 마커이미지의 주소입니다
			this.imageSize   = new kakao.maps.Size(20, 20);					// 마커이미지의 크기입니다
			this.imageOption = { offset: new kakao.maps.Point(10, 10) };	// 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

			this.displayMarker(this.userLat, this.userLng);
			
		},

		// 현재 위치에 표시를 위한 마커 만들어준다
		displayMarker(userLat, userLng){

			var locPosition   = new kakao.maps.LatLng(userLat, userLng);
			this.map.setCenter(locPosition);
			this.map.relayout();

		},

		// 각 주차장 정보를 받아 마커를 지도에 뿌려준다.
		setMarkers(positions, limitKm){
			this.controlhideMarkers();
			this.markers = [];

			// 초록색
			var markerImage = new kakao.maps.MarkerImage(this.imageSrc, this.imageSize, this.imageOption);

			if(positions != null) {
				for(var i = 0 ; i < positions.length ; i++){

					this.markers[i] = new kakao.maps.Marker({
						map       : this.map,				// 마커를 표시할 지도
						position  : positions[i].latlng,	// 마커의 위치
						title     : positions[i].title,
						clickable : true,					// 마커 클릭가능. (지도의 클릭이벤트가 발생하지않도록 함)
						id        : positions[i].id,
						image     : markerImage
					});

					var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:300px;">'
										+     '<dl style="margin-bottom: 0px;">'
										+         '<dt>' + positions[i].title + '</dt>'
										+     '</dl>'
										+ '</div>'
					;

					this.customOverlay[i] = new kakao.maps.CustomOverlay({
						position  : positions[i].latlng,
						content   : customContent,
						xAnchor   : 0.017,
						yAnchor   : 0.5
					});

					//kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeOverListener(this.markers[i], this.customOverlay[i], positions[i].title));	// 마커 호버 이벤트
					kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeClickListener(this.markers[i], i, this.customOverlay[i]));	// 마커 호버 이벤트
					//kakao.maps.event.addListener(this.markers[i], 'click'    , this.makeClickListener(this.markers[i], i, this.customOverlay[i]));						// 마커 클릭 이벤트
					kakao.maps.event.addListener(this.markers[i], 'mouseout' , this.makeOutListener(this.customOverlay[i], this.markers[i], i));						// 마커 호버아웃 이벤트
				}
			}

		},

		makeClickListener(marker, idx, customOverlay){
			return () => {

				// 로드뷰 오버레이 나왓을시 마커 클릭 안되게함.
				if(this.overlayOn) {
					return null;
				}

				this.def         = this.positions[idx];
				this.defFlag     = true;
				this.clickStatus = 1;

				if(this.cMarkerIndex != -1){
					customOverlay.setMap(null);
					if(this.clickCustomOverlay){
						this.clickCustomOverlay.setMap(null);
					}
				}

				for(var i = 0 ; i < this.markers.length ; i++){

					if(this.markers[i] == marker){
						this.cMarkerIndex = i;													// 클릭된 마커 id값 저장
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);	// 클릭된 마커 크기및 이미지 변경
						kakao.maps.event.removeListener(this.markers[i], 'mouseout', this.makeOutListener(customOverlay, this.markers[i]));
						customOverlay.setMap(this.map);
						this.clickCustomOverlay = customOverlay;
					}else{
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);
					}

				}

			}
		},

		// 마크 에 마우스를 빼면 발생하는 이벤트(아웃)
		makeOutListener(customOverlay, marker, idx){
			return () => {

				if(idx != this.cMarkerIndex){

					var markerImage = new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption);
					marker.setImage(markerImage);
					customOverlay.setMap(null);

				}else{

					if(this.clickStatus == 1){
						marker.setImage(new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption));
						customOverlay.setMap(null);
					}

				}

			}
		},

		// 거리 및 확대레벨에 따른 마커 출력 함수
		changeImage(markers, imageSrc, imageOption){

			var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, imageOption);

			markers.setImage(markerImage);
			markers.setMap(this.map);

		},

		// "마커 감추기" 버튼을 클릭하면 호출되어 배열에 추가된 마커를 지도에서 삭제하는 함수입니다
		controlhideMarkers(){
			this.controlsetMarkers(null);
		},

		controlsetMarkers(map){
			for (var i = 0 ; i < this.markers.length ; i++) {
				this.markers[i].setMap(map);
			}
		},

		modalHide(){
			this.showModal = false;
		},

		popupFocus(event){
			const targetId = event.currentTarget.id;

			// if(event.which == 13 || event.which == 32){
			// 	document.getElementById(targetId).style.background='red';
			// 	document.getElementById(targetId).className += ' check-active';
			// 	document.querySelector('#modalTit').focus();
			// }

			document.getElementById(targetId).className += ' check-active';
			document.querySelector('#modalTit').focus();
		},

		mapController(){
			const focusEle   = document.activeElement;
			const clearClass = document.querySelector('.check-active');

			this.mapModal=!this.mapModal;

			document.querySelector('.check-active').focus();

			if(clearClass){
				clearClass.classList.remove('check-active');
				clearClass.style.background='black';
			}

			//document.querySelectorAll('table-in-btn').classList.remove('.check-active');
			
			// if (document.getElementsByClassName('.check-active') == focusEle) {
			// 	console.log(true);
			// } else {
			// 	console.log(false);
			// }
		},
	}
}
</script>

<style scoped>
	.mapWH100{ width: 100%; height: 100%; }

	#container {
		overflow : hidden;
		height   : 590px;
		position : relative;
	}
	#mapWrapper {
		width   : 100%;
		height  : 550px;
		z-index : 5;
	}

	.modal-kakaoMap {
		position   : fixed;
		border     : 1px solid;
		display    : block;
		background : #FFFFFF;
		left       : 50%;
		top        : 50%;
		width      : 700px;
		height     : 675px;
		transform  : translate(-50%, -50%);
		z-index    : 2;
	}

	.modal-kakaoMap .title {
		background : #333333;
		height     : 50px;
	}

	.modal-kakaoMap .title ul {
		margin-top : 0 !important;
	}

	.modal-kakaoMap .title ul {
		display         : flex;
		justify-content : space-between;
		padding         : 10px 20px;
		box-sizing      : border-box;
		list-style      : none;
	}

	.modal-kakaoMap .title ul li:first-child {
		padding-left : 0;
		width        : 700px;
		text-align   : center;
		box-sizing   : border-box;
	}

	.modal-kakaoMap .title ul li {
		color       : #FFFFFF;
		font-size   : 1.25rem;
		font-weight : 400;
		list-style  : none;
	}

	.modal-kakaoMap .title ul li.popup-close {
		cursor : pointer;
	}

	.modal-kakaoMap .content {
		height     : 620px;
		padding    : 15px 15px 30px;
		box-sizing : border-box;
	}

	.modal-kakaoMap .content .content-inner {
		padding     : 20px;
		background  : #EFEFEF;
		height      : 100%;
		text-align  : center;
		line-height : 1.5;
		box-sizing  : border-box;
	}

	.modal-kakaoMap .btn-list {
		margin-top  : 45px;
		padding-top : 25px;
		border-top  : 1px solid #eeeeee;
	}
</style>