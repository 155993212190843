<template>
	<div v-show="showModal">
		<section class="popup-layer" v-bind:style="{ height: sizeX + 'px', width: sizeY + 'px', top: posX + 'px', left: posY + 'px' }">
			<article class="title">
				<ul>
					<li>
						<slot name="info"></slot>
					</li>
					<li class="popup-close" @click="modalHide" tabindex="2" @keydown.enter="modalHide">
						<span class="hide">닫기 아이콘</span>
						<slot name="close"></slot>
					</li>
				</ul>
			</article>
			<article class="content">
					<div class="content-inner">
						<slot name="content"></slot>
						<a :href="linkUrl">
							<img :src="popupImage" alt="팝업이미지"/>
						</a>
					</div>
			</article>
			<article class="btn-list">
				<slot name="button"></slot>
			</article>
		</section>
		<section class="dim"></section>
	</div>
</template>

<script>

export default {
	data(){
		return{
			showModal  : true,
			posX       : '',
			posY       : '',
			sizeX      : '',
			sizeY      : '',
			popupImage : '',
			linkUrl    : ''
		}
	},

	methods : {
		modalHide(){
			this.$emit('emitChangeModal');
		},

		setProps(){
			if(this.propsData != null){
				this.posX       = this.propsData.posX;
				this.posY       = this.propsData.posY;
				this.sizeX      = this.propsData.sizeX;
				this.sizeY      = this.propsData.sizeY;
				this.popupImage = this.propsData.popupImage;
				this.linkUrl    = this.propsData.linkUrl;
			}
		},
	},

	props: ['propsData'],

	watch: {
		'propsData': {
			handler() {
				this.setProps();
			},
			immediate : true,
			deep      : true
		}
	},

}
</script>

<style>

</style>