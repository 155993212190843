<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement"    title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist"    title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist"          title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist"          title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist"  title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist"          title="메뉴관리" class="active">메뉴관리</router-link></li>
				<li><router-link to="/createmenu"        title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist"       title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist"     title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist"          title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist"         title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo"        title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>메뉴관리</h4>
			</article>
			<article>
				<h3>메뉴관리</h3> 
			</article>	
			<article class="btn-list right mt0">
				<button class="btn"        @click="insertBtn">등록</button>
				<button class="btn black"  @click="updateBtn">수정</button>
				<button class="btn-border" @click="deleteConfirm()">삭제</button>
				<button class="btn gray"   @click="resetMenu()">초기화</button>
			</article>
			<section id="contentLayout">
				<article>
					<img src="../../../assets/images/menu/menu_base_new.gif" alt="ROOT" style="margin-right: 10px;">
					<label for="root">ROOT</label>
					<ul class="three">
						<li v-for="(list, index) in treeNodes" :key="index">

							<template v-if="hasChildTreeNode(list.menuNo)">
								<!-- 2depth -->
								<template v-if="lastTreeSibling(list.menuNo, list.upperMenuId)">
									<a @click="openCloseExMenuAdd(list.menuNo, 1)/*, threeOpenCloseDepth2()*/" class="plus_position depth-2" >
										<template v-if="isTreeNodeOpen(list.menuNo)">
											<img src="../../../assets/images/menu/menu_minus_new.gif" v-bind:id="'joinM' + list.menuNo" alt="Open/Close node 111" >
										</template>
										<template v-else>
											<img src="../../../assets/images/menu/menu_plus_new.gif" v-bind:id="'joinM' + list.menuNo" alt="2depth 시작">
										</template>
									</a>
								</template>
								<!-- 1depth -->
								<template v-else>
									<a @click="openCloseExMenuAdd(list.menuNo, 0)" class="plus_position depth-1">
										<template v-if="isTreeNodeOpen(list.menuNo)">
											<img src="../../../assets/images/menu/menu_minus_new.gif" v-bind:id="'joinM' + list.menuNo" alt="Open/Close node">		
										</template>
										<template v-else>
											<img src="../../../assets/images/menu/menu_plus_new.gif" v-bind:id="'joinM' + list.menuNo" alt="1depth 시작">
										</template>
									</a>
								</template>
							</template>
							<template v-else>
								<!-- 3depth 시작 / 종료 -->
								<template v-if="lastTreeSibling(list.menuNo, list.upperMenuId)">
									<img src="../../../assets/images/menu/menu_joinbottom.gif" alt="3depth 마지막" v-show="menuToggleDepth3" class="depth-line">
								</template>
								<template v-else>
									<img src="../../../assets/images/menu/menu_join.gif" alt="3depth 시작" v-show="menuToggleDepth3" class="depth-line">
								</template>
							</template>
							
							<template v-if="hasChildTreeNode(list.menuNo)">
								<a @click="choiceNodes(index)" class="folder_position depth-image">
									<img v-if="isTreeNodeOpen(list.menuNo)" src="../../../assets/images/menu/menu_folderopen_new.gif" v-bind:id="'iconM' + list.menuNo" alt="Folder 444">
									<img v-else src="../../../assets/images/menu/menu_folder_new.gif" v-bind:id="'iconM' + list.menuNo" alt="1, 2depth Folder">
									<!-- <input type="checkbox" v-bind:id="list.menuNo" > -->
									<label v-bind:for="list.menuNo">{{ list.menuNm }}</label>
								</a>
							</template>
							<template v-else>
								<!-- 3depth 페이지 이미지 -->
								<a @click="choiceNodes(index)" class="folder_position depth-image" v-show="menuToggleDepth3">
									<img src="../../../assets/images/menu/menu_page_new.gif" alt="3depth Page" v-bind:id="'iconM' + list.menuNo" style="margin-right: 10px;">
									<label v-bind:for="list.menuNo">{{ list.menuNm }}</label>
								</a>
							</template>

						</li>
					</ul>

				</article>
				<article>
					<table class="vertical-table mt0">
						<caption class="sr-only">메뉴관리 컨텐츠</caption>
						<colgroup>
							<col style="width:30%" />
							<col style="width:70%" />
						</colgroup>
						<tbody>
							<tr>
								<th class="asterisk-after">메뉴No</th>
								<td>
									<ul class="input-style">
										<li>
											<input type="text" placeholder="메뉴No" class="place-hide" v-model="menuNo">
											<div class="error" v-if="!$v.menuNo.required">필수 입력란입니다.</div>
											<div class="error" v-if="!$v.menuNo.numRegex">숫자만 입력 가능합니다.</div>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">메뉴순서</th>
								<td>
									<ul class="input-style">
										<li>
											<input type="text" placeholder="메뉴순서" class="place-hide" v-model="menuOrdr">
											<div class="error" v-if="!$v.menuOrdr.required">필수 입력란입니다.</div>
											<div class="error" v-if="!$v.menuOrdr.numRegex">숫자만 입력 가능합니다.</div>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">메뉴명</th>
								<td>
									<ul class="input-style">
										<li>
											<input type="text" placeholder="메뉴명" class="place-hide" v-model="menuNm">
											<div class="error" v-if="!$v.menuNm.required">필수 입력란입니다.</div>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">상위메뉴No</th>
								<td>
									<ul class="input-style button">
										<li>
											<input type="text" placeholder="메뉴선택" class="place-hide" v-model="upperMenuId" readonly>
											<button class="table-btn black" @click="openMenuPopup">메뉴선택</button>
										</li>
										<div class="error" v-if="!$v.upperMenuId.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.upperMenuId.numRegex">숫자만 입력 가능합니다.</div>
									</ul>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">파일명</th>
								<td>
									<ul class="input-style button">
										<li>
											<input type="text" placeholder="프로그램 파일명" class="place-hide" v-model="progrmFileNm" readonly>
											<button class="table-btn black" @click="openProgramPopup">프로그램 파일명</button>
										</li>
										<div class="error" v-if="!$v.progrmFileNm.required">필수 입력란입니다.</div>
									</ul>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">관련이미지명</th>
								<td>
									<ul class="input-style">
										<li>
											<input type="text" placeholder="관련이미지경로" class="place-hide" v-model="relateImageNm">
											<div class="error" v-if="!$v.relateImageNm.required">필수 입력란입니다.</div>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">관련이미지경로</th>
								<td>
									<ul class="input-style">
										<li>
											<input type="text" placeholder="관련이미지경로" class="place-hide" v-model="relateImagePath">
											<div class="error" v-if="!$v.relateImagePath.required">필수 입력란입니다.</div>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th>메뉴설명</th>
								<td>
								<textarea class="in-table place-hide" placeholder="메뉴설명" v-model="menuDc">

								</textarea>
								</td>
							</tr>
						</tbody>
					</table>
					<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
				</article>
			</section>
		</section>

		<!-- [메뉴선택] 팝업창 -->
		<section class="modal-manager menu on" v-if="menuPopupStatus">
			<article class="title">
				<ul>
					<li>메뉴선택</li>
					<li @click="closeMenuPopup()" class="popup-close"></li>
				</ul>
			</article>
			<article class="content">
				<img src="../../../assets/images/menu/menu_base_new.gif" alt="ROOT" style="margin-right: 10px;">
				<label for="root">ROOT</label>
				<ul>
					<li v-for="(list, index) in treeNodes" :key="index">

						<template v-if="hasChildTreeNode(list.menuNo)">
							<!-- 2depth -->
							<template v-if="lastTreeSibling(list.menuNo, list.upperMenuId)">
								<a @click="openCloseExMenuAddModal(list.menuNo, 1)" class="plus_position depth-2 menu-modal">
									<template v-if="isTreeNodeOpenModal(list.menuNo)">
										<img src="../../../assets/images/menu/menu_minus_new.gif" v-bind:id="'join' + list.menuNo" alt="Open/Close node 111 모달">
									</template>
									<template v-else>
										<img src="../../../assets/images/menu/menu_plus_new.gif" v-bind:id="'join' + list.menuNo" alt="2depth 시작 모달">
									</template>
								</a>
							</template>
							<!-- 1depth -->
							<template v-else>
								<a @click="openCloseExMenuAddModal(list.menuNo, 0)" class="plus_position depth-1 menu-modal">
									<template v-if="isTreeNodeOpenModal(list.menuNo)">
										<img src="../../../assets/images/menu/menu_minus_new.gif" v-bind:id="'join' + list.menuNo" alt="Open/Close node 222 모달">		
									</template>
									<template v-else>
										<img src="../../../assets/images/menu/menu_plus_new.gif" v-bind:id="'join' + list.menuNo" alt="1depth 시작 모달">
									</template>
								</a>
							</template>
						</template>
						<template v-else>
							<!-- 3depth 시작 / 종료 -->
							<template v-if="lastTreeSibling(list.menuNo, list.upperMenuId)">
								<img src="../../../assets/images/menu/menu_joinbottom.gif" alt="3depth 마지막 모달">
							</template>
							<template v-else>
								<img src="../../../assets/images/menu/menu_join.gif" alt="3depth 시작 모달">
							</template>
						</template>
						
						<template v-if="hasChildTreeNode(list.menuNo)">
							<a @click="choiceNodesModal(index)" class="folder_position depth-image menu-modal">
								<img v-if="isTreeNodeOpenModal(list.menuNo)" src="../../../assets/images/menu/menu_folderopen_new.gif" v-bind:id="'icon' + list.menuNo" alt="Folder 모달">
								<img v-else src="../../../assets/images/menu/menu_folder_new.gif" v-bind:id="'icon' + list.menuNo" alt="1, 2depth Folder 모달">
								<label>{{ list.menuNm }}</label>
							</a>
						</template>
						<template v-else>
							<!-- 3depth 페이지 이미지 -->
							<a @click="choiceNodesModal(index)" class="folder_position depth-image menu-modal">
								<img src="../../../assets/images/menu/menu_page_new.gif" alt="3depth Page 모달" v-bind:id="'icon' + list.menuNo">
								<label>{{ list.menuNm }}</label>
							</a>
						</template>

					</li>
				</ul>
			</article>
		</section>
		
		<!-- 프로그램 파일명 팝업창 -->
		<section class="modal-manager program on" v-if="programPopupStatus">
			<article class="title">
				<ul>
					<li>프로그램 파일명 검색</li>
					<li @click="closeProgramPopup" class="popup-close"></li>
				</ul>
			</article>
			<article class="content">
				<table class="vertical-table">
					<caption class="sr-only">프로그램 파일명 검색</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>                    
						<tr>
							<th>프로그램명</th>
							<td>
								<ul class="input-style button">
									<li>
										<input type="text" placeholder="프로그램명" class="place-hide" v-model="searchProgramName" @keyup.enter="selectProgramList">
										<button class="table-btn" @click="selectProgramList()">검색하기</button>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
				<table class="popup-table program">
					<caption class="sr-only">프로그램명 리스트</caption>
					<colgroup>
						<col style="width:50%" />
						<col style="width:50%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">프로그램파일명</th>
							<th scope="col">프로그램명</th>
						</tr>
					</thead>
					<tbody>
						<tr class="nodate" v-if="programNodata">
							<td colspan="2" >조회된 데이터가 없습니다.</td>
						</tr>
						<!-- <tr v-for="(program, index) in programListData" :key="index" @click="choiceProgram(program.progrmFileNm, program.progrmId)"> -->
						<tr v-for="(program, index) in programCalData" :key="index" @click="choiceProgram(program.progrmFileNm, program.progrmId)">
							<td scope="row">{{ program.progrmFileNm }}</td>
							<td>{{ program.progrmKoreanNm }}</td>
						</tr>
					</tbody>
				</table>
				<v-pagination v-model="programCurPageNum" :length="programNumOfPages"></v-pagination>
			</article>
		</section>

	</main>
</template>

<script>
import axios from 'axios';
import { required, helpers } from 'vuelidate/lib/validators'

const numRegex = helpers.regex('numRegex', /\d/g);
const instance = axios.create({
  timeout: 30000,
});
export default {
	data() {
		return{
			nodata             : false,
			listData           : [],
			treeData           : [],
			menuPopupStatus    : false,
			programPopupStatus : false,
			programCurPageNum  : 1,
			programDataPerPage : 10,

			menuNo             : "",
			menuOrdr           : "",
			menuNm             : "",
			upperMenuId        : "",
			menuDc             : "",
			relateImageNm      : "",
			relateImagePath    : "",
			progrmFileNm       : "",
			progrmId           : "",
			clickbottom        : 0,
			
			startNode          : null,
			openNode           : null,
			treeOpenYn         : false,
			treeOpenModalYn    : false,
			treeMenuAdd        : [],
			treeNodes          : new Array(),
			treeIcons          : new Array(6),
			openTreeNodes      : new Array(),

			searchProgramName  : "",
			programListData    : [],
			programNodata      : false,
			submitStatus  	   : "",
			menuToggleDepth2   : false,
			//menuToggleDepth3   : false,
			menuToggleDepth3   : true,
		}
	},

	validations: {
		menuNo: {
			required,
			numRegex
		},
		menuOrdr: {
			required,
			numRegex
		},
		menuNm: {
			required
		},
		upperMenuId: {
			required,
			numRegex
		},
		progrmFileNm: {
			required
		},
		relateImageNm: {
			required
		},
		relateImagePath: {
			required
		},
		menuDc: {
			required
		}
	},

	mounted() {
		this.searchBtn();
		this.preloadIcons();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {

		// 메뉴 선택 열기
		openMenuPopup() {
			this.menuPopupStatus = true;
		},

		// 메뉴선택 닫기
		closeMenuPopup(){
			this.menuPopupStatus = false;
		},

		// 프로그램 파일명 닫기
		closeProgramPopup() {
			this.programPopupStatus = false;
		},

		// 프로그램 파일명 오픈
		openProgramPopup() {
			this.programPopupStatus = true;
			this.selectProgramList();
		},

		// 프로그램 리스트 조회
		selectProgramList(){
			instance.get('/api/program/list.json', {
				params: {
					searchCondition : "1",
					searchKeyword   : this.searchProgramName
				}
			})
			.then((response => {
				var data = response.data;

				if(data.resultList && data.resultList.length > 0){
					this.programListData = data.resultList;
					this.programNodata   = false;
				} else {
					this.programListData = [];
					this.programNodata   = true;
				}

				this.programCurPageNum = 1;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			});

		},

		// 프로그램 리스트 항목 선택시 모달 닫기
		choiceProgram(progrmFileNm, progrmId){
			this.progrmFileNm      = progrmFileNm;
			this.progrmId          = progrmId;
			this.searchProgramName = "";
			this.closeProgramPopup();
		},

		// 초기화
		resetMenu(){
			this.menuNo          = "";
			this.menuOrdr        = "";
			this.menuNm          = "";
			this.upperMenuId     = "";
			this.menuDc          = "";
			this.relateImagePath = "";
			this.relateImageNm   = "";
			this.progrmFileNm    = "";
			this.progrmId        = "";
		},

		// 검색
		searchBtn() {
			instance.get('/api/menu/menuListSelect.json')
			.then((res => {
				var resData = res.data.list_menulist;

				if(resData && resData.length > 0){
					this.listData  = resData;
					this.treeNodes = resData;
					this.nodata    = false;
				} else {
					this.listData  = [];
					this.treeNodes = [];
					this.nodata    = true;
				}

			}));
			//.catch(function (error) {
			//	//console.log(error);
			//	alert("서버와 통신중 오류가 발생하였습니다.")
			//})
		},

		// 노드, 트리 구성 이미지 정보
		preloadIcons(){
			this.treeIcons[0] = new Image();
			this.treeIcons[0].src = require("../../../assets/images/menu/menu_plus_new.gif");
			this.treeIcons[1] = new Image();
			this.treeIcons[1].src = require("../../../assets/images/menu/menu_plusbottom_new.gif");
			this.treeIcons[2] = new Image();
			this.treeIcons[2].src = require("../../../assets/images/menu/menu_minus_new.gif");
			this.treeIcons[3] = new Image();
			this.treeIcons[3].src = require("../../../assets/images/menu/menu_minusbottom_new.gif");
			this.treeIcons[4] = new Image();
			this.treeIcons[4].src = require("../../../assets/images/menu/menu_folder_new.gif");
			this.treeIcons[5] = new Image();
			this.treeIcons[5].src = require("../../../assets/images/menu/menu_folderopen_new.gif");
		},

		// 하위 트리노드 존재 여부 확인
		hasChildTreeNode(parentNode){
			for(var i = 0 ; i < this.treeNodes.length ; i++){
				var nodeValues = this.treeNodes[i];
				if(nodeValues.upperMenuId == parentNode){
					return true;
				}
			}
			return false;
		},

		// 트리노드 최하위 여부 확인
		lastTreeSibling(node, parentNode){
			var lastChild = 0;
			
			for(var i = 0 ; i < this.treeNodes.length ; i++){
				var nodeValues = this.treeNodes[i];
				if(nodeValues.upperMenuId == parentNode){
					lastChild = nodeValues.menuNo;
				}
			}
			if(lastChild == node){
				return true;
			}else{
				return false;
			}
		},

		// 트리노드 오픈 여부 확인
		isTreeNodeOpen(node){
			if(this.treeOpenYn){
				return true;
			}
			for(var i = 0 ; i < this.openTreeNodes.length ; i++){
				if(this.openTreeNodes[i] == node){
					return true;
				}
			}
			return false;
		},

		// 트리노드 액션(열기/닫기)
		openCloseExMenuAdd(node){

			var treeJoin = document.getElementById("joinM" + node);
			var treeIcon = document.getElementById("iconM" + node);

			if(treeJoin.src != this.treeIcons[2].src){
				treeJoin.src = this.treeIcons[2].src;
				treeIcon.src = this.treeIcons[5].src;
			}else{
				treeJoin.src = this.treeIcons[0].src;
				treeIcon.src = this.treeIcons[4].src;
			}

		},

		threeOpenCloseDepth1(){
			this.menuToggleDepth2 = !this.menuToggleDepth2;
		},

		threeOpenCloseDepth2(){
			//this.menuToggleDepth3 = !this.menuToggleDepth3;
			this.menuToggleDepth3 = true;
		},

		// (모달) 트리노드 오픈 여부 확인
		isTreeNodeOpenModal(node){
			if(this.treeOpenModalYn){
				return true;
			}
			for(var i = 0 ; i < this.openTreeNodes.length ; i++){
				if(this.openTreeNodes[i] == node){
					return true;
				}
			}
			return false;
		},

		// (모달) 트리노드 액션(열기/닫기)
		openCloseExMenuAddModal(node){

			var treeJoin = document.getElementById("join" + node);
			var treeIcon = document.getElementById("icon" + node);

			if(treeJoin.src != this.treeIcons[2].src){
				treeJoin.src = this.treeIcons[2].src;
				treeIcon.src = this.treeIcons[5].src;
			}else{
				treeJoin.src = this.treeIcons[0].src;
				treeIcon.src = this.treeIcons[4].src;
			}
		},

		// (모달) 클릭시 상위 메뉴NO 입력
		choiceNodesModal(index) {
			var nodeValues   = this.treeNodes[index];
			this.upperMenuId = nodeValues.menuNo;
			this.closeMenuPopup();
		},

		// 상세내역조회 함수
		choiceNodes(index) {
			var nodeValues       = this.treeNodes[index];
			this.menuNo          = nodeValues.menuNo;
			this.menuOrdr        = nodeValues.menuOrdr;
			this.menuNm          = nodeValues.menuNm;
			this.upperMenuId     = nodeValues.upperMenuId;
			this.menuDc          = nodeValues.menuDc;
			this.relateImagePath = nodeValues.relateImagePath;
			this.relateImageNm   = nodeValues.relateImageNm;
			this.progrmFileNm    = nodeValues.progrmFileNm;
			this.progrmId        = nodeValues.progrmId;
		},

		insertBtn(){
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("menuDc", this.menuDc);
				apiParams.append("menuNm", this.menuNm);
				apiParams.append("menuNo", this.menuNo);
				apiParams.append("menuOrdr", this.menuOrdr);
				apiParams.append("progrmFileNm", this.progrmFileNm);
				apiParams.append("progrmId", this.progrmId);
				apiParams.append("relateImageNm", this.relateImageNm);
				apiParams.append("relateImagePath", this.relateImagePath);
				apiParams.append("upperMenuId", this.upperMenuId);
	
				instance.post('/api/menu/menuListInsert.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.searchBtn();
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		updateBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("menuDc", this.menuDc);
				apiParams.append("menuNm", this.menuNm);
				apiParams.append("menuNo", this.menuNo);
				apiParams.append("menuOrdr", this.menuOrdr);
				apiParams.append("progrmFileNm", this.progrmFileNm);
				apiParams.append("progrmId", this.progrmId);
				apiParams.append("relateImageNm", this.relateImageNm);
				apiParams.append("relateImagePath", this.relateImagePath);
				apiParams.append("upperMenuId", this.upperMenuId);
	
				instance.post('/api/menu/menuListUpdt.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.searchBtn();
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		deleteBtn() {
			let apiParams = new FormData();
			apiParams.append("menuNo", this.menuNo);

			instance.post('/api/menu/menuListDelete.json', apiParams)
			.then((response => {
				let message = response.data.message;
				
				alert(message);
				this.searchBtn();
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		deleteConfirm(){
			this.$confirm(
				{
					message: `삭제하시겠습니까?`,
					button: {
						no: '아니오',
						yes: '예'
					},
					callback: confirm => {
						if (confirm) {
						this.deleteBtn();
						}
					}
				}
			)
		}
		
	},

	computed: {
		programStartOffset() {
			return ((this.programCurPageNum - 1) * this.programDataPerPage);
		},
		programEndOffset() {
			return (this.programStartOffset + this.programDataPerPage);
		},
		programNumOfPages() {
			return Math.ceil(this.programListData.length / this.programDataPerPage);
		},
		programCalData() {
			if(this.programListData.length) {
				return this.programListData.slice(this.programStartOffset, this.programEndOffset);
			}
		}
	},

}
</script>

<style scoped>
/*
	[id$="1"],
	[id$="1"] + label{
		position: relative;
		margin-right: 5px;
    left: 20px;
    vertical-align: super;
	}

	[id$="0"]{
		margin-right: 5px;	
	}

	.depth-line,
	[id$="2"],
	[id$="3"],
	[id$="4"],
	[id$="5"],
	[id$="6"],
	[id$="7"],
	[id$="2"] + label,
	[id$="3"] + label,
	[id$="4"] + label,
	[id$="5"] + label,
	[id$="6"] + label,
	[id$="7"] + label{
		position: relative;
		margin-right: 5px;
		left: 20px;
    vertical-align: super;
	}
*/
</style>