<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">선불권(종이)</div>
			<ul>
				<li><router-link to="" title="안내" class="active">선불권(종이)</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 선불권</h2>
				<h4>선불권 조회</h4>
			</article>
			<article>
				<h3>선불권 조회</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">선불권 조회</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>					
					<tr>
						<th scope="row">주차장 선택</th>
						<td>롯데백화점</td>
					</tr>
					<tr>
						<th>선불권 선택</th>
						<td>30분권: 200매</td>
					</tr>
					<tr>
						<th>구매일</th>
						<td>2021.00.00</td>
					</tr>
					<tr>
						<th>금액</th>
						<td>2,000,0000원</td>
					</tr>
					<tr>
						<th>이름</th>
						<td>홍길동</td>
					</tr>
					<tr>
						<th>전화번호</th>
						<td>010-0000-0000</td>
					</tr>
					<tr>
						<th>사업장</th>
						<td>롯데백화점</td>
					</tr>
				</tbody>
			</table>
			<article class="message">
				<p>선불권 구입 상세내역입니다.</p>
			</article>
			<article class="btn-list">
				<button class="one-btn">확인</button>
			</article>	
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style>

</style>