<template>
	<div v-show="showModal">
		<section class="modal-manager">
			<article class="title">
				<ul>
					<li>
						<slot name="info"></slot>
					</li>
				</ul>
			</article>
			<article class="content">
					<div class="content-inner">
						<slot name="content"></slot>
					</div>
			</article>
			<article class="btn-list">
				<slot name="button"></slot>
			</article>
		</section>
		<section class="dim"></section>
	</div>
</template>

<script>

export default {
	data(){
		return{
			showModal : true
		}
	},
	methods : {
		modalHide(){
			this.$emit('emitChangeModal');
		}
	}
}
</script>

<style>

</style>