<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">미납주차요금</div>
			<ul>
				<li><router-link to="unpaidinfo" title="미납주차요금 안내" >안내</router-link></li>
				<li><router-link to="lookupunpaid" title="미납주차요금 조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>미납주차요금</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>미납주차요금 결제</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">미납주차요금 결제 리스트 테이블</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">차량번호</th>
						<td>{{ carNo }}</td>
					</tr>
					<tr>
						<th>전체 미납 건수 </th>
						<td>{{ totCnt }} 건</td>
					</tr>
					<tr>
						<th>미납 총액</th>
						<td><span class="orange">{{ payResult.amount | comma }} 원</span></td>
					</tr>
				</tbody>
			</table>
			
			<article>
			</article>
			<table class="default-table-view" v-if="!isMobile">
				<caption class="sr-only">미납주차요금 결제 상세보기 테이블</caption>
				<colgroup>
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td colspan="3">{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>
			<table class="default-table-view" v-else>
				<caption class="sr-only">미납주차요금 결제 상세보기 테이블</caption>
				<colgroup>
					<col style="width:30%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
					</tr>
					<tr>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat}}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td>{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>

			<article class="message">
				<p v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">결제가 완료 되었습니다.</p>
			</article>

			<article class="btn-list">
				<button class="one-btn" @click="cancelBtn(carNo)">확인</button>
			</article>

		</section>
	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return{
			fResCd     : '',
			resCd      : '',
			iNervPayId : '',
			carNo      : '',
			pgCno      : '',
			approvalNo : '',
			totCnt     : 0,
			payResult  : '',
			isMobile   : this.$isMobile(),
		}
	},
	filters : {
		comma : function(value){
			if(value != null){
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
		dateFormat : function(value){
			if(value != null){
				var year  = value.toString().substr(0, 4);
				var month = value.toString().substr(4, 2);
				var day   = value.toString().substr(6, 2);
				var time  = value.toString().substr(8, 2);
				var min   = value.toString().substr(10, 2);
				var sec   = value.toString().substr(12, 2);
				return year + "-" + month + "-" + day + " " + time + ":" + min + ":" + sec;
			}
		}
	},
	created(){
		var data = this.$route.query;
		this.fResCd     = data.a;
		this.resCd      = data.b;
		this.iNervPayId = data.c;
		this.carNo      = data.d;
		this.pgCno      = data.e;
		this.approvalNo = data.f;
		this.totCnt     = data.g;
	},
	mounted(){
		let apiParams = new Map();
		apiParams.set("fResCd"    , this.fResCd);
		apiParams.set("resCd"     , this.resCd);
		apiParams.set("iNervPayId", this.iNervPayId);
		apiParams.set("carNo"     , this.carNo);
		apiParams.set("pgCno"     , this.pgCno);
		apiParams.set("approvalNo", this.approvalNo);

		instance.get("/api/unpaid/result.json", {
			params : Object.fromEntries(apiParams)
		})
		.then((res => {

			//console.dir(res);
			this.payResult  = res.data.payResult;

		}));

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods: {
		cancelBtn(carNo){
			this.$router.push({
				name  : 'lookupunpaid',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},
	}
}

</script>

<style>

</style>