<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>비밀번호 변경</h2>
			</article>
			<article class="figure">
				<figure class="img-find"></figure>
			</article>
			<article>
				<table class="lookup-table">
					<caption class="sr-only">비밀번호 변경 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">이름</th>
							<td>
								<ul class="input-style">
									<li>{{ empNm }}</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row">아이디</th>
							<td>
								<ul class="input-style">
									<li>{{ empNo }}</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row">변경할 비밀번호</th>
							<td>
								<ul class="input-style">
									<li>
										<label for="pwd" class="hide">변경할 비밀번호</label>
										<input type="password" v-model="password" class="place-hide" id="pwd">
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row">변경할 비밀번호 확인</th>
							<td>
								<ul class="input-style">
									<li>
										<label for="pwdConfirm" class="hide">변경할 비밀번호 확인</label>
										<input type="password" v-model="passwordCon" class="place-hide" id="pwdConfirm">
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">비밀번호를 올바르게 입력하여주세요.</p>

			<article class="btn-list">
				<button class="btn-border" @click="cancelBtn()">취소</button>
				<button class="btn"        @click="updateBtn()">비밀번호 변경</button>
			</article>	
		</section>

	</main>
</template>

<script>

import axios from 'axios';
import { required, isUnique, email, sameAs, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'
const instance = axios.create({
  timeout: 30000,
});

const passRegex      = helpers.regex('passRegex', /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi);

export default {

	data(){
		return{
			empNo        : "",
			empNm        : "",
			empMobile    : "",
			findPwChk    : false,
			password     : "",
			passwordCon  : "",
			submitStatus : "",
			birth        : "",
		}
	},

	validations: {
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(20),
			passRegex: passRegex
		},
		passwordCon: {
			required,
			sameAsPassword: sameAs('password'),
			minLength: minLength(8),
			maxLength: maxLength(20)
		},
	},

	mounted() {

		this.empNo     = this.$route.params.data.empNo;
		this.empNm     = this.$route.params.data.name;
		this.empMobile = this.$route.params.data.mobile;

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

	},

	methods:{

		updateBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";

				let apiParams = new Map();
				apiParams.set("empNo"      , this.empNo);
				apiParams.set("empNm"      , this.empNm);
				apiParams.set("empMobile"  , this.empMobile);
				apiParams.set("password"   , this.password);
				apiParams.set("passwordCon", this.passwordCon);

				instance.get('/api/nice/changePw.json', {
					params : Object.fromEntries(apiParams)
				})
				.then((res => {
					let message = res.data.message;
	
					alert(message);
					this.$router.push('loginmain');
				}))
				.catch(err => {
					console.log(err);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		cancelBtn(){
			this.$router.push('findpassword');
			localStorage.clear();
		},

	}

}
</script>

<style>

</style>