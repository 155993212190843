<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">선불권(종이)</div>
			<ul>
				<li><router-link to="" title="안내" class="active">선불권(종이)</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>선불권(종이)</h2>
			</article>
			<article>
				<div id="tabs">
					<ul class="tab-menu-nonstop">
						<li v-for="(tab, index) in tabs" :key="index" v-bind:class="{active:currentTab===index}">
							<a href="#" v-on:click="currentTab = index">{{ tab }}</a>
						</li>
					</ul>
					<div class="tab-content">
						<!-- 안내 -->
						<section v-show="currentTab == 0">
							<article>
								<h3>공영주차 선불권 안내</h3>
								<p class="circle">신청자격</p>
								<ul>
									<li class="hyphen">화성시의 소상공인 사업자로서 현재 영업 중인자</li>
								</ul>
							</article>
							<article>
								<p class="circle">신청절차</p>
								<ul>
									<li class="hyphen">화성도시공사 주차사업팀 방문등록 &gt; 공영주차선불권 "선불권 신청" &gt; 공영주차선불권 "조회/결제" &gt; 화성도시공사 주차사업팀 방문 수령</li>
								</ul>
							</article>
							<article>
								<p class="circle">발급제한</p>
								<ul>
									<li class="hyphen">화성시 주차요금 체납 차량</li>
									<li class="hyphen">지역경제에 도움이 되는 소상공업자가 아니라 판단된 경우</li>
									<li class="hyphen">화성도시공사에서 요구하는 증명 서류들을 구비하지 못한 경우</li>
								</ul>
							</article>
							<article>
								<h3>요금안내</h3>
								<p class="circle">이용요금</p>
								<ul>
									<li class="hyphen">각 주차장별 전일요금의 50% 할인</li>
								</ul>
							</article>
							<article>
								<p class="circle">이용문의</p>
								<ul>
									<li class="hyphen">화성도시공사 주차사업팀 Tel:031-8053-8892</li>	
								</ul>
							</article>
							<article class="btn-list">
								<button class="one-btn">신청하기</button>
							</article>
						</section>
						<!-- 선불권 신청 -->
						<section v-show="currentTab == 1">
							<article>
								<h3>선불권 신청</h3>
								<p>선불권 신청은 <span class="bold">도시공사</span>로부터 인가받은 경우메만 가능합니다.</p>
								<table class="vertical-table">
									<caption class="sr-only">선불권 신청 테이블</caption>
									<colgroup>
										<col style="width:20%" />
										<col style="width:80%" />
									</colgroup>
									<tbody>
										<tr>
											<th scope="row" class="asterisk-after">주차장 선택</th>
											<td>
												<ul class="input-style">
													<li>
														<input type="text" placeholder="관리자" class="place-hide">
													</li>
												</ul>
											</td>
										</tr>
										<tr>
											<th class="asterisk-after">선불권 선택</th>
											 <td class="select-area double">
												<label for=""></label>
												<select name="" id="">
													<option value="" selected>선택하세요</option>
													<option value=""></option>
												</select>
												<label for=""></label>
												<select name="" id="">
													<option value="" selected>선택하세요</option>
													<option value=""></option>
												</select>
											</td>
										</tr>
										<tr>
											<th class="asterisk-after">구매 코드</th>
											<td>
												<ul class="input-style">
													<li>
														<input type="text" placeholder="구매 코드" class="place-hide">
													</li>
												</ul>
											</td>
										</tr>
										<tr>
											<th class="asterisk-after">총 구매가격</th>
											<td>
												<ul class="input-style">
													<li>
														<input type="text" placeholder="총 구매가격" class="place-hide">
													</li>
												</ul>
											</td>
										</tr>
									</tbody>
								</table>
							</article>
							<article class="btn-list">
								<button class="btn">동의</button>
								<button class="btn-border">동의 안함</button>
							</article>
						</section>
						<!-- 조회 / 결제 -->
						<section v-show="currentTab == 2">
							<h3>선불권 조회</h3>
							<p>'-'를 제외한 전화번호를 입력하여 주세요.(예:<span class="orange">01012345678</span>)</p>
							<article>
								<table class="lookup-table">
									<caption class="sr-only">조회 테이블</caption>
									<colgroup>
										<col style="width:20%" />
										<col style="width:80%" />
									</colgroup>
									<tbody>
										<tr>
											<th scope="row">전화번호</th>
											<td>
												<ul class="input-style">
													<li>
														<input type="text" v-model="inputText" placeholder="01012345678" class="place-hide">
													</li>
													<li>
														<button class="table-btn">조회</button>
													</li>
												</ul>
											</td>
										</tr>
									</tbody>
								</table>
							</article>
							<article>
								<table class="default-table-board-content paper">
									<caption class="sr-only">결제카드 등록 테이</caption>
									<colgroup>
										<col style="width:70px" />
										<col style="width:20%" />
										<col style="width:10%" />
										<col style="width:10%" />
										<col style="width:20%" />
										<col style="width:20%" />
										<col style="width:20%" />
									</colgroup>
									<thead>
										<tr>
											<th scope="col" rowspan="2">번호</th>
											<th scope="col" rowspan="2">주차장명</th>
											<th scope="col" colspan="2">선불권 내역</th>
											<th scope="col" rowspan="2">구매일</th>
											<th scope="col" rowspan="2">수령확인</th>
											<th scope="col" rowspan="2">금액</th>
										</tr>
										<tr>
											<th scope="col">선불권</th>
											<th scope="col">매수</th>
										</tr>
									</thead>
									<tbody>
										<tr class="nodate">
											<td colspan="7" >조회된 데이터가 없습니다.</td>
										</tr>
										<!-- <tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr> -->
									</tbody>
								</table>
							</article>
						</section>
					</div>
				</div>
			</article>	
		</section>
	</main>
</template>

<script>
export default {
	name: '#tabs',
	data(){
		return {
			currentTab: 0,
			tabs:['안내','선불권 신청','조회/결제']
		}
	},
}
</script>

<style>

</style>