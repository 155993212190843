<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차 정기권</div>
			<ul>
				<li><router-link to="periodinfo"      title="정기권신청 안내">안내</router-link></li>
				<li><router-link to="periodagreement" title="정기권 신청" class="active">정기권 신청</router-link></li>
				<li><router-link to="periodlookup"    title="정기권 조회 &#47; 결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 정기권</h2>
				<h4>정기권 신청</h4>
			</article>
			<article>
				<h3>정기권 신청</h3>
				<p>* 필수 입력 사항입니다.</p>
			</article>
			<table class="vertical-table">
				<caption class="sr-only">정기권 신청</caption>
				<colgroup>
					<col style="width:20%; min-width: 80px;" />
					<col style="width:80%" />
				</colgroup>
				<tbody>					
					<tr>
						<th scope="row">성명</th>
						<td>
							<ul class="input-style">
								<li>
									{{ waitingNm }}
								</li>
							</ul>
						</td>
					</tr>
					<tr>
						<th>연락처</th>
						<td>
							<ul class="input-style">
								<li>
									{{ telNo }}
								</li>
							</ul>	
						</td>
					</tr>
					<tr>
						<th class="asterisk-after">신청 주차장<span class="hide">(필수입력)</span></th>
						<td class="select-area hope">
							<div>
								<label for="sectnId"></label>
								<select name="sectnId" id="sectnId" v-model="sectnId">
									<option :value="{ id: '', text: '' }">선택하세요.</option>
									<option v-for="(item, index) in parkingList" :key="index" :value="{ id: item.SECTN_ID, text: item.PARKING_NM }">
										{{ item.PARKING_NM }}
									</option>
								</select>
								<div class="error" v-if="!$v.sectnId.required">필수 입력란입니다.</div>
							</div>
							<!-- <input type="button" class="table-btn black" @click="periodMap()" ref="focus" value="지도선택"> -->
							<button class="table-btn black" type="button"  ref="focus" @click="popupFocus(), periodMap()">지도선택</button>
						</td>
					</tr>
					<tr>
						<th class="asterisk-after">차량 소유자명</th>
						<td>
							<ul class="input-style">
								<li>
									<label for="ownerName" class="hide">차량소유자명</label>
									<input type="text" placeholder="홍길동" class="place-hide" id="ownerName" v-model="carOwnerNm">
									<div class="error" v-if="!$v.carOwnerNm.required">필수 입력란입니다.</div>
								</li>
							</ul>	
						</td>
					</tr>
					<tr>
						<th>차량번호</th>
						<td>
							<ul class="input-style">
								<li>
									{{ carNo }}
								</li>
							</ul>	
						</td>
					</tr>
					<tr>
						<th class="asterisk-after">할인</th>
						<td>
							<ul class="input-style select-message">
								<li>
									<label for="discount" class="hide">할인</label>
									<select name="" id="discount" v-model="discountCd">
										<option v-for="(item, index) in discountList" :key="index" :value="{ id: item.code, text: item.codeNm }">
											{{ item.codeNm }}
										</option>
									</select>
									<div class="error" v-if="!$v.discountCd.required">필수 입력란입니다.</div>
								</li>
							</ul>
							<p>
								<br>*&nbsp;경차, 저공해, 장애인, 국가유공자 할인을 제외한 할인유형은 
								<br>&nbsp;&nbsp;<b>이메일(hsparking@hsuco.or.kr)</b> 또는 <b>팩스(031-8059-6539)</b> 또는 <b>회원정보</b>에서 증빙자료를 보내주셔야 합니다.
							</p>
							<p>*&nbsp;정기권 신청 기간내에 증빙자료를 보내지 않으면 할인이 적용되지 않을 수 있습니다.</p>
						</td>	
					</tr>
				</tbody>
			</table>
			<article class="message">
				<p>즉시감면 자격확인은 최대 1분까지 시간이 소요될 수 있습니다.</p>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list">
				<button class="btn"        @click="checkIrs">확인</button>
				<button class="btn-border" @click="$router.push('periodinfo')">취소</button>
			</article>
		</section>
		<!-- 모달 지도 -->
		<template>
			<div v-show="mapModal" class="kakaoMap" id="kakao">
				<section class="modal-kakaoMap">
					<article class="title">
						<ul>
							<li id="modalTit">정기권 지도 검색<span class="hide">팝업창 (스페이스 키를 누르면 닫기 버튼으로 이동이 가능합니다. )</span></li>
							<li id="modalMap" class="popup-close" @click="mapController" @keyup.enter="mapController">
							<span class="hide">닫기 아이콘 (엔터키를 누르시면 팝업창이 닫아집니다.)</span></li>
						</ul>
					</article>
					<article class="content parking-inner">
						<div class="content-inner" id="container">

							<!-- Map 화면 들어갈 곳 -->
							<div id="mapWrapper">
								<div id="map" class="mapWH100"></div>

							</div>
						</div>
					</article>
				</section>
				<section class="dim"></section>
			</div>
		</template>
		<process-dimmed v-if="show"></process-dimmed>
	</main>
</template>

<script>
import axios from 'axios';
import { required, isUnique, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'
import ProcessDimmed from '../../../components/ProcessDimmed.vue';

const instance = axios.create({
  timeout: 30000,
});


const carNoRegex     = helpers.regex('carNoRegex', /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi);
const carNoRegexDipl = helpers.regex('carNoRegexDipl', /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi); //외교차량 정규식
let insertBtnCount 	 = 0;

export default {

	components: {
		ProcessDimmed
	},

	data(){
		return{
			show         : false,
			select       : '',
			waitingNm    : '',
			//zipcode      : '',
			//addr         : '',
			//addrDetail   : '',
			telNo        : '',
			sectnId      : {
				id   : '',
				text : '',
			},
			carOwnerNm   : '',
			carNo        : '',
			// authNoF      : '',
			// authNoB      : '',
			discountCd: {
				id: 'DC001',
				text: '일반'
			},
			extraCd      : 'DC050',
			extraNm      : '일반',
			showModal    : false,
			msg          : '',
			discountList : [],
			parkingList  : [],
			submitStatus : "",

			parkingMapList        : [],
			mapModal              : false,
			overlayOn             : false,
			container             : null,
			map                   : null,
			userLat               : 37.19952770562244,
			userLng               : 126.83145146431667,
			parkingNm             : '',
			parkingAmt            : '',
			parkingAmtNm          : '',
			parkingUseTm          : '',
			parkingUseTmNm        : '',
			mapContainer          : null,

			imageSrc              : null,
			imageSize             : null,
			imageOption           : null,
			clickImageOption      : null,
			marker                : null,
			active                : false,
			parkingName           : null,
			defaultParkingMapList : [],	// 주차장저장리스트
			markers               : [],	// 마커최소 저장리스트
			positions             : [],
			customOverlay         : [],
			mapGeocoder           : null,
			clickCustomOverlay    : [],
			clickStatus           : -1,	// 1 누른상태 , -1 취소상태
			cMarkerIndex          : -1,
			geoLocationState      : -1,
			userCustomOverlay     : null,
			userLocCustomOverlay  : null,
			distance              : "",
			message               : "<div style='padding:5px;'>화성시청</div>",
			park                  : "",
			def                   : "",
			defFlag               : false,
			ckImg                 : null,
		}
	},

	validations: {
		// waitingNm: {
		// 	required,
		// },
		//zipcode: {
		//	required,
		//},
		//addr: {
		//	required,
		//},
		//addrDetail: {
		//	required,
		//},
		// telNo: {
		// 	required,
		// },
		sectnId: {
			required,
		},

		carOwnerNm: {
			required,
		},
		// carNo: {
		// 	required,
		// 	carNoCheck: or(carNoRegex, carNoRegexDipl)
		// },
		// authNoF: {
		// 	required,
		// 	minLength: minLength(6),
		// 	maxLength: maxLength(6)
		// },
		// authNoB: {
		// 	required,
		// 	minLength: minLength(7),
		// 	maxLength: maxLength(7)
		// },
		discountCd: {
			required
		},
		extraCd: {
			required
		}
	},

	mounted() {
		this.getParkingAndDiscountList();
		this.selectPeriodMapList();
		this.empInfoSet();

		(window.kakao && window.kakao.maps) ? this.initMap() : this.createScript();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		const layer = document.querySelector("#kakao"),
		layerArrow = layer.querySelectorAll("li#modalMap, li#modalTit"),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = evt => {
			if (evt.shiftKey && evt.key === "Tab") {
					evt.preventDefault();
					layerLast.focus();
			}
		};

		const lastKeydown = evt => {
				if (!evt.shiftKey && evt.key === "Tab") {
						evt.preventDefault();
						layerFirst.focus();
				}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);
	},

	destroyed(){
		insertBtnCount = 0;	
	},

	methods:{
		mapController(){
			this.mapModal= false;
			this.$refs.focus.focus();
		},
		
		// popupFocus(){
		// 	document.getElementById('modalMap').focus();

		// 	const focusEle = document.activeElement;

		// 	if (document.getElementById('modalMap') == focusEle) {
		// 		console.log(true);
		// 	} else {
		// 		console.log(false);
		// 	}
		// },

		getParkingAndDiscountList() {
			instance.get('/api/period/getParkingAndDiscountList.json')
			.then((response => {
				var data = response.data;

				this.discountList = data.discountList;
				this.parkingList  = data.parkingList;

				this.discountCd.id = data.discountCd.code;
				this.discountCd.text = data.discountCd.codeNm;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		daumPostcodeBtn() {
			this.showModal = true;
		},

		insertBtn(){

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				this.show = true;

				let apiParams = new FormData();
				apiParams.append("waitingNm" , this.waitingNm);
				//apiParams.append("zipcode"   , this.zipcode);
				//apiParams.append("addr"      , this.addr);
				//apiParams.append("addrDetail", this.addrDetail);
				apiParams.append("telNo"     , this.telNo);
				apiParams.append("sectnId"   , this.sectnId.id);
				apiParams.append("carOwnerNm", this.carOwnerNm);
				apiParams.append("carNo"     , this.carNo);
				// apiParams.append("authNoF"   , this.authNoF);
				// apiParams.append("authNoB"   , this.authNoB);
				apiParams.append("discountCd", this.discountCd.id);
				apiParams.append("extraCd"   , this.extraCd);
	
				instance.post('/api/period/insertAction.json', apiParams)
				.then((response => {
					this.show = false;
					var data = response.data.value;

					if(data.result){
						let params = {
							waitingNm  : this.waitingNm,
							//zipcode    : this.zipcode,
							//addr       : this.addr,
							//addrDetail : this.addrDetail,
							telNo      : this.telNo,
							sectnNm    : this.sectnId.text,
							carOwnerNm : this.carOwnerNm,
							carNo      : this.carNo,
							// authNoF    : this.authNoF,
							// authNoB    : this.authNoB,
							discountNm : this.discountCd.text,
							extraNm    : this.extraNm
						}

						alert(data.message);
						this.$router.push({
							name   : 'periodresult',
							params : { data : params }
						})
					} else {
						alert(data.message);
					}
				}))
				.catch(function (error) {
					//console.log(error);
					this.show = false;
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		searchedAddress(data) {
		//	this.zipcode = data.zonecode;
		//	// 도로명을 선택하는 경우
		//	if(data.userSelectedType == 'R'){
		//		this.addr = data.address;
		//	}else {
		//		this.addr = data.jibunAddress;
		//	}
		//	this.$refs.addrDetail.focus();
		//	this.showModal = false;
		},

		selectPeriodMapList(){
			instance.get('/api/period/selectPeriodMapList.json')
			.then((res => {
				var resData = res.data.value;
				this.parkingMapList = resData;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			});
		},

		mapClickReset(){
			// 만약 클릭한 상태라면, 기존의 오버레이를 지운다.
			if(this.clickStatus == 1){

				for(var i = 0 ; i < this.positions.length ; i++){
					this.customOverlay[i].setMap(null);
				}

			}

			this.map.relayout();
		},

		// 무정차 전체 주차장 [지도보기] 클릭
		periodMap(){
			this.mapClickReset();
			this.positions = [];

			// 화성 시청
			this.userLat = 37.19952770562244;
			this.userLng = 126.83145146431667;

			for(var i = 0 ; i < this.parkingMapList.length ; i++){
				var parkingLot = {};
				parkingLot.id             = this.parkingMapList[i].SECTN_ID;
				parkingLot.title          = this.parkingMapList[i].PARKING_NM;
				parkingLot.fullNm         = this.parkingMapList[i].PARKING_FULL_NM;
				parkingLot.cellCnt        = this.parkingMapList[i].CELL_CNT;
				parkingLot.periodUseDivCd = this.parkingMapList[i].PERIOD_USE_DIV_CD;
				parkingLot.periodCellCnt  = this.parkingMapList[i].PERIOD_CELL_CNT;
				parkingLot.latlng         = new kakao.maps.LatLng(this.parkingMapList[i].LAT, this.parkingMapList[i].LNG);
				this.positions.push(parkingLot);

				if(this.sectnId.id == parkingLot.id){
					this.userLat = this.parkingMapList[i].LAT;
					this.userLng = this.parkingMapList[i].LNG;
				}
			}

			setTimeout(() => {
				this.map.relayout();
				this.setMarkers(this.positions);
				this.map.setLevel(6);
				this.map.setCenter(new kakao.maps.LatLng(this.userLat, this.userLng));
			}, 1);
			
		},

		popupFocus(){
			this.mapModal = true;
			//document.querySelector("#modalTit").focus();
			setTimeout(function(){
				document.getElementById('modalTit').focus();
			});
		},

		// 사용자 gps 마커 오버레이 제거
		userCusOverlay(){
			var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:200px;">'
								+     '<dl style="margin-bottom: 0px;"><dt>' + this.message + '</dt></dl>'
								+ '</div>'
			;

			var cusOverLat = this.userLat;
			var cusOverLng = this.userLng;

			var cusOverLocPosition = new kakao.maps.LatLng(cusOverLat, cusOverLng);
			this.userCustomOverlay = new kakao.maps.CustomOverlay({
				position : cusOverLocPosition,
				content  : customContent,
				xAnchor  : 0.017,
				yAnchor  : 0.5
			});
		},

		// userLat, userLng 위치값으로부터 화성시 영역 여부를 반환 (좌표위치값을 주소로 변환하여 판단)
		changeAddressArea(){

			if(this.mapGeocoder == null){
				this.mapGeocoder = new kakao.maps.services.Geocoder();
			}

			this.mapGeocoder.coord2Address(this.userLng, this.userLat, this.checkUserLoc);

		},

		checkUserLoc(result, status){

			if(status === kakao.maps.services.Status.OK){
				var userAddr = result[0].address.address_name;	// 전체 지번 주소

				if((this.nullChk(userAddr)).indexOf("화성") == -1){
					// 지번주소에 화성시 있는지 판단
					this.userLat = 37.19952770562244;
					this.userLng = 126.83145146431667;
				}

				this.message = "<div style='padding:5px;'>내 위치</div>";
				this.displayMarker(this.userLat, this.userLng, this.message);
				this.map.setCenter(new kakao.maps.LatLng(this.userLat, this.userLng));

			}

		},

		nullChk(x){
			return (x == null || x == undefined) ? "" : x;
		},

		mapMarker(){
			return require("../../../assets/images/map/step2.png");
		},

		mapPcMarker(){
			return require("../../../assets/images/map/map_pc.png");
		},

		createScript(){
			const script  = document.createElement("script");
			script.onload = () => kakao.maps.load(this.initMap);
			script.src    = "//dapi.kakao.com/v2/maps/sdk.js?appkey=5130af43da731f05b3be59b4730e2676&libraries=services&autoload=false";
			document.head.appendChild(script);
		},

		initMap(){

			this.container = document.getElementById('container');		// 지도와 로드뷰를 감싸고 있는 div

			const mapContainer = document.getElementById('map');		// 지도를 표시할 div

			var mapCenter = new kakao.maps.LatLng(this.userLat, this.userLng);

			// 지도를 생성할 때 필요한 기본 옵션
			const mapOptions = {
				center : mapCenter,	// 지도의 중심좌표
				level  : 4			// 지도의 레벨(확대, 축소 정도)
			};

			// 지도를 표시할 div와 지도 옵션으로 지도를 생성
			this.map = new kakao.maps.Map(mapContainer, mapOptions);

			//일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
			var mapTypeControl = new kakao.maps.MapTypeControl();

			// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
			this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);	// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
			// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다

			this.imageSrc    = this.mapMarker();							// 마커이미지의 주소입니다
			this.imageSize   = new kakao.maps.Size(20, 20);					// 마커이미지의 크기입니다
			this.imageOption = { offset: new kakao.maps.Point(12, 34) };	// 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

			this.displayMarker(this.userLat, this.userLng);
			
		},

		// 현재 위치에 표시를 위한 마커 만들어준다
		displayMarker(userLat, userLng){

			var locPosition   = new kakao.maps.LatLng(userLat, userLng);
			this.map.setCenter(locPosition);
			this.map.relayout();

		},

		// 각 주차장 정보를 받아 마커를 지도에 뿌려준다.
		setMarkers(positions, limitKm){
			this.controlhideMarkers();
			this.markers = [];

			// 초록색
			var markerImage = new kakao.maps.MarkerImage(this.imageSrc, this.imageSize, this.imageOption);

			if(positions != null) {
				for(var i = 0 ; i < positions.length ; i++){

					this.markers[i] = new kakao.maps.Marker({
						map       : this.map,				// 마커를 표시할 지도
						position  : positions[i].latlng,	// 마커의 위치
						title     : positions[i].title,
						clickable : true,					// 마커 클릭가능. (지도의 클릭이벤트가 발생하지않도록 함)
						id        : positions[i].id,
						image     : markerImage
					});

					var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:300px; position: absolute; top: -140px; left: -150px;">'
										+     '<dl style="margin-bottom: 0px;">'
										+         '<dt>' + positions[i].title + '</dt>'
										+     '</dl>'
										+ '</div>'
					;

					this.customOverlay[i] = new kakao.maps.CustomOverlay({
						position  : positions[i].latlng,
						content   : customContent,
						xAnchor   : 0.017,
						yAnchor   : 0.5
					});

					//kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeOverListener(this.markers[i], this.customOverlay[i], positions[i].title));	// 마커 호버 이벤트
					kakao.maps.event.addListener(this.markers[i], 'click', this.makeClickListener(this.markers[i], i, this.customOverlay[i]));	// 마커 클릭 이벤트
					//kakao.maps.event.addListener(this.markers[i], 'mouseout' , this.makeOutListener(this.customOverlay[i], this.markers[i], i));						// 마커 호버아웃 이벤트
				}
			}

		},

		createCustomOverlay(position, customOverlay){

			var ctag_dt1 = document.createElement("dt");
				ctag_dt1.appendChild(document.createTextNode(position.fullNm));

			var ctag_closeBtn = document.createElement("button");
				ctag_closeBtn.appendChild(document.createTextNode("닫기"));
				ctag_closeBtn.style.cssText = "padding: 0 5px 0 5px; margin-right: 10px; border: 0; background: #FFFFFF; color: #F56B13;  border-radius: 3px;";
				ctag_closeBtn.onclick = () =>{
					customOverlay.setMap(null);
				}

			var ctag_applyBtn = document.createElement("button");
				ctag_applyBtn.appendChild(document.createTextNode("정기권 신청"));
				ctag_applyBtn.style.cssText = "padding: 0 5px 0 5px; margin-right: 10px; border: 0; background: #F56B13; color: #FFFFFF;  border-radius: 3px;";
				ctag_applyBtn.onclick = () => {
					this.setSectnIdMap(position);
				}

			var ctag_dt2 = document.createElement("dt");
				ctag_dt2.appendChild(ctag_applyBtn);
				ctag_dt2.appendChild(ctag_closeBtn);

			var ctag_dl = document.createElement("dl");
				ctag_dl.style.cssText = "margin-bottom : 0px;";
				ctag_dl.appendChild(ctag_dt1);
				ctag_dl.appendChild(ctag_dt2);

			var ctag_div = document.createElement("div");
				ctag_div.style.cssText = "min-height:0; width:300px; position: absolute; top: -100px; left: -150px;";
				ctag_div.className = "mapCustomOverlay-box";
				ctag_div.appendChild(ctag_dl);

			customOverlay.setContent(ctag_div);

		},

		makeClickListener(marker, idx, customOverlay){
			return () => {

				this.createCustomOverlay(this.positions[idx], customOverlay);

				// 로드뷰 오버레이 나왓을시 마커 클릭 안되게함.
				if(this.overlayOn) {
					return null;
				}

				this.def         = this.positions[idx];
				this.defFlag     = true;
				this.clickStatus = 1;

				if(this.cMarkerIndex != -1){
					customOverlay.setMap(null);
					if(this.clickCustomOverlay){
						this.clickCustomOverlay.setMap(null);
					}
				}

				for(var i = 0 ; i < this.markers.length ; i++){

					if(this.markers[i] == marker){
						this.cMarkerIndex = i;													// 클릭된 마커 id값 저장
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);	// 클릭된 마커 크기및 이미지 변경
						kakao.maps.event.removeListener(this.markers[i], 'click', this.makeOutListener(customOverlay, this.markers[i]));
						customOverlay.setMap(this.map);
						this.clickCustomOverlay = customOverlay;
					}else{
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);
					}
				}
			}
		},

		// 마커 정기권 신청 클릭
		setSectnIdMap(positions){
			this.sectnId.id   = positions.id;
			this.sectnId.text = positions.title;
			this.mapModal = false;
		},

		// 마크 에 마우스를 빼면 발생하는 이벤트(아웃)
		makeOutListener(customOverlay, marker, idx){
			return () => {

				if(idx != this.cMarkerIndex){

					var markerImage = new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption);
					marker.setImage(markerImage);
					customOverlay.setMap(null);

				}else{

					if(this.clickStatus == 1){
						this.clickStatus == -1;
						marker.setImage(new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption));
						customOverlay.setMap(null);
					}

				}

			}
		},

		// 거리 및 확대레벨에 따른 마커 출력 함수
		changeImage(markers, imageSrc, imageOption){

			var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, imageOption);

			markers.setImage(markerImage);
			markers.setMap(this.map);

		},

		// "마커 감추기" 버튼을 클릭하면 호출되어 배열에 추가된 마커를 지도에서 삭제하는 함수입니다
		controlhideMarkers(){
			this.controlsetMarkers(null);
		},

		controlsetMarkers(map){
			for (var i = 0 ; i < this.markers.length ; i++) {
				this.markers[i].setMap(map);
			}
		},

		empInfoSet(){
			this.waitingNm = this.$store.state.empNm;
			this.carOwnerNm = this.$store.state.empNm;
			this.telNo = this.$store.state.empMobile;
			this.carNo = this.$store.state.empCarNo;
			this.zipcode = this.$store.state.zipcode;
			this.addr = this.$store.state.addr;
			this.addrDetail = this.$store.state.addrDetail;
		},

		checkIrs(){
			if(insertBtnCount == 0){
				if(this.$v.$invalid) {
					this.submitStatus = "ERROR";
					alert("필수 입력란을 채워주세요.")
					return false;
				}else if(this.sectnId.id == ""){
					alert("필수 입력란을 채워주세요.")
					return false;
				}else{
					this.show = true;
					this.submitStatus = "OK";
					let apiParams = new FormData();

					apiParams.append("discountCd", this.discountCd.id);
					apiParams.append("sectnId"   , this.sectnId.id);
					apiParams.append("carNo"     , this.carNo);
					apiParams.append("carOwnerNm", this.carOwnerNm);
			
					instance.post('/api/period/checkIRS.json', apiParams)
					.then((response => {
						this.show = false;
						let data = response.data.value;

						if(data.carNoDupCheck.result) {

						} else {
							insertBtnCount = 0;
							alert(data.carNoDupCheck.useEndDt + "에 이미 신청되었습니다.");
							return false;
						}
							
						if(this.discountCd.id == "DC002" || this.discountCd.id == "DC003" || this.discountCd.id == "DC004" ||
							this.discountCd.id == "DC005" || this.discountCd.id == "DC007"){

							if(data.dcFlag === false){
								alert("할인 조회에 실패하여 일반으로 적용됩니다.");
								this.discountCd.id = data.dcCode;
								this.discountCd.text = data.dcCodeNm;
								insertBtnCount = 0;
								return false;
							}

						}else if(this.discountCd.id != "DC001"){
							alert("정기권 신청 기간내에 증빙자료를 보내지 않으면 할인이 적용되지 않을 수 있습니다.");
						}

						this.insertBtn();

					}))
						.catch(function (error) {
							alert("서버와 통신중 오류가 발생하였습니다.")
							this.show = false;
						})
				}
				insertBtnCount++;
			}else if(insertBtnCount > 0){
				return false;
			}
		}
	},
}

</script>

<style scoped>
	.mapWH100{ width: 100%; height: 100%; }

	#container {
		overflow : hidden;
		height   : 590px;
		position : relative;
	}
	#mapWrapper {
		width   : 100%;
		height  : 550px;
		z-index : 5;
	}

	.modal-kakaoMap {
		position   : fixed;
		border     : 1px solid;
		display    : block;
		background : #FFFFFF;
		left       : 50%;
		top        : 50%;
		width      : 700px;
		height     : 675px;
		transform  : translate(-50%, -50%);
		z-index    : 2;
	}

	.modal-kakaoMap .title {
		background : #333333;
		height     : 50px;
	}

	.modal-kakaoMap .title ul {
		margin-top : 0 !important;
	}

	.modal-kakaoMap .title ul {
		display         : flex;
		justify-content : space-between;
		padding         : 10px 20px;
		box-sizing      : border-box;
		list-style      : none;
	}

	.modal-kakaoMap .title ul li:first-child {
		padding-left : 0;
		width        : 700px;
		text-align   : center;
		box-sizing   : border-box;
	}

	.modal-kakaoMap .title ul li {
		color       : #FFFFFF;
		font-size   : 1.25rem;
		font-weight : 400;
		list-style  : none;
	}

	.modal-kakaoMap .title ul li.popup-close {
		cursor : pointer;
	}

	.modal-kakaoMap .content {
		height     : 620px;
		padding    : 15px 15px 30px;
		box-sizing : border-box;
	}

	.modal-kakaoMap .content .content-inner {
		padding     : 20px;
		background  : #EFEFEF;
		height      : 100%;
		text-align  : center;
		line-height : 1.5;
		box-sizing  : border-box;
	}

	.modal-kakaoMap .btn-list {
		margin-top  : 45px;
		padding-top : 25px;
		border-top  : 1px solid #eeeeee;
	}
</style>