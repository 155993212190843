<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>회원가입</h2>
			</article>
			<article class="figure">
				<figure class="img-join"></figure>
				<ul>
					<li>회원 가입이 완료되었습니다.</li>
					<li>로그인 후 이용가능합니다.</li>
				</ul>
			</article>
			<article class="btn-list">
				<button class="one-btn black" @click="$router.push('loginmain')">로그인페이지로 이동</button>
			</article>
		</section>
	</main>
</template>

<script>
export default {
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>