<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">열린공간</div>
			<ul>
				<li><router-link to="noticelist"    title="공지사항">공지사항</router-link></li>
				<li><router-link to="questionslist" title="자주하는 질문" class="active">자주하는 질문</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>열린공간</h2>
				<h4>자주하는 질문</h4>
			</article>
			<article>
				<h3>자주하는 질문</h3>
			</article>
			<!-- Table List Area -->
			<table class="vertical-table-board">
				<caption class="sr-only">자주하는질문 View 테이블</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">제목</th>
						<td>
							{{ boardObj.title }}
						</td>
					</tr>
					<tr>
						<th>내용</th>
						<td>
							<!-- {{ boardObj.cts }} -->
							<div v-html="cts" class="ckeditorCts"></div>
						</td>
					</tr>
					<tr>
						<th>첨부파일</th>
						<td>
							<ul class="input-style button interval">
								<li class="attach" v-for="(item, index) in attachList" :key="index">
									<div class="file-name">
										<span class="cursor" @click="downloadAttachFile(item.filePath, item.saveNm, item.viewNm, item.fileExt, item.repoId)">
											{{ item.viewNm }} ({{ item.fileSize | comma }})kb
										</span>
									</div>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<article class="btn-list right">
				<button class="btn" @click="$router.push('questionslist')">목록</button>
			</article>
			<!--
				<article>
					<div class="board-view-info pc">
						<ul>
							<li><span class="bold">작성</span></li>
							<li>
								<input type="text" :value="boardObj.regNm" readonly>
							</li>
							<li><span class="bold orange">수정</span></li>
							<li>
								<input type="text" :value="boardObj.updNm" readonly>
							</li>
							<li><span class="bold">작성일시</span></li>
							<li>
								<input type="text" :value="boardObj.regDttm | ptDateFormat" readonly>
							</li>
							<li><span class="bold orange">수정일시</span></li>
							<li>
								<input type="text" :value="boardObj.updDttm | ptDateFormat" readonly>
							</li>
						</ul>
					</div>
					<div class="board-view-info mo">
						<ul>
							<li><span class="bold">작성</span></li>
							<li>{{ boardObj.regNm }}</li>
							<li><span class="bold">작성일시</span></li>
							<li>{{ boardObj.regDttm | ptDateFormat }}</li>
						</ul>
					</div>
					<div class="board-view-info mo">
						<ul>
							<li><span class="bold orange">수정</span></li>
							<li>{{ boardObj.updNm }}</li>
							<li><span class="bold orange">수정일시</span></li>
							<li>{{ boardObj.updDttm | ptDateFormat }}</li>
						</ul>
					</div>
				</article>
			-->
		</section>
	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return {
			atclId     : this.$route.query.atclId,
			boardObj   : '',
			attachList : [],
			bbsCd      : 'faq',
			menuUrl    : "/api/board/faq",
			cts        : "",
		}
	},

	mounted() {
		this.initView();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		document.querySelector("#content").focus();
		document.querySelector("#content").style.border="0";
	},

	methods: {
		initView(){
			instance.get('/api/board/' + this.bbsCd + '/view.json', {
				params: {
					atclId : this.atclId
				}
			})
			.then((response => {
				var data        = response.data;
				this.boardObj   = data.boardView;
				if(this.boardObj.cts != "" || this.boardObj.cts != null){
					this.cts = this.boardObj.cts;
				}
				this.attachList = data.attachList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		downloadAttachFile(filePath, saveNm, viewNm, fileExt, repoId) {
			location.href = this.menuUrl + "/download.json?filePath=" + filePath + "&saveNm=" + saveNm + "&viewNm=" + viewNm + "&fileExt=" + fileExt + "&repoId=" + repoId;
		}
	},
	
}
</script>

<style scoped>
	.ckeditorCts { min-height: 300px; }
</style>