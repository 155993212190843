<template>
  <div class="post-code">
    <section class="modal-manager zip-code">
      <article class="title">
        <ul>
          <li>
             우편번호
          </li>
          <li id="modalMap" class="popup-close" @click="modalHide" @keydown.enter="modalHide">
          <span class="hide">닫기 아이콘</span>
          </li>
        </ul>
      </article>
      <article class="content">
        <vue-daum-postcode @complete="emitSearchedAddress"/>
      </article>
    </section>
    <section class="dim"></section>
  </div>
</template>

<script>
export default {
  data(){
    return{
      showModal : true
    }
  },
  methods:{
    emitSearchedAddress(data){
      this.$emit('emitSearchedAddress', data);
    },

    modalHide(){
      this.$emit('emitHideModal');
    }
  }
}
</script>