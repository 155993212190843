<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">사업자</div>
			<ul>
				<li><router-link to="" title="선불(포인트) 조회/적용">선불(포인트) 조회/적용</router-link></li>
				<li><router-link to="" title="포인트 결제" class="active">포인트 결제</router-link></li>
				<li><router-link to="" title="포인트 사용 내역">포인트 사용 내역</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>사업자</h2>
				<h4>포인트 결제</h4>
			</article>
			<article>
				<table class="lookup-table">
					<caption class="sr-only">조회 테이블</caption>
					<colgroup>
						<col style="width:40%" />
						<col style="width:60%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">사업자 [0000000000]</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<input type="text" v-model="inputText" placeholder="적립포인트:20,000 P" class="place-hide">
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<h3>포인트 결제</h3>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">포인트 결제 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">구매 포인트</th>
							<td class="select-area full">
								<label for=""></label>
								<select name="" id="">
									<option value="" selected>구매 포인트를 선택하세요.</option>
									<option value="">50,000 P</option>
									<option value="">100,000 P</option>
									<option value="">150,000 P</option>
									<option value="">200,000 P</option>
									<option value="">300,000 P</option>
									<option value="">500,000 P</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>현재 포인트</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="20,000 P" disabled>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>합계 포인트</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="70,000 P" disabled>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>	
			</article>
			<article class="btn-list">
				<button class="btn">결제</button>
				<button class="btn-border">선불(포인트)</button>
			</article>	
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style>

</style>