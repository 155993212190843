<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo"        title="안내">안내</router-link></li>
				<li><router-link to="residentsearch"      title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup"      title="조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 조회/결제</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 신청 조회</h3>
				<p>
					차량번호를 <span class="bold">조회 &#47; 거주자 우선 주차 배정 결제</span> 하실 수 있습니다.
					<span class="bold">전체 차량번호</span>를 입력하여 주세요.(예:<span class="orange">00가0000</span>)
				</p>
				<table class="lookup-table">
					<caption class="sr-only">거주자 우선 주차장 신청 조회 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">차량 번호</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<label for="carNumber" class="hide">차량번호 입력 (예: 00가0000)</label>
											<input type="text" ref="car" v-model="carNo" placeholder="00가0000" class="place-hide" id="carNumber">
										</li>
										<li>
											<button class="table-btn" @click="searchCarNo(carNo)">조회</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<h3>차량번호 선택</h3>
				<p>
					자신의 차량번호를 선택하여 주세요.<span class="orange">타인의 차량 결제 시 환불이 되지 않으니 확인하여 주십시오.</span>	
				</p>
				<table class="default-table">
					<caption class="sr-only">차량번호 선택 테이블</caption>
					<colgroup>
						<col style="width:10%" />
						<col style="width:30%" />
						<col style="width:25%" />
						<col style="width:15%" />
						<col style="width:20%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">차량번호</th>
							<th scope="col">신청한 주차구역</th>
							<th scope="col">이용 기간</th>
							<th scope="col">신청일</th>
							<th scope="col">신청 상태</th>
						</tr>
					</thead>
					<tbody>
						<tr class="nodate" v-if="nodata" >
							<td colspan="5">조회된 데이터가 없습니다.</td>
						</tr>
						<tr v-for="(item, index) in item" :key="index">
							<td>
								<a href="#" @click="residentView(item)" @keyup.enter="residentView(item)" title="차량번호">
									{{ item.carNo }}
								</a>
							</td>
							<td>
								<span class="emphasis-view">{{ item.dongNm }}</span>
								{{ item.sectnNm }}
								<template v-if="item.cellNm != '' && item.cellNm != null">
									{{ item.cellNm }}
								</template>
							</td>
							<td>{{ item.startDt | longToDate }} ~ {{ item.endDt | longToDate }}</td>
							<td>{{ item.reqDt | longToDate }}</td>
							<td v-if="item.allotStsCd == '' || item.allotStsCd == null" class="black">
								신청중
								<span v-if="item.waitingOdr != '' && item.waitingOdr != null" class="period-waiting">
									<template v-if="item.assignMethod == 'RPA-004'">
										<!-- 구간제 -->
										(대기 {{ item.waitingOdr }} 순위)
									</template>
									<template v-else>
										<!-- 종합점수제, 순위제, 무작위 랜덤(추첨제) -->
										(신청 {{ item.waitingOdr }} 순위)
									</template>
								</span>
							</td>
							<td v-else-if="item.allotStsCd == 'ASS-001'">
								<!-- 요금미납 -->
								<span v-if="item.limit < nowDate" class="period-unpaid">결제기간 만료</span>
								<span v-else class="period-unpaid">{{ item.allotStsNm }}</span>
							</td>
							<td v-else-if="item.allotStsCd == 'ASS-002'">
								<!-- 배정확정 -->
								<span class="period-complete">{{ item.allotStsNm }}</span>
							</td>
							<td v-else>
								<!-- 배정취소 -->
								<span class="period-cancel">{{ item.allotStsNm }}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
		</section>
		<process-dimmed v-if="show"></process-dimmed>
	</main>
</template>

<script>

import axios from 'axios';
import ProcessDimmed from '../../../components/ProcessDimmed.vue';

const instance = axios.create({
  timeout: 30000,
});

export default {

	components: {
		ProcessDimmed
	},

	data() {
		return{
			pageNo  : 1,
			limit   : 10,
			total   : "",
			carNo   : "",
			item    : [],
			nodata  : true,
			nowDate : this.getNowDate(),
			show    : false
		}
	},
	mounted(){
		this.carNo = this.$route.query.carNo;

		if(this.carNo != null && this.carNo != ""){
			this.searchCarNo(this.carNo);
		}

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	filters : {
		longToDate : function(x){
			if(x != null){
				var d = new Date(x);

				var year  = d.getFullYear();
				var month = d.getMonth() + 1;
				month     = (month < 10) ? "0" + month.toString() : month.toString();
				var day   = d.getDate();
				day       = (day < 10) ? "0" + day.toString() : day.toString();

				return year + "-" + month + "-" + day;
			}
		}
	},
	methods: {
		onSubmit(e){
			e.preventDefault();
		},
		// 지금 시간 가져오기
		getNowDate(){
			var d = new Date();

			var year  = d.getFullYear();
			var month = d.getMonth() + 1;
			month     = (month < 10) ? "0" + month.toString() : month.toString();
			var day   = d.getDate();
			day       = (day < 10) ? "0" + day.toString() : day.toString();

			return year + "-" + month + "-" + day;
		},
		// 차량번호 조회
		searchCarNo : function(carNo){

			var jsonList = [];
			this.carNo   = this.carNo.replace(/(\s*)/g, "");

			var carNoRegex     = /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi;
			var carNoRegexDipl = /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi; //외교차량 정규식

			if(!carNoRegex.test(this.carNo) && !carNoRegexDipl.test(this.carNo)){

				alert("* 차량번호 형식에 맞지 않습니다. 예:00가0000");
				this.$refs.car.focus();

			}else{
				this.show = true;
				instance.get('/api/resident/searchResidentList.json', {
					params: {
						carNo : this.carNo
					}
				})
				.then((response => {
					this.show = false;

					var data = response.data.value;

					if(data == 'undefined' || data == null || data.result === null){

						this.item   = null;
						this.nodata = true;

					}else{
						
						for(var i = 0 ; i < data.result.length ; i++){

							jsonList.push(data.result[i]);

						}

						if(jsonList.length > 0){
							this.item   = jsonList;
							this.nodata = false;
							this.pageNo = data.pageNo.page;
							this.limit  = data.pageNo.limit;
							this.total  = data.pageNo.total;
						}else{
							this.item   = null;
							this.nodata = true;
							this.pageNo = 1;
							this.limit  = 10;
							this.total  = 0;
						}

					}
				}))
				.catch(function (err) {
					//console.dir(err);
					this.show = false;
					alert("서버와 통신중 오류가 발생하였습니다.");
				})

			}
		},
		// 거주자 우선 주차 상세보기
		residentView : function(item){

			var params = {
				carNo   : item.carNo,
				allotId : item.allotId
			}

			this.$router.push({
				name   : 'residentlookupview',
				params : { data : params }
			});

		}
	}
}
</script>

<style>

</style>