<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드" class="active">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>공통코드</h4>
			</article>
			<article>
				<div class="tab-content">
					<article>
						<h3>공통코드</h3>
					</article>
					<article>
						<table class="vertical-table">
							<caption class="sr-only">공통코드 수정 테이블</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row" class="asterisk-after">코드타입</th>
									<td>{{codeTy}}</td>
								</tr>
								<tr>
									<th class="asterisk-after">코드명</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" placeholder="코드명" class="place-hide" v-model="nm">
												<div class="error" v-if="!$v.nm.required">필수 입력란입니다.</div>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="asterisk-after">사용여부</th>
									<td class="select-area">
										<label for=""></label>
										<select name="" id="" v-model="useYn">
											<option value="Y">사용</option>
											<option value="N">미사용</option>
											<div class="error" v-if="!$v.useYn.required">필수 입력란입니다.</div>
										</select>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">코드설명</th>
									<td>
										<textarea class="in-table place-hide" placeholder="코드설명" v-model="note">

										</textarea>
										<div class="error" v-if="!$v.note.required">필수 입력란입니다.</div>
									</td>
								</tr>
							</tbody>
						</table>
					</article>
					<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
					<article class="btn-list right">
						<button class="btn" @click="cancelBtn">목록</button>
						<button class="btn-border" @click="updateBtn">수정</button>
					</article>	
				</div>
			</article>	
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators'
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			codeTy: "",
			nm: "",
			useYn: "",
			note: "",
			submitStatus: ""
		}
	},

	validations: {
		nm: {
			required
		},
		useYn: {
			required
		},
		note: {
			required
		}
	},

	created() {
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("data"));
		}else{
			localStorage.setItem("data", JSON.stringify(data));
		}

		this.codeTy = data.codeTy;
		this.nm = data.nm;
		this.useYn = data.useYn;
		this.note = data.note;
	},

	methods: {
		updateBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("codeTy", this.codeTy);
				apiParams.append("nm", this.nm);
				apiParams.append("useYn", this.useYn);
				apiParams.append("note", this.note);

				instance.post('/api/code/codeUpdateAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('codelist');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		cancelBtn(){
			this.$router.push('codelist');
			localStorage.clear();
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>