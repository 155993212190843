<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>회원가입</h2>
				<h4>본인인증</h4>
			</article>
			<article class="step">
				<div>STEP 01</div>
			</article>
			
			<article>
				<h3>본인인증</h3>
				<ul>
					<li class="hyphen">
						「개인정보보호법」 등 관련 법령에 의해 주민등록번호 수집·이용 제한 및 기수집한 정보를 파기하여야 함에 따라 휴대폰 본인인증 회원가입할 수 있습니다.
					</li>
				</ul>

				<h3>휴대폰 본인인증이란?</h3>
				<ul>
					<li class="hyphen">
						모바일 본인인증 서비스는 휴대폰 번호 기반의 본인인증서비스로 개인정보 없이도 실명확인, 본인확인이 가능한 서비스 입니다.
					</li>
					<li class="hyphen">
						휴대폰 인증이랑 주민등록번호 대체수단으로 회원님의 휴대 전화를 통해 본인확인을 하는 서비스입니다.
					</li>
					<li>
						<p class="orange">※ 본인인증 오류가 발생 할 경우 인증창을 닫은 후 「새로고침」 후에 재시도 부탁드립니다.</p>
					</li>
				</ul>

				<h3>진행방법</h3>
				<ul>
					<li class="hyphen">
						「휴대폰인증」버튼 클릭 → 새(팝업)창 이동 → 휴대폰 이동통신사 선택 → 정보입력 → 본인인증확인
					</li>
					<li class="hyphen">
						본인인증이 잘 되지 않으시면 본인인증기관 고객센터로 문의 해주세요.
					</li>
					<li class="hyphen">
						문의 : NICE 평가정보 고객센터(1600-1522)
					</li>
				</ul>
			</article>
			<article class="btn-list">
				<button class="btn" @click="checkNiceID()" title="새창열림(본인인증)">본인인증</button>
			</article>

		</section>

		<form name="form_chk" method="post" ref="form_chk">
			<input type="hidden" name="m"          value="checkplusService" >
			<input type="hidden" name="EncodeData" v-model="sEncData">
		</form>

		<form name="form_nice" ref="form_nice">
			<input type="hidden" id="nice_name"       name="nice_name" >
			<input type="hidden" id="nice_mobile"     name="nice_mobile" >
			<input type="hidden" id="nice_birthDate"  name="nice_birthDate" >
			<input type="hidden" id="nice_msg"        name="nice_msg" >
			<input type="hidden" id="memberDuplCheck" name="memberDuplCheck" >
		</form>

	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			sEncData        : "",
			nice_name       : "",
			nice_mobile     : "",
			nice_birthDate  : "",
			nice_msg        : "",
			memberDuplCheck : "",
			intervals       : [],
		}
	},

	methods:{

		setValues(name, mobile, birth, msg, memberDuplCheck){

			this.nice_name       = name;
			this.nice_mobile     = mobile;
			this.nice_birthDate  = birth;
			this.nice_msg        = msg;
			this.memberDuplCheck = memberDuplCheck;

			if(memberDuplCheck > 0){
				alert("이미 가입되어 있는 회원입니다.");
				return false;
			}else{

				var params = {
					name   : name,
					mobile : mobile,
					birth  : birth,
				}

				this.$router.push({
					name   : 'membershipagreement',
					params : { data : params }
				});

			}

		},

		// Nice 본인인증
		checkNiceID(){
			
			window.name = "Parent_window";
			var niceWindow = window.open("/api/nice/checkPlusSafe_main", "popupChk", "width=500, height=550, top=100, left=1000, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");
			let interval = window.setInterval(() => {
				if(niceWindow.closed && document.form_nice.nice_mobile.value){

					this.intervals.forEach(element => {
						window.clearInterval(element);
					});

					var name            = document.form_nice.nice_name.value;
					var mobile          = document.form_nice.nice_mobile.value;
					var birth           = document.form_nice.nice_birthDate.value;
					var msg             = document.form_nice.nice_msg.value;
					var memberDuplCheck = document.form_nice.memberDuplCheck.value;

					this.setValues(name, mobile, birth, msg, memberDuplCheck);
				}
			}, 500);

			this.intervals.push(interval);

			/*
			instance.get("/api/nice/checkPlusSafe_main.json")
			.then((res => {

				var resData   = res.data.sEncData;
				this.sEncData = resData;

				window.name = "Parent_window";
				var niceWindow = window.open("", "popupChk", "width=500, height=550, top=100, left=1000, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");
				document.form_chk.EncodeData.value = resData;
				document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
				document.form_chk.target = "popupChk";
				this.$refs.form_chk.submit();
				
				let interval = window.setInterval(() => {

					if(niceWindow.closed && document.form_nice.nice_mobile.value){

						this.intervals.forEach(element => {
							window.clearInterval(element);
						});

						var name            = document.form_nice.nice_name.value;
						var mobile          = document.form_nice.nice_mobile.value;
						var birth           = document.form_nice.nice_birthDate.value;
						var msg             = document.form_nice.nice_msg.value;
						var memberDuplCheck = document.form_nice.memberDuplCheck.value;

						this.setValues(name, mobile, birth, msg, memberDuplCheck);
					}
				}, 500);

				this.intervals.push(interval);

			}))
			.catch(err => {
				//console.dir(err);
			})
			*/

		}, 
		
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	beforeRouteLeave(to, from, next) {
		this.intervals.forEach(element => {
			window.clearInterval(element);
		});
		next();
	}

}
</script>

<style>

</style>