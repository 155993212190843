<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">주차요금 사전결제</div>
			<ul>
				<li><router-link to="prepaymentinfo" title="사전결제 안내" >안내</router-link></li>
				<li><router-link to="lookup" title="사전결제 조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>주차요금 사전결제</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>주차요금 결제</h3>
				<p>
					본인의 차량을 다시 한번 확인하시고 결제 진행해주세요.(예 :<span class="orange">12가3456</span>)
				</p> 
			</article>
			<table class="default-table-view">
				<caption class="sr-only">주차요금 사전결제 리스트 테이블</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">차량번호</th>
						<td>{{ r.carNo }}</td>
					</tr>
					<tr>
						<th>이용주차장</th>
						<td>{{ r.parkingName }}</td>
					</tr>
					<tr>
						<th>이용시간</th>
						<td>
							<span>{{ r.inDttm }} ~ {{ r.outDttm }}</span>
							<span class="emphasis">{{ r.useTm | comma }}분</span>
						</td>
					</tr>
					<tr>
						<th>이용금액</th>
						<td>{{ r.useAmt | comma }} 원</td>
					</tr>
					<tr>
						<th>할인금액</th>
						<td>
							<p>{{ r.discountAmt | comma }} 원</p>
							<span class="emphasis">{{ r.discountNm }}</span>
						</td>
					</tr>
					<tr>
						<th>차량번호 할증금액</th>
						<td>
							<p>{{ r.extraAmt | comma }} 원</p>
							<span class="emphasis-gray">{{ r.extraNm }}</span>
						</td>
					</tr>
					<tr>
						<th>미납금액</th>
						<td>{{ totUnpaidAmt | comma }}원</td>
					</tr>
					<tr>
						<th>미납가산금액</th>
						<td>{{ totAddAmt | comma }}원</td>
					</tr>
					<tr>
						<th>상세내역</th>
						<td>
							이용금액 {{ r.useAmt | comma }}원 - 
							할인금액 {{ r.discountAmt | comma }}원 + 
							할증금액 {{ r.extraAmt | comma }}원 + 
							미납금액 {{ totUnpaidAmt | comma }}원 + 
							가산금액 {{ totAddAmt | comma }}원 = 
							청구금액 {{ totSumAmt | comma }}원
						</td>
					</tr>
					<tr>
						<th>청구금액</th>
						<td>
							<ul class="billing">
								<li>{{ totSumAmt | comma }}원</li>
								<li class="asterisk">결제 후 10분 안에 출차하지 않으면 추가요금이 부과됩니다.</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<article class="message">
				<p v-if="r.fullPayDivCd == 'PRT003'">결제 후 10분 안에 출차하지 않으면 추가 요금이 부과됩니다.</p>
				<p v-else>결제가 완료 되었습니다.</p>
			</article>
			<article class="btn-list">
				<button class="btn" v-if="r.fullPayDivCd == 'PRT003' && r.reqAmt> 0" @click="paymentBtn()">결제</button>
				<button class="btn-border" @click="cancelBtn(carNo)">취소</button>
			</article>
		</section>

	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return{
			inOutId         : '',
			sectnId         : '',
			carNo           : '',
			inDttm          : '',
			discountCd      : '',
			extraCd         : '',
			msg             : '',
			showModal       : false,
			r               : '',
			totUnpaidAmt    : 0,
			totAddAmt       : 0,
			totSumAmt       : 0,
			easypayParams   : '',
		}
	},
	filters : {
		comma : function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		}
	},
	created(){

		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("data"));
		}else{
			localStorage.setItem("data", JSON.stringify(data));
		}

		this.inOutId    = data.inOutId;
		this.sectnId    = data.sectnId;
		this.carNo      = data.carNo;
		this.inDttm     = data.inDttm;
		this.discountCd = data.discountCd;
		this.extraCd    = data.extraCd;

	},
	mounted(){
		
		let apiParams = new Map();
		apiParams.set("inOutId"   , this.inOutId);
		apiParams.set("sectnId"   , this.sectnId);
		apiParams.set("carNo"     , this.carNo);
		apiParams.set("inDttm"    , this.inDttm);
		apiParams.set("discountCd", this.discountCd);
		apiParams.set("extraCd"   , this.extraCd);
		apiParams.set("isMobile"  , this.$isMobile());

		instance.get("/api/prepay/view.json", {
			params : Object.fromEntries(apiParams)
		}).then((res => {

			var resData = res.data;

			this.r             = resData.resultMap.result;
			this.totUnpaidAmt  = resData.totUnpaidAmt;
			this.totAddAmt     = resData.totAddAmt;
			this.totSumAmt     = resData.totSumAmt;

			this.easypayParams = resData.resultMap.easypayParams;

		}));

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		cancelBtn(carNo){
			this.$router.push({
				name  : 'lookup',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},

		paymentBtn(){
			
			var e = this.easypayParams;

			let easypayParams = new Map();
			easypayParams.set("easypayRequestUrl"	, e.easypayRequestUrl);
			easypayParams.set("mallId"				, e.mallId);
			easypayParams.set("payMethodTypeCode"	, e.payMethodTypeCode);
			easypayParams.set("currency"			, e.currency);
			easypayParams.set("amount"				, e.amount);
			easypayParams.set("clientTypeCode"		, e.clientTypeCode);
			easypayParams.set("returnUrl"			, e.easypayReturnUrl);
			easypayParams.set("shopOrderNo"			, e.shopOrderNo);
			easypayParams.set("deviceTypeCode"		, e.deviceTypeCode);
			easypayParams.set("mallName"			, encodeURIComponent(e.mallName));
			easypayParams.set("goodsName"			, encodeURIComponent(e.goodsName));

			if(this.$store.state.isLogin){
				easypayParams.set("customerId"			, encodeURI(this.$store.state.empNo));			// e.customerId
				easypayParams.set("customerName"		, encodeURI(this.$store.state.empNm));			// e.customerName
				easypayParams.set("customerMail"		, encodeURIComponent(this.$store.state.empNo));			// e.customerMail
				easypayParams.set("customerContactNo"	, this.$store.state.empMobile);		// e.customerContactNo
			}else{
				easypayParams.set("customerId"			, encodeURI("GUEST"));				// e.customerId
				easypayParams.set("customerName"		, encodeURI("비회원"));				// e.customerName
				easypayParams.set("customerMail"		, encodeURIComponent(""));			// e.customerMail
				easypayParams.set("customerContactNo"	, "");								// e.customerContactNo
			}

			easypayParams.set("value1"				, e.value1);							// payCategory (사전정산 PREPAY)
			easypayParams.set("value2"				, e.value2);							// sectnId
			easypayParams.set("value3"				, e.value3);							// iNervPayId
			easypayParams.set("value4"				, e.value4);							// carNo
			easypayParams.set("value5"				, this.r.outDttm);
      easypayParams.set("value6"				, e.amount);
      easypayParams.set("value7"				, "");


			instance.get("/api/easypay/easypaystart.json", {
				
				params : Object.fromEntries(easypayParams)

			}).then(function(res){
				
				//console.log("SUCESS");
				//console.dir(res);
				var resData = res.data;
				
				// Mobile, PC Check & ios, android Check
				var mobileOS = "";
				if(e.deviceTypeCode == "mobile"){
					var mobileOSCheck = navigator.userAgent.toLowerCase();
					if(mobileOSCheck.indexOf('iphone') > -1 || mobileOSCheck.indexOf('ipad') > -1 || mobileOSCheck.indexOf('ipod') > -1){
						mobileOS = "ios";
					}else{
						mobileOS = "android";
					}
				}

				if(mobileOS == "ios"){
					window.location.href = resData.result.authPageUrl;
				}else{
					// 결제 모듈 - 팝업 오픈
					var webpay = window.open(resData.result.authPageUrl, "", "_blank");

					// 결제 모듈 - JSP 파일 팝업 종료 감지
					var interval = window.setInterval(function(){
						if(webpay == null || webpay.closed){
							window.clearInterval(interval);
						}
					});

				}

			})
			.catch(function(err){
				//console.log("ERROR");
				//console.dir(err);
			});

		},

	},

}

</script>

<style>

</style>