<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>아이디 찾기</h2>
			</article>
			<article class="figure">
				<figure class="img-find"></figure>
				<ul class="emphasis-font">
					<li><span class="bold">아이디</span>를 잊어버리셨나요?</li>
					<li>가입시 등록한 휴대폰 번호를 입력해 주세요.</li>
				</ul>
			</article>
			<article>
				<table class="lookup-table">
					<caption class="sr-only">사업자 ID 찾기 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">사업자 번호</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<input type="text" v-model="inputText" placeholder="사업자 번호 입력" class="place-hide" id="corNum">
											<label for="corNum"><span class="hide">사업자 번호 입력</span></label>
										</li>
									</ul>
								</form>
							</td>
						</tr>
						<tr>
							<th>휴대폰 번호</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<input type="text" v-model="inputText" placeholder="휴대폰 번호  \'-\'제외하고 입력 " class="place-hide" id="phoneNum">
											<label for="phoneNum"><span class="hide">휴대폰 번호 '-'제외하고 입력</span></label>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list">
				<button class="one-btn">아이디 찾기</button>
			</article>
		</section>
	</main>
</template>

<script>
export default {
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>