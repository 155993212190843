<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드" class="active">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>공통상세코드</h4>
			</article>
			<article>
				<div class="tab-content">
					<article>
						<h3>공통상세코드</h3>
					</article>
					<article>
						<table class="vertical-table">
							<caption class="sr-only">공통코드 등록 테이블</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row" class="asterisk-after">상위코드</th>
									<td class="select-area">
										<label for=""></label>
										<select v-model="codeTy">
											<option value="">선택하세요.</option>
											<option v-for="(item, index) in codeTyList" :key="index" :value="item.codeTy">{{item.nm}}</option>
										</select>
										<div class="error" v-if="!$v.codeTy.required">필수 입력란입니다.</div>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">상세코드</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" placeholder="상세코드" class="place-hide" v-model="code">
												<div class="error" v-if="!$v.code.required">필수 입력란입니다.</div>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">코드구분</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" placeholder="코드구분" class="place-hide" v-model="codeGubun">
												<div class="error" v-if="!$v.codeGubun.required">필수 입력란입니다.</div>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">상세코드명</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" placeholder="상세코드명" class="place-hide" v-model="nm">
												<div class="error" v-if="!$v.nm.required">필수 입력란입니다.</div>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">상세코드설명</th>
									<td>
										<textarea class="in-table place-hide" placeholder="상세코드설명" v-model="note">

										</textarea>
										<div class="error" v-if="!$v.note.required">필수 입력란입니다.</div>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">사용여부</th>
									<td class="select-area">
										<label for=""></label>
										<select name="" id="" v-model="useYn">
											<option value="Y">사용</option>
											<option value="N">미사용</option>
											<div class="error" v-if="!$v.useYn.required">필수 입력란입니다.</div>
										</select>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">정렬순번</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" placeholder="정렬 숫자" class="place-hide" v-model="orderValue">
												<div class="error" v-if="!$v.orderValue.required">필수 입력란입니다.</div>
												<div class="error" v-if="!$v.orderValue.numRegex">숫자만 입력 가능합니다.</div>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</article>
					<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
					<article class="btn-list right">
						<button class="btn"  @click="$router.push('detailedcodelist')">목록</button>
						<button class="btn-border" @click="insertBtn">확인</button>
					</article>	
				</div>
			</article>	
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import { required, helpers } from 'vuelidate/lib/validators'
const instance = axios.create({
  timeout: 30000,
});
const numRegex = helpers.regex('numRegex', /\d/g);

export default {
	data(){
		return {
			codeTyList: [],
			codeTy: "",
			code: "",
			codeGubun: "",
			nm: "",
			note: "",
			useYn: "Y",
			orderValue: "",
			submitStatus: ""
		}
	},

	validations: {
		codeTy: {
			required
		},
		code: {
			required
		},
		codeGubun: {
			required
		},
		nm: {
			required
		},
		note: {
			required
		},
		useYn: {
			required
		},
		orderValue: {
			required,
			numRegex
		}
	},

	mounted() {
		this.searchcodeTyList();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		insertBtn(){

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("codeTy", this.codeTy);
				apiParams.append("code", this.code);
				apiParams.append("codeGubun", this.codeGubun);
				apiParams.append("nm", this.nm);
				apiParams.append("useYn", this.useYn);
				apiParams.append("note", this.note);
				apiParams.append("orderValue", this.orderValue);
	
				instance.post('/api/code/detailCodeInsertAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('detailedcodelist');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		searchcodeTyList(){
			instance.get('/api/code/codeList.json')
			.then((response => {
				var data = response.data;

				if(data.resultList && data.resultList.length > 0){
					this.codeTyList = data.resultList;
				} else {
					this.codeTyList = [];
				}
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		}
	}

}
</script>

<style>

</style>