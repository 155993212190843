<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>이메일 전송</h2>
			</article>
			<article class="figure">
				<figure class="img-email"></figure>
				<ul>
					<li>이메일로 아이디를 전송했습니다.</li>
					<li>이메일을 확인해 주세요.</li>
				</ul>
			</article>
			<article class="explanation">
				<p class="orange">이메일 확인을 하시면 인증이 됩니다. 인증 후 재로그인 해주세요.</p>
			</article>
			<article class="btn-list">
				<button class="one-btn black">홈으로 가기</button>
			</article>
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style>

</style>