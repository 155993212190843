<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리" class="active">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>사용자 관리</h4>
			</article>
			<article>
				<h3>홈페이지 관리자</h3>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">사용자 관리 등록페이지 (홈페이지 관리자)</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">일반회원 아이디</th>
							<td>
								<ul class="input-style small">
									<li>
										<input type="text" placeholder="아이디" class="place-hide">
										<button class="table-btn">중복검색</button>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">일반회원 이름</th>
							<td>
								<ul class="input-style small">
									<li>
										<input type="text" placeholder="일반회원 이름" class="place-hide">
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>부서명</th>
							<td class="select-area">
								<label for=""></label>
								<select name="" id="">
									<option value="" selected disabled>선택하세요.</option>
									<option value=""></option>
									<option value=""></option>
								</select>
							</td>
						</tr>
						<tr>
							<th>팀영</th>
							<td class="select-area">
								<label for=""></label>
								<select name="" id="">
									<option value="" selected disabled>선택하세요.</option>
									<option value=""></option>
									<option value=""></option>
								</select>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<input type="password" placeholder="비밀번호" class="place-hide">
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 6~12자리</p>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호 확인</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<input type="password" placeholder="비밀번호 확인" class="place-hide">
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 6~12자리</p>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">핸드폰 번호</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<input type="text" placeholder="핸드폰 번호" class="place-hide">
									</li>
									<li>
										<p class="reference">"-"는 빼고 입력 해 주세요.</p>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list right">
				<button class="btn">목록</button>
				<button class="btn-border">확인</button>
			</article>	
		</section>
		<section class="modal-manager">
			<article class="title">
				<ul>
					<li>아이디 중복 확인</li>
					<li>닫기</li>
				</ul>
			</article>
			<article class="content">
				<section>
					<article>
						<table class="vertical-table">
							<caption class="sr-only">아이디 중복 확인</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">사용자 ID</th>
									<td>
										<ul class="input-style button">
											<li>
												<input type="text" placeholder="사용자 ID" class="place-hide">
												<button class="table-btn ml10">검색하기</button>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</article>
					<article>
						<ul>
							<li><p class="default">중복확인을 실행 하십시오.</p></li>
						</ul>
					</article>
					<article class="btn-list">
						<button class="popup-btn">닫기</button>
						<button class="popup-btn-border">확인</button>
					</article>
				</section>
			</article>
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style>

</style>