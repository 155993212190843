<template>
  <v-row>
    <v-col class="calendar one">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="s_Date" transition="scale-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="s_Date" solo v-model="date" v-bind="attrs" v-on="on" title="전입 일자 입력">
          </v-text-field>
          <label for="input-97" class="hide">전입일자를 입력해 주세요. 키보드로 입력 가능합니다. 예: 2022-01-01</label>
        </template>
        <!-- <v-date-picker v-model="date" :min="date" locale="ko-kr" :first-day-of-week="1" :active-picker="year" @change="emitSetDate"></v-date-picker> -->
        <v-date-picker v-model="date" locale="ko-kr" :first-day-of-week="1" @change="emitSetDate"></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      date : new Date().toISOString().substr(0, 10),
      s_Date :'',
      menu: false,
    }
  },

  methods: {
    emitSetDate(){
      this.$emit('emitSetDate', this.date);
    }
  },

  mounted(){
    let tag = document.getElementById("input-97");
    tag.title = '일자 입력';
  }
}
</script>