<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>회원가입</h2>
				<h4>회원약관동의</h4>
			</article>
			<article class="step">
				<div>STEP 02</div>
			</article>
			<article id="fullConsent">
				<input type="checkbox" id="agreement" v-model="allCheck" @click="checkAll">
				<label for="agreement" id="fullConsent" @keydown.space="all">
				<span role="checkbox" aria-checked="false" aria-labelledby="fullConsent" class="hide"></span>
				전체동의</label>
			</article>

			<article>
				<h3>개인정보 수집 및 이용 약관</h3>

				<privacy-policy></privacy-policy>

				<input type="checkbox" id="consent" v-model="check1" @change="checkboxCkc()">
				<label for="consent" id="agreeUse" @keydown.space="check">
				<span role="checkbox" aria-checked="false" aria-labelledby="agreeUse" class="hide"></span>
				개인정보 수집 및 이용에 대한 동의 <span class="orange">(필수)</span></label>
			</article>
			<article class="btn-list">
				<button class="btn"        @click="agreeBtn" ref="aggre">동의</button>
				<button class="btn-border" @click="disagreeBtn">동의안함</button>
			</article>
		</section>

		<pop-up v-show="showModal" @emitChangeModal="carRegistModal">>
			<template #info>
				<li>안내</li>
			</template>

			<template #close>
				<li></li>
			</template>

			<template #content>
				<p v-html="msg"></p>
			</template>

			<template #button>
				<button class="popup-btn-border" @click="closeBtn">닫기</button>
			</template>
		</pop-up>

	</main>
</template>

<script>

import PopUp         from '../../../components/PopupType1.vue';
import PrivacyPolicy from '../../../components/PrivacyPolicy.vue';

export default {
	data(){
		return{
			allCheck  : false,
			check1    : '',
			showModal : false,
			msg       : '',

			name   : '',
			mobile : '',
			birth  : '',
		}
	},

	mounted(){

		this.name   = this.$route.params.data.name;
		this.mobile = this.$route.params.data.mobile;
		this.birth  = this.$route.params.data.birth;

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

	},

	methods:{

		checkAll(){
			if(this.allCheck == false){
				this.check1 = true;
			}else{
				this.check1 = false;
			}
		},

		agreeBtn(){
			if(this.check1){
				
				var params = {
					name   : this.name,
					mobile : this.mobile,
					birth  : this.birth,
				}

				this.$router.push({
					name   : 'profileinput',
					params : { data : params }
				});

			}else{
				this.showModal = true;
				this.msg = "* 개인정보 수집 및 이용 약관에 동의하지 않으셨습니다.";
			}
		},

		disagreeBtn(){
			this.$router.push('/loginmain')
		},

		carRegistModal(){
			this.showModal = !this.showModal;
		},

		checkboxCkc(){
			if(this.check1 == true){
				this.allCheck = true;
			}else{
				this.allCheck = false;
			}
		},
		
		all(event){
			event.preventDefault();
			this.allCheck = !this.allCheck;
			this.check1 = true;

			if(this.allCheck == false){
				this.check1 = false;
			}
		},

		check(event){
			event.preventDefault();
			this.check1 = !this.check1;
			
			if(this.check1 == false){
				this.allCheck = false;
			}else{
				this.allCheck = true;
			}
		},

		closeBtn(){
			this.showModal = false;
			this.$refs.aggre.focus();
		}
	},

	components:{
		PopUp,
		PrivacyPolicy
	},
}
</script>

<style>

</style>