<template>
	<main id="subTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 확인</h4>
			</article>
			<article>
				<h3>03. 주민등록(재직증명) 상의 정보</h3>
				<table class="default-table-view">
					<caption class="sr-only">주민등록(재직증명) 상의 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">신청자 구분</th>
							<td>거주자</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>화성시 00구 00동 111-111</td>
						</tr>
						<tr>
							<th>전입일자</th>
							<td>2021-09-09</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<h3>04. 희망주차장 정보</h3>
				<table class="default-table-view">
					<caption class="sr-only">희망주차장 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">희망 주차 구간</th>
							<td>경기 화성시 노작로 226</td>
						</tr>
						<tr>
							<th>신청자수</th>
							<td>15명</td>
						</tr>
						<tr>
							<th>이용 시간 선택</th>
							<td>야간</td>
						</tr>
						<tr>
							<th>주차장 거리</th>
							<td>555m</td>
						</tr>
					</tbody>
				</table>
			</article>
      <article>
				<h3>미납주차 요금</h3>
			</article>
			<article>
				<table class="lookup-table resident">
					<caption class="sr-only">미납 주차 요금</caption>
					<colgroup>
						<col style="width:30%" />
						<col style="width:70%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">희망주차 구간</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<span class="orange">900</span> 원(=900원+0원)										
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="message resident">
				<p>
          주차 요금 미납으로 거주자 우선주차 신청을 할 수 없습니다. 먼저, 미납 주차 요금 납부를 부탁드리겠습니다.
        </p>
			</article>
      <article class="right">
        <button class="table-btn ex">미납 주차 요금 납부</button>
      </article>
			<article class="btn-list">
				<button class="btn">신청</button>
				<button class="btn-border">취소</button>
			</article>		
		</section>
	</main>
</template>

<script>
export default {
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
};
</script>

<style>

</style>