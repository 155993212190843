<template>
	<main id="subTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 확인</h4>
			</article>
			<article>
				<h3>01. 신청자 정보</h3>
				<table class="default-table-view">
					<caption class="sr-only">신청자 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>홍길동</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>화성시 00구 00동 111-111</td>
						</tr>
						<tr>
							<th>연락처</th>
							<td>010-0000-0000</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<h3>2. 차량 정보</h3>
				<table class="default-table-view">
					<caption class="sr-only">차량 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">차량번호</th>
							<td>33수3333</td>
						</tr>
						<tr>
							<th>차종</th>
							<td>리스차량(외국인)</td>
						</tr>
						<tr>
							<th>휴대폰</th>
							<td>01012345678</td>
						</tr>
						<tr>
							<th>소유주명</th>
							<td>홍길동</td>
						</tr>
						<tr>
							<th>배기량</th>
							<td>00cc</td>
						</tr>
					</tbody>
				</table>
			</article>
      <article class="btn-list">
				<button class="btn">이전</button>
				<button class="btn-border">다음</button>
			</article>	
    </section>
  </main>
</template>

<script>
export default {
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>