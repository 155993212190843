import '@babel/polyfill'
import Vue                from 'vue'
import store              from './store/index.js';
import App                from './App.vue'
import { router }         from './routers/router.js';
import Vuetify            from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import payment            from './assets/js/payment.js';
import VueMobileDetection from 'vue-mobile-detection';
import ClassicEditor      from '@ckeditor/ckeditor5-build-classic';
import CKEditor           from '@ckeditor/ckeditor5-vue2';
import Vuelidate          from 'vuelidate';
import VueDaumPostcode    from 'vue-daum-postcode';
import VueCookies         from "vue-cookies";
import axios              from 'axios';
import VueConfirmDialog   from 'vue-confirm-dialog';
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome';
import { library }        from '@fortawesome/fontawesome-svg-core';
import { faAddressCard }  from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/js/all.js';


const instance = axios.create({
  timeout: 30000,
});

library.add(faAddressCard);

Vue.use(Vuetify);
Vue.use(payment);
Vue.use(VueMobileDetection);
Vue.use(CKEditor);
Vue.use(Vuelidate);
Vue.use(VueDaumPostcode);
Vue.use(VueCookies);
Vue.use(VueConfirmDialog);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

Vue.config.productionTip = false;

Vue.$cookies.config("1d");

Vue.filter('ptDateFormat', function(x) {
  if(x != null){
    let js_date = new Date(x);

    let year = js_date.getFullYear();
    let month = js_date.getMonth() + 1;
    let day = js_date.getDate();

    if(month < 10){
      month = '0' + month;
    }

    if(day < 10){
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  }else{
    return '';
  }
})

Vue.filter('bytes', function(data, to) {
  const const_term = 1024;

  if (to === "KB") {
    return (data / const_term).toFixed(3) + "KB";
  } else if (to === "MB") {
    return (data / const_term ** 2).toFixed(3) + "MB";
  } else if (to === "GB") {
    return (data / const_term ** 3).toFixed(3) + "GB";
  } else if (to === "TB") {
    return (data / const_term ** 4).toFixed(3) + "TB";
  } else {
    return "Please pass valid option";
  }

})

Vue.filter('comma', function(x) {
  if(x != null){
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
})

axios.interceptors.response.use(
  (config) => {
    let sessionExpired = config.data.sessionExpired;

    if(sessionExpired){
      instance.post('/api/actionLogout.json')
			.then((response => {
        alert('세션이 만료되었습니다.');
				store.dispatch("logout", null);
				router.push("/loginmain");
			}))
			.catch(function (error) {
				//console.log(error);
			})
    }

    return config
  }
)

window.EventBus = new Vue()

const app = new Vue({
  vuetify: new Vuetify(),
  render: h => h(App),
  router,
  store,
  ClassicEditor,
  CKEditor,
}).$mount('#app')
