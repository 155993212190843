<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>비밀번호 찾기</h2>
			</article>
			<article class="figure">
				<figure class="img-find"></figure>
				<ul class="emphasis-font">
					<li><span class="bold">비밀번호</span>를 잊어버리셨나요?</li>
					<li>포탈에 가입하여 등록하신 이메일 아이디를 입력한 뒤</li>
					<li>등록하신 휴대폰 번호로 번호 인증을 진행해 주세요.</li>
				</ul>
			</article>
			<article>
				<table class="lookup-table">
					<caption class="sr-only">비밀번호 찾기 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">아이디</th>
							<td>
								<ul class="input-style">
									<li>
										<label for="pwd" class="hide">아이디 입력</label>
										<input type="text" v-model="empNo" placeholder="Email Address" class="place-hide" id="pwd">
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list">
				<button class="one-btn" @click="checkNiceID()" title="새창열림(비밀번호 찾기)">비밀번호 찾기</button>
			</article>
		</section>

		<form name="form_chk" method="post" ref="form_chk">
			<input type="hidden" name="m"          value="checkplusService" >
			<input type="hidden" name="EncodeData" v-model="sEncData">
		</form>

		<form name="form_nice" ref="form_nice">
			<input type="hidden" id="nice_name"       name="nice_name" >
			<input type="hidden" id="nice_mobile"     name="nice_mobile" >
			<input type="hidden" id="nice_birthDate"  name="nice_birthDate" >
			<input type="hidden" id="nice_msg"        name="nice_msg" >
			<input type="hidden" id="memberDuplCheck" name="memberDuplCheck" >
		</form>

	</main>
</template>

<script>

import axios from 'axios';
import { required, email } from 'vuelidate/lib/validators'
const instance = axios.create({
  timeout: 30000,
});

export default {

	data(){
		return{
			sEncData        : "",
			nice_name       : "",
			nice_mobile     : "",
			nice_birthDate  : "",
			nice_msg        : "",
			memberDuplCheck : "",

			empNo     : "",
			empNm     : "",
			empMobile : "",
			findPwChk : false,
			intervals : [],
		}
	},

	validations: {
		empNo: {
			required,
			email
		},
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods:{

		setValues(name, mobile, birth, msg, memberDuplCheck){

			this.nice_name       = name;
			this.nice_mobile     = mobile;
			this.nice_birthDate  = birth;
			this.nice_msg        = msg;
			this.memberDuplCheck = memberDuplCheck;

			if(memberDuplCheck > 0){
				
				let apiParams = new Map();
				apiParams.set("name"  , name);
				apiParams.set("mobile", mobile);
				apiParams.set("empNo" , this.empNo);

				instance.get("/api/nice/findPw.json", {
					params : Object.fromEntries(apiParams)
				})
				.then((res => {
					var resData = res.data.findPw;

					if(resData == null || resData == ""){
						alert("가입되어 있는 계정이 없습니다.");
						return false;
					}else{
						this.empNm     = resData.EMP_NM;
						this.empMobile = resData.EMP_MOBILE;

						var params = {
							empNo  : this.empNo,
							name   : this.empNm,
							mobile : this.empMobile,
						}

						this.$router.push({
							name   : 'changepassword',
							params : { data : params }
						});
					}

				}));

				return false;
			}else{
				alert("가입되어 있는 계정이 없습니다.");
				return false;
			}

		},

		// Nice 본인인증
		checkNiceID(){

			if(this.$v.$invalid){
				alert("* 이메일 형태의 아이디를 입력해주세요 *");
				return null;
			}

			window.name = "Parent_window";
			var niceWindow = window.open("/api/nice/checkPlusSafe_main", "popupChk", "width=500, height=550, top=100, left=1000, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");
			let interval = window.setInterval(() => {
				if(niceWindow.closed && document.form_nice.nice_mobile.value){

					this.intervals.forEach(element => {
						window.clearInterval(element);
					});

					var name            = document.form_nice.nice_name.value;
					var mobile          = document.form_nice.nice_mobile.value;
					var birth           = document.form_nice.nice_birthDate.value;
					var msg             = document.form_nice.nice_msg.value;
					var memberDuplCheck = document.form_nice.memberDuplCheck.value;

					this.setValues(name, mobile, birth, msg, memberDuplCheck);
				}
			}, 500);

			this.intervals.push(interval);

		},
		
	},

	beforeRouteLeave(to, from, next) {
		this.intervals.forEach(element => {
			window.clearInterval(element);
		});
		next();
	}

}
</script>

<style>

</style>