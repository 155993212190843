<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">무정차 신청</div>
			<ul>
				<li><router-link to="/nonstopinfo" title="무정차 신청" class="active">무정차 신청</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>무정차 신청</h2>
				<h4>차량 등록</h4>
			</article>
			<article>
				<div id="tabs">
					<ul class="tab-menu-nonstop">
						<li>신청 안내</li>
						<li>서비스 신청</li>
						<li>결제카드 등록</li>
						<li class="active">차량 등록</li>
					</ul>
					<div class="tab-content">

						<!-- 1. 차량 등록 정보 -->
						<article>
							<ul id="title-next">
								<li><h3>차량 등록 정보</h3></li>
								<li><button class="one-btn-border" @click="carRegistTableOpen()">차량추가</button></li>
							</ul>
						</article>
						<article>
							<table class="default-table">
								<caption class="sr-only">차량 등록 정보 테이블</caption>
								<colgroup>
									<col style="width:20%" />
									<col style="width:20%" />
									<col style="width:20%" />
									<col style="width:20%" />
									<col style="width:20%" />
								</colgroup>
								<thead>
									<tr>
										<th scope="col">차량번호</th>
										<th scope="col">등록일자</th>
										<th scope="col">취소일자</th>
										<th scope="col">미납회차</th>
										<th scope="col">비고</th>
									</tr>
								</thead>
								<tbody>
									<tr class="nodate" v-if="carListNodata">
										<td colspan="5">조회된 데이터가 없습니다.</td>
									</tr>
									<tr v-for="(car, index) in getCarList" :key="index">
										<td @click="getInoutCarNoInfo(car.carNo)" @keyup.enter="getInoutCarNoInfo(car.carNo)">{{ car.carNo }}<span class="hide">차량번호가 맞으면 선택해 주세요.</span></td>
										<td>{{ car.regDttm }}</td>
										<td v-if="car.canDttm != ''">{{ car.canDttm }}</td>
										<td v-else>-</td>
										<td>{{ car.unpaidCnt }}</td>
										<td>
											<input type="button" v-if="car.canDttm == ''" id="nonstopCancel" class="table-in-btn black" @click="carInfoCancel(car.carregtId, car.carNo)"  value="취소">
										</td>
									</tr>
								</tbody>
							</table>
						</article>
						<article>
							<ul>
								<li class="asterisk">차량번호 클릭하면 입출차 이력을 확인할 수 있습니다.</li>
								<li class="asterisk">무정차정산 미납회차가 1회이상 차량번호는 무정차 정산이 되지 않고 주차장 현장에서 주차요금을 수동 결제 해야 됩니다.</li>
							</ul>
						</article>

						<!-- 2. 차량 등록 -->
						<article v-show="carRegistFlag">
							<h3>차량 등록</h3>
							<table class="default-table-board enrollment-pc">
								<caption class="sr-only">차량등록 테이블</caption>
								<colgroup>
									<col style="width:5%;" />
									<col style="width:25%;" />
									<col style="width:50%;" />
									<col style="width:20%;" />
								</colgroup>
								<thead>
									<tr>
										<th scope="col">순번</th>
										<th scope="col" colspan="2">차량번호</th>
										<!-- <th scope="col">할인유형</th> -->
										<th scope="col"><button class="table-in-btn-add" @click="carRegistTableAppend()">추가</button></th>
									</tr>
								</thead>								
								<tbody class="carRegistTBody">
									<tr v-for="(row, index) in addCarNoRow" :key="index">
										<td>{{ index + 1 }}</td>
										<td colspan="2">
											<label for="carNumber" class="hide">차량번호를 입력</label>
											<input type="text" placeholder="차량번호를 입력해주세요." class="place-hide" v-model="row.addCarNo" id="carNumber">
										</td>
										<!-- <td>
											<ul>
												<li><button class="discount-btn">할인유형 확인</button></li>
												<li class="reference">할인 유형이 확인이 되지 않았습니다.</li>
											</ul>
										</td> -->
										<td class="btn-location">
											<button class="table-in-btn black" @click="carRegistTableRemove(index)">삭제</button>
										</td>
									</tr>
									<!-- <tr>
										<td colspan="4">
											<p>할인유형확인이란</p>
											<p>행정안전부(차량등록 정보) 및 화성시 감면정책(다자녀, 자원봉사자등)에 등록되어 있는 차량정보로 확인하며,</p>
											<p>주차장 이용시 현장에서 자동 감면혜택을 받을 수 있습니다.</p>
											<p>(단, 행정안전부는 공동소유/리스/법인/외국인 차량은 일반으로 적용됩니다.)</p>
										</td>
									</tr> -->
								</tbody>
							</table>
							<article class="btn-list">
								<button class="btn" @click="carRegistModalOpen()">저장</button>
								<button class="btn-border" @click="carRegistFlag=!carRegistFlag">완료</button>
							</article>

						</article>

						<!-- 3. 입출차 정보 -->
						<article>
							<h3>입출차 정보</h3>
							<table class="lookup-table enrollment-pc">
								<caption class="sr-only">입출차 정보 조회 테이블</caption>
								<colgroup>
									<col style="width:10%" />
									<col style="width:20%" />
									<col style="width:10%" />
									<col style="width:40%" />
									<col style="width:10%" />
								</colgroup>
								<tbody>
									<tr>
										<th scope="row">차량번호</th>
										<td>
											<ul class="input-style">
												<li>
													<label for="carNum"><span class="hide">차량번호</span></label>
													<input type="text" v-model="ioCarNo" placeholder="00가0000" class="place-hide" id="carNum"/>
												</li>
											</ul>
										</td>
										<th scope="row">수납일자</th>
										<td>
											<date-picker @emitSetDate="setDate"></date-picker>
										</td>
										<td>
											<button class="table-in-btn-select" @click="getInOutInfo()">조회</button>
										</td>
									</tr>
								</tbody>
							</table>
							<table class="default-table" id="inOutCarTable">
								<caption class="sr-only">입출차 정보 테이블</caption>
								<colgroup>
									<col style="width:16.6%" />
									<col style="width:16.6%" />
									<col style="width:16.6%" />
									<col style="width:16.6%" />
									<col style="width:16.6%" />
									<col style="width:16.6%" />
								</colgroup>
								<thead>
									<tr>
										<th scope="col">주차장</th>
										<th scope="col">입차일시</th>
										<th scope="col">출차일시</th>
										<th scope="col">수납일자</th>
										<th scope="col">수납금액</th>
										<th scope="col">영수증</th>
									</tr>
								</thead>
								<tbody>
									<tr class="nodate" v-if="ioCarListNodata">
										<td colspan="6" >조회된 데이터가 없습니다.</td>
									</tr>
									<tr v-for="(ioCar, index) in ioGetCarList" :key="index">
										<td>{{ ioCar.parkingName }}</td>
										<td>{{ ioCar.inDttm }}</td>
										<td>{{ ioCar.outDttm }}</td>
										<td>{{ ioCar.receiptDt }}</td>
										<td>{{ ioCar.receiptAmt | comma }} 원</td>
										<td class="btn-location">
											<button class="table-in-btn-receipt" @click="receiptPaper(ioCar.receiptId)">수납영수증</button>
										</td>
									</tr>
								</tbody>
							</table>
						</article>

						<article>
							<ul id="title-next">
								<li class="asterisk enrollment-mo" index="0">입출차정보는 수납일자 기준, 조회일부터 최대 6개월까지 조회 가능합니다.</li>
								<li><button class="one-btn-next" @click="getInOutInfoXlsx()">엑셀</button></li>
							</ul>
						</article>

						<article class="btn-list">
							<button class="btn" @click="goPrevious()">이전</button>
						</article>

					</div>
				</div>
			</article>
		</section>
		
		
		<!-- 차량 등록 팝업  -->
		<pop-up v-show="showCarRegModal" id="registrationLayer" class="modal-payment">
			<template #info>
				<li id="registrationCar" tabindex="1">알림창</li>
			</template>
			<template #close>
				<li></li>
			</template>
			<template #content>
				<p v-html="carRegMsg"></p>
			</template>
			<template #button>
				<button class="popup-btn regBtn" tabindex="1"       @click="carRegBtn()">예</button>
				<button class="popup-btn-border regBtn" tabindex="1" @click="carRegistModalClose()">아니요</button>
			</template>
		</pop-up>

		<!-- 등록 차량 취소 팝업  -->
		<pop-up v-show="showCarDelModal" id="cancleLayer" class="modal-payment">
			<template #info>
				<li id="cancleCar" tabindex="1">알림창</li>
			</template>
			<template #close>
				<li></li>
			</template>
			<template #content>
				<p v-html="carDelMsg"></p>
			</template>
			<template #button>
				<button class="popup-btn cancleBtn" tabindex="1"       @click="carDelBtn()">예</button>
				<button class="popup-btn-border cancleBtn" tabindex="1" @click="carDeleteModalClose()">아니요</button>
			</template>
		</pop-up>
	</main>
</template>

<script>

import axios      from 'axios';
import dayjs      from 'dayjs';
import XLSX       from 'xlsx';
import PopUp      from '../../../components/PopupType1.vue';
import DatePicker from '../../../components/DatePicker2.vue';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return {
			applyId         : "",
			applyNm         : "",
			applyAddr       : "",
			applyMobile     : "",
			carRegistFlag   : false,
			carListNodata   : true,
			getCarList      : "",
			carNoList       : "",
			addCarNoRow     : [{ addCarNo : "" }],
			carregtId       : "",
			carNo           : "",
			carRegistTr     : "",
			showCarRegModal : false,
			carRegMsg       : '<ul class="info">'
							+     '<li class="regi" tabindex="1">무정차 정산 차량등록 하시겠습니까?</li>'
							+ '</ul>',
			showCarDelModal : false,
			carDelMsg       : '<ul class="info">'
							+     '<li class="cancal" tabindex="1">무정차 정산 등록차량을 취소 하시겠습니까?</li>'
							+ '</ul>',
			ioCarNo         : "",
			startDate       : new Date().toISOString().substr(0, 10),
			endDate         : new Date().toISOString().substr(0, 10),
			ioCarListNodata : true,
			ioGetCarList    : "",
		}
	},
	filters : {
		// 숫자 #,### 포맷팅
		comma : function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
	},

	components:{
		PopUp,
		DatePicker,
	},
	created(){

		let data = null;

		// if(localStorage.getItem("nonstopData") !== null){
		// 	data = JSON.parse(localStorage.getItem("nonstopData"));
		// 	this.applyAddr   = data.applyAddr;
		// }else{
		// 	alert('서비스 신청 정보를 입력해주세요.')
		// 	this.$router.push('/nonstopagree');
		// }

		this.applyId = this.$store.state.empNo;
		this.applyNm = this.$store.state.empNm;
		this.applyMobile = this.$store.state.empMobile;

	},
	
	mounted(){

		this.carInfoList();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		// 등록 버튼
		const layer = document.querySelector("#registrationLayer"),
		layerArrow = layer.querySelectorAll('li#registrationCar, li.regi, button.regBtn'),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
				e.preventDefault();
				layerLast.focus();
			}
		};

		const lastKeydown = e => {
			if (!e.shiftKey && e.key === "Tab") {
				e.preventDefault();
				layerFirst.focus();
			}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);

		//취소 버튼
		const cancleLayer = document.querySelector("#cancleLayer"),
		cancleLayerArrow = cancleLayer.querySelectorAll('li#cancleCar, li.cancal, button.cancleBtn'),
		cancleLayerFirst = cancleLayerArrow[0],
		cancleLayerLast = cancleLayerArrow[cancleLayerArrow.length -1];

		const cancleFirstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
				e.preventDefault();
				cancleLayerLast.focus();
			}
		};

		const cancleLastKeydown = e => {
			if (!e.shiftKey && e.key === "Tab") {
				e.preventDefault();
				cancleLayerFirst.focus();
			}
		};

		cancleLayerFirst.addEventListener("keydown", cancleFirstKeydown);
		cancleLayerLast.addEventListener("keydown", cancleLastKeydown);

	},

	methods : {
		// 등록된 차량 정보 목록 조회
		carInfoList(){

			var jsonList = [];

			let apiParams = new Map();
			apiParams.set("applyId", this.applyId);

			instance.get("/api/nonstop/nonstopCarList.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {

				var resData = res.data;

				var carList = resData.result.result.list;

				for(var i = 0 ; i < carList.length ; i++){
					jsonList.push(carList[i]);
				}

				if(jsonList.length > 0){
					this.getCarList    = jsonList;
					this.carListNodata = false;
				}else{
					this.getCarList    = null;
					this.carListNodata = true;
				}

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},
		// 차량 등록 정보 - [취소]
		carInfoCancel(carregtId, carNo){
			this.showCarDelModal = true;
			this.carregtId       = carregtId;
			this.carNo           = carNo;

			setTimeout(function(){
				document.getElementById('cancleCar').focus();
			});
		},
		// 차량 등록 정보 - [취소] - [예]
		carDelBtn(){

			this.showCarDelModal = false;

			let apiParams = new Map();
			apiParams.set("applyId"  , this.applyId);
			apiParams.set("carregtId", this.carregtId);
			apiParams.set("carNo"    , this.carNo);

			instance.get("/api/nonstop/nonstopCarEdit.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {
				
				var resData   = res.data.result;
				var resResult = resData.result.data;

				if(resResult.result){
					alert(resResult.msg);
					this.carInfoList();
				}else{
					// 실패시 문구 출력
					alert(resResult.msg);
				}

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});
		},

		// 차량 등록 정보 - [취소] - [아니요]
		carDeleteModalClose(){
			this.showCarDelModal = false;
			this.carregtId        = "";
			this.carNo           = "";

			document.getElementById("nonstopCancel").focus();
		},
		// 차량 등록 - [추가 버튼]
		carRegistTableOpen(){
			this.carRegistFlag = true;
		},
		// 차량 등록 - [행 추가]
		carRegistTableAppend : function(){
			this.addCarNoRow.push({ addCarNo : "" });
		},
		// 차량 등록 - [행 삭제]
		carRegistTableRemove : function(index){
			this.addCarNoRow.splice(index, 1);
		},
		// 차량 등록 - [저장 버튼]
		carRegistModalOpen(){

			this.carNoList = [];

			var carNoRegex = /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi;

			for(var i = 0 ; i < this.addCarNoRow.length ; i++){

				var carNo = this.addCarNoRow[i].addCarNo.replace(/(\s*)/g, "");

				if(carNo == "" || carNo.length < 7){
					this.carNoList = [];
					alert("* (" + (i + 1) + ")번의 차량번호가 형식에 맞지 않습니다. 예:00가0000");
					return false;
				}

				var carNoDuple = this.carNoList.indexOf(carNo);

				if(carNoDuple != -1){
					this.carNoList = [];
					alert("* (" + (i + 1) + ")번의 차량번호가 중복됩니다.");
					return false;
				}

				this.carNoList.push(carNo);
			}

			if(this.carNoList.length > 0){
				this.showCarRegModal = true;

				setTimeout(function(){
					document.getElementById('registrationCar').focus();
				});
			}else{
				this.carNoList = [];
				return false;
			}



		},

		// 차량 등록 - [저장] - [예]
		carRegBtn(){
			this.showCarRegModal = false;

			let apiParams = new Map();
			apiParams.set("applyId"  , this.applyId);
			apiParams.set("carNoList", this.carNoList.join());

			instance.get("/api/nonstop/nonstopCarListAdd.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {

				var resData   = res.data.result;
				var resResult = resData.result.data;

				if(resResult.result){
					alert(resResult.msg);
					this.carRegistFlag = false;
					this.addCarNoRow = [{ addCarNo : "" }];
					this.carInfoList();
				}else{
					// 실패시 문구 출력
					alert(resResult.msg);
				}

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},
		// 차량 등록 - [저장] - [아니요]
		carRegistModalClose(){
			this.showCarRegModal = false;
		},
		// 입출차 정보 - 날짜 세팅
		setDate(sd, ed){
			this.startDate = sd;
			this.endDate   = ed;
		},
		// 등록된 차량 클릭시 자동 조회
		getInoutCarNoInfo(carNo){
			this.ioCarNo = carNo;
			this.getInOutInfo();
		},
		// 입출차 정보 - [조회]
		getInOutInfo(){
			
			if(this.ioCarNo == "" || this.ioCarNo.length < 7){
				alert("* 차량번호가 형식에 맞지 않습니다. 예:00가0000");
				return false;
			}

			if(!this.carContains(this.getCarList, this.ioCarNo)){
				alert("* 등록되지 않은 차량번호 입니다.");
				return null;
			}

			var jsonList = [];

			let apiParams = new Map();
			apiParams.set("applyId", this.applyId);
			apiParams.set("carNo"  , this.ioCarNo);
			apiParams.set("startDt", this.startDate);
			apiParams.set("endDt"  , this.endDate);

			instance.get("/api/nonstop/nonstopReceiptList.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {

				var resData = res.data;

				var carList = resData.result.result.list;

				for(var i = 0 ; i < carList.length ; i++){
					jsonList.push(carList[i]);
				}

				if(jsonList.length > 0){
					this.ioGetCarList    = jsonList;
					this.ioCarListNodata = false;
				}else{
					this.ioGetCarList    = null;
					this.ioCarListNodata = true;
				}

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},
		commaAmt(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
		// 입출차 정보 - [조회] - [영수증]
		receiptPaper(receiptId){

			let apiParams = new Map();
			apiParams.set("receiptId", receiptId);

			instance.get("/api/nonstop/nonstopGetReceiptPaper.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {

				var resData = res.data.result.result.data;

				// 영수증 내용
				var printContents	= "<div id='receiptContents'>"
									+     "<article class='receipt-tit'>"
									+         "<ul>"
									+             "<li>[주차 영수증]</li>"
									+             "<li>" + resData.carNo + "</li>"
									+         "</ul>"
									+         "<p>===================</p>"
									+     "</article>"
									+     "<article class='receipt-cont'>"
									+         "<ul>"
									+             "<li>구　 　분 : 시간주차</li>"
									+             "<li>입차 시간 : " + resData.inDttm + "</li>"
									+             "<li>출차 시간 : " + resData.outDttm + "</li>"
									+         "</ul>"
									+         "<ul class='receipt-cont-inner'>"
									+             "<li>할인/할증 : " + resData.discountNm + "/" + resData.extraNm + "</li>"
									+             "<li>결제 유형 : " + resData.receiptWay + "</li>"
									+             "<li>납부 일자 : " + resData.receiptDttm + "</li>"
									+             "<li class='parking-fee'>주차 요금 : " + this.commaAmt(resData.receiptSumAmt) + " 원</li>"
									+         "</ul>"
									+         "<p>===================</p>"
									+     "</article>"
									+     "<article class='receipt-number'>"
									+         "<p>주차 장소 : " + resData.parkingName + "</p>"
									+         "<ul>"
									+             "<li>사업자 번호 : 000-00-00000</li>"
									+             "<li>문의 연락처 : 000-000-0000</li>"
									+         "</ul>"
									+         "<p class='greetings'>이용해 주셔서 감사합니다.</p>"
									+     "</article>"
									+     "<article class='receipt-logo'>"
									+         "<ul>"
									+             "<li>화성도시공사</li>"
									+             "<li>로고</li>"
									+         "</ul>"
									+     "</article>"
									+ "</div>"
				;

				// 영수증 CSS
				var printStyle	= "<style>"
								+     "#receiptContents, article, ul, li, p { margin: 0; padding: 0; }"
								+     "#receiptContents { border: 1px solid; padding: 22px 30px 30px; width: 210px; height: 457px; }"
								+     "#receiptContents article {}"
								+     "#receiptContents article ul li { list-style: none; font-size: 12px; }"
								+     "#receiptContents article ul li span.spacing { padding-left: 25px; }"
								+     "#receiptContents article ul li span.bold { font-weight: 600; }"
								+     "#receiptContents article.receipt-tit { text-align: center; }"
								+     "#receiptContents article.receipt-tit ul li { font-size: 20px; margin-bottom: 5px; font-weight: 500; }"
								+     "#receiptContents article.receipt-cont ul { margin-top:-3px; }"
								+     "#receiptContents article.receipt-cont ul li { margin-top: 5px; }"
								+     "#receiptContents article.receipt-cont ul.receipt-cont-inner { margin-top: 10px; }"
								+     "#receiptContents article.receipt-cont ul.receipt-cont-inner li.parking-fee { font-size: 18px; font-weight: 600; }"
								+     "#receiptContents article.receipt-cont p { margin-top: 7px; text-align: center; }"
								+     "#receiptContents article.receipt-number { margin-top: 8px; }"
								+     "#receiptContents article.receipt-number p { margin-bottom: 10px; font-size: 12px; }"
								+     "#receiptContents article.receipt-number ul{ margin-bottom: 3px; }"
								+     "#receiptContents article.receipt-number ul li { margin-bottom: 5px; }"
								+     "#receiptContents article.receipt-number p.greetings { position: relative; margin-top: -2px; left: 20px; font-size:12px; }"
								+     "#receiptContents article.receipt-number p.greetings:before { content: '*'; position: absolute; left: -15px; font-weight: 600; }"
								+     "#receiptContents article.receipt-logo ul {}"
								+     "#receiptContents article.receipt-logo ul li { display: inline-block; font-size: 16px; letter-spacing: -2px; vertical-align: middle; }"
								+     "#receiptContents article.receipt-logo ul li:first-child { margin-right: 20px; }"
								+     "#receiptContents article.receipt-logo ul li:last-child { margin-left: 20px; }"
								+     "#receiptContents article.receipt-logo ul li img { width:60px; }"
								+     "@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){ #receiptContents{padding: 22px 30px;} }"
								+ "</style>"
				;

				var W = document.documentElement.clientWidth;  if(!W){ W = document.body.clientWidth; }
				var H = document.documentElement.clientHeight; if(!H){ H = document.body.clientHeight; }

				var features  = "menubar=no, toolbar=no, location=no, directories=no, status=no, scrollbars=yes, resizable=yes, width=" + W + ", height=" + H + ", left=0, top=0";
				var PrintPage = window.open("", "print_view", features);
				
				PrintPage.document.open();

				PrintPage.document.write("<head><title>영수증</title></head>");
				PrintPage.document.write("<body>");
				PrintPage.document.write(printStyle);
				PrintPage.document.write(printContents);
				PrintPage.document.write("</body>");
				PrintPage.document.close();

				setTimeout(function(){
					PrintPage.print();
					PrintPage.close();
				}, 700);

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},
		// 입출차 정보 - [엑셀]
		getInOutInfoXlsx(){
			
			var jsonList = [];
			var map = {};

			if(!this.ioGetCarList){
				return null;
			}

			for(var i = 0 ; i < this.ioGetCarList.length ; i++){
				map = {
					"주차장"	: this.ioGetCarList[i].parkingName,
					"차량번호"	: this.ioGetCarList[i].carNo,
					"입차시간"	: this.ioGetCarList[i].inDttm,
					"출차시간"	: this.ioGetCarList[i].outDttm,
					"할인코드"	: this.ioGetCarList[i].discountNm,
					"수납시간"	: this.ioGetCarList[i].receiptDt,
					"수납금액"	: this.ioGetCarList[i].receiptAmt,
				}
				jsonList.push(map);
			}

			// 엑셀 워크시트로 json 내보내기 : 배열만 가능
			var ws = XLSX.utils.json_to_sheet(jsonList);
			
			// 엑셀의 workbook을 만듬 wookbook은 엑셀 파일에 지정된 이름
			var wb = XLSX.utils.book_new();
			var fileName = dayjs().format("YYYYMMDDHHmmss") + '무정차.xlsx';
			// wookbook에 워크시트 추가 시트명
			XLSX.utils.book_append_sheet(wb, ws, '무정차 입출차 정보');
			XLSX.writeFile(wb, fileName);

		},
		// [이전]
		goPrevious(){
			
			var params = {
				applyId     : this.applyId,
				applyNm     : this.applyNm,
				applyAddr   : this.applyAddr,
				applyMobile : this.applyMobile,
			}

			this.$router.push({
				name   : 'nonstopcardregist',
				params : { data : params }
			});

		},

		carContains(list, carNo){
			let isContain = false;

			list.forEach(element => {
				if(element.carNo === carNo){
					isContain = true;
				}
			});
			
			return isContain;
		}
	}
}
</script>

<style>

</style>