<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">사업자</div>
			<ul>
				<li><router-link to="" title="선불(포인트) 조회/적용" class="active">선불(포인트) 조회/적용</router-link></li>
				<li><router-link to="" title="포인트 결제">포인트 결제</router-link></li>
				<li><router-link to="" title="포인트 사용 내역">포인트 사용 내역</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>사업자</h2>
				<h4>선불(포인트) 조회/적용</h4>
			</article>
			<article>
				<h3>선불(포인트) 상세보기</h3>
				<table class="lookup-table">
					<caption class="sr-only">조회 테이블</caption>
					<colgroup>
						<col style="width:40%" />
						<col style="width:60%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">사업자 [0000000000]</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<input type="text" v-model="inputText" placeholder="0000" class="place-hide">
										</li>
										<li>
											<button class="table-btn">포인트 결제</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">선불(포인트) 상세보기</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th>차량번호</th>
							<td>00가 0000</td>
						</tr>
						<tr>
							<th>이용주차장</th>
							<td></td>
						</tr>
						<tr>
							<th>이용시간</th>
							<td>
								<table class="table-in-table">
									<caption class="sr-only">이용시간 안내</caption>
									<colgroup>
										<col style="width:17%" />
										<col style="width:17%" />
										<col style="width:33%" />
										<col style="width:33%" />
									</colgroup>
									<thead>
										<tr>
											<th scope="col" colspan="2">주차시간</th>
											<th scope="col" rowspan="2">입차시간</th>
											<th scope="col" rowspan="2">출차시간(현재시간)</th>
										</tr>
										<tr>
											<th scope="col">분</th>
											<th scope="col">시간</th>
										</tr>
									</thead>
									<tbody> 
										<tr>
											<td scope="row">235분</td>
											<td>3시간 55분</td>
											<td>2021-03-16 00:00:00</td>
											<td>2021-03-16 00:00:00</td>
										</tr>
									</tbody>
								</table>	
							</td>
						</tr>
						<tr>
							<th>할인적용</th>
							<td>
								<ul class="discount">
									<li><span class="orange">초기화</span></li>
									<li>30분</li>
									<li>1시간</li>
									<li>1시간 30분</li>
									<li>2시간</li>
									<li>2시간 30분</li>
									<li>3시간</li>
									<li>3시간 30분</li>
									<li>4시간</li>
									<li>4시간 30분</li>
									<li>5시간</li>
									<li>5시간 30분</li>
									<li>6시간</li>
									<li>6시간 30분</li>
									<li>7시간</li>
									<li><span class="orange">전체할인</span></li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>주차요금</th>
							<td>5000원</td>
						</tr>
						<tr>
							<th>적용 포인트</th>
							<td><span class="orange">5,000 P</span></td>
						</tr>
						<tr>
							<th>적용 후 포인트</th>
							<td>20,000 <span class="orange"> -> 15,000 P</span></td>
						</tr>
					</tbody>
				</table>		
			</article>
			<article class="btn-list">
				<button class="btn">확인</button>
				<button class="btn-border">취소</button>
			</article>
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style>

</style>