<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo" title="안내">안내</router-link></li>
				<li><router-link to="residentsearch" title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청" class="active">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup" title="조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 확인</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 신청서 확인</h3>
			</article>
			<article>
				<p class="circle">신청자 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">신청자 정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>홍길동</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>화성시 00구 00동 111-111</td>
						</tr>
						<tr>
							<th>연락처</th>
							<td>010-0000-0000</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">차량 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">차량 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">차량번호</th>
							<td>33수3333</td>
						</tr>
						<tr>
							<th>차종</th>
							<td>리스차량(외국인)</td>
						</tr>
						<tr>
							<th>휴대폰</th>
							<td>01012345678</td>
						</tr>
						<tr>
							<th>소유주명</th>
							<td>홍길동</td>
						</tr>
						<tr>
							<th>배기량</th>
							<td>00cc</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">주민등록(재직증명) 상의 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">주민등록(재직증명) 상의 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">신청자 구분</th>
							<td>거주자</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>화성시 00구 00동 111-111</td>
						</tr>
						<tr>
							<th>전입일자</th>
							<td>2021-09-09</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">희망주차장 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">희망주차장 정보 관련 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">희망 주차 구간</th>
							<td>경기 화성시 노작로 226</td>
						</tr>
						<tr>
							<th>신청자수</th>
							<td>15명</td>
						</tr>
						<tr>
							<th>이용 시간 선택</th>
							<td>야간</td>
						</tr>
						<tr>
							<th>주차장 거리</th>
							<td>555m</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<h3>미납주차 요금</h3>
			</article>
			<article>
				<table class="lookup-table">
					<caption class="sr-only">미납 주차 요금</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">희망주차 구간</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<span class="orange">900</span> 원(=900원+0원)										
										</li>
										<li>
											<button class="table-btn ex">미납 주차 요금 납부</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="message ex">
				<p>주차 요금 미납으로 거주자 우선주차 신청을 할 수 없습니다.</p>
				<p>먼저, 미납 주차 요금 납부를 부탁드리겠습니다.</p>
			</article>
			<article class="btn-list">
				<button class="btn">신청</button>
				<button class="btn-border">취소</button>
			</article>		
		</section>
	</main>
</template>

<script>
export default {
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
};
</script>

<style>

</style>