<template>
  <div>
    <section class="modal-manager">
      <article class="title">
        <ul>
          <li>
            <slot name="info"></slot>
          </li>
          <li>
            <slot name="close"></slot>
          </li>
        </ul>
      </article>
      <article class="content">
        <slot name="content"></slot>
        <article class="content-inner mypage">
          <slot name="content-notice"></slot>
        </article>
      </article>
      <article class="btn-list">
        <slot name="button"></slot>
      </article>
    </section>
    <section class="dim"></section>
  </div>
</template>

<script>
export default {

}
</script>