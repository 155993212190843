<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차장</div>
			<ul>
				<li><router-link to="/introduce"      title="주차장 소개" class="active">주차장 소개</router-link></li>
				<li><router-link to="/map"            title="지도 검색">지도 검색</router-link></li>
				<li><router-link to="/list"           title="주차장 검색">주차장 검색</router-link></li>
				<li><router-link to="/feeinformation" title="주차장 요금안내">주차장 요금안내</router-link></li>
				<li><router-link to="/realtimeparking" title="실시간 주차정보">실시간 주차정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차장</h2>
				<h4>주차장 소개</h4>
			</article>

			<article>
				<h3>공영주차장 안내</h3>
				<p>
					화성도시공사는 화성시가 설치한 유료주차장을 대행하여 관리 운영하고 있으며 관내 원활한 교통소통 및 주차난 해소를 위하여 항상 노력하고 있습니다.
				</p>
				<div class="layout-flex">
					<table class="default-table">
						<caption class="sr-only">노상 주차장 안내</caption>
						<colgroup>
								<col style="width:100%" />
						</colgroup>
						<thead>
							<tr>
								<th scope="col">노상주차장</th>
							</tr>
						</thead>
						<tbody>
							<tr style="background: #fff;">
								<td scope="row">
									<p>도로변의 노면에 설치된 주차구획으로 인근주민에게 편의를 제공하는 주차장입니다.</p>
									<p class="street-parking">
										<span class="hide">노상 주차장 이미지</span>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="default-table">
						<caption class="sr-only">노외 주차장 안내</caption>
						<colgroup>
								<col style="width:100%" />
						</colgroup>
						<thead>
							<tr>
								<th scope="col">노외 주차장</th>
							</tr>
						</thead>
						<tbody>
							<tr style="background: #fff;">
								<td scope="row">
									<p>일반 부지에 설치된 주차장으로 지방자치단체에서 설치하여 운영하는 주차장입니다.</p>
									<p class="offstreet-parking">
										<span class="hide">노외 주차장 이미지</span>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</article>
			<div class="btn-list">
				<button class="btn"        @click="goPage('/map')">지도검색</button>
				<button class="btn-border" @click="goPage('/list')">주소검색</button>
			</div>
			<article>
				<h3>차번호 인식 시스템</h3>
				<p>
					공사가 직영하는 대부분의 주차장은 입출차 및 주차요금 차번호 인식 시스템이 구축되어 운영 효율화 및 이용시민의 편의를 도모하고 있습니다.
				</p>
				<p>
					공영주차장은 주차관리원이 없는 무인주차장으로 운영합니다. 차량이 주차장에 들어올때 차량번호인식기(LPR)로 입차시간을 확인하고, 출차시 무인정산기를 통해 주차요금을 징수합니다. 주차요금은 신용카드, 체크카드 결제가 가능하며
					<span class="orange">현금 결제는 불가합니다.</span>
				</p>
			</article>
			<article>
				<h3>주차장 연락처</h3>
				<section class="contact-list">
					<article>
						<div class="circle"></div>
						<dl>
							<dt class="orange">담당부서</dt>
							<dd>화성도시공사 주차운영부</dd>
						</dl>
					</article>
					<article>
						<div class="circle"></div>
						<dl>
							<dt class="orange">문의전화</dt>
							<dd>Tel) 031-8059-6538</dd>
							<dd>Fax) 031-8059-6539</dd>
						</dl>
					</article>
					<article>
						<div class="circle"></div>
						<dl>
							<dt class="orange">주소</dt>
							<dd>화성시 오산동 967-1009</dd>
							<dd>동탄여울공원 주차장 내 통합관제센터</dd>
						</dl>
					</article>
				</section>
			</article>
		</section>
	</main>
</template>

<script>

export default {
	methods : {
		// 페이지 이동 [ 주차장지도 / 사전정산 / 미납결제 / 정기권 등 ]
		goPage(path){
			this.$router.push(path);
		},
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}

</script>

<style scoped>
.btn-list {
	margin-top: 0px;
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
}
</style>