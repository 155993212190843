<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리" >프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
        <li><router-link to="/grouplist" title="그룹 관리" class="active">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>그룹 관리</h4>
			</article>
			<article>
				<h3>그룹 관리</h3>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">그룹 관리 등록</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th class="asterisk-after">그룹명</th>
							<td>
                <ul class="input-style">
                    <li>
                        <input type="text"  placeholder="그룹명" class="place-hide" v-model="groupNm">
						<div class="error" v-if="!$v.groupNm.required">필수 입력란입니다.</div>
                    </li>
                </ul>
              </td>
						</tr>
						<tr>
							<th class="asterisk-after">설명</th>
							<td>
                 <textarea class="in-table place-hide" placeholder="설명" v-model="groupDc">
 
                </textarea>
				<div class="error" v-if="!$v.groupDc.required">필수 입력란입니다.</div>
              </td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list right">
				<button class="btn"  @click="$router.push('grouplist')">목록</button>
				<button class="btn-border" @click="insertBtn">확인</button>
			</article>
		</section>
	</main>
</template>
<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators'
 const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			groupNm: "",
			groupDc: "",
			submitStatus: ""
		}
	},

	validations: {
		groupNm: {
			required
		},
		groupDc: {
			required
		}
	},

	methods: {
		insertBtn(){

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("groupNm", this.groupNm);
				apiParams.append("groupDc", this.groupDc);
	
				instance.post('/api/auth/groupInsert.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('grouplist');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
	
}
</script>
<style>

</style>