<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo"      title="안내">안내</router-link></li>
				<li><router-link to="residentsearch"    title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청" class="active" v-focus>거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup"    title="조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청</h4>
			</article>
			<article>
				<h3>개인정보 수집 동의 안내</h3>
			</article>
		
			<article>
				<h3>개인정보 이용 동의</h3>

				<privacy-policy></privacy-policy>

				<input type="checkbox" id="consent1" v-model="privacy">
				<label for="consent1" id="agreement" @keydown.space="check" @keydown.enter="check">
				<span role="checkbox" aria-checked="false" aria-labelledby="agreement" class="hide"></span>
				개인정보 수집&middot;이용에 동의 하십니까?</label>
			</article>

			<article class="btn-list">
				<button class="btn" @click="agreeBtn">동의</button>
				<button class="btn-border" @click="cancelBtn">동의안함</button>
			</article>		
		</section>
		
	</main>
</template>

<script>

import PrivacyPolicy from '../../../components/PrivacyPolicy.vue';

export default {
	data(){
		return{
			privacy         : false,
			dongId          : "",
			dongNm          : "",
			sectnId         : "",
			sectnNm         : "",
			cellId          : "",
			cellNm          : "",
			isAllowedPeriod : "",
			assignMethod    : "",
		}
	},
	components:{
		PrivacyPolicy
	},

	created(){
		var map = this.$route.params.data;

		if(map == null){
			map = JSON.parse(localStorage.getItem("map"));
		}else{
			localStorage.setItem("map", JSON.stringify(map));
		}

		this.dongId          = map.dongId;
		this.dongNm          = map.dongNm;
		this.sectnId         = map.sectnId;
		this.sectnNm         = map.sectnNm;
		this.cellId          = map.cellId;
		this.cellNm          = map.cellNm;
		this.isAllowedPeriod = map.isAllowedPeriod;
		this.assignMethod    = map.assignMethod;

	},

	methods: {
		agreeBtn(){
			if(this.privacy){	
				var params = {
					dongId          : this.dongId,
					dongNm          : this.dongNm,
					sectnId         : this.sectnId,
					sectnNm         : this.sectnNm,
					cellId          : this.cellId,
					cellNm          : this.cellNm,
					isAllowedPeriod : this.isAllowedPeriod,
					assignMethod    : this.assignMethod
				}

				if(this.$isMobile()){
					// 모바일 거주자 신청 페이지
					this.$router.push({
						name   : 'applicantinfo',
						params : { data : params }
					});
				}else{
					// PC 거주자 신청 페이지
					this.$router.push({
						name   : 'residentapplication',
						params : { data : params }
					});
				}
					
				//this.$router.push('residentapplication');
			} else {
				alert('개인정보이용에 동의하지 않으셨습니다.');
			}

			this.$router.go();
		},

		cancelBtn(){
			localStorage.removeItem("map");
			this.$router.push('residentinfo');
		},

		check(event){
			this.privacy = !this.privacy;
			event.preventDefault();
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}

}
</script>

<style>

</style>