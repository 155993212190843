<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">주차요금 사전결제</div>
			<ul>
				<li><router-link to="prepaymentinfo" title="사전결제 안내" class="active">안내</router-link></li>
				<li><router-link to="lookup"         title="사전결제 조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>주차요금 사전결제</h2>
				<h4>안내</h4>
			</article>
			<article>
				<h3>주차요금 사전결제란?</h3>
				<ul>
					<li class="hyphen">주차요금 사전결제 서비스로 주차장에서 출차하기 전에 주차요금을 사전결제하여 빠르고 편리하게 주차장 이용 가능</li>
					<li class="hyphen">웹을 이용한 사전결제는 PC/테블릿/모바일 기기 등을 통하여 주차요금을 사전결제하고 별도의 절차없이 출차하는 서비스</li>
				</ul>
			</article>
			<article class="service">
				<h3>서비스 절차 및 감면</h3>
				<p class="hyphen">
					포탈사이트 접속 &#9655; 주차요금 사전결제 &#9655; 조회 &#47; 결제 &#9655; 10분 이내 출차<br>감면 차량은 자동으로 감면되며, 기타 감면 대상 차량은 화성도시공사 주차운영부 (031)8059-6538로 연락하여 확인.
				</p>
				<p class="asterisk">감면차량: 경차, 유공자, 장애인, 저공해 차량이며 기타 화성시 주차장 감면 관련 조례 참조</p>	
			</article>
			<article>
				<h3>유의사항</h3>
				<ul>
					<li class="hyphen">결제취소불가, 타인의 차량을 결제하지 않도록 차량번호 확인</li>
					<li class="hyphen">결제 후 10분 안에 출차를 하지 않으면 주차요금이 추가 발생, 추가요금은 출차시 추가 결제</li>
				</ul>
			</article>
			<div class="btn-list">
				<button class="one-btn" @click="pageList">주차요금 사전결제</button>
			</div>	
		</section>
	</main>
</template>

<script>
export default {
	methods: {
		pageList(){
			this.$router.push('/lookup')
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>