<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle"       title="무료차량 관리">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist"    title="정기권 주차장 관리" >정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement"  title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement"     title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist"         title="팝업 관리" class="active">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>팝업 관리</h4>
			</article>
			<article>
				<h3>팝업 관리</h3>
			</article>
			<article>
				<!-- Table List Area -->
				<table class="vertical-table">
					<caption class="sr-only">팝업관리 등록 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:30%" />
						<col style="width:20%" />
						<col style="width:30%" />
					</colgroup>
					<tbody>
						<tr>
							<th class="asterisk-after">제목</th>
							<td colspan="3">
								<ul class="input-style">
									<li>
										<input type="text" placeholder="제목을 입력 해 주세요." class="place-hide" v-model="title">
										<div class="error" v-if="!$v.title.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">게시기간</th>
							<td colspan="3">
								<li class="application">
									<date-picker @emitSetDate="setDate"></date-picker>
								</li>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">사용여부</th>
							<td>
								<ul class="input-radio">
									<li>
										<input type="radio" id="Y" v-model="useYn" value="Y">
										<label for="Y">사용함</label>
									</li>
									<li>
										<input type="radio" id="N" v-model="useYn" value="N">
										<label for="N">사용안함</label>
									</li>
								</ul>
								<div class="error" v-if="!$v.useYn.required">필수 입력란입니다.</div>
							</td>
							<th class="asterisk-after">위치</th>
							<td>
								<ul class="input-style location">
									<li>X : </li>
									<li>
										<input type="number" placeholder="X값" class="place-hide" v-model="posY" @blur="setProps">
									</li>
									<li>Y : </li>
									<li>
										<input type="number" placeholder="Y값" class="place-hide" v-model="posX" @blur="setProps">
									</li>
								</ul>
								<div class="error" v-if="!$v.posX.required || !$v.posY.required">필수 입력란입니다.</div>
								<!-- <div class="error" v-if="!$v.posX.numRegex || !$v.posY.numRegex">숫자만 입력 가능합니다.</div> -->
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">크기</th>
							<td colspan="3">
								<ul class="input-style location">
									<li>가로 : </li>
									<li>
										<input type="number" class="place-hide" v-model="sizeY" @blur="setProps">
									</li>
									<li>세로 : </li>
									<li>
										<input type="number" class="place-hide" v-model="sizeX" @blur="setProps">
									</li>
								</ul>
								<div class="error" v-if="!$v.sizeX.required || !$v.sizeY.required">필수 입력란입니다.</div>
								<!-- <div class="error" v-if="!$v.sizeX.numRegex || !$v.sizeY.numRegex">숫자만 입력 가능합니다.</div> -->
								<div class="error" v-if="!$v.sizeY.between">가로값은 640을 넘게 하지 말아주세요.</div>
							</td>
						</tr>
						<tr>
							<th>링크주소</th>
							<td colspan="3">
								<ul class="input-style">
									<li>
										<input type="text" placeholder="링크주소를 입력 해 주세요." class="place-hide" v-model="linkUrl" @blur="setProps">
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>팝업내용</th>
							<td colspan="3">
								<div id="ckeditorTA">
									<ckeditor :editor="editor" v-model="contents" :config="editorConfig" @ready="onReady"></ckeditor>
								</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">첨부파일</th>
							<td colspan="3">
								<ul class="input-style button">
									<input type="file" class="place-hide" ref="files" @change="changeFile" @click="resetFile">
									<li v-for="(item, index) in eFiles" :key="index">
										{{ item.name }}({{item.size | bytes('MB')}})
									</li>
								</ul>
								<p class="asterisk mt10">PNG/JPEG/GIF 형식만 가능합니다.</p>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list right">
				<button class="btn"        @click="$router.push('popuplist')">목록</button>
				<button class="btn black"  @click="popupPreview">미리보기</button>
				<button class="btn-border" @click="insertBtn">등록</button>
			</article>
		</section>

		<pop-up v-show="showModal" @emitChangeModal="changeModal" v-bind:propsData="propsDataArr">
			<template #info>
				{{ title }}
			</template>

			<template #close>
				<div class="hide">팝업창 닫기</div>
			</template>

			<template #content>
				<p v-html="contents">  </p>
			</template>

			<template #button>
				<!-- <button class="popup-btn-border" @click="showModal = false">닫기</button> -->
			</template>
		</pop-up>

	</main>
</template>

<script src="../node_modules/@ckeditor/ckeditor5-build-classic/build/ckeditor.js"></script>
<script src="../node_modules/@ckeditor/ckeditor5-vue2/dist/ckeditor.js"></script>

<script>

import PopUp         from '../../../components/PopupType7.vue';
import axios         from 'axios';
import { required, helpers, between } from 'vuelidate/lib/validators'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor      from '@ckeditor/ckeditor5-vue2';
import DatePicker    from '../../../components/DatePicker2.vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/ko';

const instance = axios.create({
  timeout: 30000,
});


const numRegex = helpers.regex('numRegex', /\d/g);

const uploadAdapter = require("../../../assets/js/UploadAdapter");

export default {
	name : 'ckeditorTA',

    data(){
        return{
			editor       : ClassicEditor,
			editorConfig : {
				language       : 'ko',
				enterMode      : 2,
				shiftEnterMode : 3,
				extraPlugins   : [ uploadAdapter.MyCustomUploadAdapterPlugin ],
				mediaEmbed     : {
					previewsInData : true,
				}
			},
			submitStatus : '',
			title        : '',
			openDate     : new Date().toISOString().substr(0, 10),
			closeDate    : new Date().toISOString().substr(0, 10),
			useYn        : 'Y',
			posX         : '500',
			posY         : '1000',
			sizeX        : 540,
			sizeY        : 540,
			linkUrl      : '',
			contents     : '',
			showModal    : false,
			propsDataArr : {
				posX       : '',
				posY       : '',
				sizeX      : '',
				sizeY      : '',
				popupImage : '',
				linkUrl    : ''
			},
			eFiles       : '',
			popupImage   : ''
		}
    },

	validations: {
		title : {
			required
		},
		openDate : {
			required
		},
		closeDate : {
			required
		},
		useYn : {
			required
		},
		posX : {
			required,
			/* numRegex */
		},
		posY : {
			required,
			/* numRegex */
		},
		sizeX : {
			required,
			/* numRegex, */
		},
		sizeY : {
			required,
			between : between(0, 640),
			/* numRegex */
		}
	},

	methods : {
		onReady(editor){
			editor.ui.getEditableElement().parentElement.insertBefore(
				editor.ui.view.toolbar.element,
				editor.ui.getEditableElement()
			);
		},

		setDate(sd, ed){
			this.openDate = sd;
			this.closeDate = ed;
		},

		insertBtn(){
			this.initEFiles();

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("title", this.title);
				apiParams.append("openDateString", this.openDate);
				apiParams.append("closeDateString", this.closeDate);
				apiParams.append("useYn", this.useYn);
				apiParams.append("posX", this.posX);
				apiParams.append("posY", this.posY);
				apiParams.append("sizeX", this.sizeX);
				apiParams.append("sizeY", this.sizeY);
				apiParams.append("linkUrl", this.linkUrl);
				apiParams.append("popupContents", this.contents);
				if(this.eFiles !== '') {
					apiParams.append("files", this.eFiles);
				}

				instance.post('/api/admin/popup/insertAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('popuplist');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		changeModal(){
			this.showModal = false;
		},

		popupPreview(){
			this.initEFiles();

			if(this.posX === '' || this.posY === '' || this.sizeX === '' || this.sizeY === ''){
				alert('위치, 크기를 입력하여주세요.');
				return null;
			}

			this.showModal = true;
		},

		setProps(){
			this.propsDataArr.posX = this.posX;
			this.propsDataArr.posY = this.posY;
			this.propsDataArr.sizeX = this.sizeX;
			this.propsDataArr.sizeY = this.sizeY;
			this.propsDataArr.popupImage = this.popupImage;
			this.propsDataArr.linkUrl = this.linkUrl;
		},

		changeFile(e){
			let file = e.target.files[0];
			
			if(file['type']==='image/jpeg' || file['type']==='image/png' || file['type']==='image/gif' ){
				this.eFiles = file;
				const url = URL.createObjectURL(this.eFiles);
				this.popupImage = url;
				this.setProps();
			}else{
				alert('PNG/JPEG/GIF 형식만 가능합니다.');
				this.$refs.files.value = '';
				this.eFiles = '';
			}
		},

		resetFile(){
			this.$refs.files.value = '';
			this.eFiles = [];
		},

		initEFiles(){
			if(this.eFiles.length == 0){
				this.eFiles = '';
			}
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	components:{
		DatePicker,
		PopUp
	},

	compoents:{
		// Use the <ckeditor> component in this view.
		ckeditor : CKEditor.compoents
	}
};
</script>

<style>

</style>