<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리" class="active">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>롤 관리</h4>
			</article>
			<article>
				<h3>롤 관리</h3>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">롤관리 등록</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th class="asterisk-after">롤 명</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="롤 명" class="place-hide" v-model="roleNm">
										<div class="error" v-if="!$v.roleNm.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">롤 패턴</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="롤 패턴" class="place-hide" v-model="rolePtn">
										<div class="error" v-if="!$v.rolePtn.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">롤 설명</th>
							<td>
								<textarea class="in-table place-hide" placeholder="롤 설명" v-model="roleDc">
				
								</textarea>
								<div class="error" v-if="!$v.roleDc.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">롤 타입</th>
							<td class="select-area">
								<label for=""></label>
								<select name="" id="" v-model="roleTyp">
									<option value="">선택하세요.</option>
									<option v-for="(item, index) in codeTypeList" :key="index" :value="item.codeTy">{{item.codeNm}}</option>
								</select>
								<div class="error" v-if="!$v.roleTyp.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">롤 정렬</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="롤 정렬" class="place-hide" v-model="roleSort">
										<div class="error" v-if="!$v.roleSort.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.roleSort.numRegex">숫자만 입력 가능합니다.</div>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list right">
				<button class="btn"  @click="$router.push('rolllist')">목록</button>
				<button class="btn-border" @click="insertBtn">확인</button>
			</article>
		</section>
	</main>
</template>
<script>
import axios from 'axios';
import { required, helpers } from 'vuelidate/lib/validators'
const instance = axios.create({
  timeout: 30000,
});
const numRegex = helpers.regex('numRegex', /\d/g);

export default {
	data(){
		return {
			codeTypeList: [],
			roleNm: "",
			rolePtn: "",
			roleDc: "",
			roleTyp: "",
			roleSort: "",
			submitStatus: ""
		}
	},

	validations: {
		roleNm: {
			required
		},
		rolePtn: {
			required
		},
		roleDc: {
			required
		},
		roleTyp: {
			required
		},
		roleSort: {
			required,
			numRegex
		}
	},

	mounted() {
		this.getRoleTypeList();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		insertBtn(){

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("roleNm", this.roleNm);
				apiParams.append("rolePtn", this.rolePtn);
				apiParams.append("roleDc", this.roleDc);
				apiParams.append("roleTyp", this.roleTyp);
				apiParams.append("roleSort", this.roleSort);
	
				instance.post('/api/auth/roleInsertAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('rolllist');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		getRoleTypeList() {
			instance.get('/api/auth/getRoleTypeList.json')
			.then((response => {
				var data = response.data;
				this.codeTypeList = data.roleTypeList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		}
	}
}
</script>
<style>

</style>