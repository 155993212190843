<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차 정기권</div>
			<ul>
				<li><router-link to="periodinfo"      title="정기권신청 안내">안내</router-link></li>
				<li><router-link to="periodagreement" title="정기권 신청" class="active">정기권 신청</router-link></li>
				<li><router-link to="periodlookup"    title="정기권 조회 &#47; 결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 정기권</h2>
				<h4>정기권 신청</h4>
			</article>
			<article>
				<h3>정기권 신청 완료</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">정기권 신청 완료</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">이름</th>
						<td>{{ waitingNm }}</td>
					</tr>
					<!--<tr>
						<th>주소</th>
						<td>{{ zipcode }} {{ addr }} {{ addrDetail }}</td>
					</tr>-->
					<tr>
						<th>연락처</th>
						<td>{{ telNo }}</td>
					</tr>
					<tr>
						<th>신청주차장</th>
						<td>{{ sectnId }}</td>
					</tr>
					<tr>
						<th>차량 소유자명</th>
						<td>{{ carOwnerNm }}</td>
					</tr>
					<tr>
						<th>차량번호</th>
						<td>{{ carNo }}</td>
					</tr>
					<!-- <tr>
						<th>생년월일</th>
						<td>{{ authNoF }}</td>
					</tr> -->
					<tr>
						<th>할인</th>
						<td>{{ discountCd }}</td>
					</tr>
					<tr>
						<th>할증</th>
						<td>{{ extraCd }}</td>
					</tr>
				</tbody>
			</table>
			<article class="message">
				<p>정기권 신청이 완료 되었습니다.</p>
			</article>
			<article class="btn-list">
				<button class="one-btn" @click="$router.push('periodinfo')">확인</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';
const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return {
			waitingNm  : "",
			zipcode    : "",
			addr       : "",
			addrDetail : "",
			telNo      : "",
			sectnNm    : "",
			carOwnerNm : "",
			carNo      : "",
			// authNoF    : "",
			discountNm : "",
			extraNm    : "",
		}
	},

	created() {
		var data = this.$route.params.data;

		this.waitingNm  = data.waitingNm;
		this.zipcode    = data.zipcode;
		this.addr       = data.addr;
		this.addrDetail = data.addrDetail;
		this.telNo      = data.telNo;
		this.sectnId    = data.sectnNm;
		this.carOwnerNm = data.carOwnerNm;
		this.carNo      = data.carNo;
		// this.authNoF    = data.authNoF;
		this.discountCd = data.discountNm;
		this.extraCd    = data.extraNm;
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}

</script>

<style>

</style>