<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo"      title="안내" >안내</router-link></li>
				<li><router-link to="residentsearch"    title="거주자 우선 주차장 검색" class="active">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup"    title="조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 검색</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 검색</h3>
			</article>
			<article>
				<div class="board-search-list resident">
					<ul>
						<li>
							<label for="selectboxMethod"><span class="hide">주차 검색 선택</span></label>
							<select v-model="selectboxMethod" id="selectboxMethod">
								<option :value="'dong'" >주차동 검색</option>
								<option :value="'address'" >주소 검색</option>
							</select>
						</li>
					</ul>

					<!-- 주차동 검색 -->
					<div class="quarter" v-show="selectboxMethod=='dong'">
						<ul>
							<li class="w100">
								<label for="dong"><span class="hide">주차하시려는 동을 선택</span></label>
								<select v-model="dongId" @change="sectnNmInMap()" id="dong">
									<option value="" disabled >주차하시려는 동을 선택 하세요.</option>
									<option v-for="dong in dongs" :key="dong.dongId" :value="dong.dongId">
										{{dong.dongNm}}
									</option>
								</select>
							</li>
<!--						<li>
								<label for="parkingArea"><span class="hide">주차구간 선택</span></label>
								<select id="parkingArea">
									<option value="" disabled selected>주차 구간을 선택 하세요.</option>
									<option v-for="sectn in sectns" :key="sectn.SECTN_ID" :value="sectn.SECTN_ID" @click="cellNmInMap(sectn.DONG_ID, sectn.SECTN_ID, sectn.SECTN_NM)">
										{{sectn.SECTN_NM}}
									</option>
								</select>
							</li>
-->
							<li>
								<button class="table-btn real-black" @keyup.enter.space="searchBtn()">자동검색</button>
							</li>
						</ul>
					</div>

					<!-- 주소 검색 -->
					<div class="quarter" v-show="selectboxMethod=='address'">
						<ul>
							<li class="w100">
								<label for="inputAddress"><span class="hide">화성시 도로명 주소(동, 지번)를 입력하세요.</span></label>
								<input type="text" id="inputAddress" v-model="inputAddress" placeholder="화성시 도로명 주소(동, 지번)를 입력하세요." class="place-hide" style="width: 596px;" @keyup.enter="searchBtn">
							</li>
							<li>
								<button class="table-btn real-black" @click="searchBtn()" >검색</button>
							</li>
						</ul>
					</div>

				</div>
			</article>
			<article class="parking-inner">

				<div id="container">

					<!-- 로드뷰 화면 들어갈 곳 -->
					<div id="rvWrapper">
						<div id="roadview" class="mapWH100"></div>
						<div id="close" title="로드뷰닫기" @click="closeRoadview()"><span class="img"></span></div>
					</div>

					<!-- Map 화면 들어갈 곳 -->
					<div id="mapWrapper">

						<div id="map" class="mapWH100"></div>
						<div id="roadviewControl" :class="{ 'active' : active === true }" @click="setRoadviewRoad()"></div>

					</div>

				</div>

				<article class="view-details" v-show="defFlag">
					<section>
						<article class="parking-name">
							{{ def.fullNm }} ({{ def.cellNm }})
						</article>
						<article class="parking-info">
							<table class="vertical-table">
								<caption class="sr-only">거주자 주차장 상세정보 테이블</caption>
								<colgroup>
										<col style="width:30%" />
										<col style="width:20%" />
										<col style="width:30%" />
										<col style="width:20%" />
								</colgroup>
								<tbody>
									<tr>
										<th scope="row">선택한 주차면</th>
										<td>{{ def.cellNm }}</td>
										<th scope="row">주차가용대수</th>
										<td>{{ positions.length }}</td>
									</tr>
									<tr>
										<th>주소</th>
										<td colspan="3">
											<p>{{ def.fullNm }}</p>
										</td>
									</tr>
									<tr>
										<th>신청 가능 여부</th>
										<td colspan="3">
											<p v-if="def.isAllowedPeriod == 1">신청 가능</p>
											<p v-else>신청 기간 아님</p>
										</td>
									</tr>
									<tr>
										<td colspan="4" style="text-align: center;">
											<button class="table-in-btn-select" @click="residentApply(def)">신청</button>
											<button class="table-in-btn-select" style="margin: 0 0 0 10px;" @click="defFlag=!defFlag">닫기</button>
										</td>
									</tr>
										
								</tbody>
							</table>
						</article>
					</section>
				</article>

			</article>

		</section>
	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data() {
		return{
			selectboxMethod : 'dong',
			dongs           : [],
			sectns          : [],
			cells           : [],
			dongId          : "",
			dongNm          : "",
			sectnId         : "",
			name            : "",

			overlayOn             : false,
			container             : null,
			map                   : null,
			userLat               : 37.19952770562244,
			userLng               : 126.83145146431667,
			imageSrc              : null,
			redImageSrc           : null,
			imageSize             : null,
			imageOption           : null,
			clickImageOption      : null,
			rv                    : null,
			rvClient              : null,
			marker                : null,
			active                : false,
			parkingList           : null,
			parkingName           : null,
			defaultParkingMapList : [],	// 주차장저장리스트
			markers               : [],	// 마커최소 저장리스트
			positions             : [],
			customOverlay         : [],
			mapGeocoder           : null,
			clickCustomOverlay    : [],
			clickStatus           : -1,	// 1 누른상태 , -1 취소상태
			cMarkerIndex          : -1,
			geoLocationState      : -1,
			userCustomOverlay     : null,
			userLocCustomOverlay  : null,
			distance              : "",
			message               : "<div style='padding:5px;'>화성시청</div>",
			park                  : "",
			def                   : "",
			defFlag               : false,
			ckImg                 : null,

			currentOverlayIdx     : -1,

			inputAddress          : "",
			circle                : null,
			myPositionMarker      : null,
			myPositionWindow      : null,
		}
	},

	mounted() {

		this.selectDongList();

		(window.kakao && window.kakao.maps) ? this.initMap() : this.createScript();
4
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		const layer = document.querySelector(".view-details"),
		layerArrow = layer.querySelectorAll('button.table-in-btn-select'),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
					e.preventDefault();
					layerLast.focus();
			}
		};

		const lastKeydown = e => {
				if (!e.shiftKey && e.key === "Tab") {
						e.preventDefault();
						layerFirst.focus();
				}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);
	},

	methods: {

		residentApply(p){

			var params = {
				dongId          : p.dongId,
				dongNm          : p.dongNm,
				sectnId         : p.sectnId,
				sectnNm         : p.sectnNm,
				cellId          : p.cellId,
				cellNm          : p.cellNm,
				isAllowedPeriod : p.isAllowedPeriod,
				assignMethod    : p.assignMethod
			}

			this.$router.push({
				name   : 'residentagreement',
				params : { data : params }
			});

		},

		// 동 리스트 가져오기
		selectDongList(){
			instance.get('/api/resident/selectDongList.json')
			.then((response => {
				var data   = response.data;
				this.dongs = data.list;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});
		},

		// 동 선택
		sectnNmInMap(){
			this.selectParkingCellList(this.dongId, "");
			this.selectParkingSectnList(this.dongId);
		},

		// 동 ID 를 기준으로 면을 검색한다.
		selectParkingCellList(dongId, sectnId){

			this.positions = [];

			instance.get("/api/resident/selectParkingCellList.json", {
				params : {
					paramsDongId  : dongId,
					paramsSectnId : sectnId
				}
			})
			.then(res => {

				var resData   = res.data.list;

				for(var i = 0 ; i < resData.length ; i++){
					var parkingLot = {};
					parkingLot.id               = resData[i].CELL_ID;
					parkingLot.title            = resData[i].CELL_NM;
					parkingLot.fullNm           = resData[i].SECTN_NM;
					parkingLot.dongId           = resData[i].DONG_ID;
					parkingLot.dongNm           = resData[i].DONG_NM;
					parkingLot.sectnId          = resData[i].SECTN_ID;
					parkingLot.sectnNm          = resData[i].SECTN_NM;
					parkingLot.cellId           = resData[i].CELL_ID;
					parkingLot.cellNm           = resData[i].CELL_NM;
					parkingLot.isAllowedPeriod  = resData[i].IS_ALLOWED_PERIOD;
					parkingLot.assignMethod     = resData[i].ASSIGN_METHOD;
					parkingLot.latlng           = new kakao.maps.LatLng(resData[i].LAT, resData[i].LNG);
					this.positions.push(parkingLot);
				}

				this.setMarkers(this.positions);
				this.map.setCenter(this.positions[0].latlng);
				this.map.relayout();

				if(sectnId == "" || sectnId == null){
					this.map.setLevel(3);
				}else{
					this.map.setLevel(2);
				}

			})
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},

		// 동 ID 기준으로 구간을 검색한다
		selectParkingSectnList(dongId){
			instance.get("/api/resident/selectParkingSectnList.json", {
				params : { paramsDongId  : dongId, }
			})
			.then(res => {

				var resData = res.data.resultSectnList;
				this.sectns = resData;

			})
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});
		},

		// 동+구간으로 면을 검색한다
		cellNmInMap(dongId, sectnId, sectnNm){
			this.selectParkingCellList(dongId, sectnId);
		},

		mapMarker() {
			return require("../../../assets/images/map/step2_green.png");
		},

		redMapMarker(){
			return require("../../../assets/images/map/step2.png");
		},

		mapPcMarker(){
			return require("../../../assets/images/map/map_pc.png");
		},

		createScript(){
			const script  = document.createElement("script");
			script.onload = () => kakao.maps.load(this.initMap);
			script.src    = "//dapi.kakao.com/v2/maps/sdk.js?appkey=5130af43da731f05b3be59b4730e2676&libraries=services&autoload=false";
			document.head.appendChild(script);
		},

		initMap(){

			this.overlayOn = false;										// 지도 위에 로드뷰 오버레이가 추가된 상태를 가지고 있을 변수
			this.container = document.getElementById('container');		// 지도와 로드뷰를 감싸고 있는 div
			
			const mapWrapper   = document.getElementById('mapWrapper');	// 지도를 감싸고 있는 div
			const mapContainer = document.getElementById('map');		// 지도를 표시할 div
			const rvContainer  = document.getElementById('roadview');	//로드뷰를 표시할 div

			var mapCenter = new kakao.maps.LatLng(this.userLat, this.userLng);

			// 지도를 생성할 때 필요한 기본 옵션
			const mapOptions = {
				center : mapCenter,	// 지도의 중심좌표
				level  : 5			// 지도의 레벨(확대, 축소 정도)
			};

			// 지도를 표시할 div와 지도 옵션으로 지도를 생성
			this.map = new kakao.maps.Map(mapContainer, mapOptions);
			
			//일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
			var mapTypeControl = new kakao.maps.MapTypeControl();

			// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
			this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);	// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
			// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다

			//this.imageSrc    = "/src/assets/images/map/map_marker.png";		
			this.imageSrc    = this.mapMarker();							// 마커이미지의 주소입니다
			this.redImageSrc = this.redMapMarker();							// 빨간색 마커 이미지
			this.imageSize   = new kakao.maps.Size(20, 20);					// 마커이미지의 크기입니다
			this.imageOption = { offset: new kakao.maps.Point(12, 34) };	// 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

			//var clickImageSrc     = "/src/assets/images/map/map_pc.png";
			var clickImageSrc     = this.mapPcMarker();
			var clickImageSize    = new kakao.maps.Size(34,50);
			this.clickImageOption = { offset: new kakao.maps.Point(17, 50) };

			// 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
			var markerImage = new kakao.maps.MarkerImage(this.imageSrc, this.imageSize, this.imageOption);

			// 로드뷰 객체를 생성
			this.rv   = new kakao.maps.Roadview(rvContainer);

			// 좌표로부터 로드뷰 파노라마 ID를 가져올 로드뷰 클라이언트 객체를 생성
			this.rvClient = new kakao.maps.RoadviewClient();

			// 로드뷰에 좌표가 바뀌었을 때 발생하는 이벤트를 등록
			kakao.maps.event.addListener(this.rv, 'position_changed', () => {

				// 현재 로드뷰의 위치 좌표를 얻어옵니다 
				var rvPosition = this.rv.getPosition();

				// 지도의 중심을 현재 로드뷰의 위치로 설정합니다
				this.map.setCenter(rvPosition);

				// 지도 위에 로드뷰 도로 오버레이가 추가된 상태이면
				if(this.overlayOn) {
					// 마커의 위치를 현재 로드뷰의 위치로 설정합니다
					this.marker.setPosition(rvPosition);
				}
			});

			// 마커 이미지를 생성합니다
			var rvMarkImage = new kakao.maps.MarkerImage(
				'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
				new kakao.maps.Size(26, 46),
				{
					// 스프라이트 이미지를 사용합니다.
					// 스프라이트 이미지 전체의 크기를 지정하고
					spriteSize: new kakao.maps.Size(1666, 168),
					// 사용하고 싶은 영역의 좌상단 좌표를 입력합니다.
					// background-position으로 지정하는 값이며 부호는 반대입니다.
					spriteOrigin: new kakao.maps.Point(705, 114),
					offset: new kakao.maps.Point(13, 46)
				}
			);

			// 드래그가 가능한 마커를 생성합니다
			this.marker = new kakao.maps.Marker({
				image     : rvMarkImage,
				position  : mapCenter,
				draggable : true
			});

			// 마커에 dragend 이벤트를 등록합니다
			kakao.maps.event.addListener(this.marker, 'dragend', mouseEvent => {

				// 현재 마커가 놓인 자리의 좌표입니다 
				var position = this.marker.getPosition();

				// 마커가 놓인 위치를 기준으로 로드뷰를 설정합니다
				this.toggleRoadview(position);

			});

			//지도에 클릭 이벤트를 등록합니다
			kakao.maps.event.addListener(this.map, 'click', mouseEvent => {
				
				// 지도 위에 로드뷰 도로 오버레이가 추가된 상태가 아니면 클릭이벤트를 무시합니다 
				if(!this.overlayOn) {
					return;
				}

				// 클릭한 위치의 좌표입니다 
				var position = mouseEvent.latLng;

				// 마커를 클릭한 위치로 옮깁니다
				this.marker.setPosition(position);

				// 클락한 위치를 기준으로 로드뷰를 설정합니다
				this.toggleRoadview(position);

			});

			/*
			// 지도 축소 확대시 이벤트 (거리별 마커 크기 변경)
			kakao.maps.event.addListener(this.map, 'zoom_changed', () => {
				if(this.clickStatus == 1){

					for(var i = 0 ; i < this.positions.length ; i++){
						this.customOverlay[i].setMap(null);
					}

				}

				if(this.geoLocationState == 1){
					// 사용자 마커위치 오버레이 삭제됨.
					this.userCustomOverlay.setMap(null);
				}

				this.imageSrc = this.mapMarker();
				this.map.relayout();

			});
			*/

			// HTML5의 geolocation으로 사용할 수 있는지 확인합니다 
			if (navigator.geolocation) {

				// GeoLocation을 이용해서 접속 위치 가져오기
				navigator.geolocation.getCurrentPosition(position => {
					
					this.userLat = position.coords.latitude;	// 위도
					this.userLng = position.coords.longitude;	// 경도

					// 마커가 표시될 위치를 geolocation으로 얻어온 좌표로 생성
					var locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);

					// 사용자 위치정보와 화성시 위치 판단 및 커스텀오버레이 생성
					this.changeAddressArea();
					this.userCusOverlay();

					this.geoLocationState = 1;
				});

			}else{

				// HTML5의 GeoLocation을 사용할 수 없을때 마커 표시 위치와 인포윈도우 내용을 설정합니다
				// 기본적으로 평택시청 위치값을 넣어둡니다.
				this.userLat = 37.19952770562244;
				this.userLng = 126.83145146431667;
				this.displayMarker(this.userLat, this.userLng, this.message);

			}

		},

		// 각 주차장 정보를 받아 마커를 지도에 뿌려준다.
		setMarkers(positions, limitKm){
			this.controlhideMarkers();
			this.markers = [];

			// 초록색
			var markerImage    = new kakao.maps.MarkerImage(this.imageSrc, this.imageSize, this.imageOption);

			if(positions != null) {
				for(var i = 0 ; i < positions.length ; i++){

					this.markers[i] = new kakao.maps.Marker({
						map       : this.map,				// 마커를 표시할 지도
						position  : positions[i].latlng,	// 마커의 위치
						title     : positions[i].title,
						clickable : true,					// 마커 클릭가능. (지도의 클릭이벤트가 발생하지않도록 함)
						id        : positions[i].id,
						image     : markerImage
					});

					var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:200px;">'
										+     '<dl style="margin-bottom: 0px;"><dt>' + positions[i].dongNm + "(" + positions[i].title + ')</dt></dl>'
										+ '</div>'
					;

					this.customOverlay[i] = new kakao.maps.CustomOverlay({
						position : positions[i].latlng,
						content  : customContent,
						xAnchor  : 0.017,
						yAnchor  : 0.5
					});

					//kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeOverListener(this.markers[i], this.customOverlay[i], positions[i].title));	// 마커 호버 이벤트
					kakao.maps.event.addListener(this.markers[i], 'click'    , this.makeClickListener(this.markers[i], i, this.customOverlay[i]));						// 마커 클릭 이벤트
					kakao.maps.event.addListener(this.markers[i], 'mouseout' , this.makeOutListener(this.customOverlay[i], this.markers[i], i));						// 마커 호버아웃 이벤트
				}
			}
		},

		//마크 에 마우스를 대면 발생하는 이벤트(호버)
		makeOverListener(markers, customOverlay, title){
			return () => {
				var initMarker = new kakao.maps.MarkerImage(this.mapMarker(), this.ImageSize, this.imageOption);
				markers.setImage(initMarker);

				var imageSrc  = this.redMapMarker();
				var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, this.imageOption);

				markers.setImage(markerImage);
				customOverlay.setMap(this.map)
			}
		},

		makeClickListener(marker, idx, customOverlay){
			return () => {

				// 로드뷰 오버레이 나왓을시 마커 클릭 안되게함.
				if(this.overlayOn) {
					return null;
				}

				this.def         = this.positions[idx];
				this.defFlag     = true;
				this.clickStatus = 1;

				if(this.cMarkerIndex != -1){
					customOverlay.setMap(null);
					if(this.clickCustomOverlay){
						this.clickCustomOverlay.setMap(null);
					}
				}

				for(var i = 0 ; i < this.markers.length ; i++){

					if(this.markers[i] == marker){
						this.cMarkerIndex = i;														// 클릭된 마커 id값 저장
						this.changeImage(this.markers[i], this.redMapMarker(), this.imageOption);	// 클릭된 마커 크기및 이미지 변경
						kakao.maps.event.removeListener(this.markers[i], 'mouseout' , this.makeOutListener(customOverlay, this.markers[i]));
						customOverlay.setMap(this.map);
						this.clickCustomOverlay = customOverlay;
					}else{
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);
					}

				}

			}
		},

		// 마크 에 마우스를 빼면 발생하는 이벤트(아웃)
		makeOutListener(customOverlay, marker, idx){
			return () => {

				if(idx != this.cMarkerIndex){

					var markerImage = new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption);
					marker.setImage(markerImage);
					customOverlay.setMap(null);

				}else{

					if(this.clickStatus == -1){
						marker.setImage(new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption));
						customOverlay.setMap(null);
					}

				}

			}
		},

		// 거리 및 확대레벨에 따른 마커 출력 함수
		changeImage(markers, imageSrc, imageOption){

			var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, imageOption);

			markers.setImage(markerImage);
			markers.setMap(this.map);

		},

		// "마커 감추기" 버튼을 클릭하면 호출되어 배열에 추가된 마커를 지도에서 삭제하는 함수입니다
		controlhideMarkers(){
			this.controlsetMarkers(null);
		},

		controlsetMarkers(map){
			for (var i = 0 ; i < this.markers.length ; i++) {
				this.markers[i].setMap(map);
			}
		},

		// userLat, userLng 위치값으로부터 화성시 영역 여부를 반환 (좌표위치값을 주소로 변환하여 판단)
		changeAddressArea(){

			if(this.mapGeocoder == null){
				this.mapGeocoder = new kakao.maps.services.Geocoder();
			}

			this.mapGeocoder.coord2Address(this.userLng, this.userLat, this.checkUserLoc);

		},

		checkUserLoc(result, status){

			if(status === kakao.maps.services.Status.OK){
				var userAddr = result[0].address.address_name;	// 전체 지번 주소

				if((this.nullChk(userAddr)).indexOf("화성") == -1){
					// 지번주소에 화성시 있는지 판단
					this.userLat = 37.19952770562244;
					this.userLng = 126.83145146431667;
				}

				this.message = "<div style='padding:5px;'>내 위치</div>"
				this.displayMarker(this.userLat, this.userLng, this.message);
				this.map.setCenter(new kakao.maps.LatLng(this.userLat, this.userLng));

			}

		},

		nullChk(x){
			return (x == null || x == undefined) ? "" : x;
		},

		// 현재 위치에 표시를 위한 마커 만들어준다
		displayMarker(userLat, userLng, message, addr){

			var locPosition   = new kakao.maps.LatLng(userLat, userLng);

			// 마커를 생성합니다
			var userLocmarker = new kakao.maps.Marker({
				map      : this.map,
				position : locPosition
			});

			this.map.setCenter(locPosition);
			this.map.relayout();

		},

		// 사용자 gps 마커 오버레이 제거
		userCusOverlay(){
			var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:200px;">'
								+     '<dl style="margin-bottom: 0px;"><dt>' + this.message + '</dt></dl>'
								+ '</div>'
			;

			var mLevel     = this.map.getLevel();
			var cusOverLat = this.userLat;
			var cusOverLng = this.userLng;

			var cusOverLocPosition = new kakao.maps.LatLng(cusOverLat, cusOverLng);
			this.userCustomOverlay = new kakao.maps.CustomOverlay({
				position : cusOverLocPosition,
				content  : customContent,
				xAnchor  : 0.017,
				yAnchor  : 0.5
			});
		},

		// 전달받은 좌표(position)에 가까운 로드뷰의 파노라마 ID를 추출하여
		// 로드뷰를 설정하는 함수입니다
		toggleRoadview(position){
			this.rvClient.getNearestPanoId(position, 50, panoId => {
				// 파노라마 ID가 null 이면 로드뷰를 숨깁니다
				if (panoId === null) {
					this.toggleMapWrapper(true, position);
				} else {
					this.toggleMapWrapper(false, position);

					// panoId로 로드뷰를 설정합니다
					this.rv.setPanoId(panoId, position);
				}
			});
		},

		// 지도를 감싸고 있는 div의 크기를 조정하는 함수입니다
		toggleMapWrapper(active, position) {
			if (active) {

				// 지도를 감싸고 있는 div의 너비가 100%가 되도록 class를 변경합니다 
				this.container.className = '';

				// 지도의 크기가 변경되었기 때문에 relayout 함수를 호출합니다
				this.map.relayout();

				// 지도의 너비가 변경될 때 지도중심을 입력받은 위치(position)로 설정합니다
				this.map.setCenter(position);

			} else {

				// 지도만 보여지고 있는 상태이면 지도의 너비가 50%가 되도록 class를 변경하여
				// 로드뷰가 함께 표시되게 합니다
				if (this.container.className.indexOf('view_roadview') === -1) {
					this.container.className = 'view_roadview';

					// 지도의 크기가 변경되었기 때문에 relayout 함수를 호출합니다
					this.map.relayout();

					// 지도의 너비가 변경될 때 지도중심을 입력받은 위치(position)로 설정합니다
					this.map.setCenter(position);
				}
			}
		},

		// 지도 위의 로드뷰 도로 오버레이를 추가,제거하는 함수입니다
		toggleOverlay(active) {
			if (active) {
				this.overlayOn = true;

				// 지도 위에 로드뷰 도로 오버레이를 추가합니다
				this.map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

				// 지도 위에 마커를 표시합니다
				this.marker.setMap(this.map);

				// 마커의 위치를 지도 중심으로 설정합니다 
				this.marker.setPosition(this.map.getCenter());

				// 로드뷰의 위치를 지도 중심으로 설정합니다
				this.toggleRoadview(this.map.getCenter());
			} else {
				this.overlayOn = false;

				// 지도 위의 로드뷰 도로 오버레이를 제거합니다
				this.map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

				// 지도 위의 마커를 제거합니다
				this.marker.setMap(null);
			}
		},

		// 지도 위의 로드뷰 버튼을 눌렀을 때 호출되는 함수입니다
		setRoadviewRoad() {

			this.active = !this.active;

			// 버튼이 눌린 상태가 아니면
			if (this.active) {
				// 로드뷰 도로 오버레이가 보이게 합니다
				this.toggleOverlay(true);
			} else {
				// 로드뷰 도로 오버레이를 제거합니다
				this.toggleOverlay(false);
			}
		},

		// 로드뷰에서 X버튼을 눌렀을 때 로드뷰를 지도 뒤로 숨기는 함수입니다
		closeRoadview() {
			var position = this.marker.getPosition();
			this.toggleMapWrapper(true, position);
		},

		// 동 선택시
		/*
		listSectn(){
			instance.get('/api/resident/selectSectnList.json', {
				params: {
					di: this.dongId
				}
			})
			.then((response => {
				var data    = response.data;
				this.sectns = data.list;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			})
		},
		*/

		// 주소 검색
		searchBtn(){

			if(this.inputAddress == "" || this.inputAddress == null){
				alert("검색하실 화성시 도로명 주소 정보(동, 지번)를 입력해주세요.");
				return false;
			}else{
				var searchAddress = "화성시 " + this.inputAddress;

				this.addressSearch(searchAddress, 1200);

			}

		},

		// 주소로 좌표를 검색하고 해당 장소와 특정 거리 이내에 있는 주차면을 마커로 표시
		addressSearch(address, distance){
			this.mapGeocoder.addressSearch(address, (result, status) => {
				
				// 정상적으로 검색이 완료됐으면
				if(status == kakao.maps.services.Status.OK){

					// 정해진 화성시 내의 동 검색이기에 복수개 결과 고려 안함.
					var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

					if(this.circle){
						this.clearCricle();
					}
					if(this.myPositionMarker != null){
						this.myPositionMarker.setMap(null);
					}

					this.myPositionMarker = new kakao.maps.Marker({
						map      : this.map,
						position : coords
					});

					if(this.myPositionWindow != null){
						this.myPositionWindow.close();
					}

					this.myPositionWindow = new kakao.maps.InfoWindow({
						content : "<div style='width:150px; text-align:center; padding:6px 0;'>" + result[0].address_name + "</div>"
					});

					this.myPositionWindow.open(this.map, this.myPositionMarker);
					this.map.setCenter(coords);

					this.selectParkingCellNearMyPositionList(coords, distance);
				}

			});
		},

		clearCricle(){

			if(this.myPositionMarker != null){
				this.myPositionMarker.setMap(null);
			}
			if(this.myPositionWindow != null){
				this.myPositionWindow.close();
			}
			if(this.circle){
				this.circle.setMap(null);
			}

		},

		// 특정 거리(meter) 이내의 주차면 목록 조회후 마커로 표시
		selectParkingCellNearMyPositionList(myPosition, distance){

			instance.get('/api/resident/selectParkingCellListByDistance.json', {
				params : {
					lat      : myPosition.getLat(),
					lng      : myPosition.getLng(),
					distance : distance
				}
			})
			.then(res => {

				var resData    = res.data.list;
				this.positions = [];

				for(var i = 0 ; i < resData.length ; i++){
					var parkingLot = {};
					parkingLot.id               = resData[i].CELL_ID;
					parkingLot.title            = resData[i].CELL_NM;
					parkingLot.fullNm           = resData[i].SECTN_NM;
					parkingLot.dongId           = resData[i].DONG_ID;
					parkingLot.dongNm           = resData[i].DONG_NM;
					parkingLot.sectnId          = resData[i].SECTN_ID;
					parkingLot.sectnNm          = resData[i].SECTN_NM;
					parkingLot.cellId           = resData[i].CELL_ID;
					parkingLot.cellNm           = resData[i].CELL_NM;
					parkingLot.isAllowedPeriod  = resData[i].IS_ALLOWED_PERIOD;
					parkingLot.assignMethod     = resData[i].ASSIGN_METHOD;
					parkingLot.latlng           = new kakao.maps.LatLng(resData[i].LAT, resData[i].LNG);
					this.positions.push(parkingLot);
				}

				this.setMarkers(this.positions);
				this.map.relayout();

				this.circle = new kakao.maps.Circle({
					center        : myPosition,	// 원의 중심 좌표
					radius        : 1200,		// 미터 단위의 원의 반지름입니다 
					strokeWeight  : 3,			// 선의 두께입니다 
					strokeColor   : '#75B8FA',	// 선의 색깔입니다
					strokeOpacity : 1,			// 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
					strokeStyle   : 'dashed',	// 선의 스타일 입니다
					fillColor     : '#CFE7FF',	// 채우기 색깔입니다
					fillOpacity   : 0.3 		// 채우기 불투명도 입니다   
				});

				this.circle.setMap(this.map);

				if(this.$isMobile()){
					this.map.setLevel(6);
				}else{
					this.map.setLevel(3);
				}
				
			})
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},

		/*
		searchBtn(){
			instance.get('/api/resident/searchParkingCell.json', {
				params: {
					di : this.dongId,
					si : this.sectnId
				}
			})
			.then((response => {
				if(this.dongId == "") return;

				var data   = response.data;
				this.cells = data.list;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},
		*/

	},

}
</script>

<style scoped lang="scss">
	.mapWH100{ width: 100%; height: 100%; }

	// 마커 마우스 오버시 주차장 명 출력
	#map .mapCustomOverlay-box { position: absolute; top: -90px; left: -95px; }
	#map .mapCustomOverlay-box dl dt { padding: 5px; background: #333333; color: #ffffff; text-align: center; }

	#container {
		overflow : hidden;
		height   : 550px;
		position : relative;
	}
	#mapWrapper {
		width   : 100%;
		height  : 550px;
		z-index : 1;
	}
	#rvWrapper {
		width    : 50%;
		height   : 550px;
		top      : 0;
		right    : 0;
		position : absolute;
		z-index  : 0;
	}
	#container.view_roadview #mapWrapper { width: 50%; }
	#roadviewControl {
		position   : absolute;
		top        : 5px;
		left       : 5px;
		width      : 42px;
		height     : 42px;
		z-index    : 1;
		cursor     : pointer;
		background : url(https://t1.daumcdn.net/localimg/localimages/07/2018/pc/common/img_search.png) 0 -450px no-repeat;
	}
	#roadviewControl.active { background-position:0 -350px; }
	#close {
		position      : absolute;
		padding       : 4px;
		top           : 5px;
		left          : 5px;
		cursor        : pointer;
		background    : #fff;
		border-radius : 4px;
		border        : 1px solid #c8c8c8;
		box-shadow    : 0px 1px #888;
	}
	#close .img {
		display    : block;
		background : url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/rv_close.png) no-repeat;
		width      : 14px;
		height     : 14px;
	}

	#tabs{
		.tab-menu{
			display: flex;

			li{
				padding: 10px 0;
				width: 50%;
				text-align: center;
				border: 1px solid $color;

				a{
					color: $color;
				}

				&.active{
					background-color: $color;

					a{
						color:$white;
					}
				}
			}
		}
	}
	
</style>