<template>
	<main id="error">
    <figure>
      <img src="../../../assets/images/add/img404.png" alt="404에러">
      <!-- <img src="../../../assets/images/add/img500.png" alt="500에러"> -->
      <figcaption>페이지를 찾을 수 없습니다.</figcaption>
    </figure>
    <article class="btn-list">
      <button class="one-btn">메인으로</button>
    </article>
	</main>
</template>

<script>
export default {
	data(){
		return{

		}
	},
}
</script>

<style>

</style>