<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">비대면 자격확인</div>
			<ul>
				<li><router-link to="reductioninfo"      title="비대면 자격확인 안내">안내</router-link></li>
				<li><router-link to="reductionagreement" title="비대면 자격확인 조회" class="active">비대면 자격확인 조회</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>비대면 자격확인</h2>
				<h4>비대면 자격확인 조회</h4>
			</article>
			<article>
				<h3>비대면 자격확인서</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">비대면 자격확인서</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>					
<!--					<tr>-->
<!--						<th scope="row">이름</th>-->
<!--						<td>{{ carOwnerNm }}</td>-->
<!--					</tr>-->
					<tr>
						<th>차량번호</th>
						<td>{{ carNo }}</td>
					</tr>
					<!-- <tr>
						<th>생년월일</th>
						<td>{{ authNoF }}</td>
					</tr> -->
					<tr>
						<th>감면가능항목</th>
						<td>
							<ul>
								<template v-if="dcList.length > 0">
									<li v-for="(dcList, index) in dcList" :key="index" style="width: 100%;">
										<b>{{ dcList.note }}</b>
									</li>
								</template>
								<template v-else>
									<li style="width: 100%;">
										<b>일반</b>
									</li>
								</template>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<article class="btn-list">
				<button class="one-btn" @click="confirmBtn">확인 </button>
			</article>	
		</section>
	</main>
</template>

<script>
export default {
	data(){
		return {
			carOwnerNm : '',
			carNo      : '',
			// authNoF    : '',
			dcList     : '',
		}
	},
	created(){

		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("data"));
		}else{
			localStorage.setItem("data", JSON.stringify(data));
		}

		if(data == null){
			alert("* 잘못된 접근입니다.");
			this.$router.push('/reductioninfo');
		}

		this.carOwnerNm = data.carOwnerNm;
		this.carNo      = data.carNo;
		// this.authNoF    = data.authNoF;
		this.dcList     = data.dcList;

	},
	methods:{
		confirmBtn(){
			localStorage.removeItem("data");
			this.$router.push('/reductioninfo');
		}
	},
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>