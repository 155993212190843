<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo" title="안내">안내</router-link></li>
				<li><router-link to="residentsearch" title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청" class="active">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup" title="조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 확인</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 신청서 확인</h3>
			</article>
			<article>
				<p class="circle">신청자 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">신청자 정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>{{userNm}}</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>{{userAddress}}</td>
						</tr>
						<tr>
							<th>연락처</th>
							<td>{{useHpPhone}}</td>
						</tr>
						<tr>
							<th>생년월일</th>
							<td>{{birth}}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">차량 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">차량 정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">차량번호</th>
							<td>{{carNo}}</td>
						</tr>
						<tr>
							<th>차종</th>
							<td>{{carKind}}</td>
						</tr>
						<tr>
							<th>휴대폰</th>
							<td>{{useHpPhone}}</td>
						</tr>
						<tr>
							<th>소유주명</th>
							<td>{{carOwnerNm}}</td>
						</tr>
						<tr>
							<th>배기량</th>
							<td>{{carDisplcmnt}}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">주민등록(재직증명) 상의 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">주민등록(재직증명) 상의 정보 태아불</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">신청자 구분</th>
							<td>{{applicantNm}}</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>{{address}} {{addressDetail}}</td>
						</tr>
						<tr>
							<th>전입일자</th>
							<td>{{movingDt}}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">희망주차장 정보</p>
				<table class="default-table-view">
					<caption class="sr-only">희망주차장 정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">희망 주차 구간</th>
							<td>경기 화성시 노작로 226</td>
						</tr>
						<tr>
							<th>신청자수</th>
							<td>{{appcntCount}}</td>
						</tr>
						<tr>
							<th>이용 시간 선택</th>
							<td>{{useTimeNm}}</td>
						</tr>
						<tr>
							<th>주차장 거리</th>
							<td>{{parkingLength}}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list">
				<button class="btn" @click="actInsertPriorparkingAppl">신청</button>
				<button class="btn-border" @click="$router.push('residentinfo')">취소</button>
			</article>		
		</section>
	</main>
</template>

<script>
import axios from 'axios';
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			userNm: '',
			userAddress: '',
			useHpPhone: '',
			useTelPhone: '',
			birth: '',
			carNo: '',
			carOwnerNm: '',
			// registerNumber1: '',
			// registerNumber2: '',
			discountCd: '',
			discountNm: '',
			renYnCheckBox: '',
			applicant: '',
			applicantNm: '',
			zipCode: '',
			address: '',
			addressDetail: '',
			movingDt: '',
			dongId: '',
			dongNm: '',
			sectnId: '',
			sectnNm: '',
			cellId: '',
			cellNm: '',
			useTime: '',
			useTimeNm: '',
			submitStatus: '',
			carSpecCode: '',
			carKind: '',
			carDisplcmnt: '',
			assignMethod: '',
			appcntCount: '',
			parkingLength: '',
		}
	},

	created() {
		var data = this.$route.params.data;

		this.userNm = data.userNm;
		this.userAddress = data.userAddress;
		this.useHpPhone = data.useHpPhone;
		this.useTelPhone = data.useTelPhone;
		this.birth = data.birth;
		this.carNo = data.carNo;
		this.carOwnerNm = data.carOwnerNm;
		// this.registerNumber1 = data.registerNumber1;
		// this.registerNumber2 = data.registerNumber2;
		this.discountCd = data.discountCd;
		this.discountNm = data.discountNm;
		this.renYnCheckBox = data.renYnCheckBox;
		this.applicant = data.applicant;
		this.applicantNm = data.applicantNm;
		this.zipCode = data.zipCode;
		this.address = data.address;
		this.addressDetail = data.addressDetail;
		this.movingDt = data.movingDt;
		this.dongId = data.dongId;
		this.dongNm = data.dongNm;
		this.sectnId = data.sectnId;
		this.sectnNm = data.sectnNm;
		this.cellId = data.cellId;
		this.cellNm = data.cellNm;
		this.useTime = data.useTime;
		this.useTimeNm = data.useTimeNm;
		this.submitStatus = data.submitStatus;
		this.carSpecCode = data.carSpecCode;
		this.carKind = data.carKind;
		this.carDisplcmnt = data.carDisplcmnt;
		this.assignMethod = data.assignMethod;
		this.parkingLength = data.parkingLength;
	},

	mounted() {
		this.actSelectParkingCntApi();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		actSelectParkingCntApi() {
			let apiParams = new FormData();
			apiParams.append("paramsDongId", this.dongId);
			apiParams.append("paramsSectnId", this.sectnId);
			apiParams.append("assignMethod", this.assignMethod);

			instance.post('/api/resident/actSelectParkingCntApi.json', apiParams)
			.then((response => {
				var data = response.data.resultMap;

				if(data.code=='00'){
					this.appcntCount = data.data.appcnt;
				}
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		actInsertPriorparkingAppl() {
			let apiParams = new FormData();

			apiParams.append("userNm", this.userNm);
			apiParams.append("userAddress", this.userAddress);
			apiParams.append("useHpPhone", this.useHpPhone);
			apiParams.append("useTelPhone", this.useTelPhone);
			apiParams.append("birth", this.birth);
			apiParams.append("carNo", this.carNo);
			apiParams.append("carOwnerNm", this.carOwnerNm);
			apiParams.append("empNo", this.$store.state.empNo);
			// apiParams.append("registerNumber1", this.registerNumber1);
			// apiParams.append("registerNumber2", this.registerNumber2);
			apiParams.append("discountCd", this.discountCd);
			apiParams.append("renYnCheckBox", this.renYnCheckBox);
			apiParams.append("applicant", this.applicant);
			apiParams.append("zipCode", this.zipCode);
			apiParams.append("address", this.address);
			apiParams.append("addressDetail", this.addressDetail);
			apiParams.append("movingDt", this.movingDt);
			apiParams.append("movingYear", this.movingDt.substr(0, 4));
			apiParams.append("dongId", this.dongId);
			apiParams.append("sectnId", this.sectnId);
			apiParams.append("cellId", this.cellId);
			apiParams.append("useTime", this.useTime);
			apiParams.append("submitStatus", this.submitStatus);
			apiParams.append("carSpecCode", this.carSpecCode);
			apiParams.append("carKind", this.carKind);
			apiParams.append("carDisplcmnt", this.carDisplcmnt);

			instance.post('/api/resident/actInsertPriorparkingAppl.json', apiParams)
			.then((response => {
				const data = response.data.resultMap;

				if(data.result==true && data.code=='00'){
					alert('신청되었습니다.');
					this.$router.push('residentinfo');
				} else{
					alert('실패하였습니다.');
				}
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		}
	}
};
</script>

<style>

</style>