<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리" class="active">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>사용자 관리</h4>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">사용자 관리 등록페이지</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">아이디</th>
							<td>
								{{ empNo }}
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<input type="password" placeholder="비밀번호" class="place-hide" v-model="password">
										<div class="error" v-if="!$v.password.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.password.minLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.maxLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.passRegex">조건에 맞지 않습니다.</div>
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호 확인</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<input type="password" placeholder="비밀번호 확인" class="place-hide" v-model="passwordCon">
										<div class="error" v-if="!$v.passwordCon.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.sameAsPassword">일치하지 않는 비밀번호입니다.</div>
										<div class="error" v-if="!$v.passwordCon.minLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.maxLength">8~20자리 입력란입니다.</div>
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list right">
				<button class="btn"        @click="cancelBtn">목록</button>
				<button class="btn black" @click="updateBtn">수정</button>
			</article>	
		</section>
	
	</main>
</template>

<script>
import axios from 'axios';
import { required, isUnique, email, sameAs, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'
const instance = axios.create({
  timeout: 30000,
});
const passRegex = helpers.regex('passRegex', /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gi);

export default {
	data(){

		return {
			empEsntlId: this.$route.query.empEsntlId,
			empNo: this.$route.query.empNo,
			password: "",
			passwordCon: "",
			submitStatus: "",
		}

	},

	validations: {

		empNo: {
			required
		},
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(20),
			passRegex: passRegex
		},
		passwordCon: {
			required,
			sameAsPassword: sameAs('password'),
			minLength: minLength(8),
			maxLength: maxLength(20)
		},
	},

	mounted() {

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

	},

	methods: {

		updateBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("empNo", this.empNo);
				apiParams.append("empEsntlId", this.empEsntlId);
				apiParams.append("password", this.password);
				apiParams.append("passwordCon", this.passwordCon);

				instance.post('/api/member/updateAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('usermanagement');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		cancelBtn(){
			this.$router.push('usermanagement');
			localStorage.clear();
		},

	},

}
</script>

<style>

</style>