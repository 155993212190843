<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차 정기권</div>
			<ul>
				<li><router-link to="periodinfo"      title="정기권신청 안내">안내</router-link></li>
				<li><router-link to="periodagreement" title="정기권 신청">정기권 신청</router-link></li>
				<li><router-link to="periodlookup"    title="정기권 조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 정기권</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>차량번호조회</h3>
				<p>
					공영주차 정기권을 <span class="bold">조회 &#47; 결제</span> 하실 수 있습니다.
					<span class="bold">전체 차량번호</span>를 입력하여 주세요.(예:<span class="orange">00가0000</span>)
				</p>
			</article>
			<table class="lookup-table">
				<caption class="sr-only">차량번호 조회 테이블</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">차량 번호</th>
						<td>
							<form v-on:submit="onSubmit">
								<ul class="input-style">
									<li>{{empCarNo}}</li>
									<li>
										<button class="table-btn" @click="searchCarNo(carNo)">조회</button>
									</li>
								</ul>
							</form>
						</td>
					</tr>
				</tbody>
			</table>
			<article>
				<h3>차량번호</h3>
			</article>
			<table class="default-table period">
				<caption class="sr-only">차량번호 리스트 테이블</caption>
				<colgroup>
					<col style="width:15%" />
					<col style="width:25%" />
					<col style="width:30%" />
					<col style="width:20%" />
					<col style="width:20%" />
				</colgroup>
				<thead>
					<tr>
						<th scope="col">차량번호</th>
						<th scope="col">신청 공영주차장</th>
						<th scope="col">이용기간</th>
						<th scope="col">신청일</th>
						<th scope="col">신청상태</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="nodata" class="nodate">
						<td colspan="5" >조회된 데이터가 없습니다.</td>
					</tr>
					<tr v-for="(item, index) in item" :key="index" @click="periodView(item)">
						<td>
							<a href="#" @keyup.enter="periodView(item)" title="차량번호">
								{{ item.carNo }}
							</a>
						</td>
						<td>
							<span class="emphasis-view">{{ item.parkingDivCdNm }}</span>
							{{item.parkingNm}}
						</td>
						<td v-if="item.useStartDt == '' && item.useEndDt == ''">-</td>
						<td v-else>{{ item.useStartDt | dateFormat }} ~ {{ item.useEndDt | dateFormat }}</td>
						<td>{{ item.reqDt | datetimeFormat }}</td>
						<td v-if="item.procStsCd == 'MUT-001'" class="black">
							신청중
							<!--<span v-if="item.waitingOdr != ''" class="period-waiting">(대기 {{ item.waitingOdr }} 순위)</span>-->
						</td>
						<td v-if="item.procStsCd == 'MUT-002'">
							<span class="period-complete">배정완납</span>
						</td>
						<td v-if="item.procStsCd == 'MUT-003'">
							<span class="period-cancel">배정해지</span>
						</td>
						<td v-if="item.procStsCd == 'MUT-004' || item.procStsCd == 'MUT-005' || item.procStsCd == 'MUT-006'">
							<span class="period-unpaid">배정미납</span>
						</td>
					</tr>
				</tbody>
			</table>
			
		</section>
		<process-dimmed v-if="show"></process-dimmed>
	</main>
</template>

<script>

import axios from 'axios';
import ProcessDimmed from '../../../components/ProcessDimmed.vue';

const instance = axios.create({
  timeout: 30000,
});

export default {

	computed: {
		empCarNo() {
				return this.$store.state.empCarNo;
		}
	},

	components: {
		ProcessDimmed
	},

	data(){
		return{
			pageNo : 1,
			limit  : 10,
			total  : "",
			carNo  : "",
			item   : {},
			nodata : true,
			show   : false
		}
	},
	filters : {
		dateFormat : function(x){
			if(x != null){
				var d = x.toString().replace(/[^0-9]/g, '');

				var year  = d.toString().substr(0, 4);
				var month = d.toString().substr(4, 2);
				var day   = d.toString().substr(6, 2);

				return year + "-" + month + "-" + day;
			}
		},
		datetimeFormat : function(x){
			if(x != null){
				var d = x.toString().replace(/[^0-9]/g, '');

				var year  = d.toString().substr(0, 4);
				var month = d.toString().substr(4, 2);
				var day   = d.toString().substr(6, 2);
				var time  = d.toString().substr(8, 2);
				var min   = d.toString().substr(10, 2);

				return year + "-" + month + "-" + day + " " + time + ":" + min;
			}
		}
	},
	mounted(){
		this.carNo = this.$route.query.carNo;

		// if(this.carNo != null && this.carNo != ""){
		//	this.searchCarNo(this.carNo);
		//} 

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		onSubmit(e){
			e.preventDefault();
		},
		// 차량번호 조회
		async searchCarNo(carNo){

			var jsonList = [];
/*
			this.carNo   = this.carNo.replace(/(\s*)/g, "");

			var carNoRegex     = /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi;
			var carNoRegexDipl = /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi; //외교차량 정규식

			if(!carNoRegex.test(this.carNo) && !carNoRegexDipl.test(this.carNo)){

				alert("* 차량번호 형식에 맞지 않습니다. 예:00가0000");
				this.$refs.car.focus();

			}else{ */
				this.show = true;
				await instance.get('/api/period/searchPeriodList.json', {
					params: {
						carNo : this.empCarNo
					}
				})
				.then((response => {
					this.show = false;

					var data = response.data.value;

					if(data == 'undefined' || data == null || data.result === null){

						this.item   = null;
						this.nodata = true;

					}else{

						for(var i = 0 ; i < data.result.length ; i++){

							jsonList.push(data.result[i]);

						}

						if(jsonList.length > 0){
							this.item   = jsonList;
							this.nodata = false;
							this.pageNo = data.pageNo.page;
							this.limit  = data.pageNo.limit;
							this.total  = data.pageNo.total;
						}else{
							this.item   = null;
							this.nodata = true;
							this.pageNo = 1;
							this.limit  = 10;
							this.total  = 0;
						}

					}
				}))
				.catch(function (err) {
					//console.dir(err);
					this.show = false;
					alert("서버와 통신중 오류가 발생하였습니다.");
				});

			//}

		},
		// 정기권 결제 상세보기
		periodView : function(item){

			if(item.procStsCd === 'MUT-001'){
				return null;
			}

			var params = {
				carNo    : item.carNo,
				periodId : item.periodId
			}

			this.$router.push({
				name   : 'periodview',
				params : { data : params }
			});

		}
	}
}

</script>

<style>

</style>