<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo"      title="안내">안내</router-link></li>
				<li><router-link to="residentsearch"    title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청" class="active">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup"    title="조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청</h4>
			</article>
			<article>
				<h3 >거주자 우선 주차장 신청</h3>
			</article>
			<article>
				<p class="circle">신청자 정보</p>
				<table class="vertical-table">
					<caption class="sr-only">신청자 정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>{{ userNm }}</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>{{ userAddress }}</td>
						</tr>
						<tr>
							<th class="asterisk-after">휴대폰번호<span class="hide">(필수 입력)</span></th>
							<td>
								<ul class="input-style tel-small">
									<li>
										<label for="phoneNum1" class="hide">휴대폰 번호 첫번째자리(010) </label>
										<input type="text" placeholder="010" v-model="useHpPhone1" id="phoneNum1" ref="phoneNum1">-
										<label for="phoneNum2" class="hide">휴대폰 번호 가운데자리</label>
										<input type="text" placeholder="1234" class="place-hide" v-model="useHpPhone2" id="phoneNum2" ref="phoneNum2">-
										<label for="phoneNum3" class="hide">휴대폰 번호 뒷자리</label>
										<input type="text" placeholder="1234" class="place-hide" v-model="useHpPhone3" id="phoneNum3" ref="phoneNum3">
										<div class="error" v-if="!$v.useHpPhone1.required || !$v.useHpPhone2.required || !$v.useHpPhone3.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>전화번호</th>
							<td>
								<ul class="input-style tel-small">
									<li>
										<label for="firstNumber" class="hide">지역번호</label>
										<input type="text" placeholder="02"   class="place-hide" v-model="useTelPhone1" id="firstNumber">-
										<label for="secondNumber" class="hide">전화번호 앞자리</label>
										<input type="text" placeholder="1234" class="place-hide" v-model="useTelPhone2" id="secondNumber">-
										<label for="thirdNumber" class="hide">전화번호 뒷자리</label>
										<input type="text" placeholder="1234" class="place-hide" v-model="useTelPhone3" id="thirdNumber">
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">생년월일<span class="hide">(필수 입력)</span></th>
							<td>
								<ul class="input-style tel-small">
									<li>
										<label for="birth" class="hide">지역번호</label>
										<input type="text" placeholder="800101"   class="place-hide" v-model="birth" id="firstNumber">
										<div class="error" style="margin: 0 10px" v-if="!$v.birth.required">필수 입력란입니다.</div>
										<div class="error" style="margin: 0 10px" v-if="!$v.birth.birthCheck">생년월일 6자리를 입력해주세요.</div>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">차량 정보</p>
				<table class="vertical-table">
					<caption class="sr-only">차량  정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:40%" />
						<col style="width:20%" />
						<col style="width:20%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">차량번호<span class="hide">(필수입력)</span></th>
							<td colspan="3">
								<ul class="input-style small">
									<li>
										<!-- <input type="text" placeholder="차량번호" class="place-hide" v-model="carNumber"> -->
										{{ carNumber }}
										<!-- <div class="error" v-if="!$v.carNumber.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.carNumber.carNoCheck">차량번호 형식에 맞지 않습니다.</div> -->
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">소유주명 <span class="hide">(필수입력)</span></th>
							<td colspan="3">
								<ul class="input-style small">
									<li>
										<label for="proPrietor" class="hide">소유주명</label>
										<input type="text" placeholder="소유주명" class="place-hide" v-model="carOwnerNm" id="proPrietor" ref="proPrietor">
										<div class="error" v-if="!$v.carOwnerNm.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<!-- <tr>
							<th class="asterisk-after">주민등록번호</th>
							<td colspan="3">
								<ul class="input-style security-number">
									<li>
										<input type="text" placeholder="123456" class="place-hide" v-model="registerNumber1">-
										<input type="password" placeholder="*******" class="place-hide" v-model="registerNumber2">
										<div class="error" v-if="!$v.registerNumber1.required || !$v.registerNumber2.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.registerNumber1.minLength || !$v.registerNumber1.maxLength || !$v.registerNumber2.minLength || !$v.registerNumber2.maxLength">주민등록번호를 올바르게 입력하세요.</div>
									</li>
								</ul>
							</td>
						</tr> -->
						<tr>
							<th>차종</th>
							<td>
								자동조회
							</td>
							<th>배기량</th>
							<td>
								자동조회
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">할인 선택<span class="hide">(필수입력)</span></th>
							<td colspan="3" class="select-area">
								<label for="discountCd" class="hide">할인선택</label>
								<select name="discountCd" id="discountCd" v-model="discountCd">
									<option v-for="(item, index) in discountList" :key="index" :value="{ id : item.code, text : item.codeNm }">{{item.codeNm}}</option>
								</select>
								<div class="error" v-if="!$v.discountCd.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th>공동소유 &#47; 리스 &#47; 외국인 &#47; <br>법인차량 여부</th>
							<td colspan="3">
								<p class="hide">공동소유, 리스, 외국인, 법인차량 여부</p>
								<ul class="input-radio">
									<li>
										<input type="radio" id="false" v-model="renYnCheckBox" value="false">
										<label for="false" @keyup.enter="radioSelect">아니오</label>
									</li>
									<li>
										<input type="radio" id="true" v-model="renYnCheckBox" value="true">
										<label for="true" @keyup.enter="radioSelect">예</label>
									</li>
									<div class="error" v-if="!$v.renYnCheckBox.required">필수 입력란입니다.</div>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">주민등록(재직증명)상의 정보</p>
				<table class="vertical-table">
					<caption class="sr-only">주민등록(재직증명)상의 정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">신청자구분</th>
							<td>
								<ul class="input-radio">
									<li>
										<input type="radio" id="resident" v-model="applicant" :value="{id:'APT-001', text:'거주자'}">
										<label for="resident" @keyup.enter="applicantSelect(1)" >거주자</label>
									</li>
									<li>
										<input type="radio" id="fullTime" v-model="applicant" :value="{id:'APT-002', text:'상근자'}">
										<label for="fullTime" @keyup.enter="applicantSelect()" >상근자</label>
									</li>
									<div class="error" v-if="!$v.applicant.required">필수 입력란입니다.</div>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">주소<span class="hide">(필수입력)</span></th>
							<td>
								<ul class="input-style button">
									<li>
										<label for="zipCode" class="hide">우편번호</label>
										<input type="text" placeholder="우편번호" class="place-hide" v-model="zipCode" id="zipCode" ref="zipCode">
										<input type="button" id="zipcodeButton" class="table-btn black" @click="zipcodeOpen()" value="우편번호">
									</li>
									<li>
										<label for="address" class="hide">주소</label>
										<input type="text" placeholder="주소" class="place-hide" v-model="address" id="address" ref="address">
									</li>
									<li>
										<label for="addressDetail" class="hide">상세주소</label>
										<input type="text" placeholder="상세주소" class="place-hide" v-model="addressDetail" ref="addressDetail" id="addressDetail">
									</li>
									<div class="error" v-if="!$v.zipCode.required || !$v.address.required || !$v.addressDetail.required">필수 입력란입니다.</div>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">전입 일자<span class="hide">(필수입력)</span></th>
							<td>
								<date-picker @emitSetDate="setDate">
								</date-picker>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<p class="circle">희망주차장 정보</p>
				<table class="vertical-table">
					<caption class="sr-only">희망주차장 정보 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">희망주차장 구간<span class="hide">(필수입력)</span></th>
							<td class="select-area hope">
								<div>
									<label for="dongId" class="hide">주차하려는 동 선택</label>
									<select name="dongId" id="dongId" v-model="dongId" @change="selectParkingSectnList()">
										<option :value="{ id : '', text : '' }">주차하려는 동을 선택 하세요.</option>
										<option v-for="dong in dongList" :key="dong.dongId" :value="{ id : dong.dongId, text : dong.dongNm }">
											{{ dong.dongNm }}
										</option>
									</select>
									<div class="error" v-if="!$v.dongId.required">필수 입력란입니다.</div>
								</div>
								<div>
									<label for="sectnId" class="hide">주차구간 선택</label>
									<select name="sectnId" id="sectnId" v-model="sectnId" @change="resetCell()">
										<option :value="{ id : '', text : '', assignMethod : '' }">주차 구간을 선택하세요.</option>
										<option v-for="sectn in sectnList" :key="sectn.SECTN_ID" :value="{ id : sectn.SECTN_ID, text : sectn.SECTN_NM, assignMethod : sectn.ASSIGN_METHOD }">
											{{ sectn.SECTN_NM }}
										</option>
									</select>
									<div class="error" v-if="!$v.sectnId.required">필수 입력란입니다.</div>
								</div>
								<div>
									<label for="cellId" class="hide">주차 면 선택</label>
									<select name="cellId" id="cellId" v-model="cellId">
										<option :value="{ id : '', text : '' }">주차 면을 선택하세요.</option>
										<option v-for="cell in cellList" :key="cell.cellId" :value="{ id : cell.CELL_ID, text : cell.CELL_NM }">
											{{ cell.CELL_NM }}
										</option>
									</select>
								</div>
								<input type="button" id="parkingLotSearch" class="table-btn black" @click="popupFocus(), residentMap(dongId, sectnId)" value="주차 면">
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">이용 시간 선택<span class="hide">(필수입력)</span></th>
							<td class="select-area hope">
								<div>
									<label for="useTime" class="hide">이용시간 선택</label>
									<select name="useTime" id="useTime" v-model="useTime">
										<option :value="{ id : 'UTM-002', text : '주간' }">주간</option>
										<option :value="{ id : 'UTM-003', text : '야간' }">야간</option>
										<option :value="{ id : 'UTM-001', text : '전일' }">전일</option>
									</select>
									<div class="error" v-if="!$v.useTime.required">필수 입력란입니다.</div>
								</div>
								<div>
									<p class="orange">이용 시간을 선택해주세요.</p>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="paragraph">
				<p>거주자우선주차 신청정보와 주민등록(재직증명)상의 정보 주소, 전입(입사)일자가 다른 경우 배정취소 됩니다.</p>
				<p>거주자우선주차 신청완료 후에 변경은 화성도시공사를 통하여 할 수 있으니 다시 한번 입력 정보를 확인하여 주시기 바랍니다.</p>
			</article>
			<!-- 필수 입력칸 체크 팝업 끝 -->
			<article class="btn-list">
				<button class="btn" @click="insertBtn">계속</button>
				<button class="btn-border">취소</button>
			</article>
			
		<!-- 필수 입력칸 체크 팝업 시작 -->
		<pop-up v-show="showModal" class="modal-payment" @emitChangeModal="checkModal()">
			<template #info>
				<li>알림창</li>
			</template>
			<template #content>
				<p v-html="msg"></p>
			</template>
			<template #button>
				<button id="modalMap" class="popup-btn" @click="checkModal()" ref="popupBtn">확인</button>
			</template>
		</pop-up>

		</section>
		<!-- 우편 번호 -->
		<template>
			<div id="zipCodePopup" class="post-code" v-show="zipcodePopup">
				<section class="modal-manager zip-code">
					<article class="title hide">
						<!--
						<ul>
							<li id="zipcodeTit" class="zipcode-header" tabindex="1">
								우편번호
							</li>
							<li id="modalZipcode" class="popup-close zipcode-header" @click="zipcodeController" tabindex="1" @keyup.enter="zipcodeController">
							<span class="hide">닫기 아이콘</span>
							</li>
						</ul>
						-->
						
					</article>
					<article class="content">
						<div ref="embed"></div>
					</article>
				</section>
				<section class="dim"></section>
			</div>
		</template>
		<!-- 모달 지도 -->
		<template>
			<div v-show="mapModal" class="kakaoMap" id="kakao">
				<section class="modal-kakaoMap">
					<article class="title">
						<ul>
							<li id="popupTit" class="popup-header" tabindex="1">거주자 지도 검색<span class="hide">팝업창</span> <span class="hide">팝업창</span></li>
							<li id="popupMap" class="popup-close popup-header" @click="mapController" @keyup.enter="mapController" tabindex="1"><span class="hide">닫기 아이콘</span></li>
						</ul>
						<ul>
							<li>
								<div>
									주차동
								</div>
								<div>
									<label for="dongIdMap" class="hide">주차하려는 동 선택</label>
									<select name="dongIdMap" id="dongIdMap" class="parking-search" tabindex="1" v-model="dongId" @change="[selectParkingSectnList(), sectnNmInMap()]">
										<option :value="{ id : '', text : '' }" disabled>주차하려는 동을 선택 하세요.</option>
										<option v-for="dong in dongList" :key="dong.dongId" :value="{ id : dong.dongId, text : dong.dongNm }">
											{{ dong.dongNm }}
										</option>
									</select>
								</div>
								<div>
									주차구간
								</div>
								<div>
									<label for="sectnIdMap" class="hide">주차구간</label>
									<select name="sectnIdMap" id="sectnIdMap" class="parking-search" tabindex="1" v-model="sectnId" @change="resetCell()">
										<option :value="{ id : '', text : '', assignMethod : '' }">주차 구간을 선택하세요.</option>
										<option v-for="sectn in sectnList" :key="sectn.SECTN_ID" :value="{ id : sectn.SECTN_ID, text : sectn.SECTN_NM, assignMethod : sectn.ASSIGN_METHOD }">
											{{ sectn.SECTN_NM }}
										</option>
									</select>
								</div>
							</li>
						</ul>
					</article>
					<article class="content parking-inner">
						<div class="content-inner" id="container">

							<!-- Map 화면 들어갈 곳 -->
							<div id="mapWrapper">

								<div id="map" class="mapWH100"></div>

							</div>

						</div>
					</article>
				</section>
				<section class="dim"></section>
			</div>
		</template>

	</main>
	
</template>

<script>

import axios      from 'axios';
import DatePicker from '../../../components/DatePicker1.vue';
import { required, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'
import PopUp from '../../../components/PopupType1.vue';

const carNoRegex     = helpers.regex('carNoRegex', /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi);
const carNoRegexDipl = helpers.regex('carNoRegexDipl', /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi); //외교차량 정규식
const birthRegex     = helpers.regex('birthRegex', /^[0-9]{6}$/gi);

const instance = axios.create({
  timeout: 30000,
});

export default {

	data(){
		return{
			picker: null,
			dongList: [],
			sectnList: [],
			cellList: [],
			discountList: [],
			userNm: '',
			userAddress: '',
			useHpPhone1: '010',
			useHpPhone2: '',
			useHpPhone3: '',
			useTelPhone1: '',
			useTelPhone2: '',
			useTelPhone3: '',
			birth: '',
			carNumber: '',
			carOwnerNm: '',
			// registerNumber1: '',
			// registerNumber2: '',
			discountCd: {
				id: 'DC001',
				text: '일반'
			},
			renYnCheckBox: "false",
			applicant: {
				id: 'APT-001',
				text: '거주자',
			},
			zipCode: '',
			address: '',
			addressDetail: '',
			movingDt: new Date().toISOString().substr(0, 10),
			dongId: {
				id   : '',
				text : ''
			},
			sectnId: {
				id           : '',
				text         : '',
				assignMethod : ''
			},
			cellId: {
				id   : '',
				text : ''
			},
			isAllowedPeriod : '',
			useTime: {
				id: 'UTM-001',
				text: '전일'
			},
			submitStatus: '',
			carKind: '',
			carDisplcmnt: '',
			parkingLength: '',
			showModal: false,
			mapIsAllowedPeriod : "",

			mapModal              : false,
			overlayOn             : false,
			container             : null,
			map                   : null,
			userLat               : 37.19952770562244,
			userLng               : 126.83145146431667,
			mapContainer          : null,
			imageSrc              : null,
			imageSize             : null,
			imageOption           : null,
			clickImageOption      : null,
			marker                : null,
			active                : false,
			parkingName           : null,
			defaultParkingMapList : [],	// 주차장저장리스트
			markers               : [],	// 마커최소 저장리스트
			positions             : [],
			customOverlay         : [],
			mapGeocoder           : null,
			clickCustomOverlay    : [],
			clickStatus           : -1,	// 1 누른상태 , -1 취소상태
			cMarkerIndex          : -1,
			geoLocationState      : -1,
			userCustomOverlay     : null,
			userLocCustomOverlay  : null,
			distance              : "",
			message               : "<div style='padding:5px;'>화성시청</div>",
			park                  : "",
			def                   : "",
			defFlag               : false,
			ckImg                 : null,
			zipcodePopup          : false,
			msg                   : '<ul class="info">'
								  + '<li>필수 입력란을 채워주세요.</li>'
								  + '</ul>'
		}
	},

	validations: {
		useHpPhone1: {
			required
		},
		useHpPhone2: {
			required
		},
		useHpPhone3: {
			required
		},
		birth:{
			required,
			birthCheck: birthRegex
		},
		// carNumber: {
		// 	required,
		// 	carNoCheck: or(carNoRegex, carNoRegexDipl)
		// },
		carOwnerNm: {
			required
		},
		// registerNumber1: {
		// 	required,
		// 	minLength: minLength(6),
		// 	maxLength: maxLength(6)
		// },
		// registerNumber2: {
		// 	required,
		// 	minLength: minLength(7),
		// 	maxLength: maxLength(7)
		// },
		discountCd: {
			required
		},
		renYnCheckBox: {
			required
		},
		applicant: {
			required
		},
		zipCode: {
			required
		},
		address: {
			required
		},
		addressDetail: {
			required
		},
		dongId: {
			required
		},
		sectnId: {
			required
		},
		// cellId: {
		// 	required
		// },
		cellId: {
			id: {
				required
			}
		},
		useTime: {
			required
		}
	},

	created(){
		var map = this.$route.params.data;

		if(map == null){
			map = JSON.parse(localStorage.getItem("map"));
		}else{
			localStorage.setItem("map", JSON.stringify(map));
		}

		this.dongId.id   = map.dongId;
		this.dongId.text = map.dongNm;

		this.sectnId.id = map.sectnId;
		this.sectnId.text = map.sectnNm;
		this.sectnId.assignMethod = map.assignMethod;

		this.cellId.id = map.cellId;
		this.cellId.text = map.cellNm;

		this.mapIsAllowedPeriod = map.isAllowedPeriod;
	},

	mounted() {
		this.selectParkingDongList();
		this.selectParkingSectnList();
		this.selectCellList();
		this.getDiscountList();
		this.empInfoSet();

		(window.kakao && window.kakao.maps) ? this.initMap() : this.createScript();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
		
		// if(this.zipcodePopup === true){
		// 	document.getElementById("zipCodePopup").querySelector('.modal-manager').tabindex="5000";
		// 	document.getElementById("zipCodePopup").querySelector('.modal-manager').focus();
		// }

		// const zipcodeLayer = document.querySelector("#zipCodePopup"),
		// zipcodeLayerArrow = zipcodeLayer.querySelectorAll('li.zipcode-header'),
		// zipcodeLayerFirst = zipcodeLayerArrow[0],
		// zipcodeLayerLast = zipcodeLayerArrow[zipcodeLayerArrow.length -1];

		// const zipcodeFirstKeydown = e => {
		// 	if (e.shiftKey && e.key === "Tab") {
		// 			e.preventDefault();
    //       zipcodeLayerLast.focus();
		// 	}
		// };

		// const zipcodeLastKeydown = e => {
		// 		if (!e.shiftKey && e.key === "Tab") {
		// 				e.preventDefault();
		// 				zipcodeLayerFirst.focus();
		// 		}
		// };

		// zipcodeLayerFirst.addEventListener("keydown", zipcodeFirstKeydown);
		// zipcodeLayerLast.addEventListener("keydown", zipcodeLastKeydown);


		if(this.mapModal === true){
			document.getElementById("kakao").querySelector('.modal-kakaoMap').tabindex="5000";
			document.getElementById("kakao").querySelector('.modal-kakaoMap').focus();
		}

		const layer = document.querySelector("#kakao"),
		layerArrow = layer.querySelectorAll('li.popup-header, select.parking-search'),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
					e.preventDefault();
          layerLast.focus();
			}
		};

		const lastKeydown = e => {
				if (!e.shiftKey && e.key === "Tab") {
						e.preventDefault();
						layerFirst.focus();
				}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);
	},

	methods: {
		
		// 필수 입력칸 빠져있을 경우 모달팝업 띄우기
		checkModal(){
			this.showModal = !this.showModal;
			if(this.$refs.phoneNum1.value == ""){
				document.getElementById('phoneNum1').focus();
			}else if(this.$refs.phoneNum2.value == ""){
				document.getElementById('phoneNum2').focus();
			}else if(this.$refs.phoneNum3.value == ""){
				document.getElementById('phoneNum3').focus();
			}else if(this.$refs.proPrietor.value == ""){
				document.getElementById('proPrietor').focus();
			}else if(this.$refs.zipCode.value == ""){
				document.getElementById('zipCode').focus();
			}else if(this.$refs.address.value == ""){
				document.getElementById('address').focus();
			}else if(this.$refs.addressDetail.value == ""){
				document.getElementById('addressDetail').focus();
			}else if(JSON.stringify(this.dongId.id) == '""'){
				document.getElementById('dongId').focus();
			}else if(JSON.stringify(this.sectnId.id) == '""'){
				document.getElementById('sectnId').focus();
			}else if(JSON.stringify(this.cellId.id) == '""'){
				document.getElementById('parkingLotSearch').focus();
			}			
		},
		
		// 거주자 주차장 신청 가능 동 목록
		selectParkingDongList(){
			instance.get('/api/resident/selectParkingDongList.json')
			.then((response => {
				var data      = response.data;
				this.dongList = data.resultDongList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			})
		},

		// 해당 동의 주차구간 목록
		selectParkingSectnList(){
			this.resetSectn();
			this.resetCell();

			instance.get('/api/resident/selectParkingSectnList.json', {
				params : { paramsDongId  : this.dongId.id, }
			})
			.then((response => {
				var data       = response.data;
				this.sectnList = data.resultSectnList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			})
		},

		// 해당 주차구간의 주차면 목록
		selectCellList(){
		
			if(this.dongId.id != '' && this.sectnId.id != ''){

				instance.get('/api/resident/selectParkingCellList.json', {
				params : { paramsDongId  : this.dongId.id,
					 	   paramsSectnId : this.sectnId.id, }
				})
				.then((response => {
					var data       = response.data;
					this.cellList = data.list;
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.");
				})
				
			}
			
		},

		getDiscountList() {
			instance.get('/api/resident/getDiscountList.json')
			.then((response => {
				var data = response.data;

				this.discountList = data.discountList;

				this.discountCd.id = data.discountCd.code;
				this.discountCd.text = data.discountCd.codeNm;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		setDate(date){
			this.movingDt = date;
		},

		insertBtn() {
			this.sectnYnCheck();
			this.$refs.popupBtn.focus();
		},

		sectnYnCheck() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR"
				this.showModal = !this.showModal;
				this.$nextTick(function(){
					this.$refs.popupBtn.focus();
				})
			}else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("sectnId", this.sectnId.id);

				instance.post('/api/resident/sectnYnCheck.json', apiParams)
				.then((response => {
					const sectnYn = response.data.yn;
					if(sectnYn == 1){
						this.carNoDupCheck();
					} else{
						alert('선택하신 구간은 신청 기한이 만료되었습니다.');
					}
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		carNoDupCheck() {
			let apiParams = new FormData();
			apiParams.append("carNo", this.carNumber);
			apiParams.append("dongId", this.dongId.id);
			apiParams.append("sectnId", this.sectnId.id);

			instance.post('/api/resident/carNoDupCheck.json', apiParams)
			.then((response => {
				const data = response.data.resultMap.data;

				if(data.useYn == "NO"){
					alert("이미 신청완료된 차량입니다.\n신청자 : " + data.appcntNm + "님\n차량번호 : " + data.dupCarNo + "\n신청일시 : " + data.appcntDt);
				} else {
					if(data.useYn == "MAX") {
						alert("신청접수 대기자로 2건이상 존재합니다. 신청불가.\n차량번호 : " + data.dupCarNo);
					}else if(data.useYn == "OK") {
						this.validationCheck();
					}
				}
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		validationCheck() {
			this.actSelectParkingLength();

			let apiParams = new FormData();
			// apiParams.append("authNoF", this.registerNumber1);
			// apiParams.append("authNoB", this.registerNumber2);
			apiParams.append("carNo", this.carNumber);
			apiParams.append("carOwnerNm", this.carOwnerNm);
			apiParams.append("discountCd", this.discountCd.id);
			apiParams.append("renYn", this.renYnCheckBox);

			instance.post('/api/resident/validationCheck.json', apiParams)
			.then((response => {
				const data = response.data.resultMap;

				// this.carSpecCode = data.carSpec.code;
				// $("#dcFlag").val(data.dcFlag);

				// 차적 조회
				if(data.carSpec.code == "00"){			// 성공
					this.carKind = data.carSpec.carKind;
					this.carDisplcmnt = data.carSpec.carDisplcmnt;
				}else if(data.carSpec.code == "98"){		// 데이터 없음
					this.carKind = '';
					this.carDisplcmnt = '';
					alert('차적 정보가 존재하지 않습니다.\n소유주 정보(차량번호/이름/주민등록번호)를 정확하게 입력하여 주세요.');
				}else{										// 실패
					this.carKind = '';
					this.carDisplcmnt = '';
					alert('차적 정보 조회에 실패하였습니다. 다시 시도하여 주세요.');
				}

				// 할인대상 체크
				if(data.dcFlag == true){ 
					if(data.carSpec.code != "98" && data.carSpec.code != "99"){		// 할증체크
						let params = {
							userNm : this.userNm,
							userAddress : this.userAddress,
							useHpPhone : this.useHpPhone1 + this.useHpPhone2 + this.useHpPhone3,
							useTelPhone : this.useTelPhone1 + this.useTelPhone2 + this.useTelPhone3,
							birth : this.birth,
							carNo : this.carNumber,
							carOwnerNm : this.carOwnerNm,
							// registerNumber1 : this.registerNumber1,
							// registerNumber2 : this.registerNumber2,
							discountCd : this.discountCd.id,
							discountNm : this.discountCd.text,
							renYnCheckBox : this.renYnCheckBox,
							applicant : this.applicant.id,
							applicantNm : this.applicant.text,
							zipCode : this.zipCode,
							address : this.address,
							addressDetail : this.addressDetail,
							movingDt : this.movingDt,
							dongId : this.dongId.id,
							dongNm : this.dongId.text,
							sectnId : this.sectnId.id,
							sectnNm : this.sectnId.text,
							cellId : this.cellId.id,
							cellNm : this.cellId.text,
							useTime : this.useTime.id,
							useTimeNm : this.useTime.text,
							submitStatus : this.submitStatus,
							carKind : this.carKind,
							carDisplcmnt : this.carDisplcmnt,
							assignMethod : this.sectnId.assignMethod,
							parkingLength : this.parkingLength
						}

						this.$router.push({
							name : 'residentconfirmation',
							params: {data : params}
						})
					}
				}else{
					alert(this.carOwnerNm + '님은 [' + this.discountCd.text + '] 대상이 아닙니다.');
					return false;
				}
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		actSelectParkingLength() {
			let apiParams = new FormData();
			apiParams.append("sectnId", this.sectnId.id);
			apiParams.append("address", this.address + ' ' + this.addressDetail);

			instance.post('/api/resident/actSelectParkingLength.json', apiParams)
			.then((response => {
				const data = response.data.resultMap;

				this.parkingLength = data;
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		openPopup() {
			// const targetId = event.currentTarget.id;
			// const focusEle = document.activeElement;

			// console.log(targetId);

			// if (document.getElementById('modalZipcode') == focusEle) {
			// 	console.log(true);
			// } else {
			// 	console.log(false);
			// }
		},

		zipcodeController(){
			this.zipcodePopup = false;
			document.getElementById("zipcodeButton").focus();
		},

		emitSearchedAddress(data){
			//console.dir(data);
     		 //this.$emit('searchedAddress', data);
			
			this.zipCode = data.zonecode;
			// 도로명을 선택하는 경우
			if(data.userSelectedType == 'R'){
				this.address = data.address;
			}else {
				this.address = data.jibunAddress;
			}
			this.$refs.addressDetail.focus();
			
			this.zipcodePopup = false;
    	},

		mapController(){
			this.mapModal = false;
			document.getElementById("parkingLotSearch").focus();
		},

		searchedAddress(data) {
			this.zipCode = data.zonecode;
			// 도로명을 선택하는 경우
			if(data.userSelectedType == 'R'){
				this.address = data.address;
			}else {
				this.address = data.jibunAddress;
			}
			this.$refs.addressDetail.focus();
			this.showModal = false;
		},

		mapMarker(){
			return require("../../../assets/images/map/step2.png");
		},

		mapPcMarker(){
			return require("../../../assets/images/map/map_pc.png");
		},

		createScript(){
			const script  = document.createElement("script");
			script.onload = () => kakao.maps.load(this.initMap);
			script.src    = "//dapi.kakao.com/v2/maps/sdk.js?appkey=5130af43da731f05b3be59b4730e2676&libraries=services&autoload=false";
			document.head.appendChild(script);
		},

		initMap(){

			this.container = document.getElementById('container');		// 지도와 로드뷰를 감싸고 있는 div

			const mapContainer = document.getElementById('map');		// 지도를 표시할 div

			var mapCenter = new kakao.maps.LatLng(this.userLat, this.userLng);

			// 지도를 생성할 때 필요한 기본 옵션
			const mapOptions = {
				center : mapCenter,	// 지도의 중심좌표
				level  : 4			// 지도의 레벨(확대, 축소 정도)
			};

			// 지도를 표시할 div와 지도 옵션으로 지도를 생성
			this.map = new kakao.maps.Map(mapContainer, mapOptions);

			//일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
			var mapTypeControl = new kakao.maps.MapTypeControl();

			// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
			this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);	// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
			// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다

			this.imageSrc    = this.mapMarker();							// 마커이미지의 주소입니다
			this.imageSize   = new kakao.maps.Size(20, 20);					// 마커이미지의 크기입니다
			this.imageOption = { offset: new kakao.maps.Point(12, 34) };	// 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

			this.displayMarker(this.userLat, this.userLng);
			
		},

		// 현재 위치에 표시를 위한 마커 만들어준다
		displayMarker(userLat, userLng){

			var locPosition   = new kakao.maps.LatLng(userLat, userLng);
			this.map.setCenter(locPosition);
			this.map.relayout();

		},

		mapClickReset(){
			// 만약 클릭한 상태라면, 기존의 오버레이를 지운다.
			if(this.clickStatus == 1){

				for(var i = 0 ; i < this.positions.length ; i++){
					this.customOverlay[i].setMap(null);
				}

			}

			this.map.relayout();
		},

		zipcodeOpen(){
			this.zipcodePopup = true;
			
			 new window.daum.Postcode({
        oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
            let extraRoadAddr = ''; // 도로명 조합형 주소 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
              extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = ' (' + extraRoadAddr + ')';
            }
            // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
            if(fullRoadAddr !== ''){
                fullRoadAddr += extraRoadAddr;
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.zipCode = data.zonecode; //5자리 새우편번호 사용
		    if(data.userSelectedType == 'R'){
				this.address = fullRoadAddr;
			}else {
				this.address = data.jibunAddress;
			}
			this.addressDetail = "";

						this.zipcodePopup = false;
						document.getElementById("zipcodeButton").focus();
        }
      }).embed(this.$refs.embed)
		},

		popupFocus(){
			this.mapModal = true;
			setTimeout(function(){
				document.getElementById('popupTit').focus();
			});
		},

		residentMap(dong, sectn){
			if(this.dongId.id === ""){
				alert("주차동을 선택하세요.");
				return null;
			}

			this.mapModal = true;

			this.mapClickReset();

			this.sectnNmInMap();

			setTimeout(() => {
				this.map.relayout();
				this.setMarkers(this.positions);
				this.map.setLevel(6);
				this.map.setCenter(new kakao.maps.LatLng(this.userLat, this.userLng));
			}, 1);

		},

		// 동 선택
		sectnNmInMap(){
			this.selectParkingCellList(this.dongId.id, "");
			this.selectParkingSectnList();
		},

		// 동 ID 를 기준으로 면을 검색한다.
		selectParkingCellList(dongId, sectnId){

			this.positions = [];

			instance.get("/api/resident/selectParkingCellList.json", {
				params : {
					paramsDongId  : dongId,
					paramsSectnId : sectnId
				}
			})
			.then(res => {

				var resData   = res.data.list;

				for(var i = 0 ; i < resData.length ; i++){
					var parkingLot = {};
					parkingLot.id               = resData[i].CELL_ID;
					parkingLot.title            = resData[i].CELL_NM;
					parkingLot.fullNm           = resData[i].SECTN_NM;
					parkingLot.dongId           = resData[i].DONG_ID;
					parkingLot.dongNm           = resData[i].DONG_NM;
					parkingLot.sectnId          = resData[i].SECTN_ID;
					parkingLot.sectnNm          = resData[i].SECTN_NM;
					parkingLot.cellId           = resData[i].CELL_ID;
					parkingLot.cellNm           = resData[i].CELL_NM;
					parkingLot.isAllowedPeriod  = resData[i].IS_ALLOWED_PERIOD;
					parkingLot.assignMethod     = resData[i].ASSIGN_METHOD;
					parkingLot.latlng           = new kakao.maps.LatLng(resData[i].LAT, resData[i].LNG);
					this.positions.push(parkingLot);
				}

				this.setMarkers(this.positions);
				this.map.setCenter(this.positions[0].latlng);
				this.map.relayout();

				if(sectnId == "" || sectnId == null){
					this.map.setLevel(3);
				}else{
					this.map.setLevel(2);
				}

			})
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},

		// 각 주차장 정보를 받아 마커를 지도에 뿌려준다.
		setMarkers(positions, limitKm){
			this.controlhideMarkers();
			this.markers = [];

			// 초록색
			var markerImage = new kakao.maps.MarkerImage(this.imageSrc, this.imageSize, this.imageOption);

			if(positions != null) {
				for(var i = 0 ; i < positions.length ; i++){

					this.markers[i] = new kakao.maps.Marker({
						map       : this.map,				// 마커를 표시할 지도
						position  : positions[i].latlng,	// 마커의 위치
						title     : positions[i].title,
						clickable : true,					// 마커 클릭가능. (지도의 클릭이벤트가 발생하지않도록 함)
						id        : positions[i].id,
						image     : markerImage
					});

					var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:200px; position: absolute; top: -90px; left: -95px;">'
										+     '<dl style="margin-bottom: 0px;">'
										+         '<dt>' + positions[i].title + '</dt>'
										+     '</dl>'
										+ '</div>'
					;

					this.customOverlay[i] = new kakao.maps.CustomOverlay({
						position  : positions[i].latlng,
						content   : customContent,
						xAnchor   : 0.017,
						yAnchor   : 0.5
					});

					//kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeOverListener(this.markers[i], this.customOverlay[i], positions[i].title));	// 마커 호버 이벤트
					kakao.maps.event.addListener(this.markers[i], 'click', this.makeClickListener(this.markers[i], i, this.customOverlay[i]));	// 마커 호버 이벤트
					//kakao.maps.event.addListener(this.markers[i], 'click'    , this.makeClickListener(this.markers[i], i, this.customOverlay[i]));						// 마커 클릭 이벤트
					//kakao.maps.event.addListener(this.markers[i], 'mouseout' , this.makeOutListener(this.customOverlay[i], this.markers[i], i));						// 마커 호버아웃 이벤트
				}
			}

		},

		// "마커 감추기" 버튼을 클릭하면 호출되어 배열에 추가된 마커를 지도에서 삭제하는 함수입니다
		controlhideMarkers(){
			this.controlsetMarkers(null);
		},

		controlsetMarkers(map){
			for (var i = 0 ; i < this.markers.length ; i++) {
				this.markers[i].setMap(map);
			}
		},

		createCustomOverlay(position, customOverlay){

			var ctag_dt1 = document.createElement("dt");
				ctag_dt1.appendChild(document.createTextNode(position.fullNm + "(" + position.title + ")"));

			var ctag_closeBtn = document.createElement("button");
				ctag_closeBtn.appendChild(document.createTextNode("닫기"));
				ctag_closeBtn.style.cssText = "padding: 0 5px 0 5px; margin-right: 10px; border: 0; background: #FFFFFF; color: #F56B13;  border-radius: 3px;";
				ctag_closeBtn.onclick = () =>{
					customOverlay.setMap(null);
				}

			var ctag_applyBtn = document.createElement("button");
				ctag_applyBtn.appendChild(document.createTextNode("거주자 신청"));
				ctag_applyBtn.style.cssText = "padding: 0 5px 0 5px; margin-right: 10px; border: 0; background: #F56B13; color: #FFFFFF;  border-radius: 3px;";
				ctag_applyBtn.onclick = () => {
					this.setSectnIdMap(position);
				}

			var ctag_dt2 = document.createElement("dt");
				ctag_dt2.appendChild(ctag_applyBtn);
				ctag_dt2.appendChild(ctag_closeBtn);

			var ctag_dl = document.createElement("dl");
				ctag_dl.style.cssText = "margin-bottom : 0px;";
				ctag_dl.appendChild(ctag_dt1);
				ctag_dl.appendChild(ctag_dt2);

			var ctag_div = document.createElement("div");
				ctag_div.style.cssText = "min-height:0; width:300px; position: absolute; top: -100px; left: -150px;";
				ctag_div.className = "mapCustomOverlay-box";
				ctag_div.appendChild(ctag_dl);

			customOverlay.setContent(ctag_div);

		},

		// 마커 정기권 신청 클릭
		setSectnIdMap(positions){
			this.dongId.id   = positions.dongId;
			this.dongId.text = positions.dongNm;

			this.sectnId.id           = positions.sectnId;
			this.sectnId.text         = positions.sectnNm;
			this.sectnId.assignMethod = positions.assignMethod;

			this.cellId.id   = positions.cellId;
			this.cellId.text = positions.cellNm;

			this.mapModal = false;
		},
		
		makeClickListener(marker, idx, customOverlay){
			return () => {

				this.createCustomOverlay(this.positions[idx], customOverlay);

				// 로드뷰 오버레이 나왓을시 마커 클릭 안되게함.
				if(this.overlayOn) {
					return null;
				}

				this.def         = this.positions[idx];
				this.defFlag     = true;
				this.clickStatus = 1;

				if(this.cMarkerIndex != -1){
					customOverlay.setMap(null);
					if(this.clickCustomOverlay){
						this.clickCustomOverlay.setMap(null);
					}
				}

				for(var i = 0 ; i < this.markers.length ; i++){

					if(this.markers[i] == marker){
						this.cMarkerIndex = i;													// 클릭된 마커 id값 저장
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);	// 클릭된 마커 크기및 이미지 변경
						kakao.maps.event.removeListener(this.markers[i], 'mouseout', this.makeOutListener(customOverlay, this.markers[i]));
						customOverlay.setMap(this.map);
						this.clickCustomOverlay = customOverlay;
					}else{
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);
					}

				}

			}
		},

		// 마크 에 마우스를 빼면 발생하는 이벤트(아웃)
		makeOutListener(customOverlay, marker, idx){
			return () => {

				if(idx != this.cMarkerIndex){

					var markerImage = new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption);
					marker.setImage(markerImage);
					customOverlay.setMap(null);

				}else{

					if(this.clickStatus == 1){
						marker.setImage(new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption));
						customOverlay.setMap(null);
					}

				}

			}
		},

		// 거리 및 확대레벨에 따른 마커 출력 함수
		changeImage(markers, imageSrc, imageOption){

			var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, imageOption);

			markers.setImage(markerImage);
			markers.setMap(this.map);

		},

		empInfoSet(){
			this.userNm = this.$store.state.empNm;
			this.carOwnerNm = this.$store.state.empNm;
			this.userAddress = this.$store.state.addr + ' ' + this.$store.state.addrDetail;
			this.useHpPhone1 = this.$store.state.empMobile.substr(0, 3);
			this.useHpPhone2 = this.$store.state.empMobile.substr(3, 4);
			this.useHpPhone3 = this.$store.state.empMobile.substr(7);
			this.carNumber = this.$store.state.empCarNo;
			this.zipCode = this.$store.state.zipcode;
			this.address = this.$store.state.addr;
			this.addressDetail = this.$store.state.addrDetail;
		},

		resetSectn(){
			this.sectnId.id = '';
			this.sectnId.text = '';
			this.sectnId.assignMethod = '';
		},

		resetCell(){
			this.cellId.id = '';
			this.cellId.text = '';
			this.selectCellList();
		},

		radioSelect(){
			this.renYnCheckBox = !this.renYnCheckBox;
		},

		applicantSelect(event){
			if (event == "1"){
				this.applicant.id = 'APT-001';
				this.applicant.text = '거주자';
			} else {
				this.applicant.id = 'APT-002';
				this.applicant.text = '상근자';
			}
		},

		sss(){
			this.zipcodePopup = false;
		}
	},

	components : {
		DatePicker,
		PopUp
	}
}
</script>

<style scoped>

	input[type="text"]{
		width: 100px;
	}

	.mapWH100{ width: 100%; height: 100%; }

	#container {
		overflow : hidden;
		height   : 590px;
		position : relative;
	}
	#mapWrapper {
		width   : 100%;
		height  : 550px;
		z-index : 5;
	}

	.modal-kakaoMap {
		position   : fixed;
		border     : 1px solid;
		display    : block;
		background : #FFFFFF;
		left       : 50%;
		top        : 50%;
		width      : 700px;
		height     : 735px;
		transform  : translate(-50%, -50%);
		z-index    : 2;
	}

	.modal-kakaoMap .title {
		background : #333333;
		height     : auto;
	}

	.modal-kakaoMap .title ul {
		margin-top : 0 !important;
	}

	.modal-kakaoMap .title ul {
		display         : flex;
		justify-content : space-between;
		padding         : 10px 20px;
		box-sizing      : border-box;
		list-style      : none;
	}

	.modal-kakaoMap .title ul li:first-child {
		padding-left : 0;
		width        : 700px;
		text-align   : center;
		box-sizing   : border-box;

		display: flex;
		margin-right: 10px;
		width: 100%;
		justify-content: space-between;

	}
	.modal-kakaoMap .title ul li:first-child div{
		line-height: 40px;
	}

	.modal-kakaoMap .title ul li:first-child div:nth-child(2n){
		background-color: white;
	}

	.modal-kakaoMap .title ul li:first-child div:nth-child(2n) select{
		padding: 0 10px;
	}
	.modal-kakaoMap .title ul li {
		color       : #FFFFFF;
		font-size   : 1.25rem;
		font-weight : 400;
		list-style  : none;
	}

	.modal-kakaoMap .title ul li.popup-close {
		cursor : pointer;
	}

	.modal-kakaoMap .content {
		height     : 620px;
		padding    : 15px 15px 30px;
		box-sizing : border-box;
	}

	.modal-kakaoMap .content .content-inner {
		padding     : 20px;
		background  : #EFEFEF;
		height      : 100%;
		text-align  : center;
		line-height : 1.5;
		box-sizing  : border-box;
	}

	.modal-kakaoMap .btn-list {
		margin-top  : 45px;
		padding-top : 25px;
		border-top  : 1px solid #eeeeee;
	}


</style>