<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle" title="무료차량 관리" class="active">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist" title="정기권 주차장 관리">정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement" title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist" title="팝업 관리">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>무료차량 관리</h4>
			</article>
			<article>
				<h3>무료차량 관리</h3>
				<table class="vertical-table">
					<caption class="sr-only">무료차량 관리 VIEW</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th>소유자명</th>
							<td>{{freeCarObj.carOwnerNm}}</td>
						</tr>
						<tr>
							<th>소속</th>
							<td>{{freeCarObj.orgNm}}</td>
						</tr>
						<tr>
							<th>차량번호</th>
							<td>{{freeCarObj.carNo}}</td>
						</tr>
						<tr>
							<th>신청주차장</th>
							<td>{{freeCarObj.parkingNm}}</td>
						</tr>
						<tr>
							<th>신청기간</th>
							<td>{{freeCarObj.startDt}} - {{freeCarObj.endDt}}</td>
						</tr>
						<tr>
							<th>비고(용도)</th>
							<td>{{freeCarObj.freeNote}}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list right">
				<button class="btn" @click="$router.push('freevehicle')">목록</button>
				<button class="btn black" @click="updateBtn(freeCarObj)">수정</button>
				<button class="btn-border" @click="deleteConfirm()">삭제</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return {
			freeCarId : this.$route.query.freeCarId,
			freeCarObj : ''
		}
	},

	mounted() {
		this.initView();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		initView(){
			instance.get('/api/admin/freecar/view.json', {
				params: {
					freeCarId: this.freeCarId
				}
			})
			.then((response => {
				var data = response.data;
				this.freeCarObj = data.resultVo.result;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		updateBtn(freeCarObj){
			let params = {
				freeCarId : freeCarObj.freeCarId,
				carOwnerNm : freeCarObj.carOwnerNm,
				orgNm : freeCarObj.orgNm,
				carNo : freeCarObj.carNo,
				sectnId : freeCarObj.sectnId,
				startDt : freeCarObj.startDt,
				endDt : freeCarObj.endDt,
				freeNote : freeCarObj.freeNote,
			}

			this.$router.push({
				name : 'freevehicleupdate',
				params : {data : params}
			})
		},

		deleteBtn(){
			let apiParams = new FormData();
			apiParams.append("freeCarId", this.freeCarId);

			instance.post('/api/admin/freecar/delete.json', apiParams)
			.then((response => {
				let message = response.data.message;
				alert(message);
				this.$router.push('freevehicle');
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		deleteConfirm(){
			this.$confirm(
				{
					message: `삭제하시겠습니까?`,
					button: {
						no: '아니오',
						yes: '예'
					},
					callback: confirm => {
						if (confirm) {
						this.deleteBtn();
						}
					}
				}
			)
		}
	}
}
</script>

<style>

</style>