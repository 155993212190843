import Vue       from 'vue';
import VueRouter from 'vue-router';
import Main      from '../html/mainpage/index.vue';
import store     from "../store/index"

/* KICC Easypay 결제 관련 */
import EasypayCert from '../html/subpage/easypay/EasypayCert.vue';

/* Util */
import SiteMap                 from '../html/subpage/util/SiteMap.vue';
import LoginMain               from '../html/subpage/util/LoginMain.vue';
import AdminLoginMain               from '../html/subpage/util/AdminLoginMain.vue';
import MembershipCertification from '../html/subpage/util/MembershipCertification.vue';
import MembershipAgreement     from '../html/subpage/util/MembershipAgreement.vue';
import ProfileInput            from '../html/subpage/util/ProfileInput.vue';
import MembershipCompletion    from '../html/subpage/util/MembershipCompletion.vue';
import FindId                  from '../html/subpage/util/FindId.vue';
import BuisnessFindId          from '../html/subpage/util/BuisnessFindId.vue';
import SendEmail               from '../html/subpage/util/SendEmail.vue';
import FindPassword            from '../html/subpage/util/FindPassword.vue';
import ChangePassword          from '../html/subpage/util/ChangePassword.vue';

/* 공영 주차장 */
import Parking        from '../html/subpage/parking/ParkingIntro.vue';
import ParkingMap     from '../html/subpage/parking/ParkingMap.vue';
import ParkingAddr    from '../html/subpage/parking/ParkingAddress.vue';
import FeeInformation from '../html/subpage/parking/FeeInformation.vue';
import ParkingRealtime from '../html/subpage/parking/ParkingRealtime.vue';
import ParkingOutdoor from '../html/subpage/parking/ParkingOutdoor.vue';

/* 주차요금 사전결제 */
import PrepaymentInfo from '../html/subpage/prepayment/PrepaymentInfo.vue';
import LookupPayment  from '../html/subpage/prepayment/LookupPayment.vue';
import PaymentView    from '../html/subpage/prepayment/PaymentView.vue';
import PaymentResult  from '../html/subpage/prepayment/PaymentResult.vue';

/* 미납주차요금 */
import UnpaidInfo   from '../html/subpage/unpaid/UnpaidInfo.vue';
import LookupUnpaid from '../html/subpage/unpaid/LookupUnpaid.vue';
import UnpaidView   from '../html/subpage/unpaid/UnpaidView.vue';
import UnpaidResult from '../html/subpage/unpaid/UnpaidResult.vue';

/* 즉시감면 자격확인 */
import ReductionInfo      from '../html/subpage/reduction/ReductionInfo.vue';
import ReductionAgreement from '../html/subpage/reduction/ReductionAgreement.vue';
import ReductionConfirm   from '../html/subpage/reduction/ReductionConfirm.vue';
import ReductionResult    from '../html/subpage/reduction/ReductionResult.vue';

/* 거주자 우선 주차장 */
import ResidentInfo         from '../html/subpage/resident/ResidentInfo.vue';
import ResidentSearch       from '../html/subpage/resident/ResidentSearch.vue';
import ResidentAgreement    from '../html/subpage/resident/ResidentAgreement.vue';
import ResidentApplication  from '../html/subpage/resident/ResidentApplication.vue';
import ResidentConfirmation from '../html/subpage/resident/ResidentConfirmation.vue';
import ResidentUnpaid       from '../html/subpage/resident/ResidentUnpaid.vue';
import ResidentResult       from '../html/subpage/resident/ResidentResult.vue';
import ResidentLookup       from '../html/subpage/resident/ResidentLookup.vue';
import ResidentLookupView   from '../html/subpage/resident/ResidentLookupView.vue';
import ResidentViewResult   from '../html/subpage/resident/ResidentViewResult.vue';

/* 거주자 우선 주차장(모바일) */
import ApplicantInfo        from '../html/mobile/resident/ApplicantInfo.vue';
import VehicleInfo          from '../html/mobile/resident/VehicleInfo.vue';
import ResidentRegistration from '../html/mobile/resident/ResidentRegistration.vue';
import ParkingInfo          from '../html/mobile/resident/ParkingInfo.vue';
import ApplicantInfoConfirm from '../html/mobile/resident/ApplicantInfoConfirm.vue';
import TenureInfoConfirm    from '../html/mobile/resident/TenureInfoConfirm.vue';

/* 공영주차장 정기권 */
import PeriodInfo         from '../html/subpage/period/PeriodInfo.vue';
import PeriodAgreement    from '../html/subpage/period/PeriodAgreement.vue';
import PeriodApplication  from '../html/subpage/period/PeriodApplication.vue';
import PeriodResult       from '../html/subpage/period/PeriodResult.vue';
import PeriodLookup       from '../html/subpage/period/PeriodLookup.vue';
import PeriodView         from '../html/subpage/period/PeriodView.vue';
import PeriodLookupResult from '../html/subpage/period/PeriodLookupResult.vue';

/* 무정차 신청 */
import NonstopInfo       from '../html/subpage/nonstop/NonstopInfo.vue';
import NonstopAgree      from '../html/subpage/nonstop/NonstopAgree.vue';
import NonstopCardRegist from '../html/subpage/nonstop/NonstopCardRegist.vue';
import NonstopCarRegist  from '../html/subpage/nonstop/NonstopCarRegist.vue';

/* 열린공간 */
import NoticeList    from '../html/subpage/board/NoticeList.vue';
import NoticeView    from '../html/subpage/board/NoticeView.vue';
import QuestionsList from '../html/subpage/board/QuestionsList.vue';
import QuestionsView from '../html/subpage/board/QuestionsView.vue';

/* 관리자: 무료차량관리 */
import FreeVehicle           from '../html/manager/freevehicle/FreeVehicle.vue';
import FreeVehicleView       from '../html/manager/freevehicle/FreeVehicleView.vue';
import FreeVehicleEnrollment from '../html/manager/freevehicle/FreeVehicleEnrollment.vue';
import FreeVehicleUpdate     from '../html/manager/freevehicle/FreeVehicleUpdate.vue';

/* 관리자: 정기권 주차장 관리 */
import PrepaymentList from '../html/manager/prepayment/PrepaymentList.vue';

/* 관리자: 공지사항  관리 */
import NoticeManagement           from '../html/manager/notice/NoticeManagement.vue';
import NoticeManagementView       from '../html/manager/notice/NoticeManagementView.vue';
import NoticeManagementEnrollment from '../html/manager/notice/NoticeManagementEnrollment.vue';
import NoticeManagementUpdate     from '../html/manager/notice/NoticeManagementUpdate.vue';

/* 관리자: 자주하는 질문   관리 */
import FaQManagement           from '../html/manager/faq/FaQManagement.vue';
import FaQManagementEnrollment from '../html/manager/faq/FaQManagementEnrollment.vue';
import FaQManagementUpdate     from '../html/manager/faq/FaQManagementUpdate.vue';
import FaQManagementView       from '../html/manager/faq/FaQManagementView.vue'; 

/* 관리자: 팝업 관리 */
import PopupList       from '../html/manager/popup/PopupList.vue';
import PopupEnrollment from '../html/manager/popup/PopupEntollment.vue';
import PopupUpdate     from '../html/manager/popup/PopupUpdate.vue';
import PopupView       from '../html/manager/popup/PopupView.vue'; 

/* 관리자: 차단기 제어 */
import BreakerControl from '../html/manager/breaker/BreakerControl.vue';

/* 관리자: 거주자 주차장 관리 */
import ResidentManagement from '../html/manager/resident/ResidentManagement.vue';

/* 시스템 : 사용자 관리 */
import UserManagement     from '../html/system/user/UserManagement.vue';
import ManagerEnrollment  from '../html/system/user/ManagerEnrollment.vue';
import ManagerUpdate      from '../html/system/user/ManagerUpdate.vue';
import OperatorEnrollment from '../html/system/user/OperatorEnrollment.vue';
import OperatorUpdate     from '../html/system/user/OperatorUpdate.vue';
import UserEnrollment     from '../html/system/user/UserEnrollment.vue';
import UserUpdate         from '../html/system/user/UserUpdate.vue';
import UserPassUpdate     from '../html/system/user/UserPassUpdate.vue';

/* 시스템 : 부서관리 */
import DepartmentList       from '../html/system/department/DepartmentList.vue';
import DepartmentEnrollment from '../html/system/department/DepartmentEnrollment.vue';
import DepartmentView       from '../html/system/department/DepartmentView.vue';
import DepartmentUpdate     from '../html/system/department/DepartmentUpdate.vue';

/* 시스템 : 팀관리 */
import TeamList       from '../html/system/team/TeamList.vue';
import TeamEnrollment from '../html/system/team/TeamEnrollment.vue';
import TeamUpdate     from '../html/system/team/TeamUpdate.vue';
import TeamView       from '../html/system/team/TeamView.vue';

/* 시스템 : 공통코드 */
import CodeList       from '../html/system/code/CodeList.vue';
import CodeEnrollment from '../html/system/code/CodeEnrollment.vue';
import CodeUpdate     from '../html/system/code/CodeUpdate.vue';
import CodeView       from '../html/system/code/CodeView.vue';

/* 시스템 : 공통상세코드 */
import DetailedCodeList       from '../html/system/detailedcode/DetailedCodeList.vue';
import DetailedCodeView       from '../html/system/detailedcode/DetailedCodeView.vue';
import DetailedCodeUpdate     from '../html/system/detailedcode/DetailedCodeUpdate.vue';
import DetailedCodeEnrollment from '../html/system/detailedcode/DetailedCodeEnrollment.vue';

/* 시스템 : 메뉴관리 */
import MenuList from '../html/system/menu/MenuList.vue';

/* 시스템 : 메뉴관리 */
import CreateMenu       from '../html/system/menu/CreateMenu.vue';
import CreateMenuSelect from '../html/system/menu/CreateMenuSelect.vue';

/* 시스템 : 프로그램관리 */
import ProgramList       from '../html/system/program/ProgramList.vue';
import ProgramView       from '../html/system/program/ProgramView.vue';
import ProgramEnrollment from '../html/system/program/ProgramEnrollment.vue';
import ProgramUpdate     from '../html/system/program/ProgramUpdate.vue';

/* 시스템 : 권한관리 */
import AuthorityList       from '../html/system/authority/AuthorityList.vue';
import AuthorityView       from '../html/system/authority/AuthorityView.vue';
import AuthorityEnrollment from '../html/system/authority/AuthorityEnrollment.vue';
import AuthorityUpdate     from '../html/system/authority/AuthorityUpdate.vue';
import AuthorityRoll       from '../html/system/authority/AuthorityRoll.vue'; 
import UserAuthorityList   from '../html/system/authority/UserAuthorityList.vue';

/* 시스템 : 롤관리 */
import RollList       from '../html/system/roll/RollList.vue';
import RollEnrollment from '../html/system/roll/RollEnrollment.vue';
import RollUpdate     from '../html/system/roll/RollUpdate.vue';

/* 시스템 : 개인별 접속정보 */
import AccessInfo from '../html/system/access/AccessInfo.vue';

/* 시스템 : 그룹관리 */
import GroupList       from '../html/system/group/GroupList.vue';
import GroupView       from '../html/system/group/GroupView.vue';
import GroupEnrollment from '../html/system/group/GroupEnrollment.vue';

/* 사업자 : 선불권(종이) */
import PrepaymentPaper       from '../html/buisness/PrepaymentPaper.vue';
import PaperApplication      from '../html/buisness/PaperApplication.vue';
import PaperPaymentHistory   from '../html/buisness/PaperPaymentHistory.vue';
import PrepaymentPaperLookup from '../html/buisness/PrepaymentPaperLookup.vue';

/* 사업자 : 선불권(포인트) */
import PrepaymentPoint        from '../html/buisness/PrepaymentPoint.vue';
import PrepaymentPointView    from '../html/buisness/PrepaymentPointView.vue';
import PrepaymentPointSearch  from '../html/buisness/PrepaymentPointSearch.vue';
import PrepaymentUsageHistory from '../html/buisness/PrepaymentUsageHistory.vue';

import EmailGuide from '../html/footer/EmailGuide.vue';
import ErrorPage from '../html/subpage/error/ErrorPage.vue';

/* 마이페이지 */
import MypageMain from '../html/subpage/mypage/MypageMain.vue';
import MypageView from '../html/subpage/mypage/MypageView.vue';

Vue.use(VueRouter);

export const router = new VueRouter({
	mode: 'history',
	routes: [
		
		{
			path        :'/',
			redirect:'/main',
		},

		// 결제 모듈 관련
		{
			path        : '/easypayapproval',
			component   : EasypayCert,
			name        : 'easypaycert',
			props       : true,
		},

		// 메인페이지
		{
			path        : '/main',
			name        : 'main',
			component   : Main,
		},

		// Util
		{
			path        : '/sitemap',
			component   : SiteMap,
			meta        : { category: ["사이트맵", "사이트맵"], title: '사이트맵' },
		},
		{
			path        : '/loginmain',
			component   : LoginMain,
			meta        : { category: ["로그인", "로그인"], title: '로그인', onlyGuest: true },
		},
		{
			path        : '/adminlogin',
			component   : AdminLoginMain,
			meta        : { category: ["관리자 로그인", "관리자 로그인"], title: '관리자 로그인', onlyGuest: true },
		},

		// 본인인증
		{
			path        : '/membershipcertification',
			component   : MembershipCertification,
			meta        : { category: ["회원가입", "본인인증"], title: '회원가입-본인인증', onlyGuest: true },
		},
		// 개인정보 수집 동의
		{
			path        : '/membershipagreement',
			component   : MembershipAgreement,
			meta        : { category: ["회원가입", "약관동의"], title: '회원가입-약관동의', onlyGuest: true },
			name        : 'membershipagreement',
			// props       : true,
		
			// beforeEnter : function(to, from, next){
			// 	if(to.params.data == undefined){
			// 		alert("잘못된 접근입니다.");
			// 		next("/membershipcertification");
			// 	}else{
			// 		next();
			// 	}
			// },
		},
		// 회원가입 입력란
		{
			path        : '/profileinput',
			component   : ProfileInput,
			meta        : { category: ["회원가입", "회원가입"], title: '회원가입-회원정보입력', onlyGuest: true },
			name        : 'profileinput',
			props       : true,
			// 주석처리시 본인인증 안해도 회원가입 입력란으로 넘어감
			beforeEnter : function(to, from, next){
				if(to.params.data == undefined){
					alert("잘못된 접근입니다.");
					next("/membershipcertification");
				}else{
					next();
				}
			},
		},
		{
			path        : '/membershipcompletion',
			component   : MembershipCompletion,
			meta        : { category: ["회원가입", "가입완료"], title: '회원가입-가입완료', onlyGuest: true },
		},
		{
			path        : '/findid',
			component   : FindId,
			meta        : { category: ["로그인", "아이디 찾기"], title: '일반 아이디 찾기', onlyGuest: true },
		},
		{
			path        : '/buisnessfindid',
			component   : BuisnessFindId,
			meta        : { category: ["로그인", "아이디 찾기"], title: '사업자 아이디 찾기', onlyGuest: true },
		},
		{
			path        : '/sendemail',
			component   : SendEmail,
		},

		// 비밀번호 찾기
		{
			path        : '/findpassword',
			component   : FindPassword,
			meta        : { category: ["로그인", "비밀번호 찾기"], title: '비밀번호 찾기', onlyGuest: true },
		},

		// 비밀번호 변경
		{
			path        : '/changepassword',
			component   : ChangePassword,
			meta        : { category: ["로그인", "비밀번호 변경"], title: '비밀번호 변경', onlyGuest: true },
			name        : 'changepassword',
			props       : true,
			
			beforeEnter : function(to, from, next){
				if(to.params.data == undefined){
					alert("잘못된 접근입니다.");
					next("/findpassword");
				}else{
					next();
				}
			},
		},

		// 공영주차장
		{
			path        : '/introduce',
			component   : Parking,
			meta        : { category: ["공영주차장", "주차장 소개"], title: '공영주차장-주차장 소개' },
		},
		{
			path        : '/map',
			component   : ParkingMap,
			name        : 'map',
			meta        : { category: ["공영주차장", "지도 검색"], title: '공영주차장-지도 검색' },
		},
		{
			path        : '/list',
			component   : ParkingAddr,
			name        : 'list',
			props       : true,
			meta        : { category: ["공영주차장", "주차장 검색"], title: '공영주차장-주차장 검색' },
		},
		{
			path        : '/feeinformation',
			component   : FeeInformation,
			meta        : { category: ["공영주차장", "주차장 요금안내"], title: '공영주차장-주차장 요금안내' },
		},
		{
			path        : '/realtimeparking',
			component   : ParkingRealtime,
			meta        : { category: ["공영주차장", "실시간 주차정보"], title: '공영주차장-실시간 주차정보' },
		},
		{
			path        :'/parkingoutdoor',
			component   : ParkingOutdoor,
		},

		// 주차요금 사전결제
		// 1. 안내
		{
			path        : '/prepaymentinfo',
			component   : PrepaymentInfo,
			meta        : { category: ["주차요금 사전결제", "안내"], title: '주차요금 사전결제-안내' },
		},
		// 2. 조회
		{
			path        : '/lookup',
			component   : LookupPayment,
			name        : 'lookup',
			props       : true,
			meta        : { category: ["주차요금 사전결제", "조회/결제"], title: '주차요금 사전결제-조회' },
		},
		// 3. 상세보기 및 결제
		{
			path        : '/paymentview',
			component   : PaymentView,
			name        : 'paymentview',
			props       : true,
			meta        : { category: ["주차요금 사전결제", "조회/결제"], title: '주차요금 사전결제-상세보기 및 결제' },
			
			beforeEnter : function(to, from, next){
				if(to.params.data == undefined){
					alert("잘못된 접근입니다.");
					next("/lookup");
				}else{
					next();
				}
			},
		},
		// 4. 결제 결과
		{
			path        : '/paymentresult',
			component   : PaymentResult,
			name        : 'paymentresult',
			props       : true,
			meta        : { category: ["주차요금 사전결제", "조회/결제"], title: '주차요금 사전결제-결제 결과' },
			
			beforeEnter : function(to, from, next){
				if(to.query.a == "0000" && to.query.b == "0000"){
					next();
				}else{
					alert("잘못된 접근입니다.");
					next("/lookup");
				}
			}
		},

		// 미납주차요금
		// 1. 안내
		{
			path        : '/unpaidinfo',
			component   : UnpaidInfo,
			meta        : { category: ["미납주차요금", "안내"], title: '미납주차요금-안내' },
		},
		// 2. 조회
		{
			path        : '/lookupunpaid',
			component   : LookupUnpaid,
			name       : 'lookupunpaid',
			props      : true,
			meta        : {  category: ["미납주차요금", "조회/결제"], title: '미납주차요금-조회'},
			//authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 3. 상세보기 및 결제
		{
			path        :'/unpaidview',
			component   : UnpaidView,
			name: 'unpaidview',
			props: true,
			meta        : {  category: ["미납주차요금", "조회/결제"], title: '미납주차요금-상세보기 및 결제'},
			//authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 4. 결제 결과
		{
			path        : '/unpaidresult',
			component   : UnpaidResult,
			name        : 'unpaidresult',
			props       : true,
			meta        : {  category: ["미납주차요금", "조회/결제"], title: '미납주차요금-결제 결과'},
			//authorization: ["ROLE_USER", "ROLE_ADMIN"] },
			
			beforeEnter : function(to, from, next){
				if(to.query.a == "0000" && to.query.b == "0000"){
					next();
				}else{
					alert("잘못된 접근입니다.");
					next("/lookupunpaid");
				}
			}
		},

		//즉시감면자격확인
		// 1. 안내
		{
			path        : '/reductioninfo',
			component   : ReductionInfo,
			meta        : { category: ["비대면 자격확인", "안내"], title: '비대면 자격확인-안내' },
		},
		// 2. 동의
		{
			path        : '/reductionagreement',
			component   : ReductionAgreement,
			meta        : {  category: ["비대면 자격확인", "비대면 자격확인 조회"], title: '비대면 자격확인-약관동의',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 3. 입력
		{
			path        : '/reductionconfirm',
			component   : ReductionConfirm,
			name       : 'reductionconfirm',
			props      : true,
			meta        : {  category: ["비대면 자격확인", "비대면 자격확인 조회"], title: '비대면 자격확인-정보입력',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 4. 결과
		{
			path        : '/reductionresult',
			component   : ReductionResult,
			name       : 'reductionresult',
			props      : true,
			meta        : {  category: ["비대면 자격확인", "비대면 자격확인 조회"], title: '비대면 자격확인-결과',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},

		// 거주자 우선 주차장
		// 1. 안내
		{
			path        : '/residentinfo',
			component   : ResidentInfo,
			meta        : { category: ["거주자 우선 주차장", "안내"], title: '거주자 우선 주차장-안내' },
		},
		// 2. 주차장 검색
		{
			path        :'/residentsearch',
			component   : ResidentSearch,
			meta        : { category: ["거주자 우선 주차장", "거주자 우선 주차장 검색"], title: '거주자 우선 주차장 -검색' },
		},
		// 3. 동의
		{
			path        : '/residentagreement',
			component   : ResidentAgreement,
			name       : 'residentagreement',
			props      : true,
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"], title: '거주자 우선 주차장-약관동의',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 4. 주차장 신청
		{
			path        : '/residentapplication',
			component   : ResidentApplication,
			name       : 'residentapplication',
			props      : true,
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"], title: '거주자 우선 주차장 -신청',
				authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 5. 신청 결과
		{
			path        : '/residentconfirmation',
			component   : ResidentConfirmation,
			name       : 'residentconfirmation',
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"], title: '거주자 우선 주차장 -신청결과',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 6. 미납
		{
			path        : '/residentunpaid',
			component   : ResidentUnpaid,
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"], title: '거주자 우선 주차장- 미납',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 7. 신청 결과
		{
			path        : '/residentresult',
			component   : ResidentResult,
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"], title: '거주자 우선 주차장-신청결과',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 8. 조회/결제
		{
			path        : '/residentlookup',
			component   : ResidentLookup,
			name       : 'residentlookup',
			props      : true,
			meta        : {  category: ["거주자 우선 주차장", "조회/결제"], title: '거주자 우선 주차장-조회/결제',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 9. 상세보기
		{
			path        : '/residentlookupview',
			component   : ResidentLookupView,
			name       : 'residentlookupview',
			props      : true,
			meta        : {  category: ["거주자 우선 주차장", "조회/결제"], title: '거주자 우선 주차장-상세보기',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 10. 결제결과
		{
			path        : '/residentviewresult',
			component   : ResidentViewResult,
			name       : 'residentviewresult',
			props      : true,
			meta        : {  category: ["거주자 우선 주차장", "조회/결제"], title: '거주자 우선 주차장-결제결과',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 11. 신청 모바일 
		{
			path        : '/applicantinfo',
			component   : ApplicantInfo,
			name       : 'applicantinfo',
			props      : true,
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"],
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		{
			path        : '/vehicleinfo',
			component   : VehicleInfo,
			name       : 'vehicleinfo',
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"],
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		{
			path        : '/residentregistration',
			component   : ResidentRegistration,
			name       : 'residentregistration',
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"],
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		{
			path        : '/parkinginfo',
			component   : ParkingInfo,
			name       : 'parkinginfo',
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"],
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		{
			path        : '/applicantinfoconfirm',
			component   : ApplicantInfoConfirm,
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"],
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		{
			path        : '/tenureinfoconfirm',
			component   : TenureInfoConfirm,
			meta        : {  category: ["거주자 우선 주차장", "거주자 우선 주차장 신청"],
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},

		// 공영주차 정기권
		// 1. 안내
		{
			path        : '/periodinfo',
			component   : PeriodInfo,
			meta        : { category: ["공영주차장 정기권", "안내"], title: '공영주차장 정기권-안내' },
		},
		// 2. 동의
		{
			path        : '/periodagreement',
			component   : PeriodAgreement,
			meta        : {  category: ["공영주차장 정기권", "정기권 신청"], title: '공영주차장 정기권-약관동의',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 3. 신청
		{
			path        : '/periodapplication',
			component   : PeriodApplication,
			meta        : {  category: ["공영주차장 정기권", "정기권 신청"], title: '공영주차장 정기권-신청',
		},
		},
		// 4. 신청 결과
		{
			path        : '/periodresult',
			component   : PeriodResult,
			name       :'periodresult',
			meta        : {  category: ["공영주차장 정기권", "정기권 신청"], title: '공영주차장 정기권-신청결과',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 5. 조회
		{
			path        : '/periodlookup',
			component   : PeriodLookup,
			name       : 'periodlookup',
			props      : true,
			meta        : {  category: ["공영주차장 정기권", "조회/결제"], title: '공영주차장 정기권-조회',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 6. 상세보기 및 결제
		{
			path        : '/periodview',
			component   : PeriodView,
			name       : 'periodview',
			props      : true,
			meta        : {  category: ["공영주차장 정기권", "조회/결제"], title: '공영주차장 정기권-상세보기 및 결제',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
		},
		// 7. 결제 결과
		{
			path        : '/periodlookupresult',
			component   : PeriodLookupResult,
			name        : 'periodlookupresult',
			props       : true,
			meta        : {  category: ["공영주차장 정기권", "조회/결제"], title: '공영주차장 정기권-결제결과',
			authorization: ["ROLE_USER", "ROLE_ADMIN"] },
			
			beforeEnter : function(to, from, next){
				if(to.query.a == "0000" && to.query.b == "0000"){
					next();
				}else{
					alert("잘못된 접근입니다.");
					next("/lookupunpaid");
				}

			}
		},

		//무정차 신청
		// 1. 신청안내
		{
			path        : '/nonstopinfo',
			component   : NonstopInfo,
			name        : 'nonstopinfo',
			meta        : { category: ["무정차 신청", "무정차 신청"], title: '무정차 신청-신청안내' },
		},
		// 2. 서비스 신청
		{
			path        : '/nonstopagree',
			component   : NonstopAgree,
			name        : 'nonstopagree',
			meta        : {  
				category  : ["무정차 신청", "무정차 신청"], title: '무정차 신청-서비스신청',
				authorization: ["ROLE_USER", "ROLE_ADMIN"]  
			},
		},
		// 3. 결제카드 등록
		{
			path        : '/nonstopcardregist',
			component   : NonstopCardRegist,
			name        : 'nonstopcardregist',
			meta        : {  
				category  : ["무정차 신청", "무정차 신청"], title: '무정차 신청-결제카드 등록',
				authorization: ["ROLE_USER", "ROLE_ADMIN"] 
			},
			
			beforeEnter : function(to, from, next){
				next();
			}
		},
		
		// 4. 차량 등록
		{
			path        : '/nonstopcarregist',
			component   : NonstopCarRegist,
			name        : 'nonstopcarregist',
			meta        : {  
				category  : ["무정차 신청", "무정차 신청"], title: '무정차 신청-차량등록',
				authorization: ["ROLE_USER", "ROLE_ADMIN"]
			},
		},

		//열린 공간
		{
			path        : '/noticelist',
			component   : NoticeList,
			meta        : { category: ["열린공간", "공지사항"], title: '열린공간-공지사항 리스트' },
		},
		{
			path        : '/noticeview',
			component   : NoticeView,
			meta        : { category: ["열린공간", "공지사항"], title: '열린공간-공지사항 상세보기' },
		},
		{
			path        : '/questionslist',
			component   : QuestionsList,
			meta        : { category: ["열린공간", "자주하는 질문"], title: '열린공간-자주하는 질문 리스트' },
		},
		{
			path        : '/questionsview',
			component   : QuestionsView,
			meta        : { category: ["열린공간", "자주하는 질문"], title: '열린공간-자주하는 질문 상세보기' },
		},


		// 관리자 메뉴 : 무료차량 관리
		{
			path        : '/freevehicle',
			component   : FreeVehicle,
			meta        : { category: ["관리자 메뉴", "무료차량 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/freevehicleview',
			component   : FreeVehicleView,
			meta        : { category: ["관리자 메뉴", "무료차량 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/freevehicleenrollment',
			component   : FreeVehicleEnrollment,
			meta        : { category: ["관리자 메뉴", "무료차량 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/freevehicleupdate',
			component   : FreeVehicleUpdate,
			name: 'freevehicleupdate',
			meta        : { category: ["관리자 메뉴", "무료차량 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		// 관리자 메뉴 : 정기권 주차장 관리
		{
			path        : '/prepaymentlist',
			component   : PrepaymentList,
			meta        : { category: ["관리자 메뉴", "정기권 주차장 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		// 관리자 메뉴 : 공지사항관리
		{
			path        : '/noticemanagement',
			component   : NoticeManagement,
			meta        : { category: ["관리자 메뉴", "정기권 주차장 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/noticemanagementview',
			component   : NoticeManagementView,
			meta        : { category: ["관리자 메뉴", "정기권 주차장 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/noticemanagementenrollment',
			component   : NoticeManagementEnrollment,
			meta        : { category: ["관리자 메뉴", "정기권 주차장 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/noticemanagementupdate',
			component   : NoticeManagementUpdate,
			name: 'noticemanagementupdate',
			meta        : { category: ["관리자 메뉴", "정기권 주차장 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		// 관리자 메뉴 : 자주하는 질문 관리
		{
			path        : '/faqmanagement',
			component   : FaQManagement,
			meta        : { category: ["관리자 메뉴", "자주하는 질문 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/faqmanagementview',
			component   : FaQManagementView,
			meta        : { category: ["관리자 메뉴", "자주하는 질문 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/faqmanagementenrollment',
			component   : FaQManagementEnrollment,
			meta        : { category: ["관리자 메뉴", "자주하는 질문 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/faqmanagementupdate',
			component   : FaQManagementUpdate,
			name: 'faqmanagementupdate',
			meta        : { category: ["관리자 메뉴", "자주하는 질문 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		// 관리자 메뉴 : 팝업 관리
		{	
			path        :'/popuplist',
			component   : PopupList,
			meta        : { category: ["관리자 메뉴", "팝업 관리"], title: '관리자메뉴-팝업 관리',
			authorization: ["ROLE_ADMIN"] },
		},
		{	
			path        :'/popupenrollment',
			component   : PopupEnrollment,
			meta        : { category: ["관리자 메뉴", "팝업 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/breakercontrol',
			component   : BreakerControl,
			meta        : { category: ["관리자 메뉴", "차단기 제어"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/popupupdate',
			component   : PopupUpdate,
			name        :'popupupdate',
			meta        : { category: ["관리자 메뉴", "팝업 관리"], title: '관리자메뉴-팝업 수정',
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/popupview',
			component   : PopupView,
			meta        : { category: ["관리자 메뉴", "팝업 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		// 관리자 메뉴 : 거주자 주차장 관리
		{
			path        : '/residentmanagement',
			component   : ResidentManagement,
			meta        : { category: ["관리자 메뉴", "거주자 주차장 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템 관리 : 사용자 관리
		{
			path        : '/usermanagement',
			component   : UserManagement,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/managerenrollment',
			component   : ManagerEnrollment,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/managerupdate',
			component   : ManagerUpdate,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/operatorenrollment',
			component   : OperatorEnrollment,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/operatorupdate',
			component   : OperatorUpdate,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/userenrollment',
			component   : UserEnrollment,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/userupdate',
			component   : UserUpdate,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/userpassupdate',
			component   : UserPassUpdate,
			meta        : { category: ["시스템 관리", "사용자 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 부서관리
		{
			path        : '/departmentlist',
			component   : DepartmentList,
			meta        : { category: ["시스템 관리", "부서 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/departmentenrollment',
			component   : DepartmentEnrollment,
			meta        : { category: ["시스템 관리", "부서 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/departmentview',
			component   : DepartmentView,
			name: 'departmentview',
			meta        : { category: ["시스템 관리", "부서 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/departmentupdate',
			component   : DepartmentUpdate,
			name: 'departmentupdate',
			meta        : { category: ["시스템 관리", "부서 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 팀관리
		{
			path        : '/teamlist',
			component   : TeamList,
			meta        : { category: ["시스템 관리", "팀 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/teamenrollment',
			component   : TeamEnrollment,
			meta        : { category: ["시스템 관리", "팀 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/teamview',
			component   : TeamView,
			meta        : { category: ["시스템 관리", "팀 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/teamupdate',
			component   : TeamUpdate,
			name: 'teamupdate',
			meta        : { category: ["시스템 관리", "팀 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 공통코드
		{
			path        : '/codelist',
			component   : CodeList,
			meta        : { category: ["시스템 관리", "공통코드"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/codeenrollment',
			component   : CodeEnrollment,
			meta        : { category: ["시스템 관리", "공통코드"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/codeview',
			component   : CodeView,
			meta        : { category: ["시스템 관리", "공통코드"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/codeupdate',
			component   : CodeUpdate,
			name: 'codeupdate',
			meta        : { category: ["시스템 관리", "공통코드"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 공통상세코드
		{
			path        : '/detailedcodelist',
			component   : DetailedCodeList,
			meta        : { category: ["시스템 관리", "공통상세코드"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/detailedcodeview',
			component   : DetailedCodeView,
			meta        : { category: ["시스템 관리", "공통상세코드"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/detailedcodeupdate',
			component   : DetailedCodeUpdate,
			name: 'detailedcodeupdate',
			meta        : { category: ["시스템 관리", "공통상세코드"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/detailedcodeenrollment',
			component   : DetailedCodeEnrollment,
			meta        : { category: ["시스템 관리", "공통상세코드"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 메뉴관리
		{
			path        : '/menulist',
			component   : MenuList,
			meta        : { category: ["시스템 관리", "메뉴관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		
		//시스템관리 : 메뉴생성관리
		{
			path        : '/createmenu',
			component   : CreateMenu,
			meta        : { category: ["시스템 관리", "메뉴생성 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/createmenuselect',
			component   : CreateMenuSelect,
			meta        : { category: ["시스템 관리", "메뉴생성 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 프로그램 관리
		{
			path        : '/programlist',
			component   : ProgramList,
			meta        : { category: ["시스템 관리", "프로그램 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/programview',
			component   : ProgramView,
			meta        : { category: ["시스템 관리", "프로그램 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/programenrollment',
			component   : ProgramEnrollment,
			meta        : { category: ["시스템 관리", "프로그램 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/programupdate',
			component   : ProgramUpdate,
			name:'programupdate',
			meta        : { category: ["시스템 관리", "프로그램 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 권한관리
		{
			path        :'/authoritylist',
			component   : AuthorityList,
			meta        : { category: ["시스템 관리", "권한 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/authorityview',
			component   : AuthorityView,
			meta        : { category: ["시스템 관리", "권한 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/authorityenrollment',
			component   : AuthorityEnrollment,
			meta        : { category: ["시스템 관리", "권한 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/authorityupdate',
			component   : AuthorityUpdate,
			name:'authorityupdate',
			meta        : { category: ["시스템 관리", "권한 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/authorityroll',
			component   : AuthorityRoll,
			meta        : { category: ["시스템 관리", "권한 관리"],
				authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/userauthoritylist',
			component   : UserAuthorityList,
			meta        : { category: ["시스템 관리", "사용자 권한 관리"],
				authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 롤관리
		{
			path        :'/rolllist',
			component   : RollList,
			meta        : { category: ["시스템 관리", "롤 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/rollenrollment',
			component   : RollEnrollment,
			meta        : { category: ["시스템 관리", "롤 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        :'/rollupdate',
			component   : RollUpdate,
			name:'rollupdate',
			meta        : { category: ["시스템 관리", "롤 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		//시스템관리 : 개인별 접속 정보
		{
			path        :'/accessinfo',
			component   : AccessInfo,
			meta        : { category: ["시스템 관리", "개인별 접속정보"],
			authorization: ["ROLE_ADMIN"] },
		},

		
		//시스템관리 : 그룹 관리
		{
			path        : '/grouplist',
			component   : GroupList,
			meta        : { category: ["시스템 관리", "그룹 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/GroupView',
			component : GroupView,
			meta        : { category: ["시스템 관리", "그룹 관리"],
			authorization: ["ROLE_ADMIN"] },
		},
		{
			path        : '/groupenrollment',
			component : GroupEnrollment,
			meta        : { category: ["시스템 관리", "그룹 관리"],
			authorization: ["ROLE_ADMIN"] },
		},

		// 사업자 : 산블권(종이)
		{
			path      : '/prepaymentpaper',
			component : PrepaymentPaper,
		},
		{
			path      : '/paperapplication',
			component : PaperApplication,
		},
		{
			path      : '/paperpaymenthistory',
			component : PaperPaymentHistory,
		},
		{
			path      : '/prepaymentpaperlookup',
			component : PrepaymentPaperLookup,
		},
		
		// 사업자 : 산블권(포인트)
		{
			path      : '/prepaymentpoint',
			component : PrepaymentPoint,
		},
		{
			path      : '/prepaymentpointview',
			component : PrepaymentPointView,
		},
		{
			path      : '/prepaymentpointsearch',
			component : PrepaymentPointSearch,
		},
		{
			path      : '/prepaymentusagehistory',
			component : PrepaymentUsageHistory,
		},

		
		// 이메일무단수집거부
		{
			path      : '/emailguide',
			component : EmailGuide,
			meta      : { category: ["이메일무단수집거부", "이메일무단수집거부"], title: '이메일무단수집거부' },
		},

		// Error 페이지
		{
			path      : '/errorpage',
			component : ErrorPage,
			name      : 'error',
		},

		//마이페이지
		{
			path      : '/mypagemain',
			name      : 'mypagemain',
			component : MypageMain,
			meta      : { category: ["마이페이지", "회원정보변경"], title: '마이페이지' },
		},
		{
			path      : '/mypageview',
			component : MypageView,
			name: 'mypageview',
			meta      : { category: ["마이페이지", "회원정보변경"], title: '마이페이지' },
		}
	]
});

router.beforeEach((to, from, next) => {
	const isLogin = store.state.isLogin;
	const authorCode = store.state.authorCode;
	const { authorization } = to.meta;

	if(to.matched.some(record => record.meta.authorization)){
		if(!isLogin){
			alert('로그인이 필요한 메뉴입니다.');
			return next({ path        : "/loginmain"})
		}

		if(!authorization.includes(authorCode)){
			alert('접근 권한이 존재하지 않습니다.');
			return next({ path        : '/main' })
		}
	}

	if(to.matched.some(record => record.meta.onlyGuest)){
		if(isLogin){
		//	alert('비회원만 접근가능합니다.');
			return next({ path        : '/main'})
		}
	}

	next();
})

router.afterEach((to, from) => {
	const title = to.meta.title === undefined ? '화성시 통합주차포탈' : to.meta.title;
	
	Vue.nextTick(() => {
	document.title = title;
	});
})

router.beforeEach((to, from, next) => {
  document.body.setAttribute("tabindex", "-1");
  document.body.focus();
  document.body.removeAttribute("tabindex");
  next();
});