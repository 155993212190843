<template>
	<div v-show="showModal">
		<div class="modal-manager alert">
            <div style="position: relative;">
                <article class="content">
                        <div class="inner">
                            <p>
                                <slot name="text"></slot>
                            </p>
                        </div>
                        <article class="btn-area">
                            <slot name="button" class="confirm"></slot>
                        </article>
                        
                </article>

                <article class="btn-area">
                    <slot name="close"></slot>
                </article>
            </div>
		</div>
		<div class="dim" style="display: block;"></div>
	</div>
</template>

<script>

export default {
	data(){
		return{
			showModal : true
		}
	},
	methods : {
		modalHide(){
			this.$emit('emitChangeModal');
		},
	}
}
</script>

<style>

</style>