<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">미납주차요금</div>
			<ul>
				<li><router-link to="unpaidinfo" title="미납주차요금 안내">안내</router-link></li>
				<li><router-link to="lookupunpaid" title="미납주차요금 조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>미납주차요금</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>차량번호 조회</h3>
				<p>
					미납 주차요금을 <span class="bold">조회 &#47; 결제</span> 하실 수 있습니다. <span class="bold">전체 차량번호</span>를 입력하여 주세요.(예:<span class="orange">00가0000</span>)
				</p>
				<table class="lookup-table">
					<caption class="sr-only">차량번호 조회 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">차량 번호</th>
							<td>
								<form v-on:submit="onSubmit">
									<ul class="input-style">
										<li>
											<label for="unpaidCarNumber" class="hide">미납주차 차량번호 입력 예)00가0000 </label>
											<input type="text" ref="car" v-model="carNo" placeholder="00가0000" class="place-hide" id="unpaidCarNumber">
										</li>
										<li>
											<button class="table-btn" @click="searchCarNo">조회</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<h3>미납요금내역</h3>
				<table class="default-table-content unpaid">
					<caption class="sr-only">미납요금내역 테이블</caption>
					<colgroup>
						<col style="width:70px;" />
						<col style="width:16.66%;" />
						<col style="width:16.66%;" />
						<col style="width:16.66%;" />
						<col style="width:16.66%;" />
						<col style="width:16.66%;" />
						<col style="width:16.66%;" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">번호</th>
							<th scope="col">주차장소</th>
							<th scope="col">주차일시</th>
							<th scope="col">출차일시</th>
							<th scope="col">미납주차료</th>
							<th scope="col">가산금</th>
							<th scope="col">미납총액</th>
						</tr>
					</thead>
					<tbody>
						<tr class="nodate" v-if="nodata">
							<td colspan="7" class="web">조회된 데이터가 없습니다.</td>
							<td colspan="4" class="mobile">조회된 데이터가 없습니다.</td>
						</tr>
						<tr v-for="(item, index) in item" :key="index">
							<td>{{ index + 1 }}</td>
							<td>{{ item.parkingName }}</td>
							<td>{{ item.inDttm }}</td>
							<td>{{ item.outDttm }}</td>
							<td>{{ item.unpaidAmt | comma }}</td>
							<td>{{ item.addAmt | comma }}</td>
							<td>{{ item.sumAmt | comma }}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="arrears">
				<table class="lookup-table" v-if="unpaidSum">
					<caption class="sr-only">미납금액 합계 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">미납금액 합계</th>
							<td>
								<form v-on:submit="unpaid">
									<ul class="input-style">
										<li><span class="orange">{{ totSumAmt | comma }}</span>원 (원금: {{ totUnpaidAmt | comma }}원 + 가산금 : {{ totAddAmt | comma }}원)</li>
										<li>
											<button class="table-btn" @click="unpaidPaymentBtn(unpaidItem)">미납금 결제</button>
										</li>
									</ul>
								</form>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
		</section>
		<process-dimmed v-if="show"></process-dimmed>
	</main>
</template>

<script>
import axios from 'axios';
import ProcessDimmed from '../../../components/ProcessDimmed.vue';

const instance = axios.create({
  timeout: 30000,
});


export default {
	
	components: {
		ProcessDimmed
	},

	data(){
		return{
			carNo        : '',
			item         : {},
			nodata       : true,
			unpaidSum    : false,
			totSumAmt    : '',
			totUnpaidAmt : '',
			totAddAmt    : '',
			unpaidItem   : '',
			show         : false
		}
	},
	mounted(){
		this.carNo = this.$route.query.carNo;

		if(this.carNo != null && this.carNo != ""){
			this.searchCarNo(this.carNo);
		}
		
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	filters : {
		comma: function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
	},
	methods : {
		onSubmit(e){
			e.preventDefault();
		},
		searchCarNo: function(carNo){

			this.carNo = this.carNo.replace(/(\s*)/g, "");

			var carNoRegex     = /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi;
			var carNoRegexDipl = /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi;//외교차량 정규식

			if(!carNoRegex.test(this.carNo) && !carNoRegexDipl.test(this.carNo)){

				alert("* 차량번호 형식에 맞지 않습니다. 예:00가0000");
				this.$refs.car.focus();

			}else{
				this.show = true;
				instance.get('/api/unpaid/searchUnpaidList.json', {
					params: {
						carNo : this.carNo
					}
				})
				.then((response => {
					this.show = false;

					var data = response.data.value;
					
					if(data.result == 'undefined' || data.result == null){

						this.item      = null;
						this.nodata    = true;
						this.unpaidSum = false;

					}else{

						if(data.result.length > 0 ){
							
							//console.dir(response.data);

							this.item       = data.result;
							this.unpaidItem = data;

							this.totSumAmt    = Number(data.totUseAmt) + Number(data.totAddAmt);
							this.totUnpaidAmt = data.totUseAmt;
							this.totAddAmt    = data.totAddAmt;

							this.nodata    = false;
							this.unpaidSum = true;

						}else{
							
							this.item   = null;
							this.nodata = true;
							this.unpaid = false;
						}

					}
				}))
				.catch(function (error) {
					this.show = false;
					alert("서버와 통신중 오류가 발생하였습니다.");
				})
			}
		},
		unpaid(e){
			e.preventDefault();
		},
		// 미납 결제 상세보기
		unpaidPaymentBtn : function(item){

			var params = {
				inOutIds     : item.inOutIds,
				sectnIds     : item.sectnIds,
				sectnNms     : item.sectnNms,
				carNo        : this.carNo,
				totSumAmt    : (item.totUseAmt + item.totAddAmt),
				totUnpaidAmt : item.totUseAmt,
				totAddAmt    : item.totAddAmt,
				totCnt       : item.cnt,
				parkingName  : item.result[0].parkingName
			}

			this.$router.push({
				name: 'unpaidview',
				params: { data : params }
			});

		},
	}
}
</script>

<style>

</style>