<template>
	<section id="category">
		<section>
			<article>
				<h2 class="hide">카테고리</h2>
				<ul>
					<li class="icon-home"><span class="hide">홈 아이콘</span></li>
					<li class="icon-arrow"><span class="hide">화살표 아이콘</span></li>
					<li>{{ lCategory }}</li>
					<li class="icon-arrow"><span class="hide">화살표 아이콘</span></li>
					<li>{{ sCategory }}</li>
				</ul>
			</article>
		</section>
	</section>
</template>

<script>
export default {
	data(){
		return{
			lCategory: '대분류',
			sCategory: '소분류',
		}
	},

	created() {
		EventBus.$on('emitCategory', res => {
			this.lCategory = res[0];
			this.sCategory = res[1];
		});
	},
}
</script>

<style>

</style>