<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차장</div>
			<ul>
				<li><router-link to="/introduce" title="주차장 소개">주차장 소개</router-link></li>
				<li><router-link to="/map" title="지도 검색">지도 검색</router-link></li>
				<li><router-link to="/list" title="주차장 검색">주차장 검색</router-link></li>
        <li><router-link to="/feeinformation" title="주차장 요금안내" class="active">주차장 요금안내</router-link></li>
        <li><router-link to="/realtimeparking" title="실시간 주차정보">실시간 주차정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차장</h2>
				<h4>주차장 요금안내</h4>
			</article>
      <section>        
        <article>
          <p class="circle">주차장 요금안내(일일요금)</p>
        </article>
        <article>
          <table class="default-table-list-view exception">
            <caption class="sr-only">주차장 요금안내(일일요금)</caption>
            <colgroup>
              <col style="width:10%" />
              <col style="width:10%" />
              <col style="width:15%" />
              <col style="width:65%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" colspan="2">구분</th>
                <th scope="col" colspan="2">지역</th>
              </tr>
            </thead>
            <tbody>                   
              <tr>
                <td rowspan="5">공영</td>
                <td>
                  <ul>
                    <li>동부권</li>
                    <li>&middot;</li>
                    <li>환승</li>
                    <li>&middot;</li>
                    <li>상업지구</li>
                  </ul>
                </td>
                <td colspan="2">1:29까지 무료, 1:30~2:29까지 10분당 400원, 2:30부터 10분당 600원</td>
              </tr>
              <tr>                
                <td>서부권</td>
                <td colspan="2">1:29까지 무료, 1:30~2:29까지 10분당 300원, 2:30부터 10분당 400원</td>
              </tr>
              <tr>                
                <td>전통시장</td>                
                <td colspan="2">1:59까지 무료, 2:00~2:59까지 10분당 300원, 3:00부터 10분당 400원</td>
              </tr>
              <tr>                
                <td rowspan="2">관광지</td>
                <td>제부도4·5호</td>
                <td>4:59까지 1,000원, 5:00~9:59까지 2,000원, 10:00~24:00까지 3,000원</td>
              </tr>
              <tr>
                <td>제부도 임시</td>
                <td>시간당 3,000원(상한선 없음)</td>
              </tr>              
              <tr>
                <td colspan="2">공원1</td>
                <td colspan="2">
                  <ul>
                    <li>00:59까지 무료, 1:00~2:59까지 1,000원, 3:00부터 10분당 500원</li>
                    <li>요금 상한선 : 10.000원(24시간 기준), 야간무료 없음</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">공원2</td>
                <td colspan="2">
                  <ul>
                    <li>00:59까지 무료, 1:00~2:59까지 1,000원, 3:00부터 10분당 500원</li>
                    <li>요금 상한선 없음, 야간무료 없음</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">공원3</td>
                <td colspan="2">
                  <ul>
                    <li>1:59까지 무료, 2:00~2:59까지 1,000원, 3:00부터 10분당 500원</li>
                    <li>요금 상한선 : 10,000원(24시간 기준), 야간무료 없음</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">공원4</td>
                <td colspan="2">
                  <ul>
                    <li>5:59까지 무료, 6:00부터 10.000원</li>
                    <li>요금 상한선 : 10,000원(24시간 기준), 야간무료 없음</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ul>
                    <li>요금 상한선</li>
                    <li>(24시간 기준)</li>
                  </ul>
                </td>
                <td colspan="2">
                  <ul>
                    <li>환승공영주차장 : 10,000원</li>
                    <li>제부도4·5호 : 3,000원</li>
                    <li>공원주차장 : 공원1, 3, 4</li>
                    <li>※그 외 주차장 요금 상한선 없음.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">무료시간</td>
                <td colspan="2">
                  <ul>
                    <li>- 공영주차장: 최초 1시간 30분 무료 및 야간시간대 23:00 ~ 익일10:00 무료</li>
                    <li>※ 단, 전통시장 2시간 무료, 관광지 무료 시간 없음.</li>
                    <li>※ 야간 입차시 야간무료시간에  최초 무료시간이 포함됩니다.</li>
                    <li>예)10:00 입차 시 … 11:30부터 요금 발생</li>
                    <li>09:00 입차 시 … 10:30부터 요금 발생</li>
                    <li>08:00 입차 시 … 10:00부터 요금 발생</li>
                    <li>07:00 입차 시 … 10:00부터 요금 발생</li>
                    <li>※ 유인노상주차장(영천1·2, 반송마을) 공휴일, 주말 무료</li>
                    <li>- 공원주차장 : 최초 1시간 무료</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
        <article>
          <table class="default-table-list-view exception">
            <caption class="sr-only">주차장 요금안내(일일요금)</caption>
            <colgroup>
              <col style="width:10%" />
              <col style="width:15%" />
              <col style="width:75%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" colspan="2">구분</th>
                <th scope="col">지역</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="9">공영</td>
                <td>동부권</td>
                <td>진안, 석우동 1·2, 한빛(지상, 지하), 다은(지상, 지하), 오산동, 노작마을, 목동2, 동탄산단</td>
              </tr>                       
              <tr>
                <td>동부권<span>노상</span></td>
                <td>센트럴파크, 능동, 능동 제1, 북광장(노상), 남광장(노상), 나루, 솔빛, 치동천변, 은행사거리, 노작공원(노상), 영천1·2(유인노상), 반송마을(유인노상)</td>
              </tr>
              <tr>
                <td>서부권<span>상업지구</span></td>
                <td>향남2지구 하길리, 하길리 임시, 봉담 동화 중심 주차타워, 행정리, 상리1, 상리2</td>
              </tr>
              <tr>
                <td>서부권</td>
                <td>남양리 구도심, 하가등천, 남양뉴타운(중심상가), 향남2지구 상신리, 남양택지 주3, 상리(행복주택)</td>
              </tr>
              <tr>
                <td>서부권<span>노상</span></td>
                <td>시청앞</td>
              </tr>
              <tr>
                <td>전통시장</td>
                <td>남양재래시장, 평리, 우정</td>
              </tr>
              <tr>
                <td>환승</td>
                <td>동탄역 임시, 병점역, 향남환승터미널</td>
                <td></td>
              </tr>
              <tr>
                <td>거주자우선</td>
                <td>병점1동 거주자우선주차, 진안동 거주자우선주차</td>
              </tr>
              <tr>
                <td>관광지</td>
                <td>제부도4·5호, 제부도 임시</td>
              </tr>
              <tr>
                <td colspan="2">공원1</td>
                <td>여울공원(관리, 작가), 동탄호수공원, 센트럴파크, 치동천체육공원, 반석산에코스쿨, 노작공원</td>
              </tr>
               <tr>
                <td colspan="2">공원2</td>
                <td>돌모루, 동학산</td>
              </tr>
              <tr>
                <td colspan="2">공원3</td>
                <td>근린4호</td>
              </tr>
              <tr>
                <td colspan="2">공원4</td>
                <td>수노을중앙공원</td>
              </tr>
            </tbody>
          </table>
        </article>
        <article>
          <p class="circle">주차장 요금안내 (정기권)</p>
        </article>
        <article>
          <table class="default-table-list-view exception">
            <caption class="sr-only">주차장 요금안내(정기권)</caption>
            <colgroup>
              <col style="width:30%" />
              <col style="width:30%" />
              <col style="width:40%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">주차장명</th>
                <th scope="col">정기권요금</th>
                <th scope="col">권역</th>
              </tr>
            </thead>
            <tbody>                       
              <tr>
                <td scope="row">한빛지하주차장</td>
                <td rowspan="4">100,000</td>
                <td rowspan="10">동  부</td>
              </tr>
              <tr>
                <td>다은지하주차장</td>
              </tr>
              <tr>
                <td>진안공영주차장</td>
              </tr>
              <tr>
                <td>목동2공영주차장</td>
              </tr>
              <tr>
                <td>동탄역 공영주차장(임시)</td>
                <td rowspan="4">90,000</td>
              </tr>
              <tr>
                <td>석우동1 공영주차장</td>
              </tr>
              <tr>
                <td>석우동2 공영주차장</td>
              </tr>
              <tr>
                <td>병점공영주차장</td>
              </tr>
              <tr>
                <td>오산동 공영주차장</td>
                <td rowspan="2">60,000</td>
              </tr>
              <tr>
                <td>노작마을 공영주차장</td>
              </tr>
              <tr>
                <td>행정리공영주차장</td>
                <td rowspan="4">100,000</td>
                <td rowspan="15">서  부 </td>
              </tr>
              <tr>
                <td>향남2 상신리 공영주차장</td>
              </tr>
              <tr>
                <td>향남2 하길리 공영주차장</td>
              </tr>
              <tr>
                <td>봉담동화 공영주차장</td>
              </tr>
              <tr>
                <td>상리1 공영주차장</td>
                <td rowspan="2">60,000</td>
              </tr>
              <tr>
                <td>상리2 공영주차장</td>
              </tr>
              <tr>
                <td>향남환승터미널공영주차장</td>
                <td>90,000</td>
              </tr>
              <tr>
                <td>우정공영주차장</td>
                <td rowspan="8">60,000<br>(재래시장은 50% 감면 적용)</td>
              </tr>
              <tr>
                <td>평리공영주차장</td>
              </tr>
              <tr>
                <td>하가등천공영주차장 </td>
              </tr>
              <tr>
                <td>제부도4호 공영주차장</td>
              </tr>
              <tr>
                <td>제부도5호 공영주차장</td>
              </tr>
              <tr>
                <td>남양재래시장 공영주차장(재래시장)</td>
              </tr>
              <tr>
                <td>남양리 구도심 공영주차장</td>
              </tr>
              <tr>
                <td>상리(행복주택)공영주차장</td>
              </tr>
            </tbody>
          </table>
        </article>
        <article>
            <p class="circle">주차장 요금안내(거주자우선)</p>
        </article>
        <article>
          <table class="default-table-list-view exception">
            <caption class="sr-only">주차장 요금안내(거주자우선)</caption>
            <colgroup>
              <col style="width:10%" />
              <col style="width:22.5%" />
              <col style="width:22.5%" />
              <col style="width:22.5%" />
              <col style="width:22.5%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">구분</th>
                <th scope="col">전일제</th>
                <th scope="col">공유주차(야간)</th>
                <th scope="col">공유주차(주간)</th>
                <th scope="col">미신청 주차면</th>
              </tr>
            </thead>
            <tbody>                       
              <tr>
                <td scope="row">적용시간</td>
                <td>24시간(전일)</td>
                <td>17:00 ~ 익일 10:00</td>
                <td>10:00 ~ 17:00</td>
                <td>10:00 ~ 23:00</td>
              </tr>
              <tr>
                <td scope="row">요금제</td>
                <td>30,000원/월</td>
                <td>15,000원/월</td>
                <td colspan="2">
                  <ul>
                    <li>공영주차장 구분에 따른 해당지역</li>
                    <li>공영주차장 요금 적용</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td scope="row">비고</td>
                <td colspan="2">3개월 단위 운영 </td>
                <td colspan="2">
                  <ul>
                    <li>미신청 주차면 야간 무료</li>
                    <li>(23:00 ~ 익일 10:00)</li>
                  </ul>
                </td>
              </tr>              
            </tbody>
          </table>
        </article>
      </section>
        <section>
          <article>
            <p class="circle">주차장 감면대상 및 감면기준</p>
          </article>
          <article>
            <table class="default-table-list-view exception">
              <caption class="sr-only">주차장 감면대상 및 감면기준</caption>
              <colgroup>
                <col style="width:70%" />
                <col style="width:30%" />
              </colgroup>
              <thead>
                <tr>
                    <th scope="col">감면대상</th>
                    <th scope="col">감면기준</th>
                </tr>
              </thead>
              <tbody>                       
                <tr>
                  <td>「국가유공자 등 예우 및 지원에 관한 법률 」제6조에 따른 국가유공자 또는 그 유족으로 등록 및 결정된 자</td>
                  <td>전액 감면</td>
                </tr>
                <tr>
                  <td>「5ㆍ18민주유공자예우에 관한 법률」제4조제2호에 따른 5ㆍ18민주화운동 부상자</td>
                  <td>전액 감면</td>
                </tr>
                <tr>
                  <td>「장애인복지법」제2조에 따른 장애인(심한 장애)</td>
                  <td>전액 감면</td>
                </tr>
                <tr>
                  <td>「장애인복지법」제2조에 따른 장애인(심하지 않은 장애)</td>
                  <td>
                    <ul>
                      <li>최초 2시간 전액 감면(무료시간 포함)</li>
                      <li>이후 시간 50% 감면</li>
                      <li>(월 정기권 및 전일주차는 50% 감면)</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>
                    국세청 「모범납세자 관리규정」에 따른 모범납세자,「경기도 성실납세자 선정 및 지원 조례」에 따른 유공납세자, 「화성시 모범납세자 선정 및 지원 조례」에 따른 모범납세자의 지정차량 1대(선정 일로부터 1년간 감면)
                  </td>
                  <td>전액 감면</td>
                </tr>
                <tr>
                  <td>1천시시 미만 경승용 차량</td>
                  <td>50% 감면</td>
                </tr>
                 <tr>
                  <td>「대기환경보전법」 제58조제12항에 따라 표지를 붙인 저공해자동차</td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>
                    환승주차를 목적으로 한 환승주차장 이용차량의 경우 환승목적임을 입증할 수 있는 확인증을 제시하는 이용자
                  </td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>「화성시 자원봉사활동 지원 조례」 제3조제5호에 따른 우수자원봉사자</td>
                  <td>50% 감면(중복할인 불가)</td>
                </tr>
                <tr>
                  <td>재래시장 활성화를 위하여 시장이 필요하다고 지정하는 재래시장의 주차장 이용</td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>
                    「저출산. 고령사회기본법」에 따라 두 자녀 이상으로 표기된 경기아이플러스(I-plus)카드 또는 화성시에서 발행한 맘애 좋은 화성 다자녀 카드를 제시한 사람
                  </td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>
                    「환경친화적 자동차의 개발 및 보급 촉진에 관한 법률」 제2조제2호에 따른 환경친화적 자동차로서 공영주차장 내 설치된 충전시설을 이용하는 차량
                  </td>
                  <td>
                    <ul>
                      <li>최초 2시간 전액 감면(무료시간 포함)</li>
                      <li>이후 시간 50퍼센트 감면</li>
                    </ul>
                  </td>
                </tr>
            <!-- 
                <tr>
                  <td>승용차 부제(승용차요일제)표지를 부착한 차량</td>
                  <td>30% 감면</td>
                </tr>
            -->
                <tr>
                  <td>
                    병역명문가로서 병무청에서 발급한 병역명문가증과 주소가 기입된 신분증을 제시하는 사람
                  </td>
                  <td>50% 감면</td>
                </tr>
              </tbody>
            </table>
            <p class="reference red">
              국가유공자, 5ㆍ18민주화운동 부상자, 장애인, 모범납세자의 경우, 차량을 본인이 운전하거나, 본인이 동승하고 다른 사람이 대리 운전하는 경우에 한정하여 요금을 감면할 수 있으며, 감면대상임을 입증할 수 있는 자료를 제출하여야 합니다. 
            </p>
            <p class="reference red">
              주차요금 감면 사유가 중복되는 경우에는 그 중 감면율이 가장 높은 사유 하나만을 적용합니다.
            </p>
          </article>
        </section>
    </section>
	</main>
</template>
<script>
export default {
	data(){
    return{
      parkingName : "",
      territories :"",
    }
  },
  
  methods:{
    searchBtn(){

    }
  },

  mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style lang="scss" scoped>
	
</style>