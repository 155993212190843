<template>
  <main id="oneTemplet">
    <section id="content">
      <article id="contentTitle">
				<h2>사이트 맵</h2>
			</article>
      <section class="sitemap-container">
        <article>
          <h3>공영주차장</h3>
          <ul>
            <li><router-link to="introduce" title="지도검색">주차장소개</router-link></li>
            <li><router-link to="map" title="지도검색">지도검색</router-link></li>
            <li><router-link to="list" title="주소검색">주소검색</router-link></li>
            <li><router-link to="/feeinformation" title="노상주차장 요금 안내">노상주차장 요금 안내</router-link></li>
          </ul>
        </article>
        <article>
          <h3>주차요금 사전결제</h3>
          <ul>
            <li><router-link to="prepaymentinfo" title="사전결제 안내">안내</router-link></li>
            <li><router-link to="lookup" title="사전결제 조회결제">조회/결제</router-link></li>
          </ul>
        </article>
        <article>
          <h3>미납주차요금</h3>
          <ul>
            <li><router-link to="unpaidinfo" title="미납주차요금 안내" class="active">안내</router-link></li>
            <li><router-link to="lookupunpaid" title="미납주차요금 조회/결제">조회/결제</router-link></li>
          </ul>
        </article>
        <article>
          <h3>비대면 자격확인</h3>
          <ul>
            <li><router-link to="reductioninfo" title="비대면 안내">안내</router-link></li>
            <li><router-link to="reductionagreement" title="비대면 자격확인">비대면 자격확인</router-link></li>
          </ul>
        </article>
        <article>
          <h3>거주자 우선 주차장</h3>
          <ul>
            <li><router-link to="residentinfo" title="거주자 우선 주차장 안내">안내</router-link></li>
            <li><router-link to="residentsearch" title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
            <li><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
            <li><router-link to="residentlookup" title="거주자 우선 주차장 조회/결제">조회/결제</router-link></li>
          </ul>
        </article>
        <article>
          <h3>공영주차장 정기권</h3>
          <ul>
            <li><router-link to="periodinfo" title=" 공영주차장 정기권 안내">안내</router-link></li>
            <li><router-link to="periodagreement" title="정기권 신청">정기권 신청</router-link></li>
            <li><router-link to="periodlookup" title="조회/결제">조회/결제</router-link></li>
          </ul>
        </article>
        <article>
          <h3>무정차 신청</h3>
          <ul>
            <li><router-link to="nonstopinfo" title="무정차 신청">무정차 신청</router-link></li>
          </ul>
        </article>
        <article>
          <h3>열린공간</h3>
          <ul>
            <li><router-link to="noticelist" title="공지사항">공지사항</router-link></li>
            <li><router-link to="questionslist" title="자주하는 질문">자주하는 질문</router-link></li>
          </ul>
        </article>
      </section>
    </section>
  </main>
</template>

<script>
export default {
  mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>