<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
      	<section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드" class="active">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>공통코드</h4>
			</article>
			<article>
				<div class="tab-content">
					<article>
						<h3>공통코드</h3>
					</article>
					<article>
						<table class="vertical-table">
							<caption class="sr-only">공통코드 등록 테이블</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row" class="asterisk-after">코드타입</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" placeholder="AAA" class="place-hide" v-model="codeTy">
												<div class="error" v-if="!$v.codeTy.isUnique">중복된 코드입니다.</div>
												<div class="error" v-if="!$v.codeTy.required">필수 입력란입니다.</div>
												<div class="error" v-if="!$v.codeTy.minLength">3자리 입력란입니다.</div>
												<div class="error" v-if="!$v.codeTy.maxLength">3자리 입력란입니다.</div>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">코드명</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" placeholder="코드명" class="place-hide" v-model="nm">
												<div class="error" v-if="!$v.nm.required">필수 입력란입니다.</div>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">사용여부</th>
									<td class="select-area">
										<label for=""></label>
										<select name="" id="" v-model="useYn">
											<option value="Y">사용</option>
											<option value="N">미사용</option>
											<div class="error" v-if="!$v.useYn.required">필수 입력란입니다.</div>
										</select>
									</td>
								</tr>
								<tr>
									<th class="asterisk-after">코드설명</th>
									<td>
										<textarea class="in-table place-hide" placeholder="코드설명" v-model="note">

										</textarea>
										<div class="error" v-if="!$v.note.required">필수 입력란입니다.</div>
									</td>
								</tr>
							</tbody>
						</table>
					</article>
					<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
					<article class="btn-list right">
						<button class="btn"  @click="$router.push('codelist')">목록</button>
						<button class="btn-border" @click="insertBtn">확인</button>
					</article>	
				</div>
			</article>	
		</section>
		<!-- 빠른 작업을 위한 코드타입 중복검색 팝업 임시 제거 -->
		<!-- <section class="modal-manager">
			<article class="title">
				<ul>
					<li>코드 중복 확인</li>
					<li>닫기</li>
				</ul>
			</article>
			<article class="content">
				<section>
					<article>
						<table class="vertical-table">
							<caption class="sr-only">코드 중복 확인</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">코드타입</th>
									<td>
										<ul class="input-style button">
											<li>
												<input type="text" placeholder="코드타입" class="place-hide">
												<button class="table-btn ml10">검색하기</button>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</article>
					<article>
						<ul>
							<li><p class="default">중복확인을 실행 하십시오.</p></li>
						</ul>
					</article>
					<article class="btn-list">
						<button class="popup-btn">닫기</button>
						<button class="popup-btn-border">확인</button>
					</article>
				</section>
			</article>
		</section> -->
	</main>
</template>

<script>
import axios from 'axios';
import { required, isUnique, minLength, maxLength } from 'vuelidate/lib/validators'
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			codeTy: "",
			nm: "",
			useYn: "Y",
			note: "",
			submitStatus: "",
			usedCnt: ""
		}
	},

	validations: {
		codeTy: {
			required,
			minLength: minLength(3),
			maxLength: maxLength(3),
			async isUnique (value) {
				if(value === '') {
					return true;
				}
				
			 	await instance.get('/api/code/codeTyDplctCnfirmAjax.json', {
			 		params: {
			 			checkCodeTy: value
			 		}
			 	})
			 	.then((response => {
					 this.usedCnt = response.data.usedCnt;
			 	}))
			 	.catch(function (error) {
			 		//console.log(error);
			 		alert("서버와 통신중 오류가 발생하였습니다.")
				 })

				if(this.usedCnt > 0) {
					return false;
				} else {
					return true;
				}
			}
		},
		nm: {
			required
		},
		useYn: {
			required
		},
		note: {
			required
		}
	},

	methods: {
		insertBtn(){

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("codeTy", this.codeTy);
				apiParams.append("nm", this.nm);
				apiParams.append("useYn", this.useYn);
				apiParams.append("note", this.note);
	
				instance.post('/api/code/codeInsertAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('codelist');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
	
}
</script>

<style>

</style>