<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">미납주차요금</div>
			<ul>
				<li><router-link to="unpaidinfo" title="미납주차요금 안내" >안내</router-link></li>
				<li><router-link to="lookupunpaid" title="미납주차요금 조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>미납주차요금</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>미납주차요금 결제</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">미납주차요금 결제</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">차량번호</th>
						<td>{{ carNo }}</td>
					</tr>
					<tr>
						<th>전체 미납 건수 </th>
						<td>{{ totCnt }}건</td>
					</tr>
					<tr>
						<th>전체 미납 원금</th>
						<td>{{ totUnpaidAmt | comma }}원</td>
					</tr>
					<tr>
						<th>전체 가산금</th>
						<td>{{ totAddAmt | comma }}원</td>
					</tr>
					<tr>
						<th>전체 내역</th>
						<td>미납원금 {{ totUnpaidAmt | comma }}원 + 가산금 {{ totAddAmt | comma }}원 = {{ totSumAmt | comma }}원</td>
					</tr>
					<tr>
						<th>미납 총액</th>
						<td><span class="orange">{{ totSumAmt | comma }}원</span></td>
					</tr>
				</tbody>
			</table>

			<article class="message">
				<p>결제 실패시 처음(차량번호 조회)부터 다시 시도 해주시기 바랍니다.</p>
			</article>

			<article class="btn-list">
				<button class="btn" @click="paymentBtn()">결제</button>
				<button class="btn-border" @click="cancelBtn(carNo)">취소</button>
			</article>
			
		</section>

		<pop-up v-if="showModal" @cancel="showModal = false">
			<template #info>
				<li>안내</li>
			</template>
			<template #close>
				<li></li>
			</template>
			<template #content>
				<p v-html="msg"></p>
			</template>
			<template #button>
				<button class="popup-btn" @click="confirmOk">예</button>
				<button class="popup-btn-border"  @click="showModal = false">아니오</button>
			</template>
		</pop-up>
		<div v-html="pForm"></div>

	</main>
</template>

<script>

import axios from 'axios';
import PopUp from '../../../components/PopupType1.vue';
const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return {
			inOutIds      : '',
			sectnIds      : '',
			sectnNms      : '',
			carNo         : '',
			totSumAmt     : 0,
			totUnpaidAmt  : 0,
			totAddAmt     : 0,
			totCnt        : 0,
			parkingName   : '',
			showModal     : false,
			r             : '',
			easypayParams : '',
			msg           : '',
			pForm         : '',
		}
	},
	filters : {
		comma : function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		}
	},
	props: ['data'],
	components:{
		PopUp,
	},
	created(){

		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("data"));
		}else{
			localStorage.setItem("data", JSON.stringify(data));
		}

		this.inOutIds     = data.inOutIds;
		this.sectnIds     = data.sectnIds;
		this.sectnNms     = data.sectnNms;
		this.carNo        = data.carNo;
		this.totSumAmt    = data.totSumAmt;
		this.totUnpaidAmt = data.totUnpaidAmt;
		this.totAddAmt    = data.totAddAmt;
		this.totCnt       = data.totCnt;
		this.parkingName  = data.parkingName;

	},
	mounted(){
		let apiParams = new Map();
		apiParams.set("inOutIds"    , this.inOutIds);
		apiParams.set("carNo"       , this.carNo);
		apiParams.set("totSumAmt"   , this.totSumAmt);
		apiParams.set("sectnIds"    , this.sectnIds);
		apiParams.set("sectnNms"    , this.sectnNms);
		apiParams.set("totCnt"      , this.totCnt);
		apiParams.set("parkingName" , encodeURI(this.parkingName));
		apiParams.set("isMobile"    , this.$isMobile());
		
		instance.get('/api/unpaid/view.json', {
			params: Object.fromEntries(apiParams)
		})
		.then((res => {
			
			var resData = res.data;

			this.r             = resData.resultMap;
			this.easypayParams = resData.resultMap.easypayParams;

		}));

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods: {
		cancelBtn(carNo){
			this.$router.push({
				name  : 'lookupunpaid',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},

		paymentBtn(){

			var e = this.easypayParams;

			let easypayParams = new Map();
			easypayParams.set("easypayRequestUrl"	, e.easypayRequestUrl);
			easypayParams.set("mallId"				, e.mallId);
			easypayParams.set("payMethodTypeCode"	, e.payMethodTypeCode);
			easypayParams.set("currency"			, e.currency);
			easypayParams.set("amount"				, e.amount);
			easypayParams.set("clientTypeCode"		, e.clientTypeCode);
			easypayParams.set("returnUrl"			, e.easypayReturnUrl);
			easypayParams.set("shopOrderNo"			, e.shopOrderNo);
			easypayParams.set("deviceTypeCode"		, e.deviceTypeCode);
			easypayParams.set("mallName"			, encodeURIComponent(e.mallName));
			easypayParams.set("goodsName"			, encodeURIComponent(e.goodsName));
		if(this.$store.state.isLogin){
				easypayParams.set("customerId"			, encodeURI(this.$store.state.empNo));			// e.customerId
				easypayParams.set("customerName"		, encodeURI(this.$store.state.empNm));			// e.customerName
				easypayParams.set("customerMail"		, encodeURIComponent(this.$store.state.empNo));			// e.customerMail
				easypayParams.set("customerContactNo"	, this.$store.state.empMobile);		// e.customerContactNo
			}else{
				easypayParams.set("customerId"			, encodeURI("GUEST"));				// e.customerId
				easypayParams.set("customerName"		, encodeURI("비회원"));				// e.customerName
				easypayParams.set("customerMail"		, encodeURIComponent(""));			// e.customerMail
				easypayParams.set("customerContactNo"	, "");								// e.customerContactNo
			}				// e.customerContactNo
			easypayParams.set("value1"				, e.value1);										// payCategory (사전정산 PREPAY)
			easypayParams.set("value2"				, e.value2);										// sectnId
			easypayParams.set("value3"				, e.value3);										// iNervPayId
			easypayParams.set("value4"				, e.value4);										// carNo
			easypayParams.set("value5"				, this.inOutIds);									// iNervPayIds
			easypayParams.set("value6"				, this.totCnt);										// totCnt
      easypayParams.set("value7"       , e.sectnId);


			instance.get("/api/easypay/easypaystart.json", {
				
				params : Object.fromEntries(easypayParams)

			}).then(function(res){

				var resData = res.data;
				
				// Mobile, PC Check & ios, android Check
				var mobileOS = "";
				if(e.deviceTypeCode == "mobile"){
					var mobileOSCheck = navigator.userAgent.toLowerCase();
					if(mobileOSCheck.indexOf('iphone') > -1 || mobileOSCheck.indexOf('ipad') > -1 || mobileOSCheck.indexOf('ipod') > -1){
						mobileOS = "ios";
					}else{
						mobileOS = "android";
					}
				}

				if(mobileOS == "ios"){
					window.location.href = resData.result.authPageUrl;
				}else{
					// 결제 모듈 - 팝업 오픈
					var webpay = window.open(resData.result.authPageUrl, "", "_blank");

					// 결제 모듈 - JSP 파일 팝업 종료 감지
					var interval = window.setInterval(function(){
						if(webpay == null || webpay.closed){
							window.clearInterval(interval);
						}
					});

				}

			})
			.catch(function(err){
				console.log("ERROR");
				//console.dir(err);
			});

		},

	}
}
</script>

<style>

</style>