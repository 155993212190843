<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">비대면 자격확인</div>
			<ul>
				<li><router-link to="reductioninfo" title="비대면 자격확인 안내">안내</router-link></li>
				<li><router-link to="reductionagreement" title="비대면 자격확인 조회" class="active">비대면 자격확인 조회</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>비대면 자격확인</h2>
				<h4>비대면 자격확인 조회</h4>
			</article>
			<article>
				<h3>비대면 자격확인서</h3>
			</article>
			<table class="vertical-table">
				<caption class="sr-only">비대면 자격확인</caption>
				<colgroup>
					<col style="width:20%; min-width: 80px;"/>
					<col style="width:80%" />
				</colgroup>
				<tbody>					
					<tr>
						<th class="asterisk-after">차량번호</th>
						<td>
							<ul class="input-style">
								<li>
									<label for="reductionCarName" class="hide">차량번호 (00가0000 : 필수입력)</label>
									<input type="text" placeholder="00가0000" class="place-hide" ref="car" v-model="carNo" maxlength="10" id="reductionCarName" disabled>
								</li>
							</ul>
						</td>
					</tr>
					<!-- <tr>
						<th class="asterisk-after">주민등록번호</th>
						<td>
							<ul class="input-style number">
								<li>
									<input type="text" placeholder="주민등록번호(앞자리)" class="place-hide" v-model="authNoF" maxlength="6">
								</li>
								<li>-</li>
								<li>
									<input type="password" placeholder="주민등록번호(뒷자리)" class="place-hide" v-model="authNoB" maxlength="7">
								</li>
							</ul>
						</td>
					</tr> -->
				</tbody>
			</table>
			<!--
			<article class="message">
				<p>이름 및 주민등록번호는 저장하지 않습니다.</p>
			</article>
			-->
			<article class="message">
				<p>비대면 자격확인은 최대 1분까지 시간이 소요될 수 있습니다.</p>
			</article>
			<article class="btn-list">
				<button class="btn"        @click="requestBtn">확인</button>
				<button class="btn-border" @click="cancel">취소</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			// carOwnerNm : '',
			carNo      : '',
			// authNoF    : '',
			// authNoB    : '',
		}
	},
	methods:{
		requestBtn(){
			if(this.validate()){

				let apiParams = new Map();
				apiParams.set("carNo"     , this.carNo);
				// apiParams.set("carOwnerNm", this.carOwnerNm);
				// apiParams.set("authNoF"   , this.authNoF);
				// apiParams.set("authNoB"   , this.authNoB);

				instance.get('/api/reduction/irs.json', {
					params : Object.fromEntries(apiParams)
				}).then((res => {
					var data = res.data.value;
          var boolean = res.data.notMatchCarNo;
          if(boolean) {
            alert("검색시 차번호와 계정에 등록된 차번호가 일치하지 않습니다.");
          }else {
            var params = {
              // carOwnerNm : data.result.carOwnerNm,
              carNo: data.result.carNo,
              // authNoF    : data.result.authNoF,
              dcList: data.dcList
            }

            this.$router.push({
              name: 'reductionresult',
              params: {data: params}
            });
          }
				})).catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},
		validate: function(){
			// var validTot = 4,
			var validTot = 1,
				validCnt = 0;

			var carNoRegex = /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi;
			var authCnt    = 0;

			// if(!this.carOwnerNm){
			// 	alert("이름이 올바르지 않습니다.");
			// }else{
			// 	validCnt++;
			// }

			if(!this.carNo || !carNoRegex.test(this.carNo)){
				alert("차량번호가 형식에 맞지 않습니다.");
			}else{
				validCnt++;
			}

			// if(!this.authNoF || this.authNoF.length != 6){
			// 	alert("주민번호 앞자리가 올바르지 않습니다.");
			// }else{
			// 	authCnt++;
			// }

			// if(!this.authNoB || this.authNoB.length != 7){
			// 	alert("주민번호 뒷자리가 올바르지 않습니다.");
			// }else{
			// 	authCnt++;
			// }
			
			// if(authCnt == 2){
			// 	validCnt = validCnt + authCnt;
			// }

			if(validTot == validCnt){
				return true;
			}else{
				return false;
			}
		},
		cancel(){
			this.$router.push('/reductionagreement')
		},

		empInfoSet(){
			this.carOwnerNm = this.$store.state.empNm;
			this.carNo = this.$store.state.empCarNo;
		},
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		this.empInfoSet();
	}
}
</script>

<style>

</style>