<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle" title="무료차량 관리">무료차량 관리</router-link></li>
       			<li><router-link to="/prepaymentlist" title="정기권 주차장 관리">정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement" title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist" title="팝업 관리">팝업 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>차단기 제어</h4>
			</article>
			<article>
				<h3>차단기 제어</h3>
				<section>
					<article>
						<p class="subheading">[화성시청부설주차장]</p>
						<section class="list-box">
							<article class="breaker-list">
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (입구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (출구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
							</article>
							<article class="breaker-list">
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (입구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (출구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
							</article>
							<article class="breaker-list">
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (입구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (출구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
							</article>
							<article class="breaker-list">
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (입구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
									<div class="breaker-items">
										<div class="breaker-title">
											정문 (출구)
										</div>
										<div class="breaker-controller">
											<ul>
												<li>열림</li>
												<li>고정</li>
												<li>닫힘</li>
												<li>해제</li>
											</ul>
										</div>
									</div>
							</article>
						</section>
					</article>
				</section>
			</article>	
		</section>
	</main>
</template>

<script>

export default {

}
</script>

<style>

</style>