<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리" class="active">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>부서관라</h4>
			</article>
			<article>
				<div class="tab-content">
					<article>
						<h3>부서관리</h3>
					</article>
					<article>
						<table class="vertical-table">
							<caption class="sr-only">부서관리 상세보기  테이블</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">부서ID</th>
									<td>{{deptObj.deptId}}</td>
								</tr>
								<tr>
									<th>부서명</th>
									<td>{{deptObj.deptNm}}</td>
								</tr>
								<tr>
									<th>부서설명</th>
									<td>{{deptObj.deptDc}}</td>
								</tr>
							</tbody>
						</table>
					</article>
					<article class="btn-list right">
						<button class="btn" @click="$router.push('departmentlist')">목록</button>
						<button class="btn black" @click="updateBtn(deptObj)">수정</button>
						<button class="btn-border" @click="deleteConfirm()">삭제</button>
					</article>	
				</div>
			</article>	
		</section>
	</main>
</template>

<script>
import axios from 'axios';
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			deptId : this.$route.query.deptId,
			deptObj : ''
		}
	},

	mounted() {
		this.initView();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		initView(){
			instance.get('/api/dept/view.json', {
				params: {
					deptId: this.deptId
				}
			})
			.then((response => {
				var data = response.data;
				this.deptObj = data.resultVo;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		updateBtn(deptObj){
			let params = {
				deptId : deptObj.deptId,
				deptNm : deptObj.deptNm,
				deptDc : deptObj.deptDc
			}

			this.$router.push({
				name : 'departmentupdate',
				params : {data : params}
			})
		},

		deleteBtn(){

			let apiParams = new FormData();
			apiParams.append("deptId", this.deptId);

			instance.post('/api/dept/delete.json', apiParams)
			.then((response => {
				let message = response.data.message;
					alert(message);
				this.$router.push('departmentlist');
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		deleteConfirm(){
			this.$confirm(
				{
					message: `삭제하시겠습니까?`,
					button: {
						no: '아니오',
						yes: '예'
					},
					callback: confirm => {
						if (confirm) {
						this.deleteBtn();
						}
					}
				}
			)
		}
	}

}
</script>

<style>

</style>