<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차장</div>
			<ul>
				<li><router-link to="/introduce" title="주차장 소개">주차장 소개</router-link></li>
				<li><router-link to="/map" title="지도 검색">지도 검색</router-link></li>
				<li><router-link to="/list" title="주차장 검색">주차장 검색</router-link></li>
				<li><router-link to="/parkingroad" title="노상주차장 요금 안내">노상주차장 요금 안내</router-link></li>
				<li><router-link to="/parkingoutdoor" title="노외주차장 요금 안내" class="active">노외주차장 요금 안내</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차장</h2>
				<h4>노외주차장 요금 안내</h4>
			</article>
      <article>
        <h3>노외주차장 요금 안내</h3>
        <section class="load">
          <article>
            <label for="">주차장명</label>
            <select v-model="parkingName">
              <option :value="''">전체</option>
            </select>
          </article>
          <article>
            <label for="">권역</label>
            <select v-model="territories">
              <option :value="''">전체</option>
              <option :value="'1'">서부</option>
              <option :value="'2'">동부</option>
              <option :value="'3'">동탄</option>
            </select>
              <button class="table-btn" @click="searchBtn">조회</button>
          </article>
        </section>
        <!-- 모바일 전용 -->
        <section class="load-mo">
          <article>
            <article>
              <label for="">주차장명</label>
              <select v-model="parkingName">
                <option :value="''">전체</option>
              </select>
            </article>
            <article>
              <label for="">권역</label>
              <select v-model="territories">
                <option :value="''">전체</option>
                <option :value="'1'">서부</option>
                <option :value="'2'">동부</option>
                <option :value="'3'">동탄</option>
              </select>
            </article>
          </article>
          <article>
            <button class="table-btn" @click="searchBtn">조회</button>
          </article>
        </section>
      </article>
      <article class="table-scroll">
        <table class="default-table-content">
          <caption class="sr-only">요금조회 테이블</caption>
          <colgroup>
              <col style="width:25%" />
              <col style="width:10%" />
              <col style="width:15%" />
              <col style="width:20%" />
              <col style="width:15%" />
              <col style="width:15%" />
          </colgroup>
          <thead>
              <tr>
                  <th scope="col">주차장명</th>
                  <th scope="col">권역</th>
                  <th scope="col">무료시간</th>
                  <th scope="col">요금 기준(10분당)</th>
                  <th scope="col">야간 무료</th>
                  <th scope="col">비고</th>
              </tr>
          </thead>
          <tbody> 
              <tr>
                  <td scope="row"></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
              </tr>
               <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
              </tr>
          </tbody>
      </table>
      </article>
    </section>
    <pop-up v-show="showModal">
      <template #info>
          감면대상 &middot;감면 기준
      </template>
  
      <template #close>
          <li></li>
      </template>
  
      <template #content>
          <div class="overflow">
            <table>
              <caption class="sr-only">감면대상 &middot;감면 기준 테이블</caption>
              <colgroup>
                <col style="width: 70%" />
                <col style="width: 30%" />
              </colgroup>
              <tbody>
                <tr>
                  <td scope="row">「 국가유공자 등 예우 및 지원에 관한 법률 」제 6조에 따른 국가유공자로 등록 및 결정된 자</td>
                  <td>전액 감면</td>
                </tr>
                <tr>
                  <td>「5ㆍ18민주유공자예우에 관한 법률」제4조제2호에 따른 5ㆍ18민주화운동 부상자</td>
                  <td>전액 감면</td>
                </tr>
                <tr>
                  <td>「장애인복지법」제2조에 따른 장애인(심한 장애)</td>
                  <td>
                    최초 4시간 전액 감면(무료시간 포함) / 이후 시간 80% 감면 /(월 정기권 및 전일주차는 80% 감면)</td>
                </tr>
                <tr>
                  <td>「장애인복지법」제2조에 따른 장애인(심하지 않은 장애)</td>
                  <td>최초 2시간 전액 감면(무료시간 포함) / 이후 시간 50% 감면 /(월 정기권 및 전일주차는 50% 감면)</td>
                </tr>
                <tr>
                  <td>
                    국세청 「모범납세자 관리규정」에 따른 모범납세자, 「경기도 성실납세자 선정 및 지원 조례」에 따른 유공납세자, 「화성시 모범납세자 선정 및 지원 조례」에 따른 모범납세자의 지정차량 1대(선정 일로부터 1년간 감면)
                  </td>
                  <td>전액 감면</td>
                </tr>
                <tr>
                  <td>1천시시 미만 경승용 차량</td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>「수도권 대기환경 개선에 관한 특별법」제28조제2항에 의한 저공해자동차 표지부착 차량</td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>환승주차를 목적으로 한 환승주차장 이용차량의 경우 환승목적임을 입증할 수 있는 확인증을 제시하는 이용자</td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>「화성시 자원봉사활동 지원 조례」 제3조제5호에 따른 우수자원봉사자증 소지자</td>
                  <td>50% 감면(중복할인 불가)</td>
                </tr>
                <tr>
                  <td>재래시장 활성화를 위하여 시장이 필요하다고 지정하는 재래시장의 주차장 이용</td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>
                    「저출산. 고령사회기본법」에 따라 두 자녀 이상으로 표기된 경기아이플러스(I-plus)카드 또는 화성시에서 발행한 맘에 좋은 화성 다자녀 카드를 제시한 사람
                  </td>
                  <td>50% 감면</td>
                </tr>
                <tr>
                  <td>「
                    환경친화적 자동차의 개발 및 보급 촉진에 관한 법률」 제2조제2호에 따른 환경친화적 자동차로서 공영주차장 내 설치된 충전시설을 이용하는 차량
                  </td>
                  <td>최초 2시간 전액 감면(무료시간 포함) / 이후 시간 50퍼센트 감면</td>
                </tr>
                <tr>
                  <td>승용차 부제(승용차요일제)표지를 부착한 차량</td>
                  <td>30% 감면</td>
                </tr>
                <tr>
                  <td>병역명문가로서 병무청에서 발급한 병역명문가증과 주소가 기입된 신분증을 제시하는 사람</td>
                  <td>50% 감면</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="reference orange">
            국가유공자, 5ㆍ18민주화운동 부상자, 장애인의 경우 차량을 본인이 운전하거나, 부상 또는 장애의 정도가 심하여 다른 사람이 대리 운전하는 경우에 한정하여 요금을 감면할 수 있으며,감면대상임을 입증할 수 있는 자료를 제출하여야 합니다.  
          </p>
      </template>
  </pop-up>
	</main>
</template>
<script>
import PopUp from '../../../components/PopupType4.vue';

export default {
	data(){
    return{
      parkingName : "",
      territories :"",
      showModal: true,
      msg: '',
    }
  },
  components:{
    PopUp,
  },
  methods:{
    searchBtn(){
      
    }
  }
}
</script>

<style lang="scss" scoped>
	
</style>