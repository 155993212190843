<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>회원가입</h2>
				<h4>회원정보 입력</h4>
			</article>
			<article class="step">
				<div>STEP 03</div>
			</article>
			<article class="table">

				<table class="vertical-table pc">
					<caption class="sr-only">회원정보 입력 </caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th>이름</th>
							<td>
								<ul class="input-style small">
									<li>
										{{ empNm }}
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>휴대폰 번호</th>
							<td>
								<ul class="input-style small">
									<li>
										{{ empMobile }}
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">일반회원 아이디<span class="hide">(필수 입력)</span></th>
							<td>
								<ul class="input-style small">
									<li>
										<label for="memberId" class="hide">일반회원 아이디</label>
										<input type="text" placeholder="test@dhicc.com" class="place-hide" v-model="empNo" @input="resetUsedCnt" id="memberId">
										<button class="table-btn" @click="checkIdDplct">중복검사</button>
										<p v-if="usedCnt === 1" class="reference orange">중복된 아이디입니다.</p>
										<p v-if="usedCnt === 0" class="reference orange">사용 가능한 아이디입니다.</p>
										<div class="error" v-if="!$v.empNo.email">이메일 형식이 아닙니다.</div>
										<div class="error" v-if="!$v.empNo.required">필수 입력란입니다.</div>
									</li>
								</ul>
								<div class="mt10">
									<p class="reference">이메일을 아이디로 사용합니다.</p>
								</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호<span class="hide">(필수입력)</span></th>
							<td>
								<ul class="input-style small-text">
									<li>
										<label for="memberPwd" class="hide">비밀번호</label>
										<input type="password" placeholder="비밀번호" class="place-hide" v-model="password" id="memberPwd">
										<div class="error" v-if="!$v.password.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.password.minLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.maxLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.passRegex">조건에 맞지 않습니다.</div>
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호 확인<span class="hide">(필수입력)</span></th>
							<td>
								<ul class="input-style small-text">
									<li>
										<label for="paswordConfirm" class="hide">비밀번호 확인</label>
										<input type="password" placeholder="비밀번호 확인" class="place-hide" v-model="passwordCon" id="paswordConfirm">
										<div class="error" v-if="!$v.passwordCon.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.sameAsPassword">일치하지 않는 비밀번호입니다.</div>
										<div class="error" v-if="!$v.passwordCon.minLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.maxLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.passRegex">조건에 맞지 않습니다.</div>
									</li>
									<li>
										<p class="reference">숫자와 영문자 특수문자 조합으로 8~20자리</p>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">주소<span class="hide">(필수입력)</span></th>
							<td>
								<ul class="input-style button cont">
									<li>
										<label for="zipCode" class="hide">우편번호</label>
										<input type="text" placeholder="우편번호" class="place-hide" v-model="zipcode" id="zipCode">
										<button id="zipBtn" class="table-btn black" @click="zipcodeOpen()">우편번호</button>
									</li>
									<li>
										<label for="address" class="hide">주소</label>
										<input type="text" placeholder="주소" class="place-hide" id="address" v-model="addr">
									</li>
									<li>
										<label for="addressDetail" class="hide">상세주소</label>
										<input type="text" placeholder="상세주소" class="place-hide" v-model="addrDetail" ref="addrDetail" id="addressDetail">
									</li>
								</ul>
								<div class="error" v-if="!$v.zipcode.required || !$v.addr.required || !$v.addrDetail.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">차량 번호<span class="hide">(필수입력)</span></th>
							<td>
								<ul class="input-style small">
									<li>
										<label for="carNumber" class="hide">차량번호</label>
										<input type="text" placeholder="차량번호" class="place-hide" v-model="empCarNo" id="carNumber">
										<div class="error" v-if="!$v.empCarNo.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.empCarNo.carNoCheck">차량번호 형식에 맞지 않습니다.</div>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
				<table class="vertical-table mo">
					<caption class="sr-only">회원정보 입력</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" >
								<p class="asterisk-after">일반회원 아이디<span class="hide">(필수입력)</span></p>
							</th>
							<td>
								<ul class="input-style small">
									<li>
										<label for="memberIdM" class="hide">일반회원 아이디</label>
										<input type="text" placeholder="※ 이메일을 아이디로 사용합니다." class="place-hide" v-model="empNo" @input="resetUsedCnt" id="memberIdM">
										<button class="table-btn overlap" @click="checkIdDplct">중복검사</button>
										<p v-if="usedCnt === 1" class="reference orange">중복된 아이디입니다.</p>
										<p v-if="usedCnt === 0" class="reference orange">사용 가능한 아이디입니다.</p>
										<div class="error" v-if="!$v.empNo.email">이메일 형식이 아닙니다.</div>
										<div class="error" v-if="!$v.empNo.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">비밀번호<span class="hide">(필수입력)</span></th>
							<td>
								<ul class="input-style small-text">
									<li>
										<label for="memberPwdM" class="hide">비밀번호</label>
										<input type="password" placeholder="※ 숫자,영문자,특수문자 (8~20자리)" class="place-hide" v-model="password" id="memberPwdM">
										<div class="error" v-if="!$v.password.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.password.minLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.maxLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.passRegex">조건에 맞지 않습니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>
								<p class="asterisk-after">비밀번호 확인<span class="hide">(필수입력)</span></p>
							</th>
							<td>
								<ul class="input-style small-text">
									<li>
										<label for="paswordConfirmM" class="hide">비밀번호 확인</label>
										<input type="password" placeholder="※ 숫자,영문자,특수문자 (8~20자리)" class="place-hide" v-model="passwordCon" id="paswordConfirmM">
										<div class="error" v-if="!$v.passwordCon.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.sameAsPassword">일치하지 않는 비밀번호입니다.</div>
										<div class="error" v-if="!$v.passwordCon.minLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.passwordCon.maxLength">8~20자리 입력란입니다.</div>
										<div class="error" v-if="!$v.password.passRegex">조건에 맞지 않습니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">주소<span class="hide">(필수입력)</span></th>
							<td>
								<ul class="input-style button cont">
									<li>
										<label for="zipCodeM" class="hide">우편번호</label>
										<input type="text" placeholder="우편번호" class="place-hide" v-model="zipcode" id="zipCodeM">
										<input type="button" id="zipcodeButton" class="table-btn black" @click="zipcodeOpen()" value="우편번호">
									</li>
									<li>
										<label for="addressM" class="hide">주소</label>
										<input type="text" placeholder="주소" class="place-hide" v-model="addr" id="addressM">
									</li>
									<li>
										<label for="addressDetailM" class="hide">상세주소</label>
										<input type="text" placeholder="상세주소" class="place-hide" v-model="addrDetail" ref="addrDetail" id="addressDetailM">
									</li>
								</ul>
								<div class="error" v-if="!$v.zipcode.required || !$v.addr.required || !$v.addrDetail.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">차량 번호</th>
							<td>
								<ul class="input-style small">
									<li>
										<label for="carNumberM" class="hide">차량번호</label>
										<input type="text" placeholder="차량 번호" class="place-hide" v-model="empCarNo" id="carNumberM">
										<div class="error" v-if="!$v.empCarNo.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.empCarNo.carNoCheck">차량번호 형식에 맞지 않습니다.</div>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list">
				<button class="btn"        @click="insertBtn">회원가입</button>
				<button class="btn-border" @click="$router.push('loginMain')">취소</button>
			</article>	
		</section>
		<template>
			<div id="zipCode" class="post-code" v-show="zipcodePopup">
				<section class="modal-manager zip-code">
					<article class="title hide">
						<ul>
							<li id="zipCodeTit" class="zipcode-header" tabindex="1">
								우편번호
							</li>
							<li id="modalZipcode" class="popup-close zipcode-header" @click="zipcodeController" tabindex="1" @keyup.enter="zipcodeController">
							<span class="hide">닫기 아이콘</span>
							</li>
						</ul>
					</article>
					<article class="content">
						<div ref="embed"></div>
					</article>
				</section>
				<section class="dim"></section>
			</div>
		</template>
	</main>
</template>

<script>
import axios from 'axios';
import { required, email, sameAs, helpers, or, minLength, maxLength } from 'vuelidate/lib/validators'

const carNoRegex     = helpers.regex('carNoRegex', /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/);
const carNoRegexDipl = helpers.regex('carNoRegexDipl', /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/); //외교차량 정규식
const passRegex      = helpers.regex('passRegex', /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			empNo        : "",
			password     : "",
			passwordCon  : "",
			empNm        : "",
			empCarNo     : "",
			empMobile    : "",
			zipcode      : "",
			addr         : "",
			addrDetail   : "",
			submitStatus : "",
			usedCnt      : -1,
			showModal    : false,
			zipcodePopup : false,

			name   : '',
			mobile : '',
			birth  : '',
		}
	},

	validations: {
		empNo: {
			required,
			email
		},
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(20),
			passRegex: passRegex
		},
		passwordCon: {
			required,
			sameAsPassword: sameAs('password'),
			minLength: minLength(8),
			maxLength: maxLength(20)
		},
		empNm: {
			required
		},
		empCarNo: {
			required,
			carNoCheck: or(carNoRegex, carNoRegexDipl)
		},
		empMobile: {
			required
		},
		zipcode: {
			required,
		},
		addr: {
			required,
		},
		addrDetail: {
			required,
		},
	},

	mounted() {

		this.name   = this.$route.params.data.name;
		this.mobile = this.$route.params.data.mobile;
		this.birth  = this.$route.params.data.birth;

		this.empNm     = this.name;
		this.empMobile = this.mobile;

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		insertBtn(){
			if(!this.idValidation()) return null;

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("empNo", this.empNo);
				apiParams.append("empEmail", this.empNo);
				apiParams.append("password", this.password);
				apiParams.append("passwordCon", this.passwordCon);
				apiParams.append("empNm", this.empNm);
				apiParams.append("empCarNo", this.empCarNo.replace(/\s/gi,""));
				apiParams.append("empMobile", this.empMobile);
				apiParams.append("zipcode", this.zipcode);
				apiParams.append("addr", this.addr);
				apiParams.append("addrDetail", this.addrDetail);
	
				instance.post('/api/member/mberSbscrb.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					this.$router.push('membershipcompletion');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		checkIdDplct(){
			if(!this.$v.empNo.$invalid) {
				instance.get('/api/member/idDplctCnfirmAjax.json', {
					params: {
						checkId: this.empNo
					}
				})
				.then((response => {
					this.usedCnt = response.data.usedCnt;
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		idValidation(){
			if(this.usedCnt === -1){
				alert('아이디 중복검사를 해주십시오.');
				return false;
			}else if(this.usedCnt === 1){
				alert('중복된 아이디입니다.');
				return false;
			}else{
				return true;
			}
		},

		resetUsedCnt(){
			this.usedCnt = -1;
		},

		searchedAddress(data) {
			this.zipcode = data.zonecode;
			// 도로명을 선택하는 경우
			if(data.userSelectedType == 'R'){
				this.addr = data.address;
			}else {
				this.addr = data.jibunAddress;
			}
			this.$refs.addrDetail.focus();
			this.showModal = false;
		},

		zipcodeOpen(){
			this.zipcodePopup = true;
			
			 new window.daum.Postcode({
        oncomplete: (data) => {
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
            let extraRoadAddr = ''; // 도로명 조합형 주소 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
              extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = ' (' + extraRoadAddr + ')';
            }
            // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
            if(fullRoadAddr !== ''){
                fullRoadAddr += extraRoadAddr;
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.zipcode = data.zonecode; //5자리 새우편번호 사용
            if(data.userSelectedType == 'R'){
				this.addr = fullRoadAddr;
			}else {
				this.addr = data.jibunAddress;
			}
			this.zipcodePopup = false;
			document.getElementById("zipcodeButton").focus();
        }
      }).embed(this.$refs.embed)
		},

		zipcodeController(){
			this.zipcodePopup = false;
			document.getElementById("zipBtn").focus();
		}
	},
}
</script>

<style>

</style>