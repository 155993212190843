class MyUploadAdapter {
	constructor( loader ) {
		// 업로드하는 동안 사용할 파일 로더 인스턴스
		this.loader = loader;
	}
	
	// 업로드 프로세스 시작
	upload() {
		return this.loader.file
			.then(file => new Promise((resolve, reject) => {
				this._initRequest();
				this._initListeners(resolve, reject, file);
				this._sendRequest(file);
		}));
	}
	
	// 업로드 프로세스 중단
	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}
	
	// 생성자에게 전달된 URL을 사용하여 XMLHtpRequest 개체를 초기화
	_initRequest() {
		const xhr = this.xhr = new XMLHttpRequest();
		xhr.open('POST', '/api/ckeditor/upload/image', true);
		xhr.responseType = 'json';
	}
	
	// XMLHttpRequest listeners 초기화
	_initListeners(resolve, reject, file) {
		const xhr              = this.xhr;
		const loader           = this.loader;
		const genericErrorText = `Couldn't upload file: ${ file.name }.`;
	
		xhr.addEventListener('error', () => reject(genericErrorText));
		xhr.addEventListener('abort', () => reject());
		xhr.addEventListener('load' , () => {
			const response = xhr.response;
			
			//console.dir(response);
			// 통합은 업로드 오류를 다른 방식으로 처리할 수 있으므로 올바르게 수행되어야 함.
			// 업로드 실패시 Reject() 함수 호출
			if (!response || response.error) {
				return reject(response && response.error ? response.error.message : genericErrorText);
			}
			
			// 업로드에 성공하면 서버의 이미지를 가리키는 "기본" URL이 하나 이상 포함된 개체로 업로드 promise 을 해결.
			// 이 URL은 콘텐츠의 이미지를 표시하는 데 사용.
			resolve({
				default: response.url
			});
		});
		
		// 지원되는 경우 업로드 진행률.
		if ( xhr.upload ) {
			xhr.upload.addEventListener('progress', evt => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded    = evt.loaded;
				}
			});
		}
	}
	
	// 데이터를 준비하고 요청을 보냄
	_sendRequest( file ) {
		// Form data 준비.
		const data = new FormData();
		
		data.append( 'upload', file );
		
		// 중요 참고 사항 : 인증, CSRF 보호와 같은 보안 메커니즘을 구현하기에 적합한 장소.
		// 예를 들어 XMLHttpRequest.setRequestHeader()은 응용 프로그램에서 이전에 생성한 CSRF 토큰을 포함하는 요청 헤더를 설정
		
		// 요청을 보냄.
		this.xhr.send( data );
	}
}

function MyCustomUploadAdapterPlugin( editor ) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		// 여기서 URL을 백엔드에 있는 업로드 스크립트로 구성!
		return new MyUploadAdapter(loader);
	};
}

export {
	MyUploadAdapter,
	MyCustomUploadAdapterPlugin
}