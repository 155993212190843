<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차 정기권</div>
			<ul>
				<li><router-link to="periodinfo"      title="정기권신청 안내" class="active">안내</router-link></li>
				<li><router-link to="periodagreement" title="정기권 신청">정기권 신청</router-link></li>
				<li><router-link to="periodlookup"    title="정기권 조회 &#47; 결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 정기권</h2>
				<h4>안내</h4>
			</article>
			<article>
				<h3>정기권 신청 시 유의사항</h3>
				<ul>
					<li>정기권은 인터넷 추첨제를 통한 판매가 기본이며, 매월마다 추첨안내가 개시됩니다.</li>
					<li>추첨제 접수기간에 접수하셔야 하며, 전산추첨 결과에 따라 구매하실 수 있습니다.</li>
					<li class="reference orange">만차, 시설물 공사 등 주차장 상황에 따라 월 정기권 구매 시민이라 하더라도 주차가 불가한 점 유의하시기 바랍니다.</li>	
				</ul>
				<div class="image04">
					<span class="hide"> 
						1.인터넷 정기권 발매공고 (화성도시공사)
						2. 본인인증 및 로그인 (이용자)
						3. 정기권 신청 (이용자)
						4. 증명서 제출 감면대상자에 한함 (이용자)
						5. 정기권신청 완료 (이용자)
					</span>
				</div>
			</article>
			<article>
				<h3>신청안내문</h3>
				<div class="paragraph">
					<ul>
						<li>각종 감면대상자는 홈페이지 우측 상단 회원정보 - 감면적용 증빙자료제출에 업로드 하시고 승인완료 후 할인 받으실 수 있습니다.</li>
						<li>정기권은 타인에게 양도 및 대여할 수 없으며 적발시 정기권 이용 정지 및 제한합니다.</li>
						<li>정기권을 신청한 후 이용 차량 변경은 본인 차량만 가능하며 이를 증명할 수 있는 증빙 서류를 제출하셔야 합니다.</li>
						<li>(단, 차량 수리에 따른 임대차는 수리 증명서 등을 제출하여 이용하실 수 있습니다.)</li>
					</ul>
				</div>
				<div class="paragraph">
					<ul>
						<li>정기권은 매달 신규로 신청을 받고, 결제 기간내에 결제를 하지 않으신 경우, 공석에 대한 차순위자 정기권 배정이 됩니다.</li>
						<li>매달 18~21일까지 접수, 22일 추첨 및 당첨안내, 결제기간 23~25일</li>
					</ul>
				</div>
				<p class="orange">차량번호는 추첨이후 사용중에는 절대로 변경이 불가능합니다.</p>
			</article>
			<article class="btn-list">
				<button class="one-btn" @click="pageList">신청하기</button>
			</article>	
		</section>
	</main>
</template>

<script>
export default {
	methods:{
		pageList(){
			this.$router.push('/periodagreement')
		}
	},
	
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>