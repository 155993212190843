<template>
	<div>
		<!-- <category-area></category-area> -->
		<main id="subTemplet">
			<!-- 좌측메뉴 (LNB) -->
			<section id="lnb">
				<div id="lnb-title">관리자 메뉴</div>
				<ul>
					<li><router-link to="/freevehicle"      title="무료차량 관리">무료차량 관리</router-link></li>
					<li><router-link to="/prepaymentlist"   title="정기권 주차장 관리">정기권 주차장 관리</router-link></li>
					<li><router-link to="/noticemanagement" title="공지사항 관리" class="active">공지사항 관리</router-link></li>
					<li><router-link to="/faqmanagement"    title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
					<li><router-link to="/popuplist"        title="팝업 관리">팝업 관리</router-link></li>
					<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
				</ul>
			</section>
			<!-- 컨텐츠 영역 -->
			<section id="content">
				<article id="contentTitle">
					<h2>관리자 메뉴</h2>
					<h4>공지사항 관리</h4>
				</article>
				<article>
					<h3>공지사항 관리</h3>
				</article>
				<article>
					<!-- Table List Area -->
					<table class="vertical-table-board">
						<caption class="sr-only">공지사항 등록 테이블</caption>
						<colgroup>
							<col style="width:20%" />
							<col style="width:80%" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row" class="asterisk-after">제목</th>
								<td>
									<ul class="input-style">
										<li>
											<input type="text" placeholder="제목을 입력 해 주세요." class="place-hide" v-model="title">
											<div class="error" v-if="!$v.title.required">필수 입력란입니다.</div>
										</li>
									</ul>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">내용</th>
								<td>
									<div id="ckeditorTA">
										<ckeditor :editor="editor" v-model="cts" :config="editorConfig" @ready="onReady"></ckeditor>
									</div>
									<div class="error" v-if="!$v.cts.required">필수 입력란입니다.</div>
								</td>
							</tr>
							<tr>
								<th class="asterisk-after">첨부파일</th>
								<td>
									<ul class="input-style button">
										<input type="file" class="place-hide" ref="files" multiple @change="changeFile" @click="resetFile">
										<li v-for="(item, index) in eFiles" :key="index">
											{{ item.name }}({{item.size | bytes('MB')}})
										</li>
									</ul>
									<p class="asterisk mt10">첨부파일 (최대용량:20M)(*실행파일은 업로드가 불가능합니다.)</p>
								</td>
							</tr>
						</tbody>
					</table>
				</article>
				<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
				<!-- Button Area -->
				<article class="btn-list right">
					<button class="btn"        @click="$router.push('noticemanagement')">목록</button>
					<button class="btn-border" @click="insertBtn">확인</button>
				</article>
			</section>
		</main>
	</div>
</template>

<script src="../node_modules/@ckeditor/ckeditor5-build-classic/build/ckeditor.js"></script>
<script src="../node_modules/@ckeditor/ckeditor5-vue2/dist/ckeditor.js"></script>

<script>

import axios               from 'axios';
import { required }        from 'vuelidate/lib/validators'
import ClassicEditor       from '@ckeditor/ckeditor5-build-classic';
//import CategoryArea  from '../../layout/CategoryArea.vue';
import CKEditor            from '@ckeditor/ckeditor5-vue2';
import '@ckeditor/ckeditor5-build-classic/build/translations/ko';

const uploadAdapter = require("../../../assets/js/UploadAdapter");

const instance = axios.create({
  timeout: 30000,
});


export default {
	name : 'ckeditorTA',

	data(){
		return{
			editor       : ClassicEditor,
			title        : '',
			cts          : '',
			editorConfig : {
				language       : 'ko',
				enterMode      : 2,
				shiftEnterMode : 3,
				extraPlugins   : [ uploadAdapter.MyCustomUploadAdapterPlugin ],
				mediaEmbed     : {
					previewsInData : true,
				}
			},
			submitStatus : '',
			bbsCd        : 'notice',
			eFiles       : ''
		};
	},

	validations : {
		title : {
			required
		},
		cts   : {
			required
		}
	},

	compoents : {
		// Use the <ckeditor> component in this view.
		ckeditor : CKEditor.compoents//, CategoryArea,
	},

	methods : {
		onReady(editor){
			editor.ui.getEditableElement().parentElement.insertBefore(
				editor.ui.view.toolbar.element,
				editor.ui.getEditableElement()
			);
		},

		insertBtn(){
			this.initEFiles();
			
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";

				let apiParams = new FormData();
				apiParams.append("title", this.title);
				apiParams.append("cts"  , this.cts);
				apiParams.append("bbsCd", this.bbsCd);
				
				if(this.eFiles !== '') {
					this.eFiles.forEach(e => {
						apiParams.append("files", e);
					});
				}
	
				instance.post('/api/admin/board/' + this.bbsCd + '/insertAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
					//console.dir(response);
					alert(message);
					this.$router.push('noticemanagement');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				});
			}
		},

		changeFile(e){
			let files = e.target.files;
			let totalSize = 0;

			for(let i = 0 ; i < files.length ; i++){
				totalSize += files[i].size;
			}

			if(files.length > 5){
				alert('최대 5개까지 업로드할 수 있습니다.');
				this.resetFile();
				return null;
			}else if(totalSize > 2000000){
				alert('최대 20mb까지 업로드할 수 있습니다.');
				this.resetFile();
				return null;
			}

			for(let i = 0 ; i < files.length ; i++){
				this.eFiles.push(files[i]);
			}
		},

		resetFile(){
			this.$refs.files.value = '';
			this.eFiles = [];
		},

		initEFiles(){
			if(this.eFiles.length == 0){
				this.eFiles = '';
			}
		},

	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
	
}

</script>

<style scope>
	.asterisk{font-weight: 300; font-size: 	0.8125rem;}
</style>