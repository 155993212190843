<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차장</div>
			<ul>
				<li><router-link to="/introduce"      title="주차장 소개">주차장 소개</router-link></li>
				<li><router-link to="/map"            title="지도 검색" class="active">지도 검색</router-link></li>
				<li><router-link to="/list"           title="주차장 검색">주차장 검색</router-link></li>
				<li><router-link to="/feeinformation" title="주차장 요금안내">주차장 요금안내</router-link></li>
				<li><router-link to="/realtimeparking" title="실시간 주차정보">실시간 주차정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차장</h2>
				<h4>지도검색</h4>
			</article>
			<article>
				<div id="tabs">
					<ul class="tab-menu" role="tablist">
						<li class="active" role="tab" aria-selected="true"><router-link to="/map" title="지도검색" >지도검색</router-link></li>
						<li role="tab" aria-selected="false"><router-link to="/list" title="주차장검색">주차장검색</router-link></li> 
					</ul>
					<section class="tab-content">
						<article class="parking-map">
							<div class="row">
								<div class="label">검색옵션</div>
								<ul class="input-radio" role="radiogroup">
									<li>
										<input type="radio" id="d500m" v-model="distance" name="distance" value="500" @change="distanceCheck($event)">
										<label for="d500m" @keydown.enter="distanceEnter(500)">500m</label>
									</li>
									<li>
										<input type="radio" id="d1Km" v-model="distance" name="distance" value="1000" @change="distanceCheck($event)">
										<label for="d1Km" @keydown.enter="distanceEnter(1000)">1km 이내</label>
									</li>
									<li>
										<input type="radio" id="d1KmUp" v-model="distance" name="distance" value="" checked @change="distanceCheck($event)">
										<label for="d1KmUp" @keydown.enter="distanceEnter">1km 이상</label>
									</li>
								</ul>
							</div>
							<div class="row">
								<div class="label"><label for="sectnId">주차장명</label></div>
								<select id="sectnId" @change="onChange($event)">
									<option  v-for="(item, index) in positions" :key="index" :value="index" >{{ item.title }}</option>
								</select>
							</div>
						</article>
						<article class="parking-inner">
							<div class="legend">
								<dl>
									<dd>노상</dd>
									<dd>노외</dd>
									<dd>부설</dd>
								</dl>
							</div>
							<div id="container">

								<!-- 로드뷰 화면 들어갈 곳 -->
								<div id="rvWrapper">
									<div id="roadview" class="mapWH100"></div>
								</div>

								<!-- Map 화면 들어갈 곳 -->
								<div id="mapWrapper">
									<div id="map" class="mapWH100"></div>
									<div id="roadviewControl" :class="{ 'active' : active === true }" @click="setRoadviewRoad()"></div>
								</div>

							</div>

							<article class="view-details" v-show="defFlag">
								<section>
									<article class="parking-name sort">
										{{ def.PARKING_NM }} ({{ def.PARKING_GUBUN }}/{{ def.PARKING_DIV_NM }})
									</article>
									<article class="closed sort"  @click="defFlag=!defFlag">
										<span class="hide">정보창 닫기 아이콘</span>
									</article>
									<article class="parking-img" id="parkingImg">
										<img :src="ckImg" alt="주차장 이미지" style="width:100%; height:100%;">
									</article>
									<article class="parking-info">
										<table class="vertical-table">
											<colgroup>
													<col style="width:30%" />
													<col style="width:20%" />
													<col style="width:30%" />
													<col style="width:20%" />
											</colgroup>
											<tbody>
												<tr>
													<th class="placement sort" scope="row">권역</th>
													<td class="sort">{{ def.REGION_DIV_NM }}</td>
													<th class="placement sort" scope="row">주차면수</th>
													<td class="sort">{{ def.CELL_CNT }}</td>
												</tr>
												<tr>
													<th class="placement sort">주소</th>
													<td colspan="3">
														<p class="sort">{{ def.CELL_ADDR_LOAD }}</p>
													</td>
												</tr>
												<tr>
													<th class="placement sort">전화번호</th>
													<td colspan="3">
														<p class="sort">{{ def.TEL_NO }}</p>									
													</td>
												</tr>
												<tr>
													<th class="placement sort">주차요금</th>
													<td colspan="3" v-if="def.FREE_YN == 'N'">
														주차장 요금안내 페이지 참고
														<!-- <table class="vertical-table">
															<caption class="sr-only">일반요금 상세정보</caption>
															<colgroup>
																<col width="34%" />
																<col width="33%" />
																<col width="33%" />
															</colgroup>
															<thead>
																<tr>
																	<th>
																		최초{{ def.DFLT_TM | comma }}분
																	</th>
																	<th>
																		{{ def.INTVL_TM | comma }}분당
																	</th>
																	<th>일일요금</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="center">
																		{{ def.DFLT_AMT | comma }}원
																	</td>
																	<td class="center">
																		{{ def.INTVL_AMT | comma }}원
																	</td>
																	<td class="center">
																		{{ def.DAY_PARKING_AMT | comma }}원
																	</td>
																</tr>
															</tbody>
														</table> -->
													</td>
													<td colspan="3" v-else>
														<table>
															<caption class="sr-only">일반요금 상세정보</caption>
															<colgroup>
																<col width="100%" />
															</colgroup>
															<thead>
																<tr>
																	<th class="sort">일일요금</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td class="center sort">
																		무료
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
												<tr>
													<th class="placement sort">이용시간</th>
													<td colspan="3">
														<p class="sort">주중 : {{ def.WDAYS_START_TM }} ~ {{ def.WDAYS_END_TM }}</p><br>
														<p class="sort">주말 : {{ def.WEND_START_TM }} ~ {{ def.WEND_END_TM }}</p>
													</td>
												</tr>
											</tbody>
										</table>
									</article>
								</section>
							</article>
						</article>
					</section>
				</div>
			</article>
		</section>
	</main>
</template>

<script>

</script>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			overlayOn             : false,
			container             : null,
			map                   : null,
			userLat               : 37.19952770562244,
			userLng               : 126.83145146431667,
			imageSrc              : null,
			imageSize             : null,
			imageOption           : null,
			clickImageOption      : null,
			rv                    : null,
			rvClient              : null,
			marker                : null,
			active                : false,
			parkingList           : null,
			parkingName           : null,
			defaultParkingMapList : [],	// 주차장저장리스트
			markers               : [],	// 마커최소 저장리스트
			positions             : [],
			customOverlay         : [],
			mapGeocoder           : null,
			clickCustomOverlay    : [],
			clickStatus           : -1,	// 1 누른상태 , -1 취소상태
			cMarkerIndex          : -1,
			geoLocationState      : -1,
			userCustomOverlay     : null,
			userLocCustomOverlay  : null,
			distance              : "",
			message               : "<div style='padding:5px;'>화성시청</div>",
			park                  : "",
			def                   : "",
			defFlag               : false,
			ckImg                 : null,
			selectParkingName			: "",
		}
	},
	filters : {
		// 숫자 #,### 포맷팅
		comma : function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
	},
	mounted(){
		(window.kakao && window.kakao.maps) ? this.initMap() : this.createScript();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		// if(this.mapModal === true){
		// 	document.getElementById("kakao").querySelector('.modal-kakaoMap').tabindex="5000";
		// 	document.getElementById("kakao").querySelector('.modal-kakaoMap').focus();
		// }

		const layer = document.querySelector(".view-details"),
		// layerFirst = layer.querySelector(".sort"),
		// layerLast = layer.querySelector('.sort');
		layerArrow = layer.querySelectorAll('a.sort, article.sort, td.sort'),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
					e.preventDefault();
          layerLast.focus();
			}
		};

		const lastKeydown = e => {
				if (!e.shiftKey && e.key === "Tab") {
						e.preventDefault();
						layerFirst.focus();
				}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);
	},
	methods : {
		// 노외 마커
		mapMarker() {
			return require("../../../assets/images/map/map_marker.png");
		},

		// 노외 클릭 마커
		mapPcMarker(){
			return require("../../../assets/images/map/map_pc.png");
		},

		// 노상 마커
		mapMarkerRed() {
			return require("../../../assets/images/map/map_marker_red.png");
		},

		// 노상 클릭 마커
		mapPcMarkerRed(){
			return require("../../../assets/images/map/map_pc_red.png");
		},

		// 무료 마커
		mapMarkerGreen() {
			return require("../../../assets/images/map/map_marker_green.png");
		},

		// 무료 클릭 마커
		mapPcMarkerGreen(){
			return require("../../../assets/images/map/map_marker_green.png");
		},

		getParkingList(){
			
			var jsonList = [];

			instance.get("/api/parking/map.json").then((res => {
				var resData     = res.data;
				var parkingList = resData.parkingMapList;
				
				for(var i = 0 ; i < parkingList.length ; i++){
					
					if(parkingList[i].SECTN_NM != "기본" || !parkingList[i].LAT || parkingList[i].LAT == "" || !parkingList[i].LNG || parkingList[i].LNG == ""){
						continue;
					}

					var parkingLot = {};
					parkingLot.id     = parkingList[i].PARKING_ID;
					parkingLot.title  = parkingList[i].PARKING_NM;
					parkingLot.latlng = new kakao.maps.LatLng(parkingList[i].LAT, parkingList[i].LNG);
					parkingLot.addr   = parkingList[i].CELL_ADDR_LOAD;
					parkingLot.pDivCd = parkingList[i].PARKING_DIV_CD;
					parkingLot.pDivNm = parkingList[i].PARKING_DIV_NM;
					parkingLot.freeYn = parkingList[i].FREE_YN;

					this.positions.push(parkingLot);					
					this.defaultParkingMapList.push(parkingList[i]);

				}
				this.initSelect(this.positions);

				this.setMarkers(this.positions);

			}))
			.catch(function(err){
				//console.dir(err);
			});

		},

		createScript(){
			const script  = document.createElement("script");
			script.onload = () => kakao.maps.load(this.initMap);
			script.src    = "//dapi.kakao.com/v2/maps/sdk.js?appkey=5130af43da731f05b3be59b4730e2676&libraries=services&autoload=false";
			document.head.appendChild(script);
		},

		initMap(){

			this.overlayOn = false;										// 지도 위에 로드뷰 오버레이가 추가된 상태를 가지고 있을 변수
			this.container = document.getElementById('container');		// 지도와 로드뷰를 감싸고 있는 div
			
			const mapWrapper   = document.getElementById('mapWrapper');	// 지도를 감싸고 있는 div
			const mapContainer = document.getElementById('map');		// 지도를 표시할 div
			const rvContainer  = document.getElementById('roadview');	//로드뷰를 표시할 div

			var mapCenter = new kakao.maps.LatLng(this.userLat, this.userLng);

			// 지도를 생성할 때 필요한 기본 옵션
			const mapOptions = {
				center : mapCenter,	// 지도의 중심좌표
				level  : 8			// 지도의 레벨(확대, 축소 정도)
			};

			// 지도를 표시할 div와 지도 옵션으로 지도를 생성
			this.map = new kakao.maps.Map(mapContainer, mapOptions);
			
			//일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
			var mapTypeControl = new kakao.maps.MapTypeControl();

			// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
			this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);	// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
			// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다

			//this.imageSrc    = "/src/assets/images/map/map_marker.png";		
			this.imageSrc    = this.mapMarker();							// 마커이미지의 주소입니다
			this.imageSize   = new kakao.maps.Size(47, 54);					// 마커이미지의 크기입니다
			this.imageOption = { offset: new kakao.maps.Point(12, 34) };	// 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

			//var clickImageSrc     = "/src/assets/images/map/map_pc.png";
			var clickImageSrc     = this.mapPcMarker();
			var clickImageSize    = new kakao.maps.Size(47,54);
			this.clickImageOption = { offset: new kakao.maps.Point(12, 34) };

			// 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
			var markerImage = new kakao.maps.MarkerImage(this.imageSrc, this.imageSize, this.imageOption);

			// 로드뷰 객체를 생성
			this.rv   = new kakao.maps.Roadview(rvContainer);

			// 좌표로부터 로드뷰 파노라마 ID를 가져올 로드뷰 클라이언트 객체를 생성
			this.rvClient = new kakao.maps.RoadviewClient();

			// 로드뷰에 좌표가 바뀌었을 때 발생하는 이벤트를 등록
			kakao.maps.event.addListener(this.rv, 'position_changed', () => {

				// 현재 로드뷰의 위치 좌표를 얻어옵니다 
				var rvPosition = this.rv.getPosition();

				// 지도의 중심을 현재 로드뷰의 위치로 설정합니다
				this.map.setCenter(rvPosition);

				// 지도 위에 로드뷰 도로 오버레이가 추가된 상태이면
				if(this.overlayOn) {
					// 마커의 위치를 현재 로드뷰의 위치로 설정합니다
					this.marker.setPosition(rvPosition);
				}
			});

			// 마커 이미지를 생성합니다
			var rvMarkImage = new kakao.maps.MarkerImage(
				'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
				new kakao.maps.Size(47, 54),
				{
					// 스프라이트 이미지를 사용합니다.
					// 스프라이트 이미지 전체의 크기를 지정하고
					spriteSize: new kakao.maps.Size(1666, 168),
					// 사용하고 싶은 영역의 좌상단 좌표를 입력합니다.
					// background-position으로 지정하는 값이며 부호는 반대입니다.
					spriteOrigin: new kakao.maps.Point(705, 114),
					offset: new kakao.maps.Point(13, 46)
				}
			);

			// 드래그가 가능한 마커를 생성합니다
			this.marker = new kakao.maps.Marker({
				image     : rvMarkImage,
				position  : mapCenter,
				draggable : true
			});

			// 마커에 dragend 이벤트를 등록합니다
			kakao.maps.event.addListener(this.marker, 'dragend', mouseEvent => {

				// 현재 마커가 놓인 자리의 좌표입니다 
				var position = this.marker.getPosition();

				// 마커가 놓인 위치를 기준으로 로드뷰를 설정합니다
				this.toggleRoadview(position);

			});

			//지도에 클릭 이벤트를 등록합니다
			kakao.maps.event.addListener(this.map, 'click', mouseEvent => {
				
				// 지도 위에 로드뷰 도로 오버레이가 추가된 상태가 아니면 클릭이벤트를 무시합니다 
				if(!this.overlayOn) {
					return;
				}

				// 클릭한 위치의 좌표입니다 
				var position = mouseEvent.latLng;

				// 마커를 클릭한 위치로 옮깁니다
				this.marker.setPosition(position);

				// 클락한 위치를 기준으로 로드뷰를 설정합니다
				this.toggleRoadview(position);

			});

			// HTML5의 geolocation으로 사용할 수 있는지 확인합니다 
			if (navigator.geolocation) {

				// GeoLocation을 이용해서 접속 위치 가져오기
				navigator.geolocation.getCurrentPosition(position => {
					
					this.userLat = position.coords.latitude;	// 위도
					this.userLng = position.coords.longitude;	// 경도

					// 마커가 표시될 위치를 geolocation으로 얻어온 좌표로 생성
					var locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);

					// 사용자 위치정보와 화성시 위치 판단 및 커스텀오버레이 생성
					this.changeAddressArea();
					this.userCusOverlay();

					this.geoLocationState = 1;
				});

			}else{

				// HTML5의 GeoLocation을 사용할 수 없을때 마커 표시 위치와 인포윈도우 내용을 설정합니다
				// 기본적으로 화성시청 위치값을 넣어둡니다.
				this.userLat = 37.19952770562244;
				this.userLng = 126.83145146431667;
				this.displayMarker(this.userLat, this.userLng, this.message);

			}

			this.getParkingList();

		},

		// 주변 주차장 검색 조건 가져오는 메소드
		distanceCheck(){
			var searchDistance = this.distance;

			if(searchDistance == "500"){
				this.setMarkersWithinKm(0.5);
			}else if(searchDistance == "1000"){
				this.setMarkersWithinKm(1.0);
			}else{
				this.imageSize = this.mapLevelCk();
				this.setMarkers(this.positions);
			}
		},

		distanceEnter(event){
			if(event == "500"){
				this.distance = '500';
			}else if(event == "1000"){
				this.distance = '1000';
			}else{
				this.distance = '';
			}
		},

		// 주변 주차장 검색 조건을 받아 마커의 위치정보와 비교후 거리에따른 주차장 출력
		setMarkersWithinKm(km){

			this.imageSize = this.mapLevelCk();
			this.setMarkers(this.positions);

			for(var i = 0 ; i < this.markers.length ; i++){
				var _pos = this.markers[i].getPosition();
				if(this.getDistanceFromLatLonInKm(_pos.getLat(), _pos.getLng(), this.userLat, this.userLng) > km){
					this.markers[i].setMap(null);
				}else if(km == null){
					this.markers[i].setMap(this.map);
				}
			}

		},

		// 현재 위치와 주차장간의 거리 계산 함수
		getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2){

			// lat1, lng1 비교대상 위치정보  || lat2, lng2 기준 위치정보
			var R    = 6371;						// Radius of the earth in km
			var dLat = this.deg2rad(lat2 - lat1);	// deg2rad below
			var dLon = this.deg2rad(lng2 - lng1);
			var a   = Math.sin(dLat / 2)
					* Math.sin(dLat / 2)
					+ Math.cos(this.deg2rad(lat1))
					* Math.cos(this.deg2rad(lat2))
					* Math.sin(dLon / 2)
					* Math.sin(dLon / 2)
			;
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			var d = R * c;							// Distance in km

			return d;

		},

		deg2rad(x){
			return x * (Math.PI / 180);
		},

		// 확대축소 레벨 별 이미지사이즈 저장
		mapLevelCk(){
			var level = this.map.getLevel();
			if(level > 7){
			}else if(level > 6){
				this.imageSize = new kakao.maps.Size(47,54);
			}else if(level > 3){
				this.imageSize = new kakao.maps.Size(47,54);
			}else if(level >= 1){
				this.imageSize = new kakao.maps.Size(47,54);
			}
			return this.imageSize;
		},

		/*
			요금구분 무료여부, 주차장구분코드를 기준으로 마커를 색상으로 구분한다.
			노상 : 빨강
			노외 : 파랑
			무료 : 초록
		*/
		setMarkerImages(freeYn, parkingDivCd){

			if(freeYn == "Y"){

				// 무료
				return this.mapMarkerGreen();

			}else{
				
				if(parkingDivCd == 'PLT-003'){

					// 노외
					return this.mapMarker();

				}else{

					// 노상
					return this.mapMarkerRed();

				}
			}
			
		},

		/*
			클릭시 요금구분 무료여부, 주차장구분코드를 기준으로 마커를 색상으로 구분한다.
			노상 : 빨강
			노외 : 파랑
			무료 : 초록
		*/
		setPcMarkerImages(freeYn, parkingDivCd){

			if(freeYn == "Y"){

				// 무료
				return this.mapPcMarkerGreen();

			}else{
				
				if(parkingDivCd == 'PLT-003'){

					// 노외
					return this.mapPcMarker();

				}else{

					// 노상
					return this.mapPcMarkerRed();

				}
			}

		},

		// 각 주차장 정보를 받아 마커를 지도에 뿌려준다.
		setMarkers(positions, limitKm){

			this.controlhideMarkers();
			this.markers = [];

			if(positions == null){
			}else{
				for(var i = 0 ; i < positions.length ; i++){

					this.markers[i] = new kakao.maps.Marker({
						map       : this.map,				// 마커를 표시할 지도
						position  : positions[i].latlng,	// 마커의 위치
						title     : positions[i].title,
						clickable : true,					// 마커 클릭가능. (지도의 클릭이벤트가 발생하지않도록 함)
						id        : positions[i].id,
						// 마커 이미지			
						image     : new kakao.maps.MarkerImage(this.setMarkerImages(positions[i].freeYn, positions[i].pDivCd), this.imageSize, this.imageOption)
					});

					var customContent	= '<div class="mapCustomOverlay-box">'
										+     '<dl style="margin-bottom: 0px;"><dt>[' + positions[i].pDivNm + '] ' + positions[i].title + '</dt></dl>'
										+ '</div>'
					;

					this.customOverlay[i] = new kakao.maps.CustomOverlay({
						position : positions[i].latlng,
						content  : customContent,
						xAnchor  : 0.017,
						yAnchor  : 0.5
					});

					kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeOverListener(this.markers[i], this.customOverlay[i], positions[i].freeYn, positions[i].pDivCd));	// 마커 호버 이벤트
					kakao.maps.event.addListener(this.markers[i], 'click'    , this.makeClickListener(i, this.customOverlay[i], positions[i]));									// 마커 클릭 이벤트
					kakao.maps.event.addListener(this.markers[i], 'mouseout' , this.makeOutListener(this.customOverlay[i], this.markers[i], i, positions[i].freeYn, positions[i].pDivCd));					// 마커 호버아웃 이벤트
				}
			}
		},

		// "마커 감추기" 버튼을 클릭하면 호출되어 배열에 추가된 마커를 지도에서 삭제하는 함수입니다
		controlhideMarkers(){
			this.controlsetMarkers(null);
		},

		// "마커 보이기" 버튼을 클릭하면 호출되어 배열에 추가된 마커를 지도에 표시하는 함수입니다
		controlshowMarkers(){
			this.controlsetMarkers(this.markers);
		},

		controlsetMarkers(map){
			for (var i = 0 ; i < this.markers.length ; i++) {
				this.markers[i].setMap(map);
			}
		},

		//마크 에 마우스를 대면 발생하는 이벤트(호버)
		makeOverListener(markers, customOverlay, freeYn, parkingDivCd){
			return () => {
				var sImageSize = this.mapLevelCk();
				var initMarker = new kakao.maps.MarkerImage(this.setMarkerImages(freeYn, parkingDivCd), sImageSize, this.imageOption);

				markers.setImage(initMarker);

				var imageSrc  = this.setPcMarkerImages(freeYn, parkingDivCd); // this.mapPcMarker();
				var level     = this.map.getLevel();
				var imageSize = new kakao.maps.Size(47,54);

				var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, this.clickImageOption);
				markers.setImage(markerImage);
				customOverlay.setMap(this.map);
			}
		},

		// 마커 클릭 이벤트
		makeClickListener(idx, customOverlay, position){
			return () => {

				// 로드뷰 오버레이 나왓을시 마커 클릭 안되게함.
				if(this.overlayOn) {
					return null;
				}

				let apiParams = new Map();
				apiParams.set("PARKING_ID", this.positions[idx].id);

				instance.get("/api/parking/operating.json", {
					params : Object.fromEntries(apiParams)
				})
				.then((res => {
					var resData = res.data.operating;

					if(resData.code == "00"){
						this.park        = resData.result;
						this.def         = this.defaultParkingMapList[idx];
						this.defFlag     = true;
						// 마커 클릭시 각정보를 상세보기 뷰에 넣어서 출력해준다.

						this.checkImg(this.def.REPO_ID, this.def.FILE_PATH, this.def.SAVE_NM, this.def.FILE_EXT);

						var mkTitle      = this.markers[idx].getTitle();
						this.clickStatus = 1;
						var imageSize    = this.mapLevelCk();

						if(this.cMarkerIndex != -1){
							customOverlay.setMap(null);
							if(this.clickCustomOverlay){
								this.clickCustomOverlay.setMap(null);
							}
						}

						for(var i = 0 ; i < this.markers.length ; i++){

							if(this.markers[i].getTitle() == this.def.PARKING_NM){

								// 클릭된 마커 id값 저장
								this.cMarkerIndex = i;

								// 클릭된 마커 크기및 이미지 변경
								this.changeImage(this.markers[i], this.setPcMarkerImages(this.positions[i].freeYn, this.positions[i].pDivCd), new kakao.maps.Size(47,54), this.clickImageOption);
								
								//kakao.maps.event.removeListener(this.markers[i], 'mouseout' , this.makeOutListener(customOverlay, this.markers[i], this.positions[i].freeYn, this.positions[i].pDivCd));
								customOverlay.setMap(this.map);
								this.clickCustomOverlay = customOverlay;

							}else{
								this.changeImage(this.markers[i], this.setMarkerImages(this.positions[i].freeYn, this.positions[i].pDivCd), imageSize, this.imageOption);
							}
						}

					}

				}))
				.catch(function(err){
					//console.log("ERROR");
					//console.dir(err);
				});
			}
		},
		// 거리 및 확대레벨에 따른 마커 출력 함수
		changeImage(markers, imageSrc, imageSize, imageOption){

			var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
			var pos         = markers.getPosition();
			var kmRadio     = this.distance;

			var km;
			if(kmRadio == "500"){
				km = 0.5;
			}else if(kmRadio == "1000"){
				km = 1.0;
			}else{
				km = 99999;
			}

			if(this.getDistanceFromLatLonInKm(pos.getLat(), pos.getLng(), this.userLat, this.userLng) <= km){
				markers.setImage(markerImage);
				markers.setMap(this.map);
			}else{
				markers.setMap(null);
			}

		},

		noParkingImage() {
			return require("../../../assets/images/parking/noparking.png");
		},

		// 주차장 이미지 없는경우 Default 이미지 설정 (인덱스 참고)
		checkImg(repoId, filePath, saveNm, fileExt){
			if(repoId == null || filePath == null || saveNm == null || fileExt == null){
				this.ckImg = this.noParkingImage();
			}else{
				this.ckImg = "/api/parking/atchfile/" + repoId + "/" + filePath + "/" + saveNm + "." + fileExt;
			}
		},

		// 마크 에 마우스를 빼면 발생하는 이벤트(아웃)
		makeOutListener(customOverlay, marker, idx, freeYn, parkingDivCd){
			return () => {

				if(idx != this.cMarkerIndex){

					var imageSrc   = this.setMarkerImages(freeYn, parkingDivCd);
					this.imageSize = new kakao.maps.Size(47,54);
					this.imageSize = this.mapLevelCk();

					var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, this.imageOption);
					marker.setImage(markerImage);
					customOverlay.setMap(null);

				}else{

					if(this.clickStatus == 1){
						var imageSrc    = this.setMarkerImages(freeYn, parkingDivCd);
						this.imageSize  = this.mapLevelCk();
						var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, this.imageOption);
						marker.setImage(markerImage);
						customOverlay.setMap(null);
					}

				}

			}
		},

		// userLat, userLng 위치값으로부터 화성시 영역 여부를 반환 (좌표위치값을 주소로 변환하여 판단)
		changeAddressArea(){

			if(this.mapGeocoder == null){
				this.mapGeocoder = new kakao.maps.services.Geocoder();
			}

			this.mapGeocoder.coord2Address(this.userLng, this.userLat, this.checkUserLoc);

		},

		checkUserLoc(result, status){

			if(status === kakao.maps.services.Status.OK){
				var userAddr = result[0].address.address_name;	// 전체 지번 주소

				if((this.nullChk(userAddr)).indexOf("화성") == -1){
					// 지번주소에 화성시 있는지 판단
					this.userLat = 37.19952770562244;
					this.userLng = 126.83145146431667;
				}

				this.message = "<div style='padding:5px;'>내 위치</div>";
				this.displayMarker(this.userLat, this.userLng, this.message);
				this.map.setCenter(new kakao.maps.LatLng(this.userLat, this.userLng));

			}

		},

		nullChk(x){
			return (x == null || x == undefined) ? "" : x;
		},

		// 현재 위치에 표시를 위한 마커 만들어준다
		displayMarker(userLat, userLng, message, addr){

			var locPosition   = new kakao.maps.LatLng(userLat, userLng);

			// 마커를 생성합니다
			var userLocmarker = new kakao.maps.Marker({
				map      : this.map,
				position : locPosition
			});

			this.map.setCenter(locPosition);
			this.map.relayout();

		},

		// 사용자 gps 마커 오버레이 제거
		userCusOverlay(){
			var customContent	= '<div class="mapCustomOverlay-box">'
								+     '<dl style="margin-bottom: 0px;"><dt>' + this.message + '</dt></dl>'
								+ '</div>'
			;

			var mLevel     = this.map.getLevel();
			var cusOverLat = this.userLat;
			var cusOverLng = this.userLng;

			var cusOverLocPosition = new kakao.maps.LatLng(cusOverLat, cusOverLng);
			this.userCustomOverlay = new kakao.maps.CustomOverlay({
				position : cusOverLocPosition,
				content  : customContent,
				xAnchor  : 0.017,
				yAnchor  : 0.5
			});
		},

		// 전달받은 좌표(position)에 가까운 로드뷰의 파노라마 ID를 추출하여
		// 로드뷰를 설정하는 함수입니다
		toggleRoadview(position){
			this.rvClient.getNearestPanoId(position, 50, panoId => {
				// 파노라마 ID가 null 이면 로드뷰를 숨깁니다
				if (panoId === null) {
					this.toggleMapWrapper(true, position);
				} else {
					this.toggleMapWrapper(false, position);

					// panoId로 로드뷰를 설정합니다
					this.rv.setPanoId(panoId, position);
				}
			});
		},

		// 지도를 감싸고 있는 div의 크기를 조정하는 함수입니다
		toggleMapWrapper(active, position) {
			if (active) {

				// 지도를 감싸고 있는 div의 너비가 100%가 되도록 class를 변경합니다 
				this.container.className = '';

				// 지도의 크기가 변경되었기 때문에 relayout 함수를 호출합니다
				this.map.relayout();

				// 지도의 너비가 변경될 때 지도중심을 입력받은 위치(position)로 설정합니다
				this.map.setCenter(position);

			} else {

				// 지도만 보여지고 있는 상태이면 지도의 너비가 50%가 되도록 class를 변경하여
				// 로드뷰가 함께 표시되게 합니다
				if (this.container.className.indexOf('view_roadview') === -1) {
					this.container.className = 'view_roadview';

					// 지도의 크기가 변경되었기 때문에 relayout 함수를 호출합니다
					this.map.relayout();

					// 지도의 너비가 변경될 때 지도중심을 입력받은 위치(position)로 설정합니다
					this.map.setCenter(position);
				}
			}
		},

		// 지도 위의 로드뷰 도로 오버레이를 추가,제거하는 함수입니다
		toggleOverlay(active) {
			if (active) {
				this.overlayOn = true;

				// 지도 위에 로드뷰 도로 오버레이를 추가합니다
				this.map.addOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

				// 지도 위에 마커를 표시합니다
				this.marker.setMap(this.map);

				// 마커의 위치를 지도 중심으로 설정합니다 
				this.marker.setPosition(this.map.getCenter());

				// 로드뷰의 위치를 지도 중심으로 설정합니다
				this.toggleRoadview(this.map.getCenter());
			} else {
				this.overlayOn = false;

				// 지도 위의 로드뷰 도로 오버레이를 제거합니다
				this.map.removeOverlayMapTypeId(kakao.maps.MapTypeId.ROADVIEW);

				// 지도 위의 마커를 제거합니다
				this.marker.setMap(null);
			}
		},

		// 지도 위의 로드뷰 버튼을 눌렀을 때 호출되는 함수입니다
		setRoadviewRoad() {

			this.active = !this.active;

			// 버튼이 눌린 상태가 아니면
			if (this.active) {
				// 로드뷰 도로 오버레이가 보이게 합니다
				this.toggleOverlay(true);
			} else {
				// 로드뷰 도로 오버레이를 제거합니다
				this.toggleOverlay(false);
			}
		},

		// 로드뷰에서 X버튼을 눌렀을 때 로드뷰를 지도 뒤로 숨기는 함수입니다
		closeRoadview() {
			var position = this.marker.getPosition();
			this.toggleMapWrapper(true, position);
		},
		initSelect(positions){
				let apiParams = new Map();
				apiParams.set("PARKING_ID", this.positions[0].id);

				instance.get("/api/parking/operating.json", {
					params : Object.fromEntries(apiParams)
				})
				.then((res => {
					var resData = res.data.operating;

					if(resData.code == "00"){
						this.park        = resData.result;
						this.def         = this.defaultParkingMapList[0];
						// 마커 클릭시 각정보를 상세보기 뷰에 넣어서 출력해준다.

						this.checkImg(this.def.REPO_ID, this.def.FILE_PATH, this.def.SAVE_NM, this.def.FILE_EXT);

						var mkTitle      = this.markers[0].getTitle();
						this.clickStatus = 1;
						var imageSize    = this.mapLevelCk();

						if(this.cMarkerIndex != -1){
							customOverlay.setMap(null);
							if(this.clickCustomOverlay){
								this.clickCustomOverlay.setMap(null);
							}
						}

						for(var i = 0 ; i < this.markers.length ; i++){

							if(this.markers[i].getTitle() == this.def.PARKING_NM){

								// 클릭된 마커 id값 저장
								this.cMarkerIndex = i;

								// 클릭된 마커 크기및 이미지 변경
								this.changeImage(this.markers[i], this.setPcMarkerImages(this.positions[i].freeYn, this.positions[i].pDivCd), new kakao.maps.Size(47,54), this.clickImageOption);
								
								//kakao.maps.event.removeListener(this.markers[i], 'mouseout' , this.makeOutListener(customOverlay, this.markers[i], this.positions[i].freeYn, this.positions[i].pDivCd));
								customOverlay.setMap(this.map);
								this.clickCustomOverlay = customOverlay;

							}else{
								this.changeImage(this.markers[i], this.setMarkerImages(this.positions[i].freeYn, this.positions[i].pDivCd), imageSize, this.imageOption);
							}
						}

					}

				}))
				.catch(function(err){
					//console.log("ERROR");
					//console.dir(err);
				});
			}
		,

		onChange(event) {
				
				let apiParams = new Map();
				apiParams.set("PARKING_ID", this.positions[event.target.value].id);

				instance.get("/api/parking/operating.json", {
					params : Object.fromEntries(apiParams)
				})
				.then((res => {
					var resData = res.data.operating;

					if(resData.code == "00"){
						this.park        = resData.result;
						this.def         = this.defaultParkingMapList[event.target.value];
						this.defFlag     = true;
						// 마커 클릭시 각정보를 상세보기 뷰에 넣어서 출력해준다.

						this.checkImg(this.def.REPO_ID, this.def.FILE_PATH, this.def.SAVE_NM, this.def.FILE_EXT);

						var mkTitle      = this.markers[event.target.value].getTitle();
						this.clickStatus = 1;
						var imageSize    = this.mapLevelCk();

						if(this.cMarkerIndex != -1){
							customOverlay.setMap(null);
							if(this.clickCustomOverlay){
								this.clickCustomOverlay.setMap(null);
							}
						}

						for(var i = 0 ; i < this.markers.length ; i++){

							if(this.markers[i].getTitle() == this.def.PARKING_NM){

								// 클릭된 마커 id값 저장
								this.cMarkerIndex = i;

								// 클릭된 마커 크기및 이미지 변경
								this.changeImage(this.markers[i], this.setPcMarkerImages(this.positions[i].freeYn, this.positions[i].pDivCd), new kakao.maps.Size(47,54), this.clickImageOption);
								
								//kakao.maps.event.removeListener(this.markers[i], 'mouseout' , this.makeOutListener(customOverlay, this.markers[i], this.positions[i].freeYn, this.positions[i].pDivCd));
								customOverlay.setMap(this.map);
								this.clickCustomOverlay = customOverlay;

							}else{
								this.changeImage(this.markers[i], this.setMarkerImages(this.positions[i].freeYn, this.positions[i].pDivCd), imageSize, this.imageOption);
							}
						}

					}

				}))
				.catch(function(err){
					//console.log("ERROR");
					//console.dir(err);
				});
			}
		,
		closeBtn() {
			this.defFlag = false;
		}

	}

}

</script>

<style scope lang="scss">
	.mapWH100{ width: 100%; height: 100%; }

	// 마커 마우스 오버시 주차장 명 출력
	#map .mapCustomOverlay-box { position: absolute; top: -70px;left: 50%;transform: translateX(-50%);min-width: 180px; border-radius: 3px;}
	#map .mapCustomOverlay-box::before{
		content:""; display : inline-block; position: absolute;bottom: -21px;left: 50%; 
		transform: translateY(-50%) rotate(90deg); border : transparent 7px solid;  border-left-color: rgba(84, 86, 93, 0.9);}
	#map .mapCustomOverlay-box dl dt { padding: 7px 24px; border-radius: 3px; background-color: rgba(84, 86, 93, 0.9);  font-size: 13px; color: #ffffff; text-align: center; }

	#container {
		overflow : hidden;
		height   : 550px;
		position : relative;
	}
	#mapWrapper {
		width   : 100%;
		height  : 550px;
		z-index : 1;
	}
	#rvWrapper {
		width    : 50%;
		height   : 550px;
		top      : 0;
		right    : 0;
		position : absolute;
		z-index  : 0;
	}
	#container.view_roadview #mapWrapper { width: 50%; }
	#roadviewControl {
		position   : absolute;
		top        : 5px;
		left       : 5px;
		width      : 42px;
		height     : 42px;
		z-index    : 1;
		cursor     : pointer;
		background : url(https://t1.daumcdn.net/localimg/localimages/07/2018/pc/common/img_search.png) 0 -450px no-repeat;
	}
	#roadviewControl.active { background-position:0 -350px; }
	#close {
		position      : absolute;
		padding       : 4px;
		top           : 5px;
		left          : 5px;
		cursor        : pointer;
		background    : #fff;
		border-radius : 4px;
		border        : 1px solid #c8c8c8;
		box-shadow    : 0px 1px #888;
	}
	#close .img {
		display    : block;
		background : url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/rv_close.png) no-repeat;
		width      : 14px;
		height     : 14px;
	}

	#tabs{
		.tab-menu{
			display: flex;

			li{
				padding: 10px 0;
				width: 50%;
				text-align: center;
				border: 1px solid $color;

				a{
					color: $color;
				}

				&.active{
					background-color: $color;

					a{
						color:$white;
					}
				}
			}
		}
	}
</style>