<template>
	<main id="utilTemplet">
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 로그인</h2>
			</article>
			<!--<article class="explanation">
				<p>화성시 통합주차포탈에 가입하시면,</p>
				<p>화성시 공영주차장 및 공원주차장에 대한 <span class="orange">다양한 서비스를 편리하게 이용</span>하실 수 있습니다.</p>
			</article>-->
			<article>
				<section>
					<article>
						<p>아이디</p>
						<ul class="input-style">
							<li>
								<label for="inputId" class="hide">아이디</label>
								<input type="text" placeholder="e-mail" class="place-hide" v-model="empNo" @keyup.enter="actionLogin" id="inputId" v-focus>
							</li>
						</ul>
					</article>
					<article>
						<p>비밀번호</p>
						<ul class="input-style">
							<li>
								<label for="pwd" class="hide">비밀번호</label>
								<input type="password" placeholder="비밀번호" class="place-hide" v-model="password" @keyup.enter="actionLogin" id=
								"pwd">
							</li>
						</ul>
					</article>
				</section>
				<section class="login-btn">
					<article>
						<button type="button" title="로그인" @click="actionLogin">로그인</button>
					</article>
				</section>
			</article>
			<!--
				<article class="save-id">
					<input type="checkbox" id="save">
					<label for="save">아이디 저장</label>
				</article>
			-->
				<!--<article class="btn-list">
				<button class="login-btn"        @click="$router.push('membershipcertification')">회원가입</button>
				<button class="login-btn-border" @click="$router.push('/findid')">아이디찿기</button>
				<button class="login-btn-border" @click="$router.push('/findpassword')">비밀번호 찾기</button>
			</article> -->
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return{
			empNo     : '',
			password  : '',
			frontType : 'vue'
		}
	},

	validations: {
		empNo: {
			required
		},
		password: {
			required,
			minLength: minLength(8),
			maxLength: maxLength(20)
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods:{
		actionLogin(){
			if(!this.validationCheck()) return;
			instance.post('/api/adminActionLogin.json', null, {
				params: {
					empNo: this.empNo,
					password: this.password
				}
			})
			.then((response => {
				const data = response.data;

				if(!data.result){
					alert(data.msg);
					return;
				}

				const sessionId = data.attributeMap.SPRING_SECURITY_CONTEXT.authentication.details.sessionId
				// const jSessionId = data.headerMap.cookie.split("JSESSIONID=")[1];
				const authorCode = data.attributeMap.loginVO.authorCode;
				const empNo = data.attributeMap.loginVO.empNo;
				const empNm = data.attributeMap.loginVO.empNm;
				const empMobile = data.attributeMap.loginVO.empMobile;
				const empCarNo = data.attributeMap.loginVO.empCarNo;
				const zipcode = data.attributeMap.loginVO.zipcode;
				const addr = data.attributeMap.loginVO.address;
				const addrDetail = data.attributeMap.loginVO.addressDetail;

				if(sessionId !== undefined && sessionId !== ''){
					// this.$store.dispatch("jSessionId", jSessionId);
					this.$store.dispatch("authorCode", authorCode);
					this.$store.dispatch("empNo"     , empNo);
					this.$store.dispatch("empNm"     , empNm);
					this.$store.dispatch("empMobile" , empMobile);
					this.$store.dispatch("empCarNo"  , empCarNo);
					this.$store.dispatch("zipcode"   , zipcode);
					this.$store.dispatch("addr"      , addr);
					this.$store.dispatch("addrDetail", addrDetail);
					this.$store.dispatch("isLogin"   , true);
					alert(data.msg);
					this.$router.push("/main");
				}
			}))
			.catch(function (error) {
				alert("로그인 정보가 올바르지 않습니다.");
			})
		},

		validationCheck(){
			if(!this.$v.empNo.required) {
				alert('아이디를 입력하여주십시오.');
				return false;
			}else if(!this.$v.password.required) {
				alert('비밀번호를 입력하여주십시오.');
				return false;
			}else if(!this.$v.password.minLength || !this.$v.password.maxLength) {
				alert('비밀번호는 8~20 사이의 자리여야 합니다.');
				return false;
			}else{
				return true;
			}
		}
	},

}
</script>

<style>

</style>