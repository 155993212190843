<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle" title="무료차량 관리"  class="active">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist" title="정기권 주차장 관리">정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement" title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist" title="팝업 관리">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>무료차량 관리</h4>
			</article>
			<article>
				<h3>무료차량 관리</h3>
				<!-- Search Area -->
				<div class="search-box">
					<search-box @emitSearch="searchBtn" @emitPerChange="changePerPage">
						<template slot="searchCondition">
							<option value="1">이름</option>
							<option value="2">차량번호</option>
							<option value="3">이름 + 차량번호</option>
						</template>
					</search-box>
				</div> 
			</article>
			<article>
				<h5 v-if="nodata">Total: 0건</h5>
				<h5 v-else>Total: {{totalCnt}}건</h5>
				<table class="default-table">
					<caption class="sr-only">무료차량 관리</caption>
					<colgroup>
						<col style="width:70px" />
						<col style="width:20%" />
						<col style="width:20%" />
						<col style="width:20%" />
						<col style="width:30%" />
						<col style="width:10%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">번호</th>
							<th scope="col">사용자명</th>
							<th scope="col">차량번호</th>
							<th scope="col">주차장</th>
							<th scope="col">무료기간</th>
							<th scope="col">비고</th>
						</tr>
					</thead>
					<tbody>
						<tr class="nodate" v-if="nodata">
							<td colspan="6" >조회된 데이터가 없습니다.</td>
						</tr>
						<tr v-for="(item, index) in listData" :key="index" @click="view(item.freeCarId)">
							<td>{{ ((curPageNum - 1) * 10) + index + 1 }}</td>
							<td>{{ item.carOwnerNm }}</td>
							<td>{{ item.carNo }}</td>
							<td>{{ item.parkingNm }}</td>
							<td>{{ item.startDt }} - {{ item.endDt }}</td>
							<td>{{ item.freeNote }}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<v-pagination v-model="curPageNum" :length="numOfPages" @input="onPageChange" class="pagination-width"></v-pagination>
			<article class="btn-list right">
				<button class="btn black" @click="excelUpload">엑셀 업로드</button>
				<button class="btn-border" @click="$router.push('freevehicleenrollment')">등록</button>
			</article>	
		</section>
		<!-- 팝업 -->
		<pop-up v-show="showModal" @emitChangeModal="hideModal()" @emitUploadFile="uploadFile">
			<template #info>
					<li>무료차량 엑셀업로드</li>
			</template>
			
			<template #close>
					<li></li>
			</template>
			
			<template #content>
					<p v-html="msg"></p>
			</template>
	</pop-up>
	</main>
</template>

<script>
import axios from 'axios';
import PopUp from '../../../components/PopupType5.vue';
import SearchBox from '../../../components/SearchBox2.vue';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data () {
		return {
			msg:'<ul>'
					+'<li class="reference">무료차량 엑셀파일을 업로드 해 주십시요.</li>'
					+'<li class="reference">자동차등록번호, 차면, 소유자명이 비어있는 값이 있는 경우 해당 행은 처리되지 않습니다.</li>'
					+'</ul>',
			showModal: false,
			nodata: false,
			listData: [],
			curPageNum: 1,
			dataPerPage: 10,
			totalCnt: '',
			page: '',
			searchCondition: '',
			searchKeyword: ''
		}
	},

	mounted() {
		this.searchBtn();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods:{
		searchBtn(searchCondition, searchKeyword) {
			this.searchCondition = searchCondition;
			this.searchKeyword = searchKeyword;
			instance.get('/api/admin/freecar/list.json', {
				params: {
					searchCondition: searchCondition,
					searchKeyword: searchKeyword
				}
			})
			.then((response => {
				var data = response.data;
				
				if(data.resultList.result && data.resultList.result.length > 0){
					this.listData = data.resultList.result;
					this.nodata = false;
					this.totalCnt = data.resultList.pageNo.total;
				} else {
					this.listData = [];
					this.nodata = true;
				}

				this.curPageNum = 1;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		changePerPage(dataPerPage) {
			this.dataPerPage = dataPerPage;
			this.curPageNum = 1;
		},

		view(freeCarId) {

			this.$router.push({
				path  : 'freevehicleview',
				query : { freeCarId : freeCarId }
			});
		},

		excelUpload(){
			this.showModal = true;
		},

		hideModal(){
			this.showModal = false;
		},

		onPageChange(curPageNum){
			instance.get('/api/admin/freecar/list.json', {
				params: {
					searchCondition: this.searchCondition,
					searchKeyword: this.searchKeyword,
					pageIndex: curPageNum
				}
			})
			.then((response => {
				var data = response.data;
				
				if(data.resultList.result && data.resultList.result.length > 0){
					this.listData = data.resultList.result;
					this.nodata = false;
					this.totalCnt = data.resultList.pageNo.total;
				} else {
					this.listData = [];
					this.nodata = true;
				}

				this.curPageNum = curPageNum;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})

			//console.log('onPageChange');
		},

		uploadFile(file){
			let apiParams = new FormData();
			apiParams.append("file", file),
	
			instance.post('/api/admin/freecar/xlsxUpload.json', apiParams, { headers: { 'Content-Type': 'multipart/form-data' }})
			.then((response => {
				let data = response.data;
				let message = data.message;
				let count = data.count;

				if(message == ''){
					alert('무료차량 등록 ' + count + '건이 완료되었습니다.');
				}else{
					alert(message);
				}

				this.showModal = false;
				this.searchBtn();
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		}
	},

	computed: {
		// startOffset() {
		// 	return ((this.curPageNum - 1) * this.dataPerPage);
		// },
		// endOffset() {
		// 	return (this.startOffset + this.dataPerPage);
		// },
		numOfPages() {
			// return Math.ceil(this.listData.length / this.dataPerPage);
			return Math.ceil(this.totalCnt / this.dataPerPage);
		},
		// calData() {
		// 	// if(this.listData.length) {
		// 	if(this.totalCnt) {
		// 		return this.listData.slice(this.startOffset, this.endOffset);
		// 	}
		// }
	},

	components:{
		SearchBox,
		PopUp,
	}
}
</script>

<style>

</style>
