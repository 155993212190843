<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo"      title="안내" class="active">안내</router-link></li>
				<li><router-link to="residentsearch"    title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup"    title="조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>안내</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 안내</h3>
			</article>
			<!--
			<article>
				<table class="lookup-table">
					<caption class="sr-only">조회 테이블</caption>
					<colgroup>
							<col style="width:20%" />
							<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">거주동</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" v-model="inputText" placeholder="거주하시는 동을 선택해주세요." class="place-hide">
									</li>
									<li>
										<button class="table-btn" @click="searchbtn">조회</button>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			-->
			<article v-if="showContent" class="resident-content">
				<div id="tabs">
					<ul class="tab-menu">
						<li v-for="(tab, index) in tabs" :key="index" v-bind:class="{ active:currentTab === index }">
							<a href="#" v-on:click="currentTab = index">{{ tab }}</a>
						</li>
					</ul>
					<div class="tab-content">
						<section v-show="currentTab == 0">
							<article>
								<p class="circle">대상지</p>
								<p class="indent">병점동 815번지 일원</p>
							</article>
							<article>
								<p class="circle">운영대상 및 운영시간</p>
								<table class="default-table-content-th">
									<caption class="sr-only">운영대상 및 운영시간 정보 테이블</caption>
									<colgroup>
											<col style="width:20%" />
											<col style="width:40%" />
											<col style="width:40%" />
									</colgroup>
									<thead>
											<tr>
												<th scope="col">구분</th>
												<th scope="col">공유주차(야간)</th>
												<th scope="col">전일제</th>
											</tr>
									</thead>
									<tbody> 
											<tr>
												<td scope="row" class="bg-gray">시간</td>
												<td>17:00 ~ 익일 10:00</td>
												<td>24시간</td>
											</tr>
											<tr>
												<td class="bg-gray">면수</td>
												<td>10면</td>
												<td>37면</td>
											</tr>											
									</tbody>
								</table>
							</article>						
							<article>
								<p class="circle">운영계획</p>
								<ul class="indent">
									<li>[공지] 2022년도 제4회 거주자 우선 주차제 신청 안내</li>
									<li>{공지사항 참고}</li>
								</ul>
							</article>
							<article>
								<p class="circle">선정방법</p>
								<p class="indent">고득점자 + 추첨제 혼합 방식</p>
							</article>
							<article>
								<p class="circle">신청자격</p>
								<p class="indent">병점동에 주민등록을 두고, 실제 거주하는 주민 또는 사업자(근로자)면 가능</p>
							</article>
							<article>
								<p class="circle">신청제외</p>
								<ul>
									<li class="indent">자동차운수사업법상 차고지 의무 확보 차량</li>
									<li class="indent">16인승 이상 승합차량 및 건설기계 등 특수차량</li>
									<li class="indent">주차구획 내 주차불가 차량(차량길이 및 너비가 해당 주차구획 초과 차량)</li>
								</ul>
							</article>
							<article>
								<p class="circle">공통제출서류</p>
								<ul>
									<li class="indent">사용신청서</li>
									<li class="indent">개인정보 수집-이용 및 제공동의서</li>
									<li class="indent">자동차등록증 사본</li>
								</ul>
							</article>
							<article>
								<p class="circle">신청자 구분에 따른 필수제출 서류</p>
								<ul>
									<li class="indent">거주자: 주민등록 초본(과거이력 포함)</li>
									<li class="indent">사업자: 사업자등록증 사본</li>
									<li class="indent">근로자: 근무기간 명시되어 있는 재직증명서</li>
								</ul>
							</article>
							<article>
								<p class="circle">서류제출방법</p>
								<p class="indent">팩스(031-357-6585) 또는 전자우편(hsparking2@hsuco.or.kr)</p>
							</article>
							<article>
								<p class="circle">문의</p>
								<table class="vertical-table">
									<caption class="sr-only">문의</caption>
									<colgroup>
										<col style="width:20%" />
										<col style="width:80%" />
									</colgroup>
									<tbody>
										<tr>
											<th scope="row">문의</th>
											<td>화성도시공사 주차운영2부(TEL. 031-357-6548) </td>
										</tr>										
									</tbody>
								</table>
							</article>
							<article class="btn-list">
								<button class="one-btn" @click="residentagreement">거주자 우선주차장 신청</button>
							</article>
						</section>
						<section v-show="currentTab == 1">
							<article>
								<p class="circle">대상지</p>
								<p class="indent">진안동 934-4번지 일원</p>
							</article>
							<article>
								<p class="circle">운영대상 및 운영시간</p>
								<table class="default-table-content-th">
									<caption class="sr-only">운영대상 및 운영시간 정보 테이블</caption>
									<colgroup>
											<col style="width:20%" />
											<col style="width:40%" />
											<col style="width:40%" />
									</colgroup>
									<thead>
											<tr>
												<th scope="col">구분</th>
												<th scope="col">공유주차(야간)</th>
												<th scope="col">전일제</th>
											</tr>
									</thead>
									<tbody> 
											<tr>
												<td scope="row" class="bg-gray">시간</td>
												<td>17:00 ~ 익일 10:00</td>
												<td>24시간</td>
											</tr>
											<tr>
												<td class="bg-gray">면수</td>
												<td>10면</td>
												<td>56면</td>
											</tr>											
									</tbody>
								</table>
							</article>						
							<article>
								<p class="circle">운영계획</p>
								<ul class="indent">
									<li>[공지] 2022년도 제4회 거주자 우선 주차제 신청 안내</li>
									<li>{공지사항 참고}</li>
								</ul>
							</article>
							<article>
								<p class="circle">선정방법</p>
								<p class="indent">고득점자 + 추첨제 혼합 방식</p>
							</article>
							<article>
								<p class="circle">신청자격</p>
								<p class="indent">진안동에 주민등록을 두고, 실제 거주하는 주민 또는 사업자(근로자)면 가능</p>
							</article>
							<article>
								<p class="circle">신청제외</p>
								<ul>
									<li class="indent">자동차운수사업법상 차고지 의무 확보 차량</li>
									<li class="indent">16인승 이상 승합차량 및 건설기계 등 특수차량</li>
									<li class="indent">주차구획 내 주차불가 차량(차량길이 및 너비가 해당 주차구획 초과 차량)</li>
								</ul>
							</article>
							<article>
								<p class="circle">공통제출서류</p>
								<ul>
									<li class="indent">사용신청서</li>
									<li class="indent">개인정보 수집-이용 및 제공동의서</li>
									<li class="indent">자동차등록증 사본</li>
								</ul>
							</article>
							<article>
								<p class="circle">신청자 구분에 따른 필수제출 서류</p>
								<ul>
									<li class="indent">거주자: 주민등록 초본(과거이력 포함)</li>
									<li class="indent">사업자: 사업자등록증 사본</li>
									<li class="indent">근로자: 근무기간 명시되어 있는 재직증명서</li>
								</ul>
							</article>
							<article>
								<p class="circle">서류제출방법</p>
								<p class="indent">팩스(031-357-6585) 또는 전자우편(hsparking2@hsuco.or.kr)</p>
							</article>
							<article>
								<p class="circle">문의</p>
								<table class="vertical-table">
									<caption class="sr-only">문의</caption>
									<colgroup>
										<col style="width:20%" />
										<col style="width:80%" />
									</colgroup>
									<tbody>
										<tr>
											<th scope="row">문의</th>
											<td>화성도시공사 주차운영2부(TEL. 031-357-6548) </td>
										</tr>										
									</tbody>
								</table>
							</article>
						</section>
						<section v-show="currentTab == 2">
							<article>
								<p class="circle">주차장 요금안내(거주자우선)</p>
							</article>
							<article>
								<table class="default-table-list-view exception">
									<caption class="sr-only">주차장 요금안내(거주자우선)</caption>
									<colgroup>
										<col style="width:10%" />
										<col style="width:22.5%" />
										<col style="width:22.5%" />
										<col style="width:22.5%" />
										<col style="width:22.5%" />
									</colgroup>
									<thead>
										<tr>																															 
											<th scope="col">구분</th>
											<th scope="col">전일제</th>
											<th scope="col">공유주차(야간)</th>
											<th scope="col">공유주차(주간)</th>
											<th scope="col">미신청 주차면</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td scope="row">적용시간</td>
											<td>24시간(전일)</td>
											<td>17:00 ~ 익일 10:00</td>
											<td>10:00 ~ 17:00</td>
											<td>10:00 ~ 23:00</td>
										</tr>
										<tr>
											<td scope="row">요금제</td>
              				<td>30,000원/월</td>
											<td>15,000원/월</td>
											<td colspan="2">
												<ul>
													<li>공영주차장 구분에 따른 해당지역</li>
													<li>공영주차장 요금 적용</li>
												</ul>
											</td>
										</tr>
										<tr>
											<td scope="row">비고</td>
											<td colspan="2">3개월 단위 운영 </td>
											<td colspan="2">
											<ul>
												<li>미신청 주차면 야간 무료</li>
												<li>(23:00 ~ 익일 10:00)</li>
											</ul>
											</td>
										</tr>
									</tbody>
								</table>
							</article>
						</section>
					</div>
				</div>	
			</article>	
		</section>
	</main>
</template>

<script>
export default {
	name: '#tabs',
	data(){
		return {
			inputText   : '',
			currentTab  : 0,
			//showContent : false,
			showContent : true,
			tabs        : [ '신청안내(병점)', '신청안내(진안)' , '요금 기준']
		}
	},
	methods:{
		searchbtn(){
			this.showContent = true;
		},
		residentagreement(){
			this.$router.push('/residentagreement')
		}
	},
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>