<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle" title="무료차량 관리" class="active">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist" title="정기권 주차장 관리">정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement" title="자주하는 질문 관리">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist" title="팝업 관리">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>무료차량 관리</h4>
			</article>
			<article>
				<h3>무료차량 관리</h3>
				<table class="vertical-table">
					<caption class="sr-only">무료차량 관리 등록 페이지</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">사용자명</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="사용자명" class="place-hide" v-model="carOwnerNm">
										<div class="error" v-if="!$v.carOwnerNm.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">소속</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="소속" class="place-hide" v-model="orgNm">
										<div class="error" v-if="!$v.orgNm.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">차량번호</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="차량번호" class="place-hide" v-model="carNo">
										<div class="error" v-if="!$v.carNo.required">필수 입력란입니다.</div>
										<div class="error" v-if="!$v.carNo.carNoCheck">차량번호 형식에 맞지 않습니다.</div>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">주차장</th>
							<td class="select-area">
								<label for=""></label>
								<select name="" id="" v-model="sectnId">
									<option value="" selected>주차장을 선택하세요.</option>
									<option v-for="parking in parkingList" :key="parking.SECTN_ID" :value="parking.SECTN_ID">{{parking.PARKING_NM}}</option>
								</select>
								<div class="error" v-if="!$v.sectnId.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">신청기간</th>
							<td class="application">
								<date-picker @emitSetDate="setDate" v-bind:propsDate="propsDateArr"></date-picker>
							</td>
						</tr>
						<tr>
							<th scope="row" class="asterisk-after">비고(용도)</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="비고(용도)" class="place-hide" v-model="freeNote">
										<div class="error" v-if="!$v.freeNote.required">필수 입력란입니다.</div>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="btn-list right">
				<button class="btn" @click="cancelBtn">목록</button>
				<button class="btn-border" @click="updateBtn">수정</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import { required, helpers, or } from 'vuelidate/lib/validators'
import DatePicker from '../../../components/DatePicker3.vue';

const carNoRegex     = helpers.regex('carNoRegex', /^\s*([가-히]{2}){0,1}\s*[0-9]{2,3}[\s]*[가-히]{1}[\s]*[0-9]{4}\s*$/gi);
const carNoRegexDipl = helpers.regex('carNoRegexDipl', /^\s*([외교,영사,준외,협정,국기,준영,대표]{2}){0,1}\s*[0-9]{3}[\s]*[-]{1}[\s]*[0-9]{3}\s*$/gi); //외교차량 정규식

const instance = axios.create({
  timeout: 30000,
});



export default {
	data(){
		return {
			parkingList: [],
			freeCarId: '',
			carOwnerNm: '',
			orgNm: '',
			carNo: '',
			sectnId: '',
			startDt: '',
			endDt: '',
			freeNote: '',
			submitStatus: '',
			propsDateArr: [],
		}
	},

	validations: {
		carOwnerNm: {
			required
		},
		orgNm: {
			required
		},
		carNo: {
			required,
			carNoCheck: or(carNoRegex, carNoRegexDipl)
		},
		sectnId: {
			required
		},
		freeNote: {
			required
		}
	},

	created() {
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("freeCarData"));
		}else{
			localStorage.setItem("freeCarData", JSON.stringify(data));
		}

		this.freeCarId = data.freeCarId;
		this.carOwnerNm = data.carOwnerNm;
		this.orgNm = data.orgNm;
		this.carNo = data.carNo;
		this.sectnId = data.sectnId;
		this.startDt = data.startDt;
		this.endDt = data.endDt;
		this.freeNote = data.freeNote;
		this.propsDateArr = {
			startDate: this.startDt,
			endDate: this.endDt
		};
	},

	mounted() {
		this.selectParkingList();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		updateBtn(){

			if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("freeCarId", this.freeCarId),
				apiParams.append("carOwnerNm", this.carOwnerNm),
				apiParams.append("orgNm", this.orgNm),
				apiParams.append("carNo", this.carNo),
				apiParams.append("sectnId", this.sectnId),
				apiParams.append("startDtString", this.startDt),
				apiParams.append("endDtString", this.endDt),
				apiParams.append("freeNote", this.freeNote)
	
				instance.post('/api/admin/freecar/updateAction.json', apiParams)
				.then((response => {
					let message = response.data.message;
	
					alert(message);
					this.$router.push('freevehicle');
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		setDate(sd, ed){
			this.startDt = sd;
			this.endDt   = ed;
		},

		selectParkingList(){
			instance.get('/api/parking/listChargedParking.json')
			.then((response => {
				var data = response.data;
				this.parkingList = data.chargedParkingList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			})
		},

		cancelBtn(){
			this.$router.push('freevehicle');
			localStorage.clear();
		},
	},

	components : {
		DatePicker,	
	}
};


</script>

<style>

</style>