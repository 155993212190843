<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement"    title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist"    title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist"          title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist"          title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist"  title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist"          title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu"        title="메뉴생성 관리" class="active">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist"       title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist"     title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist"          title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist"         title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo"        title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>메뉴생성관리</h4>
			</article>
			<article>
				<div class="tab-content">
					<article>
						<h3>메뉴생성관리</h3>
					</article>
					<article>
						<table class="lookup-table">
							<caption class="sr-only">메뉴생성 테이블</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:80%" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">권한코드</th>
									<td>
										<ul class="input-style">
											<li>
												<input type="text" v-model="authorCode" placeholder="" class="place-hide" readonly>
											</li>
											<li>
												<button class="table-btn" @click="openMenuCreatePopup">메뉴생성</button>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</article>
					<article class="tree-structure">

						<ul>
							<li>
								<a class="folder_position depth-image menu-modal">
									<input type="checkbox" id="allCheck" v-model="allChecked" @click="checkedAll">
									<label for="allCheck">전체선택</label>
								</a>
							</li>
							<li v-for="(list, index) in treeNodes" :key="index">

								<template v-if="hasChildTreeNode(list.menuNo)">
									
								</template>
								<template v-else>
									<!-- 3depth 시작 / 종료 -->
									<template v-if="lastTreeSibling(list.menuNo, list.upperMenuId)">
										<img src="../../../assets/images/menu/menu_joinbottom.gif" alt="3depth 마지막 모달">
									</template>
									<template v-else>
										<img src="../../../assets/images/menu/menu_join.gif" alt="3depth 시작 모달">
									</template>
								</template>
								
								<a class="folder_position depth-image menu-modal">
									<!-- <input type="checkbox" v-if="list.chkYeoBu == 1" v-bind:id="index" name="checkField" class="check2" @change="fCheckDir('checkField', list.menuNo, index)" v-bind:value="list.menuNo" checked>
									<input type="checkbox" v-else v-bind:id="index" name="checkField" class="check2" @change="fCheckDir('checkField', list.menuNo, index)" v-bind:value="list.menuNo" >
									<label v-bind:for="index">{{ list.menuNm }}</label> -->
									<input type="checkbox" :id="'itemCheck_' + list.menuNo" v-model="checkedValues" :value="list.menuNo">
									<label :for="'itemCheck_' + list.menuNo">{{ list.menuNm }}</label>
								</a>

							</li>
						</ul>

					</article>
				</div>
			</article>	
		</section>

		<section class="modal-manager createmenu" v-if="menuCreatePopupStatus">
			<article class="title">
				<ul>
					<li>안내</li>
					<li @click="closeMenuCreatePopup" class="popup-close"></li>
				</ul>
			</article>
			<article class="content">
				<section>
					<article>
						<ul>
							<li><p class="default">메뉴를 생성 하시겠습니까?</p></li>
						</ul>
					</article>
					<article class="btn-list">
						<button class="popup-btn" @click="menuCreatInsert">예</button>
						<button class="popup-btn-border" @click="closeMenuCreatePopup">아니오</button>
					</article>
				</section>
			</article>
		</section>
		
	</main>
</template>

<script>
import axios from 'axios';
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return {
			nodata                : false,
			listData              : [],
			authorCode            : this.$route.query.authorCode,
			menuCreatePopupStatus : false,

			treeNodes             : new Array(),
			treeIcons             : new Array(6),
			openTreeNodes         : new Array(),

			htmlValues            : [],
			checkedValues         : [],
			allChecked            : false,

			htmlMenuTreeTit       : "",
		}
	},

	mounted() {
		this.searchBtn();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {

		closeMenuCreatePopup() {
			this.menuCreatePopupStatus = false;
		},

		openMenuCreatePopup() {
			this.menuCreatePopupStatus = true;
		},

		searchBtn() {
			instance.get('/api/menu/menuCreatSelect.json', {
				params : {
					authorCode : this.authorCode
				}
			})
			.then((response => {
				var data = response.data;

				if(data.resultList && data.resultList.length> 0){
					this.listData  = data.resultList;
					this.treeNodes = data.resultList;
					this.nodata    = false;
					
					this.listData.forEach(e => {
						e.chkYeoBu == '1' ? this.checkedValues.push(e.menuNo) : null;
					})
				} else {
					this.listData  = [];
					this.treeNodes = [];
					this.nodata    = true;
				}

			}))
			//.catch(function (error) {
			//	//console.log(error);
			//	alert("서버와 통신중 오류가 발생하였습니다.")
			//});
		},

		// [0] 노드, 트리 구성 이미지 정보
		preloadIcons(){
			this.treeIcons[0] = new Image();
			this.treeIcons[0].src = require("../../../assets/images/menu/menu_plus_new.gif");
			this.treeIcons[1] = new Image();
			this.treeIcons[1].src = require("../../../assets/images/menu/menu_plusbottom_new.gif");
			this.treeIcons[2] = new Image();
			this.treeIcons[2].src = require("../../../assets/images/menu/menu_minus_new.gif");
			this.treeIcons[3] = new Image();
			this.treeIcons[3].src = require("../../../assets/images/menu/menu_minusbottom_new.gif");
			this.treeIcons[4] = new Image();
			this.treeIcons[4].src = require("../../../assets/images/menu/menu_folder_new.gif");
			this.treeIcons[5] = new Image();
			this.treeIcons[5].src = require("../../../assets/images/menu/menu_folderopen_new.gif");
		},

		// 하위 트리노드 존재 여부 확인
		hasChildTreeNode(parentNode){
			for(var i = 0 ; i < this.treeNodes.length ; i++){
				var nodeValues = this.treeNodes[i];
				if(nodeValues.upperMenuId == parentNode){
					return true;
				}
			}
			return false;
		},

		// 트리노드 최하위 여부 확인
		lastTreeSibling(node, parentNode){
			var lastChild = 0;
			for(var i = 0 ; i < this.treeNodes.length ; i++){
				var nodeValues = this.treeNodes[i];
				if(nodeValues.upperMenuId == parentNode){
					lastChild = nodeValues.menuNo;
				}
			}
			if(lastChild == node){
				return true;
			}else{
				return false;
			}
		},

		// 트리노드 오픈 여부 확인
		isTreeNodeOpen(node){
			if(this.treeOpenYn){
				return true;
			}
			for(var i = 0 ; i < this.openTreeNodes.length ; i++){
				if(this.openTreeNodes[i] == node){
					return true;
				}
			}
			return false;
		},

		// 모두선택 처리 함수
		fCheckDir(fCheckYB, fValue, fPath){
			//console.dir(fCheckYB);
			//console.dir(fValue);
			//console.dir(fPath);
			var checkField = document.getElementsByName(fCheckYB);
		},

		menuCreatInsert(){
			let apiParams = new FormData();
			apiParams.append("checkedAuthorForInsert", this.authorCode);
			apiParams.append("checkedMenuNoForInsert", this.checkedValues);
	
			instance.post('/api/menu/menuCreatInsert.json', apiParams)
			.then((response => {
				let message = response.data.message;
	
				this.menuCreatePopupStatus = false;
				alert(message);
				this.searchBtn();
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		checkedAll() {
			if(!this.allChecked) {
				this.checkedValues = [];

				this.listData.forEach(e => {
					this.checkedValues.push(e.menuNo);
				});
			} else {
				this.checkedValues = [];
			}
		},

	}
}
</script>

<style scope>
	.default-table tr td{ font-weight : 300; }

	.tree-tit.depth1 {
		left : 0;
	}

	.tree-tit {
		position      : relative;
		margin-left   : 5px;
		margin-bottom : 10px;
		left          : 20px;
		width         : calc(10% + 8px);
		min-width     : 160px;
	}

	.tree-tit.depth1 .hide_btn,
	.tree-tit.depth1 .show_btn {
		right : -35px !important;
	}

	.tree-tit > .hide_btn {
		display : none;
	}

	.tree-tit > .show_btn,
	.tree-tit > .hide_btn {
		position      : absolute;
		border        : 1px solid;
		border-radius : 3px;
		top           : 0;
		right         : 5px;
		width         : 35px;
		height        : 15px;
		text-align    : center;
		font-size     : 10px;
		line-height   : 12px;
	}


</style>