<template>
	<main id="subTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청</h4>
			</article>
			<article>
				<h3>04. 희망주차장 정보</h3>
				<table class="vertical-table mo">
					<caption class="sr-only">희망주차장 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row" class="asterisk-after">희망주차장 구간</th>
							<td class="select-area hope">
								<div>
									<label for="parkingLot" class="hide">희망주차장 구간</label>
									<select name="" id="parkingLot" v-model="dongId" @change="selectParkingSectnList">
										<option value="" selected>주차하려는 동을 선택 하세요.</option>
										<option v-for="dong in dongList" :key="dong.dongId" :value="{id:dong.dongId, text:dong.dongNm}">{{dong.dongNm}}</option>
									</select>
									<div class="error" v-if="!$v.dongId.required">필수 입력란입니다.</div>
								</div>
								<div>
									<label for="parkingArea" class="hide">주차구간 선택</label>
									<select name="" id="parkingArea" v-model="sectnId" @change="actSelectParkingLength">
										<option value="" selected>주차 구간을 선택하세요.</option>
										<option v-for="sectn in sectnList" :key="sectn.SECTN_ID" :value="{id:sectn.SECTN_ID, text:sectn.SECTN_NM, assignMethod:sectn.ASSIGN_METHOD}">{{sectn.SECTN_NM}}</option>
									</select>
									<div class="error" v-if="!$v.sectnId.required">필수 입력란입니다.</div>
								</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">이용 시간 선택</th>
							<td class="select-area hope">
								<div>
									<label for="useTime" class="hide">이용시간 선택</label>
									<select name="" id="useTime" v-model="useTime">
										<option value="" selected>선택하세요.</option>
										<option :value="{id:'UTM-002', text:'주간'}">주간</option>
										<option :value="{id:'UTM-003', text:'야간'}">야간</option>
										<option :value="{id:'UTM-001', text:'전일'}">전일</option>
									</select>
									<div class="error" v-if="!$v.useTime.required">필수 입력란입니다.</div>
								</div>
								<div>
									<p class="orange">이용 시간을 선택해주세요.</p>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
			<article class="paragraph">
				<p>거주자우선주차 신청정보와 주민등록(재직증명)상의 정보 주소, 전입(입사)일자가 다른 경우 배정취소 됩니다.</p>
				<p>거주자우선주차 신청완료 후에 변경은 부천도시공사를 통하여 할 수 있으니 다시 한번 입력 정보를 확인하여 주시기 바랍니다.</p>
			</article>
			<article class="btn-list">
				<button class="btn" @click="previousBtn">이전</button>
				<button class="btn-border" @click="insertBtn">다음</button>
			</article>		
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators'


const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			userNm: '',
			userAddress: '',
			useHpPhone: '',
			useTelPhone: '',
			carNumber: '',
			carOwnerNm: '',
			// registerNumber1: '',
			// registerNumber2: '',
			discountCd: '',
			renYnCheckBox: '',
			applicant: '',
			zipCode: '',
			address: '',
			addressDetail: '',
			movingDt: new Date().toISOString().substr(0, 10),
			dongId: {
				id: '',
				text: ''
			},
			// sectnId: '',
			sectnId: {
				id: '',
				text: '',
				assignMethod: ''
			},
			cellId: {
				id: '',
				text: ''
			},
			useTime: '',
			carKind: '',
			carDisplcmnt: '',
			parkingLength: '',
			dongList: [],
			sectnList: [],
			submitStatus: ''
		}
	},

	validations: {
		dongId: {
			required
		},
		sectnId: {
			required
		},
		useTime: {
			required
		}
	},

	created() {
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("residentApplData"));
		}else{
			localStorage.setItem("residentApplData", JSON.stringify(data));
		}

		if(localStorage.getItem("map") != null){
			this.setMapParking();
			//console.log('setMapParking');
		}

		this.userNm = data.userNm;
		this.userAddress = data.userAddress;
		this.useHpPhone = data.useHpPhone;
		this.useTelPhone = data.useTelPhone;
		this.carNumber = data.carNo;
		this.carOwnerNm = data.carOwnerNm;
		// this.registerNumber1 = data.registerNumber1;
		// this.registerNumber2 = data.registerNumber2;
		this.discountCd = {id: data.discountCd, text: data.discountNm};
		// this.discountNm = data.discountNm;
		this.renYnCheckBox = data.renYnCheckBox;
		this.applicant = {id: data.applicant, text: data.applicantNm};
		// this.applicant = data.applicant;
		// this.applicantNm = data.applicantNm;
		this.zipCode = data.zipCode;
		this.address = data.address;
		this.addressDetail = data.addressDetail;
		this.movingDt = data.movingDt;
		// this.dongId = data.dongId;
		// this.dongNm = data.dongNm;
		// this.sectnId = data.sectnId;
		// this.sectnNm = data.sectnNm;
		this.useTime = data.useTime;
		this.useTimeNm = data.useTimeNm;
	},

	mounted() {
		this.selectParkingDongList();
		this.selectParkingSectnList();
		
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		selectParkingDongList(){
			instance.get('/api/resident/selectParkingDongList.json')
			.then((response => {
				var data = response.data;
				this.dongList = data.resultDongList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			})
		},

		selectParkingSectnList(){
			instance.get('/api/resident/selectParkingSectnList.json', {
				params: {
					paramsDongId: this.dongId.id
				}
			})
			.then((response => {
				var data = response.data;
				this.sectnList = data.resultSectnList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			})
		},

		actSelectParkingLength() {
			let apiParams = new FormData();
			apiParams.append("sectnId", this.sectnId.id);
			apiParams.append("address", this.address + ' ' + this.addressDetail);

			instance.post('/api/resident/actSelectParkingLength.json', apiParams)
			.then((response => {
				const data = response.data.resultMap;

				this.parkingLength = data;
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		previousBtn() {
			this.$router.push('residentregistration');
		},

		insertBtn() {
			this.sectnYnCheck();
		},

		sectnYnCheck() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR"
			}else {
				this.submitStatus = "OK";
				let apiParams = new FormData();
				apiParams.append("sectnId", this.sectnId.id);

				instance.post('/api/resident/sectnYnCheck.json', apiParams)
				.then((response => {
					const sectnYn = response.data.yn;
					if(sectnYn == 1){
						this.carNoDupCheck();
					} else{
						alert('선택하신 구간은 신청 기한이 만료되었습니다.');
					}
				}))
				.catch(function (error) {
					//console.log(error);
					alert("서버와 통신중 오류가 발생하였습니다.")
				})
			}
		},

		carNoDupCheck() {
			let apiParams = new FormData();
			apiParams.append("carNo", this.carNumber);
			apiParams.append("dongId", this.dongId.id);
			apiParams.append("sectnId", this.sectnId.id);

			instance.post('/api/resident/carNoDupCheck.json', apiParams)
			.then((response => {
				const data = response.data.resultMap.data;

				if(data.useYn == "NO"){
					alert("이미 신청완료된 차량입니다.\n신청자 : " + data.appcntNm + "님\n차량번호 : " + data.dupCarNo + "\n신청일시 : " + data.appcntDt);
				} else {
					if(data.useYn == "MAX") {
						alert("신청접수 대기자로 2건이상 존재합니다. 신청불가.\n차량번호 : " + data.dupCarNo);
					}else if(data.useYn == "OK") {
						this.validationCheck();
					}
				}
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		validationCheck() {
			let apiParams = new FormData();
			// apiParams.append("authNoF", this.registerNumber1);
			// apiParams.append("authNoB", this.registerNumber2);
			apiParams.append("carNo", this.carNumber);
			apiParams.append("carOwnerNm", this.carOwnerNm);
			apiParams.append("discountCd", this.discountCd.id);
			apiParams.append("renYn", this.renYnCheckBox);

			instance.post('/api/resident/validationCheck.json', apiParams)
			.then((response => {
				const data = response.data.resultMap;

				// this.carSpecCode = data.carSpec.code;
				// $("#dcFlag").val(data.dcFlag);

				// 차적 조회
				if(data.carSpec.code == "00"){			// 성공
					this.carKind = data.carSpec.carKind;
					this.carDisplcmnt = data.carSpec.carDisplcmnt;
				}else if(data.carSpec.code == "98"){		// 데이터 없음
					this.carKind = '';
					this.carDisplcmnt = '';
					alert('차적 정보가 존재하지 않습니다.\n소유주 정보(차량번호/이름/주민등록번호)를 정확하게 입력하여 주세요.');
				}else{										// 실패
					this.carKind = '';
					this.carDisplcmnt = '';
					alert('차적 정보 조회에 실패하였습니다. 다시 시도하여 주세요.');
				}

				// 할인대상 체크
				if(data.dcFlag == true){ 
					if(data.carSpec.code != "98" && data.carSpec.code != "99"){		// 할증체크
						let params = {
							userNm : this.userNm,
							userAddress : this.userAddress,
							useHpPhone : this.useHpPhone,
							useTelPhone : this.useTelPhone,
							carNo : this.carNumber,
							carOwnerNm : this.carOwnerNm,
							// registerNumber1 : this.registerNumber1,
							// registerNumber2 : this.registerNumber2,
							discountCd : this.discountCd.id,
							discountNm : this.discountCd.text,
							renYnCheckBox : this.renYnCheckBox,
							applicant : this.applicant.id,
							applicantNm : this.applicant.text,
							zipCode : this.zipCode,
							address : this.address,
							addressDetail : this.addressDetail,
							movingDt : this.movingDt,
							dongId : this.dongId.id,
							dongNm : this.dongId.text,
							sectnId : this.sectnId.id,
							sectnNm : this.sectnId.text,
							useTime : this.useTime.id,
							useTimeNm : this.useTime.text,
							submitStatus : this.submitStatus,
							carKind : this.carKind,
							carDisplcmnt : this.carDisplcmnt,
							assignMethod : this.sectnId.assignMethod,
							parkingLength : this.parkingLength
						}

						this.$router.push({
							name : 'residentconfirmation',
							params: {data : params}
						})
					}
				}else{
					alert(this.carOwnerNm + '님은 [' + this.discountCd.text + '] 대상이 아닙니다.');
					return false;
				}
			}))
			.catch(error => {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		setMapParking() {
			const map = JSON.parse(localStorage.getItem("map"));

			this.dongId.id   = map.dongId;
			this.dongId.text = map.dongNm;

			this.sectnId.id = map.sectnId;
			this.sectnId.text = map.sectnNm;
			this.sectnId.assignMethod = map.assignMethod;

			this.cellId.id = map.cellId;
			this.cellId.text = map.cellNm;

			this.mapIsAllowedPeriod = map.isAllowedPeriod;
		}

	}

	

};
</script>

<style>

</style>