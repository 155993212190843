<template>
  <section class="privacy-policy">
      <article>
        화성시에서는 개인정보보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. 또한 개인정보보호법 제25조 제7항에 의한 영상정보처리기기(CCTV) 운영방침은 담당부서 홈페이지를 통해 공개하고 있습니다.
      </article>
      <section>
        <article>
          <section>
            <h3>제1조(개인정보의 처리목적)</h3>
            <article>
              <p>
                ① 화성시홈페이지는 다음의 목적을 위하여 개인정보를 처리합니다.
              </p>
              <ol type="1">
                  <li>
                    홈페이지 회원 가입 및 관리
                    <p>
                      회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별/인증, 회원자격 유지/관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종 고지/통지, 고충처리 등
                    </p>
                  </li>
                  <li>
                    민원사무 처리
                    <p>
                      민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락/통지, 처리결과 통보 등
                    </p>
                  </li>
                  <li>
                    콜센터 상담업무 처리 및 분석?관리
                    <p>
                      전화상담, 문자상담, 예약상담 등
                    </p>
                  </li>
              </ol>
            </article>
            <article>
              <p>
                ② 기타 화성시가 관리하고 있는 개인정보파일의 처리목적은 아래의 링크 및 방법을 통하여 확인하실 수 있습니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용하지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행하고 있습니다.
              </p>
              <ul>
                <li>
                  ☞ 개인정보보호포털(www.privacy.go.kr) -> 민원마당 -> 개인정보열람등 요구 -> 개인정보 열람등 요구신청 -> 기관명 “화성시” 검색 후 “경기도 화성시” 선택
                </li>
                <li class="reference">
                  화성시는 개인정보보호법 제32조에 따라 개인정보파일을 보호위원회에 등록/공개 하고 있습니다.
                </li>
              </ul>
            </article>
            <article>
              <p>
                ③ 단, 법 제15조3항, 제17조4항에 따라 아래 사항을 고려하여 개인정보를 처리함에 있어 별도의 동의를 받지 아니할 수 있습니다.
              </p>
              <ol type="1">
                <li>당초 수집 목적과 관련성이 있는지 여부</li>
                <li>개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부</li>
                <li>정보주체의 이익을 부당하게 침해하는지 여부</li>
                <li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
              </ol>
            </article>
          </section>
          <section>
            <h3>제2조(개인정보의 처리 및 보유기간)</h3>
            <article>
              <p>
                ① 화성시는 법령에 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
              </p>
            </article>
            <article>
              <p>
                ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
              </p>
              <ol type="1">
                <li>
                  홈페이지 회원 가입 및 관리 : 공공기관 홈페이지 탈퇴시까지 ※ 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
                  <ul>
                    <li>1) 관계 법령 위반에 따른 수사/조사 등이 진행중인 경우에는 해당 수사/조사 종료시 까지</li>
                    <li>2) 홈페이지 이용에 따른 채권/채무관계 잔존시에는 해당 채권/채무관계 정산시 까지</li>
                  </ul>
                </li>
                <li>
                  콜센터 상담업무 처리 및 분석·관리 : 1년
                </li>
                <li>
                  기타 개인정보 처리 및 보유기간은 <a href="https://www.privacy.go.kr/" alt="개인정보 포털">개인정보 포털(www.privacy.go.kr)</a>에서 제공하는 화성시 개인정보 파일목록을 참조하시기 바랍니다.
                </li>
              </ol>
            </article>
          </section>
          <section>
            <h3>제3조(개인정보의 제3자 제공)</h3>
            <article>
              <p>
                ① 화성시는 원칙적으로 이용자의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서 처리하며, 이용자의 사전 동의 없이 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. 다만, 다음의 경우는 예외로 처리됩니다.
              </p>
              <ol type="1">
                <li>다른 법률에 특별한 규정이 있는 경우</li>
                <li>범죄의 수사와 같이 개인정보 보호법 제 18조 2항에 해당되는 경우</li>
                <li>이용자가 사전에 제3자 제공 및 공개에 동의한 경우</li>
                <li>
                  서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적·기술적인 사유로 통상의
                </li>
                <li>동의를 받는 것이 현저히 곤란한 경우</li>
                <li>개인을 식별하기에 특정할 수 없는 상태로 가공하여 이용하는 경우</li>
              </ol>
            </article>
            <article>
              <p>
                ② 화성시 개인정보의 제3자 제공 및 목적 외 이용내역은 화성시 대표홈페이지를 통해 다음과 같이 공개 하고 있습니다.
              </p>
              <ul>
                <li>☞ 행정정보 &gt; 알림정보 &gt; 공고/고시 &gt; 개인정보 제공/위탁을 통해서 공개</li>
              </ul>
            </article>
          </section>
          <section>
            <h3>제4조(개인정보처리의 위탁)</h3>
            <article>
              <p>
                ① 화성시는 원활한 업무처리를 위하여 필요시 개인정보 처리업무를 위탁하고 있습니다.
              </p>
              <ul>
                <li class="reference">
                  개인정보 처리 위탁내역은 행정정보 &gt; 알림정보 &gt; 공고/고시 홈페이지를 통해서 공개
                </li>
              </ul>
            </article>
            <article>
              <p>
                ② 화성시는 위탁계약 체결시 개인정보보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적/관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리/감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
              </p>
            </article>
            <article>
              <p>
                ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 부서별로 화성시 대표홈페이지를 통해 공개하고 있습니다.
              </p>
            </article>
          </section>
          <section>
            <h3>제5조(정보주체의 권리/의무 및 행사방법)</h3>
            <article>
              <p>① 정보주체는 언제든지 화성시에 대해 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
              <ol type="1">
                <li>개인정보 열람요구</li>
                <li>오류 등이 있을 경우 정정요구</li>
                <li>삭제요구</li>
                <li>처리정지요구</li>
                <li>정보주체 이외로부터 수집된 정보의 출처 고지 요구</li>
              </ol>
              <p>           	
                화성시는 정보주체 이외로부터 수집한 개인정보를 처리하는 때에는 정보주체의 요구가 있으면 정보주체의 요구가 있는 날로부터 3일 이내에 다음 각 호의 사항을 정보주체에게 알리고 있습니다.
              </p>
              <ul>
                <li>1)개인정보의 수집 출처</li>
                <li>2)개인정보의 처리 목적</li>
              </ul>
            </article>
            <article>
              <p>
                ② 위 제1항에 따른 권리 행사는 화성시에 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 요구하실 수 있으며 화성시는 이에 대해 지체없이 조치하겠습니다. 다만, 다음의 경우 청구사항에 대하여 제한하거나 거부할 수 있으며 이 경우 10일 이내에 정보주체에게 안내하여 드립니다.
              </p>
              <ol type="1">
                <li>법률에 따라 열람이 금지되거나 제한되는 경우</li>
                <li>
                  다른사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그밖에 이익을 부당하게 침해할 우려가 있는 경우
                </li>
                <li>
                  다음 항목의 업무를 수행함에 중대한 지장을 초래하는 경우
                  <ul>
                    <li class="hyphen">조세의 부과·징수 또는 환급에 관한 업무</li>
                    <li class="hyphen">학력·기능 및 채용에 관한 시험, 자격심사에 관한 업무</li>
                    <li class="hyphen">보상금·급부금 산정 등에 대하여 진행중인 평가 또는 판단에 관한 업무</li>
                    <li class="hyphen">다른 법률에 따라 진행중인 감사 및 조사에 관한 업무</li>
                  </ul>
                </li>
                <li>특정 정보주체의 화상정보만을 삭제하는 것이 기술적으로 현저히 곤란한 경우</li>
                <li>기타 열람 등의 요청을 제한하거나 거절할 만한 정당한 공익적 사유가 존재하는 경우</li>
              </ol>
            </article>
            <article>
              <p>
                ③ 정보주체 권리행사시 청구주체 확인을 위하여 주민등록증·운전면허증·여권 등의 신분증명서를 지참하여야 하며, 개인정보 처리 방법에 관한 고시 별지 제8호 서식에 따른 개인정보 열람 요구서를 제출하여야 합니다.
              </p>
              <p><a href="" alt="개인정보 열람 요구서">【별지 서식】  개인정보 열람 요구서</a></p>
            </article>
            <article>
              <p>
                ④ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 화성시는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
              </p>
            </article>
            <article>
              <p>             
                ⑤ 위 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시 제11호 서식에 따른 위임장을 제출하셔야 합니다.
              </p>
              <p><a href="" alt="위임장">【별지 서식】  위임장</a></p>
            </article>
            <article>
              <p>    
                ⑥ 정보주체 권리 행사에 때에 거절 등의 조치에 불복이 있는 경우에는 열람 및 정정·삭제 청구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인해 권익을 피해 받은 경우 제기할 수 있습니다.
              </p>
              <div class="privacy01"></div>
            </article>
            <article>
              <p>
                ⑦ 정보주체의 권리행사 요구 거절 시 불복을 위한 이의제기 절차
              </p>
              <ul>
                <li class="hyphen">    
                  개인정보 관리책임자는 열람 등 요구에 대한 연기 또는 거절 시, 요구 받은 날로부터 10일 이내에 연기 또는 거절의 정당한 사유 및 이의제기 방법 등을 통지합니다.
                </li>
                 <li class="hyphen">     
                   정보주체는 열람등 요구에 대한 거절 등 조치에 대하여 불복이 있는 경우 개인정보 열람등요구 결정 이의신청서 서식으로 이의신청할 수 있습니다.
                </li>
              </ul>
              <p>
                <a href="" alt="개인정보 열람 등 요구 결정 이의신청서">【별지 서식】  개인정보 열람 등 요구 결정 이의신청서</a>
              </p>
            </article>
            <article>
              <p>
                ⑧ 정보주체는 개인정보보호법 등 관계법령을 위반하여 화성시가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다. 
              </p>
            </article>
          </section>
          <section>
            <h3>제6조(처리하는 개인정보 항목)</h3>
            <article>
              <p>
                ①	화성시홈페이지는 다음의 개인정보 항목을 처리하고 있습니다. 
              </p>
              <ol type="1">
                <li>홈페이지 회원 가입 및 관리
                  <ul>
                    <li>필수항목 : 성명, 아이디, 비밀번호, 주소, 전화번호, 이메일주소</li>
                    <li>선택항목 : 결혼여부, 직업, 최종학력, webzin 수신여부, sms수신여부</li>
                  </ul>
                </li>
                <li>민원사무 처리
                  <ul>
                    <li>필수항목 : 성명, 주민등록번호, 전화번호, 주소</li>
                    <li>선택항목 : 이메일</li>
                  </ul>
                </li>
                <li>콜센터 상담업무 처리 및 분석, 관리
                  <ul>
                    <li>필수항목 : 성명, 전화번호, 상담내용, 상담 녹취</li>
                    <li>선택항목 : 지역(혹은 주소), 차량번호, 생년월일, 팩스번호, 이메일</li>
                  </ul>
                </li>
                <li>인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다
                    <ul>
                      <li>IP주소, 방문기록</li>
                    </ul>
                </li>
                <li>
                  기타 화성시가 처리하는 개인정보 항목은 아래의 방법 및 링크를 통하여 확인 하실 수 있습니다. <a href="https://www.privacy.go.kr/" alt="개인정보 포털">개인정보보호포털(www.privacy.go.kr)</a> -> 민원마당 -> 개인정보열람등 요구 -> 개인정보 열람등 요구신청 -> 기관명 “화성시” 검색 후 “경기도 화성시” 선택
                </li>
              </ol>
            </article>
          </section>
          <section>
            <h3>제6조2 (가명정보 처리 및 활용에 관한 사항)</h3>
            <article>
              <p>
                ① 화성시는 「개인정보보호법」제3조에 따라 가명처리 하여도 개인정보 수집목적을 달성할 수 있는 경우 가명에 의하여 처리합니다.
              </p>
            </article>
            <article>
              <p>
                ② 화성시는 정보주체의 가명정보를 「개인정보보호법」 제28조의2에 따라 통계작성, 과학적 연구, 공익적 기록 보존 등에 해당하는 경우 정보주체의 동의 없이 처리할 수 있습니다.
              </p>
            </article>
            <article>
              <p>
                ③ 가명정보취급자가 가명정보를 제3자에게 제공할 경우에는 개인정보 필터링 시스템 등을 이용하여 특정 개인을 알아보기 위하여 사용될 수 있는 정보의 포함 여부를 점검 후 제공합니다.
              </p>
            </article>
            <article>
              <p>
                ④ 화성시는 가명정보 보호를 위한 내부관리계획 수립, 접근통제 및 접근권한 제한, 가명정보의 안전한 저장, 추가정보의 분리보관, 가명처리기록 보관, 보관시설 마련 및 잠금장치 설치 등 기술적·관리적·물리적 조치를 이행합니다.
              </p>
            </article>
            <article>
              <p>
                ⑤ 화성시는 「개인정보보호법」제28조의3에 따라 서로 다른 개인정보처리자 간의 가명정보 결합은 개인정보 보호위원회 또는 관계 중앙행정기관의 장이 지정하는 전문기관에서 진행합니다.
              </p>
            </article>
            <article>
              <p>
                ⑥ 화성시는 이용목적 및 방법 등 환경에 따라 처리 수준을 정의하여 가명처리 및 가명정보 결합을 합니다. 또한 개인정보 필터링 시스템을 이용하여 개인정보 포함여부를 점검하고, 특정 개인을 알아볼 수 있는 정보가 생성된 경우에는 즉시 해당 정보의 처리를 중지하고 지체없이 회수·파기합니다.
              </p>
            </article>
          </section>
          <section>
            <h3>제7조(개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)</h3>
            <article>
              <p>
                ① 화성시는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용하지 않습니다.
              </p>
            </article>
          </section>
          <section>
            <h3>제8조(개인정보의 파기)</h3>
            <article>
              <p>            
                ① 화성시는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
              </p>
            </article>
            <article>
              <p>
                ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보(또는 개인정보파일)을 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
              </p>
            </article>
            <article>
              <p>
                ③개인정보 파기의 절차 및 방법은 다음과 같습니다.
              </p>
              <ol type="1">
                <li>파기절차
                  <p>
                    화성시는 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보 파기계획을 수립한 후 파기 사유가 발생한 개인정보(또는 개인정보파일)를 선정 분야별(부서별) 개인정보보호책임자의 승인을 받아 파기합니다.
                  </p>
                </li>
                <li>파기방법
                  <p>
                    화성시는 전자적 파일 형태로 기록/저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록/저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
                  </p>
                </li>
              </ol>
            </article>
          </section>
          <section>
            <h3>우측화살표	제9조(개인정보의 안전성 확보조치)</h3>
            <article>
              <p>
                ① 화성시는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
              </p>
              <ol type="1">
                <li>
                  관리적 조치 : 내부관리계획 수립/시행, 정기적 직원 교육, 가명처리기록 보관 등
                </li>
                <li>
                  기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치, 접속기록의 보관 및 위변조 방지, 추가정보의 별도 분리 보관 등
                </li>
                <li>
                  물리적 조치: 전산실, 자료보관실 등을 보안통제구역으로 지정 접근통제
                </li>
              </ol>
            </article>
          </section>
          <section>
            <h3>제10조(개인정보보호 책임자)</h3>
            <article>
              <p>
                ① 화성시는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호 책임자를 지정운영하고 있습니다.
              </p>
              <ul>
                <li> ○ 화성시 개인정보보호 책임자
                  <ul>
                    <li class="hyphen">기획조정실장 김종대</li>
                    <li class="hyphen">연락처 : 031) 5189-6313</li>
                  </ul>
                  <p class="reference">개인정보 보호 담당부서로 연결됩니다.</p>
                </li>
                <li> ○ 화성시 개인정보보호 담당자
                  <ul>
                    <li class="hyphen">정보통신과 오효진</li>
                    <li class="hyphen">연락처 : 031) 5189-6313</li>
                    <li class="hyphen">Fax : 031) 5189-1500</li>
                  </ul>
                </li>
                <li> ○ 화성시 홈페이지 담당자
                  <ul>
                    <li class="hyphen">홍보기획관 이선아</li>
                    <li class="hyphen">연락처 : 031)5189-2091</li>
                    <li class="hyphen">Fax : 031)5189-1882</li>
                  </ul>
                </li>
                <li>분야별(부서별) 개인정보보호 책임자(CCTV포함) : 담당 부서장</li>
              </ul>
            </article>
            <article>
              <p>
                ② 정보주체는 화성시의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 화성시는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
              </p>
            </article>
          </section>
          <section>
            <h3>제11조(개인정보 열람청구)</h3>
            <article>
              <p>
                ① 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 담당부서에 하실 수 있습니다.
                화성시는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
              </p>
              <p class="reference">
                화성시 홈페이지에서 “화성시소개 &gt; 시청안내 &gt; 행정조직안내”에서 담당부서 확인
              </p>
            </article>
            <article>
              <p>
                ② 정보주체는 제1항의 열람청구 접수/처리부서 이외에, <a href="https://www.privacy.go.kr/" alt="개인정보 포털">개인정보보호포털 웹사이트(www.privacy.go.kr)</a>를 통해서도 개인정보 열람청구를 하실 수 있습니다.
              </p>
            </article>
            <article>
              <p>
                ③ 개인정보보호포털(www.privacy.go.kr)을 통한 개인정보 열람 등 요구 방법
              </p>
              <ol type="1">
                <li>개인정보보호포털(www.privacy.go.kr)에 접속</li>
                <li>[민원마당] &gt; [개인정보열람 등 요구] &gt; [개인정보 열람 등 청구] 클릭</li>
                <li>본인인증(아이핀 I-PIN인증 또는 휴대폰 인증 방법 선택)</li>
                <li>[개인정보파일 목록검색]을 통한 대상 기관 선택</li>
                <li>신청유형 선택(개인정보 열람, 개인정보 정정·삭제, 개인정보 처리정지 중 택 1)</li>
                <li>신청유형 선택(개인정보 열람, 개인정보 정정·삭제, 개인정보 처리정지 중 택 1)</li>
              </ol>
              <div class="privacy02"></div>
            </article>
          </section>
          <section>
            <h3>제12조(권익침해 구제방법)</h3>
            <article>
              <p>
                ① 정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
              </p>
              <p>
                &lt; 아래의 기관은 화성시와는 별개의 기관으로서, 화성시의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다. &gt;
              </p>
               <ul class="info">
                <li> ○ 개인정보보호포털 (한국인터넷진흥원 위탁운영)
                  <ul>
                    <li class="hyphen">소관업무 : 개인정보 침해사실 신고, 상담 신청, 자료제공</li>
                    <li class="hyphen">홈페이지 : 
                      <a href="https://www.privacy.go.kr" alt="개인정보보호포털">www.privacy.go.kr</a>
                    </li>
                    <li class="hyphen">(전화) 홈페이지‧교육 관련: 02-6952-8650</li>
                  </ul>
                  <p class="reference">법령 문의: 02-2100-2492</p>
                </li>
                <li> ○ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
                  <ul>
                    <li class="hyphen">소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
                    <li class="hyphen">홈페이지 : <a href="https://privacy.kisa.or.kr" alt="개인정보 침해신고센터">privacy.kisa.or.kr</a></li>
                    <li class="hyphen">전화 : (국번없이) 118</li>
                    <li class="hyphen">주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해 신고센터</li>
                  </ul>
                </li>
                <li> ○ 	개인정보 분쟁조정위원회
                  <ul>
                    <li class="hyphen">소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정</li>
                    <li class="hyphen">홈페이지 : <a href="https://www.kopico.go.kr" alt="개인정보 분쟁조정위원회">www.kopico.go.kr</a></li>
                    <li class="hyphen">전화 : 1833-6972</li>
                    <li class="hyphen">주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층</li>
                  </ul>
                </li>
                <li> ○ 경찰청 사이버안전국
                  <ul>
                    <li class="hyphen">소관업무 : 개인정보 침해 관련 사건 문의 및 신고</li>
                    <li class="hyphen">
                      홈페이지 : <a href="https://cyberbureau.police.go.kr" alt="경찰청 사이버안전국">cyberbureau.police.go.kr</a>
                    </li>
                    <li class="hyphen">전화 :(경찰민원 콜센터) 182</li>
                  </ul>
                </li>
               </ul>
            </article>
            <article>
              <p>
                개인정보의 열람, 정정·삭제, 처리정지 등에 대한 정보주체자의 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익을 침해받은자는 행정심판을 청구할 수 있습니다. 행정심판에 대한 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)의 홈페이지를 참고하시기 바랍니다.
              </p>
            </article>
          </section>
          <section>
            <h3>제13조(영상정보처리기기 설치운영)</h3>
            <article>
              <p>
                ① 화성시가 설치·운영하는 영상정보처리기기의 설치근거·목적, 설치대수, 부서 등은 해당부서 홈페이지에서 안내하고 있습니다.
              </p>
            </article>
            <article>
              <p>
                ② 영상정보 촬영시간, 보관기간, 보관장소, 처리방법, 관리책임자는 다음과 같습니다.
              </p>
              <ol type="1">
                <li>촬영시간 : 24시간 촬영</li>
                <li>보관기간 : 촬영 시부터 30일(별도보관규정이 있는 경우는 제외)</li>
                <li>보관장소 및 처리방법 : 담당부서에보관·처리</li>
                <li>관리책임자 : 담당부서장</li>
              </ol>
            </article>
            <article>
              <p>
                ③ 영상정보 확인 방법 및 장소 : 담당부서에 요구
              </p>
            </article>
            <article>
              <p>
                ④ 정보주체의 영상정보 열람 등 요구에 대한 조치는 개인영상정보 열람·존재확인 청구서 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명·신체·재산 이익을 위해 필요한 경우에 한해 열람을 허용합니다.
              </p>
            </article>
            <article>
              <p>            
                ⑤ 영상정보 보호를 위한 기술적·관리적·물리적 조치 : 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장·전송기술 적용, 처리기록 보관 및 위·변조 방지조치, 보관시설 마련 및 잠금장치 설치 등
              </p>
            </article>
          </section>
          <section>
            <h3>제14조(개인정보 처리방침 변경)</h3>
            <article>
              <p>① 이 개인정보 처리방침은 2021. 7. 8. 부터 적용됩니다.</p>
            </article>
            <article>
              <p>② 이전의 개인정보처리 방침 수정 이력은 아래와 같습니다.</p>
              <ul class="change">
                <li class="hyphen">
                  수정일자 : 2021. 4. 5. 끝.
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=c39a8ecf-917f-4f0e-a89c-3a6f342b96d1.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2021. 1. 7. 끝.
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=6f6a2e51-8eeb-4419-946d-98827a3d8c2f.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2020.10.29. 끝.
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=6d08d384-3efb-4eff-8b4b-2a52b85ff419.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2020.03.12. 끝.
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=ce5afc81-19c8-4c79-b3ab-af507b48d856.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2019.07.12. 끝.
                </li>
                <li class="hyphen">
                  수정일자 : 2019.04.15. 끝.
                </li>
                <li class="hyphen">
                  수정일자 : 2019.02.27. 끝.
                </li>
                <li class="hyphen">
                  수정일자 : 2018.04.09. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=e775d9d1-7e72-4342-98a6-efc8b35444c9.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2017.12.15. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=20d381f1-d077-4544-9844-180590ff5292.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2017.11.01. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=042b7e6d-99f8-40a6-9e1a-5ddd4318a1ee.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2017.07.24. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=98015d11-b10c-4dc2-a07a-71345de2d47d.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2017.04.01. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=e91842b4-6282-4ba5-b77e-efe14c4c7db4.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2016.09.01. 끝
                  <span class="direct-view"><a @click="goExternalSite('');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2015.06.30. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=5c4db612-8a7e-4b6d-a948-aed575e69457.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2014.07.01. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=ea4f1deb-6368-4f2b-912e-f7175d8e88fe.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  수정일자 : 2013.04.01. 끝
                  <span class="direct-view"><a @click="goExternalSite('https://www.hscity.go.kr/webcontent/viewer/skin/doc.html?fn=d650ae7b-7a22-4525-a9a1-01aa9770c454.hwp&rs=https://www.hscity.go.kr/webcontent/202112/');">바로보기</a></span>
                </li>
                <li class="hyphen">
                  제정일자 : 2011.09.30. 끝
                </li>
              </ul>
            </article>
          </section>
        </article>
      </section>
  </section>
</template>

<script>
export default {
	methods : {
		goExternalSite(url){
			window.open(url, "_blank");
		}
	}
}
</script>

<style>

</style>