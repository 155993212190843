<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo" title="안내">안내</router-link></li>
				<li><router-link to="residentsearch" title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청" class="active">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup" title="조회/결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 확인</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 신청서 완료</h3>
			</article>
			<article>
				<p class="circle pc-only">신청자  정보</p>
				<table class="default-table-view">
					<caption class="sr-only">거주자 우선 주차장 관련 신청 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>홍길동</td>
						</tr>
						<tr>
							<th>차량번호</th>
							<td>33수3264</td>
						</tr>
						<tr>
							<th>할인</th>
							<td>일반 (할인대상아님)</td>
						</tr>
						<tr>
							<th>주차구역</th>
							<td>화성시</td>
						</tr>
						<tr>
							<th>이용기간</th>
							<td>(야간)2021-09-02 ~ 2021-09-30</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="message">
				<p>선정이 완료되었습니다.</p>
			</article>
			<article class="btn-list">
				<button class="one-btn">확인</button>
			</article>	
		</section>
	</main>
</template>

<script>
export default {
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
};
</script>

<style>

</style>