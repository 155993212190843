<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차 정기권</div>
			<ul>
				<li><router-link to="periodinfo" title="정기권신청 안내">안내</router-link></li>
				<li><router-link to="periodagreement" title="정기권 신청" class="active">정기권 신청</router-link></li>
				<li><router-link to="periodlookup" title="정기권 조회 &#47; 결제">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 정기권</h2>
				<h4>정기권 신청</h4>
			</article>
			<article>
				<h3>정기권 신청</h3>
				<ul>
					<li>화성시청/화성도시공사는 행정안전부 즉시감면 서비스를 활용한 정보제공을 위하여 아래와 같이 개인정보를 수집, 이용 및 제3자 제공하고자 합니다.</li>
					<li>내용을 읽으신 후 동의 여부를 결정하여 주십시오.</li>
				</ul>
			</article>
			<article id="fullConsent">
				<input type="checkbox" id="agreement" v-model="allCheck">
				<label for="agreement" id="fullConsent"  @click="checkAll" @keydown.space="checkAll" @keydown.enter="checkAll">
				<span role="checkbox" aria-checked="false" aria-labelledby="fullConsent" class="hide"></span>
				전체동의</label>
			</article>
			<article>
				<h3 style="margin-top: 10px;">개인정보 이용 동의</h3>

				<privacy-policy></privacy-policy>

				<input type="checkbox" id="consent1" v-model="check1" @change="checkboxCkc()">
				<label for="consent1" id="agreeUse" @keydown.space="check" @keydown.enter="check">
				<span role="checkbox" aria-checked="false" aria-labelledby="agreeUse" class="hide"></span>
				개인정보 수집 및 이용안내를 숙지하였습니다.</label>
			</article>
			
			<article class="btn-list">
				<button class="btn" @click="agreeBtn">동의</button>
				<button class="btn-border" @click="pageIntro">동의안함</button>
			</article>
		</section>
	</main>
</template>

<script>

import PrivacyPolicy from '../../../components/PrivacyPolicy.vue';

export default {
	data(){
		return{
			allCheck: false,
			check1: '',
			showModal: false,
			msg: '',
		}
	},
	components:{
		PrivacyPolicy
	},
	methods:{

		checkAll(event){
			event.preventDefault();
			this.allCheck = !this.allCheck;
			this.check1 = true;

			if(this.allCheck == false){
				this.check1 = false;
			}
		},

		agreeBtn(){
			if(this.check1){
				this.$router.push('/periodapplication')
			}else{
				alert('개인정보이용에 동의하지 않으셨습니다.');
			}
		},

		pageIntro(){
			this.$router.push('/periodinfo');
		},

		carRegistModal(){
			this.showModal = !this.showModal;
		},

		checkboxCkc(){
			if(this.check1 == true){
				this.allCheck = true;
			}else{
				this.allCheck = false;
			}
		},

		check(event){
			event.preventDefault();
			this.check1 = !this.check1;
			
			if(this.check1 == false){
				this.allCheck = false;
			}else{
				this.allCheck = true;
			}
		}
	},
	
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>