<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">무정차 신청</div>
			<ul>
				<li><router-link to="/nonstopinfo" title="무정차 신청" class="active">무정차 신청</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>무정차 신청</h2>
				<h4>결제카드 등록</h4>
			</article>
			<article>
				<div id="tabs">
					<ul class="tab-menu-nonstop">
						<li>신청 안내</li>
						<li>서비스 신청</li>
						<li class="active">결제카드 등록</li>
						<li>차량 등록</li>
					</ul>
					<div class="tab-content">

						<!-- 결제카드 등록 -->
						<ul id="title-next">
							<li><h3>결제카드 등록 정보</h3></li>
							<li>
								<button class="one-btn-border" @click="registration">결제카드 등록</button>
							</li>
						</ul>
						<!-- 결제카드 등록  -->
						<pop-up v-show="showModal" id="paymentCard" class="modal-payment" @emitChangeModal="cardRegistModal()">
							<template #info>
								<li id="paymentNotice" tabindex="1">알림창<span class="hide">결제카드 등록</span></li>
							</template>
							<template #content>
								<p v-html="msg"></p>
							</template>
							<template #button>
								<button class="popup-btn pay" tabindex="1"       @click="cardRegistBtn()">예</button>
								<button class="popup-btn-border pay" tabindex="1" @click="cardRegistModal()">아니요</button>
							</template>
						</pop-up>
						<article>
							<table class="default-table">
								<caption class="sr-only">결제카드 등록 테이블</caption>
								<colgroup>
									<col style="width:45%" />
									<col style="width:20%" />
									<col style="width:20%" />
									<col style="width:15%;min-width: 100px;" />
								</colgroup>
								<thead>
									<tr>
										<th scope="col">결제카드 정보</th>
										<th scope="col">등록일자</th>
										<th scope="col">삭제일자</th>
										<th scope="col">비고</th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="nodata" class="nodate">
										<td colspan="4">등록된 카드정보가 없습니다.</td>
									</tr>
									<tr v-for="(card, index) in cardList" :key="index">
										<td>
											<span class="emphasis-view">{{ card.acquirer }}</span>
											<span v-if="card.disDttm != '' && card.disDttm != null">
												<del>{{ card.batchKey | cardInfoMasking }}</del>
											</span>
											<span v-else>{{ card.batchKey | cardInfoMasking }}</span>
										</td>
										<td >{{ card.approvalDate | datetimeFormat }}</td>
										<td >
											<span  v-if="card.disDttm != '' && card.disDttm != null">
												{{ card.disDttm | longToDate }}
											</span>
											<span  v-else>
												-
											</span>
										</td>
										<td>
											<span v-if="card.disDttm != '' && card.disDttm != null">
												<input type="button" :id="'restore'+ index" class="btn-recovery" @click="showRcvModalOpen(card.applyId, card.approvalNo, card.batchId, card.batchKey), popupFocus($event)" value="서비스 복구">
											</span>
											<span v-else>
												<input type="button" v-if="card.disDttm == '' || card.disDttm == null" :id="'clear' + index" class="btn-delete" @click="showDelModalOpen(card.applyId, card.approvalNo, card.batchId, card.batchKey), clearFocus($event)" value="서비스 해제">
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</article>
						<article>
							<p class="asterisk">결제카드 추가 등록 시 이전에 등록한 신용카드는 자동 서비스가 해제되며, 다시 등록해야 무정차 정산할 수 있습니다.</p>
						</article>
						<article class="btn-list">
							<button class="btn"        @click="goPrevious()">이전</button>
							<button class="btn-border" @click="goNext()">다음</button>
						</article>
					</div>
				</div>
			</article>
		</section>
		<!-- 서비스 해제  -->
		<pop-up v-show="showDelModal" id="clearPopup" class="modal-payment" @emitChangeModal="cardDeleteModal()">
			<template #info>
				<li id="clearNotice" tabindex="1">알림창<span class="hide">(해제)</span></li>
			</template>
			<template #content>
				<p v-html="delMsg"></p>
			</template>
			<template #button>
				<button class="popup-btn cle" tabindex="1"       @click="cardDeleteBtn()">예</button>
				<button class="popup-btn-border cle" tabindex="1" @click="cardDeleteModal()">아니요</button>
			</template>
		</pop-up>

		<!-- 서비스 복구  -->
		<pop-up v-show="showRcvModal" id="restorePopup" class="modal-payment" @emitChangeModal="cardRecoveryModal()">
			<template #info>
				<li id="restoreNotice" tabindex="1">알림창<span class="hide">(복구)</span></li>
			</template>
			<template #content>
				<p v-html="rcvMsg"></p>
			</template>
			<template #button>
				<button class="popup-btn rcv" tabindex="1"       @click="cardRecoveryBtn()">예</button>
				<button class="popup-btn-border rcv" tabindex="1" @click="cardRecoveryModal()">아니요</button>
			</template>
		</pop-up>
	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


import PopUp from '../../../components/PopupType1.vue';

export default {
	data(){
		return {
			applyId       : "",
			applyNm       : "",
			applyAddr     : "",
			applyMobile   : "",
			cardList      : {},
			showModal     : false,
			msg           : '<ul class="info">'
						  +     '<li class="card" tabindex="1">기존에 카드가 등록되어 있다면</li>'
						  +     '<li class="card" tabindex="1">기존 카드들은 서비스 해제가 됩니다.</li>'
						  +     '<li class="card" tabindex="1">결제카드를 등록하시겠습니까?</li>'
						  + '</ul>',
			nodata        : true,
			easypayParams : "",
			showDelModal  : false,
			delMsg        : '<ul class="info">'
						  +     '<li class="clear" tabindex="1">결제카드의 서비스를 "해제"하시겠습니까?</li>'
						  + '</ul>',
			showRcvModal  : false,
			rcvMsg        : '<ul class="info">'
						  +     '<li class="restore" tabindex="1">결제카드의 서비스를 "복구"하시겠습니까?</li>'
						  + '</ul>',
			batchId       : "",
			approvalNo    : "",
			batchKey      : "",
			clientInfo    : [],
			message       : '안녕하세요',
			tab           : "tabindex='1'",
		}
	},
	filters : {
		datetimeFormat : function(x){
			if(x != null){
				var d = x.toString().replace(/[^0-9]/g, '');

				var year  = d.toString().substr(0, 4);
				var month = d.toString().substr(4, 2);
				var day   = d.toString().substr(6, 2);
				var time  = d.toString().substr(8, 2);
				var min   = d.toString().substr(10, 2);

				return year + "-" + month + "-" + day + " " + time + ":" + min;
			}
		},

		longToDate : function(x){
			if(x != null){
				var d = new Date(x);

				var year  = d.getFullYear();
				var month = d.getMonth() + 1;
				month     = (month < 10) ? "0" + month.toString() : month.toString();
				var day   = d.getDate();
				day       = (day < 10) ? "0" + day.toString() : day.toString();

				return year + "-" + month + "-" + day;
			}
		},

		cardInfoMasking : function(x){
			if(x != null){
				var d = x.toString();
				var mask6  = d.toString().replace(d.toString().substr(6, 8), "********");
				return mask6;
			}
		}
	},
	components:{
		PopUp,
	},
	created(){
		
		let data = null;

		if(localStorage.getItem("nonstopData") !== null){
			data = JSON.parse(localStorage.getItem("nonstopData"));
			this.applyAddr   = data.applyAddr;
		}else{
			alert('서비스 신청 정보를 입력해주세요.')
			this.$router.push('/nonstopagree');
		}

		this.applyId = this.$store.state.empNo;
		this.applyNm = this.$store.state.empNm;
		this.applyMobile = this.$store.state.empMobile;

	},
	mounted(){

		instance.get("http://ipinfo.io/json").then(res => {
			this.clientInfo = res.data;
		});
		this.cardInfoList();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		// 결제카드
		const paymentLayer = document.querySelector("#paymentCard"),
		paymentLayerArrow = paymentLayer.querySelectorAll('#paymentNotice, li.card, button.pay'),
		paymentLayerFirst = paymentLayerArrow[0],
		paymentLayerLast = paymentLayerArrow[paymentLayerArrow.length -1];

		const paymentFirstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
					e.preventDefault();
          paymentLayerLast.focus();
			}
		};

		const paymentLastKeydown = e => {
				if (!e.shiftKey && e.key === "Tab") {
						e.preventDefault();
						paymentLayerFirst.focus();
				}
		};

		paymentLayerFirst.addEventListener("keydown", paymentFirstKeydown);
		paymentLayerLast.addEventListener("keydown", paymentLastKeydown);

		//서비스 복구
		const layer = document.querySelector("#restorePopup"),
		layerArrow = layer.querySelectorAll('#restoreNotice, li.restore, button.rcv'),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
					e.preventDefault();
          layerLast.focus();
			}
		};

		const lastKeydown = e => {
				if (!e.shiftKey && e.key === "Tab") {
						e.preventDefault();
						layerFirst.focus();
				}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);
		
		// 서비스 해제
		const clearLayer = document.querySelector("#clearPopup"),
		clearLayerArrow = clearLayer.querySelectorAll('#clearNotice, li.clear, button.cle'),
		clearLayerFirst = clearLayerArrow[0],
		clearLayerLast = clearLayerArrow[clearLayerArrow.length -1];

		const clearFirstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
					e.preventDefault();
          clearLayerLast.focus();
			}
		};

		const clearLastKeydown = e => {
				if (!e.shiftKey && e.key === "Tab") {
						e.preventDefault();
						clearLayerFirst.focus();
				}
		};

		clearLayerFirst.addEventListener("keydown", clearFirstKeydown);
		clearLayerLast.addEventListener("keydown", clearLastKeydown);
	},

	methods : {
		// 등록된 카드 정보 목록 조회
		cardInfoList(){

			var jsonList = [];

			let apiParams = new Map();
			apiParams.set("applyId"    , this.applyId);
			apiParams.set("applyNm"    , this.applyNm);
			apiParams.set("applyAddr"  , this.applyAddr);
			apiParams.set("applyMobile", this.applyMobile);
			apiParams.set("isMobile"   , this.$isMobile());

			instance.get("/api/nonstop/nonstopCardBatchList.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {

				var resData        = res.data;

				var cardList       = resData.result.result.list;
				this.easypayParams = resData.result.easypayParams;

				for(var i = 0 ; i < cardList.length ; i++){
					jsonList.push(cardList[i]);
				}

				if(jsonList.length > 0){
					this.cardList = jsonList;
					this.nodata   = false;
				}else{
					this.cardList = null;
					this.nodata   = true;
				}

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},
		// 카드등록 모달창
		cardRegistModal(){
			this.showModal = !this.showModal;	
		},
	
		registration(){
			this.showModal = true;

			setTimeout(function(){
				document.getElementById('paymentNotice').focus();
			});
		},
		// 카드등록 모달창 - 확인 버튼
		cardRegistBtn(){
			this.showModal = !this.showModal;

			var e = this.easypayParams;

			let easypayParams = new Map();
			easypayParams.set("easypayRequestUrl"	, e.easypayRequestUrl);
			easypayParams.set("mallId"				, e.mallId);
			easypayParams.set("payMethodTypeCode"	, e.payMethodTypeCode);
			easypayParams.set("currency"			, e.currency);
			easypayParams.set("amount"				, e.amount);
			easypayParams.set("clientTypeCode"		, e.clientTypeCode);
			easypayParams.set("returnUrl"			, e.easypayReturnUrl);
			easypayParams.set("shopOrderNo"			, e.shopOrderNo);
			easypayParams.set("deviceTypeCode"		, e.deviceTypeCode);
			easypayParams.set("mallName"			, encodeURIComponent(e.mallName));
			easypayParams.set("goodsName"			, encodeURIComponent(e.goodsName));
			easypayParams.set("customerId"			, encodeURI("tester6"));				// e.customerId
			easypayParams.set("customerName"		, encodeURI("테스터6"));					// e.customerName
			easypayParams.set("customerMail"		, encodeURIComponent("xxx@yyy.zzz"));	// e.customerMail
			easypayParams.set("customerContactNo"	, "01077778888");						// e.customerContactNo
			easypayParams.set("value1"				, e.value1);							// payCategory (사전정산 PREPAY)
			easypayParams.set("value2"				, e.value2);							// sectnId
			easypayParams.set("value3"				, e.value3);							// iNervPayId
			easypayParams.set("value4"				, e.value4);							// carNo

			instance.get("/api/easypay/easypaystart.json", {
				
				params : Object.fromEntries(easypayParams)

			}).then(function(res){
				
				var resData = res.data;
				
				// Mobile, PC Check & ios, android Check
				var mobileOS = "";
				if(e.deviceTypeCode == "mobile"){
					var mobileOSCheck = navigator.userAgent.toLowerCase();
					if(mobileOSCheck.indexOf('iphone') > -1 || mobileOSCheck.indexOf('ipad') > -1 || mobileOSCheck.indexOf('ipod') > -1){
						mobileOS = "ios";
					}else{
						mobileOS = "android";
					}
				}

				if(mobileOS == "ios"){
					window.location.href = resData.result.authPageUrl;
				}else{
					// 결제 모듈 - 팝업 오픈
					var webpay = window.open(resData.result.authPageUrl, "", "_blank");

					// 결제 모듈 - JSP 파일 팝업 종료 감지
					var interval = window.setInterval(function(){
						if(webpay == null || webpay.closed){
							window.clearInterval(interval);
						}
					});

				}

			})
			.catch(function(err){
				//console.log("ERROR");
				//console.dir(err);
			});

		},
		// 서비스 해제 - 모달창
		cardDeleteModal(){
			this.showDelModal = false;
		},

		// 서비스 해제 - 모달창 열기
		showDelModalOpen(applyId, approvalNo, batchId, batchKey){
			this.showDelModal = true;
			this.applyId      = applyId;
			this.approvalNo   = approvalNo;
			this.batchId      = batchId;
			this.batchKey     = batchKey;
		},

		// 서비스 해제 - 확인 버튼
		cardDeleteBtn(){
			this.showDelModal = false;
			
			let apiParams = new Map();
			apiParams.set("applyId"   , this.applyId);
			apiParams.set("disApplyId", this.applyId);
			//apiParams.set("approvalNo", this.approvalNo);
			//apiParams.set("batchId"   , this.batchId);
			//apiParams.set("batchKey"  , this.batchKey);
			//apiParams.set("clientIp"  , this.clientInfo.ip);

			instance.get("/api/nonstop/nonstopCardEdit.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {

				this.cardInfoList();

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

		},
		// 서비스 복구 - 모달창
		cardRecoveryModal(){			
			const clearClass = document.querySelector('.check-active');

			this.showRcvModal = false;
			document.querySelector('.check-active').focus();

			if(clearClass){
				clearClass.classList.remove('check-active');
			}
		},

		// 서비스 복구 - 모달창 열기
		showRcvModalOpen(applyId, approvalNo, batchId, batchKey){
			this.showRcvModal = true;
			this.applyId      = applyId;
			this.approvalNo   = approvalNo;
			this.batchId      = batchId;
			this.batchKey     = batchKey;
		},

		// 서비스 복구 - 확인 버튼
		cardRecoveryBtn(){
			let apiParams = new Map();
			apiParams.set("applyId"   , this.applyId);
			apiParams.set("approvalNo", this.approvalNo);
			apiParams.set("batchId"   , this.batchId);
			apiParams.set("batchKey"  , this.batchKey);
			apiParams.set("clientIp"  , this.clientInfo.ip);

			instance.get("/api/nonstop/nonstopCardEdit.json", {
				params : Object.fromEntries(apiParams)
			})
			.then((res => {

				this.cardInfoList();

			}))
			.catch(function (err) {
				//console.dir(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

			const clearClass = document.querySelector('.check-active');

			this.showRcvModal = false;
			document.querySelector('.check-active').focus();

			if(clearClass){
				clearClass.classList.remove('check-active');
			}

		},

		// 이전 페이지
		goPrevious(){

			var params = {
				applyId         : this.applyId,
				applyNm         : this.applyNm,
				applyAddr       : this.applyAddr,
				applyMobile     : this.applyMobile,
			}

			this.$router.push({
				name   : 'nonstopagree',
				params : { data : params }
			});

		},
		
		// 다음 페이지
		goNext(){

			var params = {
				applyId         : this.applyId,
				applyNm         : this.applyNm,
				applyAddr       : this.applyAddr,
				applyMobile     : this.applyMobile,
			}

			this.$router.push({
				name   : 'nonstopcarregist',
				params : { data : params }
			});

		},
	
		popupFocus(event){
			const targetId = event.currentTarget.id;

			document.getElementById(targetId).className += ' check-active';
			this.showRcvModal = true;

			setTimeout(function(){
				document.getElementById('restoreNotice').focus();
			});
		},

		clearFocus(event){
			const targetId = event.currentTarget.id;

			document.getElementById(targetId).className += ' check-active';
			//this.showRcvModal = true;

			setTimeout(function(){
				document.getElementById('clearNotice').focus();
			}, 0);
		},
	}
}
</script>

<style>
</style>