<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">사업자</div>
			<ul>
				<li><router-link to="" title="선불(포인트) 조회/적용">선불(포인트) 조회/적용</router-link></li>
				<li><router-link to="" title="포인트 결제">포인트 결제</router-link></li>
				<li><router-link to="" title="포인트 사용 내역" class="active">포인트 사용 내역</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>사업자</h2>
				<h4>포인트 사용 내역</h4>
			</article>
			<article>
				<table class="table-box">
					<caption class="sr-only">포인트 사용내역</caption>
					<colgroup>
						<col style="width:100%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">사업자[0000000001]</th>
						</tr>
					</thead>
					<tbody>                        
						<tr>
							<td scope="row">
								<div>
									<ul class="input-style">
										<li>총 적립 포인트</li>
										<li><input type="text" placeholder="20,000 P" disabled></li>
									</ul>
								</div>
								<div>
									<ul class="input-style">
										<li>총 사용 포인트</li>
										<li class="font-emphasis"><input type="text" placeholder="20,000 P" disabled></li>
									</ul>
								</div>
								<div>
									<ul class="input-style">
										<li>총 환불 포인트</li>
										<li><input type="text" placeholder="20,000 P" disabled></li>
									</ul>
								</div>
								<div>
									<ul class="input-style">
										<li>현재 잔여 포인트</li>
										<li><input type="text" placeholder="20,000 P" disabled></li>
									</ul>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article>
				<table class="vertical-table">
					<caption class="sr-only">포인트 사용내역 </caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:30%" />
						<col style="width:20%" />
						<col style="width:30%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">사용일</th>
							<td class="application">
								<v-col class="calendar">
									<v-text-field :value="startDate" label="2021-09-27" solo></v-text-field>
									<v-date-picker v-model="startDate"></v-date-picker>
								</v-col>
								<span>-</span>
								<v-col class="calendar">
									<v-text-field :value="endDate" label="2021-09-27" solo></v-text-field>
									<v-date-picker v-model="endDate"></v-date-picker>
								</v-col>
							</td>
							<th>포인트 상태</th>
							<td class="select-area full">
								<label for=""></label>
								<select name="" id="">
									<option value="" selected>사용</option>
									<option value="">미사용</option>
								</select>
							</td>
						</tr>
						<tr>
							<th>차량번호</th>
							<td>
								<ul class="input-style">
									<li>
										<input type="text" placeholder="0000" class="place-hide">
									</li>
								</ul>
							</td>
							<th>적용 웹할인</th>
							<td class="select-area full">
								<label for=""></label>
								<select name="" id="">
									<option value="" selected>&#9655; 전체</option>
									<option value=""></option>
								</select>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list right">
				<button class="btn">조회</button>
			</article>
			<article>
				<table class="default-table">
					<caption class="sr-only">포인트 사용내역</caption>
					<colgroup>
						<col style="width:70px" />
						<col style="width:14%" />
						<col style="width:14%" />
						<col style="width:14%" />
						<col style="width:14%" />
						<col style="width:14%" />
						<col style="width:14%" />
						<col style="width:14%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">순번</th>
							<th scope="col">웹할인</th>
							<th scope="col">차량번호</th>
							<th scope="col">이용주차장</th>
							<th scope="col">입차일시</th>
							<th scope="col">출차일시</th>
							<th scope="col">적용포인트</th>
							<th scope="col">적용일시</th>
						</tr>
					</thead>
					<tbody>
						<tr class="nodate">
							<td colspan="8" >조회된 데이터가 없습니다.</td>
						</tr>
						<!--                         
						<tr>
							<td scope="row"></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						-->
					</tbody>
				</table>
			</article>
		</section>
	</main>
</template>
<script>
export default {
    data(){
        return{
            startDate: null,
            endDate : null
        }
    },
};
</script>

<style>

</style>