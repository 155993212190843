<template>
  <section id="dimmed">
    <article>
      <img src="../../../main/webapp/resources/img/common/portal/loader_spinner.gif" alt="dimmed img">
    </article>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>