<template>
	<div v-show="showModal">
		<section class="modal-manager exception">
			<article class="title">
        <ul>
          <li>
            <slot name="info"></slot>
          </li>
          <li class="popup-close" @click="modalHide" tabindex="2" @keydown.enter="modalHide">
            <slot name="close"></slot>
          </li>
        </ul>
			</article>
			<article class="content">
					<div class="content-inner">
						<slot name="content"></slot>
					</div>
			</article>
			<article class="btn-list">
				<slot name="button"></slot>
			</article>
		</section>
		<section class="dim"></section>
	</div>
</template>

<script>
export default {
	data(){
		return{
			showModal : true
		}
	},
	
	methods:{
		modalHide(){
			this.showModal = !this.showModal;
		}		
	}
}
</script>

<style>

</style>