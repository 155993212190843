<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차 정기권</div>
			<ul>
				<li><router-link to="periodinfo" title="정기권신청 안내">안내</router-link></li>
				<li><router-link to="periodagreement" title="정기권 신청">정기권 신청</router-link></li>
				<li><router-link to="periodlookup" title="정기권 조회 &#47; 결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 정기권</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>정기권 결제</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">정기권 결제</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th>차량번호</th>
						<td>{{ r.carNo }}</td>
					</tr>
					<tr>
						<th scope="row">신청자</th>
						<td>{{ r.waitingNm }}</td>
					</tr>
					<tr>
						<th scope="row">차량소유주</th>
						<td>{{ r.carOwnerNm }}</td>
					</tr>
					<!--<tr>
						<th>주소</th>
						<td>{{ r.zipcode }} {{ r.addr }} {{ r.addrDetail }}</td>
					</tr>-->
					<tr>
						<th>연락처</th>
						<td>{{ r.mobile | phoneFormat }}</td>
					</tr>
					<tr>
						<th>신청주차장</th>
						<td>
							<span class="emphasis-view">{{ r.parkingDivCdNm }}</span>
							{{ r.parkingNm }}
						</td>
					</tr>
					<tr>
						<th>할인</th>
						<td>{{ discountCd.discountNm }}</td>
					</tr>
					<tr>
						<th>할증</th>
						<td>{{ extraCd.discountNm }}</td>
					</tr>
					<tr>
						<th>이용기간</th>
						<td v-if="r.useStartDt != '' && r.useEndDt != ''">
							{{ r.useStartDt | dateFormat }} ~ {{ r.useEndDt | dateFormat }}
						</td>
						<td v-else>이용기간이 존재하지 않습니다. 관리자에게 문의해주세요.</td>
					</tr>
					<tr>
						<th>납부기간</th>
						<td v-if="r.billDt != '' && r.limitDt != ''">
							{{ r.billDt | dateFormat }} ~ {{ r.limitDt | dateFormat }}
						</td>
						<td v-else>납부기간이 존재하지 않습니다. 관리자에게 문의해주세요.</td>
					</tr>
					<tr>
						<th>월 정기권 금액</th>
						<td>
							<span class="emphasis-view">{{ useDivCd.name }}</span>
							{{ r.useAmt | comma }} 원
						</td>
					</tr>
				</tbody>
				<tbody v-if="r.expiredYn == 'Y'">
					<tr v-if="procStsCd.value == 'MUT-001'">
						<!-- 배정신청 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">정기권 신청중</span>
							<span v-if="r.waitingOdr != ''" class="emphasis">대기 {{ r.waitingOdr }} 순위</span>
						</td>
					</tr>
					<tr v-else-if="procStsCd.value == 'MUT-002'">
						<!-- 배정완납 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">배정완납</span>
							결제완료 : {{ r.receiptAmt | comma }}
						</td>
					</tr>
					<tr v-else-if="procStsCd.value == 'MUT-003'">
						<!-- 삭제 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">배정취소/삭제</span>
							정기권 배정이 취소된 상태입니다. 관리자에게 문의해주세요.
						</td>
					</tr>
					<tr v-else-if="procStsCd.value == 'MUT-004'">
						<!-- 배정미납 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">배정미납</span>
							정기권 배정된 상태입니다. 결제를 진행해주세요.
						</td>
					</tr>
					<tr v-else-if="procStsCd.value == 'MUT-005'">
						<!-- 납부이전 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">납부기간이전</span>
							정기권 납부기간 이전입니다. 납부기간을 확인해주세요.
						</td>
					</tr>
					<tr v-else-if="procStsCd.value == 'MUT-006'">
						<!-- 납부만료 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">납부기간만료</span>
							정기권 납부기간이 만료되었습니다. 납부기간을 확인해주세요.
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr v-if="procStsCd.value == 'MUT-005'">
						<!-- 납부이전 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">납부기간이전</span>
							정기권 납부기간 이전입니다. 납부기간을 확인해주세요.
						</td>
					</tr>
					<tr v-else-if="procStsCd.value == 'MUT-006'">
						<!-- 납부만료 -->
						<th>진행상태</th>
						<td>
							<span class="emphasis-view">납부기간만료</span>
							정기권 납부기간이 만료되었습니다. 납부기간을 확인해주세요.
						</td>
					</tr>
				</tbody>
			</table>

			<article class="message">
				<p>결제 실패시 처음(차량번호 조회)부터 다시 시도 해주시기 바랍니다.</p>
			</article>

			<article class="btn-list" v-if="(procStsCd.value == 'MUT-004' || procStsCd.value == 'MUT-005') && r.expiredYn == 'Y'">
				<button class="btn"        @click="paymentBtn()">결제</button>
				<button class="btn-border" @click="cancelBtn(carNo)">취소</button>
			</article>
			<article class="btn-list" v-else>
				<button class="btn-border" @click="cancelBtn(carNo)">확인</button>
			</article>

		</section>
	</main>
</template>

<script>

import axios from 'axios';


const instance = axios.create({
  timeout: 60000,
});

export default {
	data(){
		return{
			carNo         : '',
			periodId      : '',
			r             : '',
			discountCd    : '',
			extraCd       : '',
			useDivCd      : '',
			procStsCd     : '',
			easypayParams : '',
		}
	},
	filters : {
		// 숫자 #,### 포맷팅
		comma : function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
		dateFormat : function(x){
			if(x != null){
				var d = x.toString().replace(/[^0-9]/g, '');

				var year  = d.toString().substr(0, 4);
				var month = d.toString().substr(4, 2);
				var day   = d.toString().substr(6, 2);

				return year + "-" + month + "-" + day;
			}
		},
		// 전화번호 포맷팅
		phoneFormat : function(x){
			if(x != null){
				var p = x.toString().replace(/[^0-9]/g, '');

				let tmp = "";

				if(p.length < 4){

					return p;

				}else if(p.length < 7){

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3);
					return tmp;

				}else if(p.length == 8){

					tmp += p.substr(0, 4);
					tmp += "-";
					tmp += p.substr(4);
					return tmp;

				}else if(p.length < 10){

					tmp += p.substr(0, 2);
					tmp += "-";
					tmp += p.substr(2, 3);
					tmp += "-";
					tmp += p.substr(5);
					return tmp;

				}else if(p.length < 11){

					if(p.substr(0, 2) == "02"){

						tmp += p.substr(0, 2);
						tmp += "-";
						tmp += p.substr(2, 4);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}else{

						tmp += p.substr(0, 3);
						tmp += "-";
						tmp += p.substr(3, 3);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}
					

				}else{

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3, 4);
					tmp += "-";
					tmp += p.substr(7);
					return tmp;

				}

			}
		}
	},
	created(){
		
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("data"));
		}else{
			localStorage.setItem("data", JSON.stringify(data));
		}

		this.carNo    = data.carNo;
		this.periodId = data.periodId;

	},
	mounted(){

		let apiParams = new Map();
		apiParams.set("periodId", this.periodId);
		apiParams.set("isMobile", this.$isMobile());

		instance.get("/api/period/view.json", {
			params : Object.fromEntries(apiParams)
		})
		.then((res => {

			var resData = res.data;

			this.r             = resData.resultMap.result;
			this.discountCd    = resData.resultMap.result.discountCd;
			this.extraCd       = resData.resultMap.result.extraCd;
			this.useDivCd      = resData.resultMap.result.useDivCd;
			this.procStsCd     = resData.resultMap.result.procStsCd;
			this.easypayParams = resData.resultMap.easypayParams;

		}));

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		// 취소 버튼
		cancelBtn(carNo){
			this.$router.push({
				name  : 'periodlookup',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},
		// 결제 버튼
		paymentBtn(){

			var e = this.easypayParams;

			let easypayParams = new Map();
			easypayParams.set("easypayRequestUrl"	, e.easypayRequestUrl);
			easypayParams.set("mallId"				, e.mallId);
			easypayParams.set("payMethodTypeCode"	, e.payMethodTypeCode);
			easypayParams.set("currency"			, e.currency);
			easypayParams.set("amount"				, e.amount);
			easypayParams.set("clientTypeCode"		, e.clientTypeCode);
			easypayParams.set("returnUrl"			, e.easypayReturnUrl);
			easypayParams.set("shopOrderNo"			, e.shopOrderNo);
			easypayParams.set("deviceTypeCode"		, e.deviceTypeCode);
			easypayParams.set("mallName"			, encodeURIComponent(e.mallName));
			easypayParams.set("goodsName"			, encodeURIComponent(e.goodsName));
			easypayParams.set("customerId"			, encodeURI(this.$store.state.empNo));				// e.customerId
			easypayParams.set("customerName"		, encodeURI(this.$store.state.empNm));				// e.customerName
			easypayParams.set("customerMail"		, encodeURIComponent(this.$store.state.empNo));		// e.customerMail
			easypayParams.set("customerContactNo"	, this.$store.state.empMobile);						// e.customerContactNo
			easypayParams.set("value1"				, e.value1);										// payCategory (사전정산 PREPAY)
			easypayParams.set("value2"				, e.value2);										// sectnId
			easypayParams.set("value3"				, e.value3);										// iNervPayId
			easypayParams.set("value4"				, e.value4);										// carNo

			instance.get("/api/easypay/easypaystart.json", {
				
				params : Object.fromEntries(easypayParams)

			}).then(function(res){
				
				//console.log("SUCESS");
				//console.dir(res);
				var resData = res.data;
				
				// Mobile, PC Check & ios, android Check
				var mobileOS = "";
				if(e.deviceTypeCode == "mobile"){
					var mobileOSCheck = navigator.userAgent.toLowerCase();
					if(mobileOSCheck.indexOf('iphone') > -1 || mobileOSCheck.indexOf('ipad') > -1 || mobileOSCheck.indexOf('ipod') > -1){
						mobileOS = "ios";
					}else{
						mobileOS = "android";
					}
				}

				if(mobileOS == "ios"){
					window.location.href = resData.result.authPageUrl;
				}else{
					// 결제 모듈 - 팝업 오픈
					var webpay = window.open(resData.result.authPageUrl, "", "_blank");

					// 결제 모듈 - JSP 파일 팝업 종료 감지
					var interval = window.setInterval(function(){
						if(webpay == null || webpay.closed){
							window.clearInterval(interval);
						}
					});

				}

			})
			.catch(function(err){
				console.log("ERROR");
				//console.dir(err);
			});

		}
	}
}

</script>

<style>

</style>