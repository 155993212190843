<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle"      title="무료차량 관리">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist"   title="정기권 주차장 관리">정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement"    title="자주하는 질문 관리" class="active">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist"        title="팝업 관리">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>자주하는 질문 관리</h4>
			</article>
			<article>
				<h3>자주하는 질문 관리</h3>
			</article>
			<article>
				<!-- Table List Area -->
				<table class="vertical-table">
					<caption class="sr-only">공지사항 상세보기 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">제목</th>
							<td>{{ boardObj.title }}</td>
						</tr>
						<tr>
							<th>내용</th>
							<td>
								<!-- {{ boardObj.cts }} -->
								<div v-html="cts" class="ckeditorCts"></div>
							</td>
						</tr>
						<tr>
							<th>첨부파일</th>
							<td>
								<ul class="input-style button interval">
									<li v-for="(item, index) in attachList" :key="index">
										<span class="cursor" @click="downloadAttachFile(item.filePath, item.saveNm, item.viewNm, item.fileExt, item.repoId)">
											{{ item.viewNm }} ({{ item.fileSize | comma }})kb
										</span>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article> 
			<!-- Button Area -->
			<article class="btn-list right">
				<button class="btn"         @click="$router.push('faqmanagement')">목록</button>
				<button class="btn black"   @click="updateBtn(boardObj)">수정</button>
				<button class="btn-border"  @click="deleteConfirm()">삭제</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return {
			atclId     : this.$route.query.atclId,
			boardObj   : '',
			attachList : [],
			bbsCd      : 'faq',
			menuUrl    : "/api/admin/board/faq",
			cts        : "",
		}
	},

	mounted() {
		this.initView();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		initView(){
			instance.get('/api/admin/board/' + this.bbsCd + '/view.json', {
				params : {
					atclId : this.atclId
				}
			})
			.then((response => {
				var data        = response.data;
				this.boardObj   = data.boardView;
				if(this.boardObj.cts != "" || this.boardObj.cts != null){
					this.cts = this.boardObj.cts;
				}
				this.attachList = data.attachList;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		updateBtn(boardObj){
			let params = {
				atclId : boardObj.atclId,
				title  : boardObj.title,
				cts    : boardObj.cts
			}

			this.$router.push({
				name   : 'faqmanagementupdate',
				params : { data : params }
			})
		},

		deleteBtn(){

			let apiParams = new FormData();
			apiParams.append("atclId", this.atclId);

			instance.post('/api/admin/board/' + this.bbsCd + '/deleteAll.json', apiParams)
			.then((response => {
				let message = response.data.message;
				alert(message);
				this.$router.push('faqmanagement');
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		downloadAttachFile(filePath, saveNm, viewNm, fileExt, repoId) {
			location.href = this.menuUrl + "/download.json?filePath=" + filePath + "&saveNm=" + saveNm + "&viewNm=" + viewNm + "&fileExt=" + fileExt + "&repoId=" + repoId;
		},

		deleteConfirm(){
			this.$confirm(
				{
					message: `삭제하시겠습니까?`,
					button: {
						no: '아니오',
						yes: '예'
					},
					callback: confirm => {
						if (confirm) {
						this.deleteBtn();
						}
					}
				}
			)
		}
	},
	
}
</script>

<style scoped>
	.ckeditorCts { min-height: 300px; }
</style>