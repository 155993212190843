<template>
  <main id="mainPage">
		<section class="slider">
			<div class="visual">
				<slick id="carousel" ref="slick" :options="slickOptions">
					<article class="slider-box1">
						<div class="txt-box">
							<p class="tit">시민이 주도하는 지속가능하고<br/>스마트한 동반성장 도시</p>
							<p class="sub-txt">시민과 함께 <b>Eco-Smart City</b> 화성</p>
						</div>
					</article>
					<article class="slider-box2">
						<div class="txt-box">
							<p class="tit">효과적인 주차정책 수립<br/>국토의 효율적인 이용</p>
							<p class="sub-txt">주차관련 실시간 정보와 <b>Bigdata Service</b> 제공</p>
						</div>
					</article>
				</slick>
				<div class="searchBar">
					<input type="text" placeholder="주차장명 또는 주소를 검색하세요" v-model="pakingName" title="주소 검색"  v-on:keyup.enter="searchParking()">
					<button type="button" class="btnSearch" @click="searchParking()"><span class="hidden">검색</span></button>
				</div>
				<ul class="icon-list">
					<li>
						<a href="#a" @click="goPage('/realtimeparking')">
							<div class="icon-parking"></div>
							<p>실시간 주차현황</p>
						</a>
					</li>
					<li>
						<a href="#a" @click="goPage('/prepaymentinfo')">
							<div class="icon-fee"></div>
							<p>주차요금 사전결제</p>
						</a>
					</li>
					<li>
						<a href="#a" @click="goPage('/unpaidinfo')">
							<div class="icon-unpaid"></div>
							<p>미납주차요금</p>
						</a>
					</li>
					<li>
						<!-- <a href="#a" @click="goPage('/periodinfo')" @keyup.tab="realparking"> -->
						<a href="#a" @click="goPage('/periodinfo')">
							<div class="icon-period" ></div>
							<p>공영주차장 정기권</p>
						</a>
					</li>
				</ul>
				<div class="play-controller">
					<button class="slick-prev"><span class="hide">이전버튼</span></button>
					<div class="slick-dot"></div>
					<button @click="play" v-if="Controller" class="slick-play"><span class="hide">재생버튼</span></button>
					<button @click="stop" v-else class="slick-stop"><span class="hide">정지버튼</span></button>
					<button class="slick-next"><span class="hide">다음버튼</span></button>
				</div>
			</div>
		</section>
		<section id="realtime">
			<article>
				<div>
					<h2>길이열리는 화성시 <b>주차장 현황</b></h2>
				</div>
				<div>
					<slick ref="slick" id="parking-list" :options="realTimeParking" v-if="parkingMapList.length">
						<a class="card" href="javascript:void(0);" v-for="(parking, index) in parkingMapList" :key="index" @click="goPageMap(parking.PARKING_NM)">
							<div class="card-img">
								<img :src="checkImg(parking.REPO_ID, parking.FILE_PATH, parking.SAVE_NM, parking.FILE_EXT)" style="width: 100%; height: 100%;" title="주차장 이미지">
								
							</div>
							<div class="card-cont">
								<p class="card-title">{{ parking.PARKING_NM }}</p>
								<ul class="card-info">
									<li class="park">주차면<em>{{parking.CELL_CNT}}</em></li>
									<li class="wheel">장애인<em>{{parking.HNDCAP_CELL_CNT}}</em></li>
								</ul>
							</div>
						</a>
					</slick>
				</div>
			</article>
		</section>
		<section id="boardList">
			<article>
				<div>
					<h2>시민과 함께 <b>열린공간</b></h2>
				</div>
				<ul>
					<li>
						<a href="" @click="goPage('/noticelist')">
							<h3>공영주차장 공지사항</h3>
							<p>화성도시공사 공영주차장<br/>주요 소식을 알려드립니다.</p>
						</a>
					</li>
					<li>
						<a href="" @click="goPage('/questionslist')">
							<h3>자주하는 질문</h3>
							<p>화성도시공사 공영주차장의<br/>자주하는 질문입니다.</p>
						</a>
					</li>
					<li class="call-center">
						<h3>화성도시공사 주차운영부</h3>
						<p>시민에게 행복을 주는 행복공영주차장</p>
						<p class="tel">031-8059-6538</p>
					</li>
				</ul>
			</article>
		</section>
		<div id="popuptest">
		<pop-up v-for="(item, index) in popupPropsDataArr" :key="index" v-bind:propsData="item"																	>
			<template #info>
				{{ item.title }}
			</template>

			<template #close>
				<span class="hide">닫기 아이콘</span>
			</template>

			<template #content>
				<p v-html="item.contents"></p>
			</template>

			<template #chkbox>
				<input type="checkbox" :id="'chked' + index" v-model="checkedValues" :value="item.popupId" @input="setPopupCookie(item.popupId)">
				<label :for="'chked' + index" @keydown.space="dayCheck" class="now" tabindex="1000">오늘 하루 보지 않기</label>
			</template>
		</pop-up>
		</div>

		<!-- 검색창 알럿창 -->
		<alert v-if="idShow">
			<template #text>
				검색어를 입력해주세요.
			</template>
			<template #button>
				<button @click="closeBtn">확인</button>
			</template>
			<template #close>
				<button @click="closeBtn" class="popup-close"></button>
			</template>
			
		</alert>
	</main>
</template>

<script>
import PopUp from '../../components/PopupType11.vue';
import Alert from '../../components/Alert.vue';
import axios from 'axios';
import Slick from 'vue-slick';

const instance = axios.create({
  timeout: 30000,
});


export default {

	components: {
		Slick,
		PopUp,
		Alert
	},

	data() {
		return {
			slickOptions   : {
				autoplay      : true,
				autoplaySpeed : 3000,
				arrows        : true,
				dots          : true,
				appendDots    : ".slick-dot",
				swipeToSlide  : true,
				prevArrow     : ".slick-prev",
				nextArrow     : ".slick-next",
			},

			realTimeParking : {
				rows          : 2,
				slidesPerRow  : 1,
				slidesToShow  : 4,
				autoplay      : true,      //자동시작 (boolean) -default:false
				autoplaySpeed : 2500,
				arrows        : true,
				dots          : false,
				swipeToSlide : true,

				responsive : [
					{
						breakpoint : 1024,
						settings   : {
							slidesToShow   : 2,
							slidesToScroll : 1,
							rows           : 1,
						}
					},
					{
						breakpoint : 768,
						settings   : {
							slidesToShow   : 1,
							slidesToScroll : 1,
							rows           : 1,
						}
					},
				]
			},

			parkingMapList    : [],
			popupList         : [],
			popupTitle        : '',
			popupContents     : '',
			popupPropsDataArr : [],
			popupFiles        : '',
			checkedValues     : false, //[]
			Controller        : false,
			pakingName	  : '',
			idShow     : false,
		}
	},

	mounted(){
		this.getParkingMapList();
		this.getPopupList();
	},

	methods : {
		// 주차장
		getParkingMapList(){

			var jsonList = [];

			instance.get("/api/parking/map.json").then((res => {

				var resData         = res.data;
				this.parkingMapList = resData.parkingMapList;

				for(var i = 0 ; i < resData.parkingMapList.length ; i++){
					if(resData.parkingMapList[i].REPO_ID == null || resData.parkingMapList[i].FILE_PATH == null
					|| resData.parkingMapList[i].SAVE_NM == null || resData.parkingMapList[i].FILE_EXT  == null){
						continue;
					}else{
						jsonList.push(resData.parkingMapList[i]);
					}
				}

				this.parkingMapList = jsonList;
			}));
		},

		noParkingImage() {
			return require("../../assets/images/parking/noparking.png");
		},

		// 주차장 사진 가져오기
		checkImg(repoId, filePath, saveNm, fileExt){
			if(repoId == null || filePath == null || saveNm == null || fileExt == null){
				return this.noParkingImage();
			}else{
				return "/api/parking/atchfile/" + repoId + "/" + filePath + "/" + saveNm + "." + fileExt;
			}
		},

		// 페이지 이동 [ 주차장지도 / 사전정산 / 미납결제 / 정기권 등 ]
		goPage(path){
			this.$router.push(path);
		},

		// 지도 검색 페이지로 이동
		goPageMap(pakingName){
			this.$router.push({
				name   : 'list',
				params : { data : pakingName }
			});
		},

		// 지도 검색 페이지로 이동
		searchParking(){
			if(this.pakingName != ''){
				this.$router.push({
					name   : 'list',
					params : { data : this.pakingName }
				});
			}else{
				this.idShow = true;
			}
		},

		setPopupProps(popupObj){
			let cookieKey = this.$cookies.get('PORTAL-POPUP-' + popupObj.popupId);
			if(cookieKey === 'N'){
				console.dir();
			}else{
				let popupData = {
					posX       : popupObj.posX,
					posY       : popupObj.posY,
					sizeX      : popupObj.sizeX,
					sizeY      : popupObj.sizeY,
					linkUrl    : popupObj.linkUrl,
					title      : popupObj.title,
					contents   : popupObj.contents,
					repoId     : popupObj.repoId,
					filePath   : popupObj.filePath,
					saveNm     : popupObj.saveNm,
					fileExt    : popupObj.fileExt,
					popupId    : popupObj.popupId
				}
	
				this.popupPropsDataArr.push(popupData);
			}
		},

		getPopupList(){	
			instance.get("/api/admin/popup/getPopupList.json")
			.then((res => {
				let data       = res.data;
				this.popupList = data.resultList;

				if(!this.existPopup){
					return null;
				}

				this.popupList.forEach(element => {
					this.setPopupProps(element);
				});
			}))
			.catch(err => {
				//console.log(err);
				alert("서버와 통신중 오류가 발생하였습니다.");
			})
		},

		existPopup(){
			if(this.popupList.length >0){
				return true;
			}else{
				return false;
			}
		},

		setPopupCookie(popupId){
			let cookieKey = this.$cookies.get('PORTAL-POPUP-' + popupId);
			if(cookieKey === 'N'){
				this.$cookies.remove('PORTAL-POPUP-' + popupId);
			}else{
				this.$cookies.set('PORTAL-POPUP-' + popupId, 'N');
			}
		},

		dayCheck(event){
			event.preventDefault();
			event.target.checked;
			this.checkedValues = !this.checkedValues;
		},

		play(){
			console.log("play");
			this.Controller = false;
			this.$refs.slick.play();
		},

		stop(){
			console.log("pause");
			this.Controller = true;
			this.$refs.slick.pause();	
		},

		realparking(){
			document.getElementById("parking-list").focus();
		},

		closeBtn(){
			this.idShow = false;
		},
	},
	
}

</script>

<style scoped>
	html, body { touch-action : none; }
</style>