<template>
	<section>
		<article>
			<label for="sortSelect" title="묶어서보기"><span class="hide">묶어서 보기</span></label>
			<select v-model.number="dataPerPage" @change="emitPerChange" id="sortSelect">
				<option value="10">10개씩 보기</option>
				<option value="30">30개씩 보기</option>
				<option value="50">50개씩 보기</option>
			</select>
		</article>
		<article>
			<ul class="input-style">
				<slot name="searchUseYn"></slot>
				<li>
					<label for="keywordSelect" title="키워드선택"><span class="hide">키워드 선택</span></label>
					<select v-model="searchCondition" id="keywordSelect">
						<slot name="searchCondition"></slot>
					</select>
				</li>
				<li>
					<label for="inputText" title="검색키워드"><span class="hide">검색키워드</span></label>
					<input type="text" v-model="searchKeyword" @keyup.enter="emitSearch" id="inputText"/>
				</li>
				<li><button class="table-btn" @click="emitSearch">조회</button></li>
			</ul>
		</article>
	</section>
</template>

<script>
export default {
	data(){
		return{
			dataPerPage: 10,
			searchCondition: 1,
			searchKeyword: null
		}
	},

	methods: {
		emitSearch() {
			this.$emit('emitSearch', this.searchCondition, this.searchKeyword);
		},

		emitPerChange() {
			this.$emit('emitPerChange', this.dataPerPage);
		}
	}
}
</script>

<style>

</style>