<template>
  <section class="lnb" v-show="closeResult"> 
    <article class="lnb-title">
      <ul>
        <li>전체메뉴</li>
        <li @click="btnClose">닫기</li>
      </ul>
    </article>
    <article class="lnb-content">
      <ul>
        <li @click="toggle1">
          <p>공영주차장</p>
          <ul v-show="parking">
            <li @click="btnClose"><router-link to="introduce"       title="지도검색" >주차장소개</router-link></li>
            <li @click="btnClose"><router-link to="map"             title="지도검색">지도검색</router-link></li>
            <li @click="btnClose"><router-link to="list"            title="주소검색">주소검색</router-link></li>
            <li @click="btnClose"><router-link to="/feeinformation" title="주차장 요금안내">주차장 요금안내</router-link></li>
            <li @click="btnClose"><router-link to="/realtimeparking" title="실시간 주차정보">실시간 주차정보</router-link></li>
          </ul>
        </li>
        <li @click="toggle2">
          <p>주차요금 사전결제</p>
          <ul v-show="prepayment">
            <li @click="btnClose"><router-link to="prepaymentinfo" title="사전결제 안내">안내</router-link></li>
            <li @click="btnClose"><router-link to="lookup"         title="사전결제 조회결제">조회/결제</router-link></li>
          </ul>
        </li>
        <li @click="toggle3">
          <p>미납주차요금</p>
          <ul v-show="unpaid">
            <li @click="btnClose"><router-link to="unpaidinfo"   title="미납주차요금 안내" class="active">안내</router-link></li>
            <li @click="btnClose"><router-link to="lookupunpaid" title="미납주차요금 조회/결제">조회/결제</router-link></li>
          </ul>
        </li>
        <li @click="toggle4">
          <p>비대면자격확인</p>
          <ul v-show="reduction">
            <li @click="btnClose"><router-link to="reductioninfo"      title="비대면 안내">안내</router-link></li>
            <li @click="btnClose"><router-link to="reductionagreement" title="비대면 자격확인">비대면 자격확인</router-link></li>
          </ul>
        </li>
        <li @click="toggle5">
          <p>거주자 우선 주차장</p>
          <ul v-show="resident">
            <li @click="btnClose"><router-link to="residentinfo"      title="거주자 우선 주차장 안내">안내</router-link></li>
            <li @click="btnClose"><router-link to="residentsearch"    title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
            <li @click="btnClose"><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
            <li @click="btnClose"><router-link to="residentlookup"    title="거주자 우선 주차장 조회/결제">조회/결제</router-link></li>
          </ul>
        </li>
        <li @click="toggle6">
          <p>공영주차장 정기권</p>
          <ul v-show="period">
            <li @click="btnClose"><router-link to="periodinfo"      title=" 공영주차장 정기권 안내">안내</router-link></li>
            <li @click="btnClose"><router-link to="periodagreement" title="정기권 신청">정기권 신청</router-link></li>
            <li @click="btnClose"><router-link to="periodlookup"    title="조회/결제">조회/결제</router-link></li>
          </ul>
        </li>
        <li @click="toggle7">
          <p>무정차 신청</p>
          <ul v-show="nonstop">
						<li @click="btnClose"><router-link to="nonstopinfo" title="무정차 신청">무정차 신청</router-link></li>
          </ul>
        </li>
        <li @click="toggle8">
          <p>열린공간</p>
          <ul v-show="board">
						<li @click="btnClose"><router-link to="noticelist"    title="공지사항">공지사항</router-link></li>
						<li @click="btnClose"><router-link to="questionslist" title="자주하는 질문">자주하는 질문</router-link></li>
          </ul>
        </li>
      </ul>
    </article>
    <section class="dim"></section>
  </section>
</template>

<script>
export default {
  data(){
		return{
      parking     : false,
      prepayment  : false,
      unpaid      : false,
      reduction   : false,
      resident    : false,
      period      : false,
      nonstop     : false,
      board       : false,
      closeResult : false,
      close       : false,
		}
	},

	methods: {
    toggle1(){
      this.unpaid     = false;
      this.prepayment = false;
      this.reduction  = false;
      this.resident   = false;
      this.period     = false;
      this.nonstop    = false;
      this.board      = false;

      this.parking = !this.parking;
    },

    toggle2(){
      this.parking   = false;
      this.unpaid    = false;
      this.reduction = false;
      this.resident  = false;
      this.period    = false;
      this.nonstop   = false;
      this.board     = false;

      this.prepayment = !this.prepayment;
    },

    toggle3(){
      this.parking    = false;
      this.prepayment = false;
      this.reduction  = false;
      this.resident   = false;
      this.period     = false;
      this.nonstop    = false;
      this.board      = false;

      this.unpaid = !this.unpaid;
    },

    toggle4(){
      this.parking    = false;
      this.unpaid     = false;
      this.prepayment = false;
      this.resident   = false;
      this.period     = false;
      this.nonstop    = false;
      this.board      = false;

      this.reduction = !this.reduction;
    },

    toggle5(){
      this.parking    = false;
      this.unpaid     = false;
      this.prepayment = false;
      this.reduction  = false;
      this.period     = false;
      this.nonstop    = false;
      this.board      = false;

      this.resident = !this.resident;
    },

    toggle6(){
      this.parking    = false;
      this.unpaid     = false;
      this.prepayment = false;
      this.reduction  = false;
      this.resident   = false;
      this.nonstop    = false;
      this.board      = false;
      
      this.period = !this.period;
    },

     toggle7(){
      this.parking    = false;
      this.unpaid     = false;
      this.prepayment = false;
      this.reduction  = false;
      this.resident   = false;
      this.period     = false;
      this.board      = false;

      this.nonstop = !this.nonstop;
    },
    
    toggle8(){
      this.parking    = false;
      this.unpaid     = false;
      this.prepayment = false;
      this.reduction  = false;
      this.resident   = false;
      this.period     = false;
      this.nonstop    = false;

      this.board = !this.board;
    },
    
    btnClose() {
     this.$emit('closeResult');
    }
	},
}
</script>

<style>

</style>