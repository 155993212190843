<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">무정차 신청</div>
			<ul>
				<li><router-link to="/nonstopinfo" title="무정차 신청" class="active">무정차 신청</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>무정차 신청</h2>
				<h4>서비스 신청</h4>
			</article>
			<article>
				<div id="tabs">
					<ul class="tab-menu-nonstop">
						<li>신청 안내</li>
						<li class="active">서비스 신청</li>
						<li>결제카드 등록</li>
						<li>차량 등록</li>
					</ul>
					<div class="tab-content">

						<!-- 서비스 신청 -->
						<article>
							<h3>신청자 정보</h3>
							<table class="vertical-table">
								<caption class="sr-only">신청자 정보 테이블</caption>
								<colgroup>
									<col style="width:20%" />
									<col style="width:80%" />
								</colgroup>
								<tbody>
									<tr>
										<th scope="row">신청자명</th>
										<td>
											<ul class="input-style">
												<li>
													<!-- <input type="text" placeholder="신청자 명" class="place-hide" v-model="applyNm" readonly/> -->
													{{applyNm}}
												</li>
											</ul>
										</td>
									</tr>
									<tr>
										<th class="asterisk-after">주소<span class="hide">(필수입력)</span></th>
										<td>
											<ul class="input-style">
												<li>
													<label for="address" class="hide">주소</label>
													<input type="text" placeholder="주소" class="place-hide" v-model="applyAddr" id="address"  />
												</li>
											</ul>
										</td>
									</tr>
									<tr>
										<th class="asterisk-after">연락처<span class="hide">(필수입력)</span></th>
										<td>
											<ul class="input-style">
												<li>
													<!-- <input type="text" placeholder="휴대폰 번호 '-' 없이 입력" class="place-hide" v-model="applyMobile" readonly/> -->
													{{applyMobile}}
												</li>
											</ul>
										</td>
									</tr>
								</tbody>
							</table>

							<article class="message" v-if="validCheck === false">
								<p>필수 입력값을 입력하여 주시기 바랍니다.</p>
							</article>

							<p class="asterisk">무정차정산 결제카드 오류 시 휴대폰 번호로 안내문자 드립니다.</p>
						</article>
						<article>
							<h3>개인정보 이용 동의</h3>
							
							<privacy-policy></privacy-policy>

							<input type="checkbox" id="privacy" v-model="applyAgreeCheck" @click="applyAgreeCheck=true" />
							<label for="privacy" ID="agreeUse" @keydown.space="check" @keydown.enter="check">
							<span role="checkbox" aria-checked="false" aria-labelledby="agreeUse" class="hide"></span>
							개인정보 수집 및 이용에 대한 동의 (필수)</label>
						</article>
						<article class="btn-list">
							<button class="btn"        @click="goNext">동의</button>
							<button class="btn-border" @click="goPrevious">동의 안함</button>
						</article>
					</div>
				</div>
			</article>
		</section>
		
		<!-- 서비스 신청 -->
		<pop-up v-if="showModal" @cancel="showModal=true" class="modal-info">
			<template #info>
				<li>안내</li>
			</template>
			<template #close>
				<li></li>
			</template>
			<template #content>
				<p v-html="msg"></p>
			</template>
			<template #button>
				<button class="popup-btn"        @click="showModal=false">예</button>
				<button class="popup-btn-border" @click="showModal=false">아니요</button>
			</template>
		</pop-up>

	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


import { required, minLength } from 'vuelidate/lib/validators'

import PrivacyPolicy from '../../../components/PrivacyPolicy.vue';
import PopUp         from '../../../components/PopupType1.vue';

export default {
	data(){
		return {
			applyId         : "",
			applyNm         : "",
			applyAddr       : "",
			applyMobile     : "",
			applyAgreeCheck : false,
			validCheck      : '',
			showModal       : false,
			msg             : '무정차 정산 서비스를 신청 하시겠습니까?',
		}
	},
	validations : {
		applyNm : {
			required,
			minLength : minLength(2)
		},
		applyAddr : {
			required
		},
		applyMobile : {
			required
		}
	},
	components:{
		PopUp,
		PrivacyPolicy
	},
	created(){
		
		let data = null;

		if(localStorage.getItem("nonstopData") !== null){
			data = JSON.parse(localStorage.getItem("nonstopData"));
			this.applyAddr   = data.applyAddr;
		}

		this.applyId = this.$store.state.empNo;
		this.applyNm = this.$store.state.empNm;
		this.applyMobile = this.$store.state.empMobile;

	},
	methods:{
		goNext(){
			
			if(!this.applyAgreeCheck){
				alert("개인정보 수집/이용 동의를 체크하지 않으셨습니다.");
				return false;
			}

			if(this.$v.$invalid) {
				alert("필수 값을 입력해주세요.");
			}else{

				let apiParams = new Map();
				apiParams.set("applyId"        , this.applyId);
				apiParams.set("applyNm"        , this.applyNm);
				apiParams.set("applyAddr"      , this.applyAddr);
				apiParams.set("applyMobile"    , this.applyMobile);
				apiParams.set("applyAgreeCheck", this.applyAgreeCheck);

				instance.get("/api/nonstop/nonstopApplyAdd.json", {
					params : Object.fromEntries(apiParams)
				})
				.then((res => {

					var resData = res.data.result;
					var resFlag = resData.result.result;

					if(resFlag){

						var params = {
							applyId         : resData.applyId,
							applyNm         : resData.applyNm,
							applyAddr       : resData.applyAddr,
							applyMobile     : resData.applyMobile,
							applyAgreeCheck : resData.applyAgreeCheck
						}

						this.$router.push({
							name   : 'nonstopcardregist',
							params : { data : params }
						});

					}else{
						//console.dir(res);
						alert("실패하였습니다.");
					}

				}))
				.catch(function (err) {
					//console.dir(err);
					alert("서버와 통신중 오류가 발생하였습니다.");
				});

				localStorage.setItem("nonstopData", JSON.stringify({
					applyAddr: this.applyAddr
				}));

			}
		},

		goPrevious(){
			this.$router.push('/nonstopinfo');
		},

		check(event){
			this.applyAgreeCheck = !this.applyAgreeCheck;
			event.preventDefault();
		}
	},
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	computed: {
		// setEmpInfo(){
		// 	this.applyId = this.$store.state.empNo;
		// 	this.applyNm = this.$store.state.empNm;
		// 	this.applyMobile = this.$store.state.empMobile;
		// },
		// applyId(){
		// 	return this.$store.state.empNo;
		// },
		// applyNm(){
		// 	return this.$store.state.empNm;
		// },
		// applyMobile(){
		// 	return this.$store.state.empMobile;
		// },
	},

}
</script>

<style>

</style>