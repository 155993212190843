/* eslint-disable vue/valid-v-model */
<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">공영주차장</div>
			<ul>
				<li><router-link to="/introduce"      title="주차장 소개">주차장 소개</router-link></li>
				<li><router-link to="/map"            title="지도 검색">지도 검색</router-link></li>
				<li><router-link to="/list"           title="주차장 검색" class="active">주차장 검색</router-link></li>
				<li><router-link to="/feeinformation" title="주차장 요금안내">주차장 요금안내</router-link></li>
				<li><router-link to="/realtimeparking" title="실시간 주차정보">실시간 주차정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차장</h2>
				<h4>주차장 검색</h4>
			</article>
			<article>
				<div id="tabs">
					<ul class="tab-menu">
						<li><router-link to="/map" title="지도검색">지도검색</router-link></li>
						<li class="active"><router-link to="/list" title="주차장검색">주차장검색</router-link></li> 
					</ul>
					<div class="tab-content">
						<article class="search exception">
							<section>
								<article>
									<label for="selectboxParkingDivCd">구분</label>
									<select id="selectboxParkingDivCd" v-model="selectboxParkingDivCd" @change="searchBtn" style="margin-right: 15px;">
										<option :value="''">전체</option>
										<option :value="'1'">노외</option>
										<option :value="'2'">노상</option>
									</select>
									<label for="selectboxRegionDivCd">권역</label>
									<select id="selectboxRegionDivCd" v-model="selectboxRegionDivCd" @change="searchBtn">
										<option :value="''">전체</option>
										<option :value="'1'">서부권</option>
										<option :value="'2'">동부권</option>
										<option :value="'3'">동탄</option>
										<option :value="'4'">공원</option>
									</select>
								</article>
								<article class="search-word">
									<label for="selectbocParkingDong">검색어</label>
									<select id="selectbocParkingDong" v-model="selectbocParkingDong">
										<option :value="''">전체</option>
										<option :value="'1'">주차장명</option>
										<option :value="'2'">주소</option>
									</select>
										<label for="parkingInput" class="hide">주차장 주소입력</label>
										<input type="text" v-model="inputParking" placeholder="주차장/주소 입력" class="place-hide" id="parkingInput" @keyup.enter="searchBtn">
										<button class="table-btn" @click="searchBtn">조회</button>
								</article>
							</section>
							<section class="hide">
								리스트에서 엔터키를 두번 클릭하시면 상세보기로 넘어갑니다.
							</section>
							<section id="dataDiv">
								<table class="default-table">
									<caption class="sr-only">공영주차장 주차장검색 리스트</caption>
									<colgroup>
										<col style="width:10%" />
										<col style="width:10%" />
										<col style="width:20%" />
										<col style="width:40%" />
										<col style="width:10%" />
									</colgroup>
									<thead>
										<tr>
											<th scope="col">권역</th>
											<th scope="col">주차장구분</th>
											<th scope="col">주차장명</th>
											<th scope="col">주소</th>
											<!--
											<th scope="col">기본요금(30분)</th>
											<th scope="col">간격요금(10분)</th>
											-->
											<th scope="col">요금구분</th>
										</tr>
									</thead>
									<tbody>
										<tr class="nodate" v-if="nodata">
											<td colspan="5">조회된 데이터가 없습니다.</td>
										</tr>
										<tr v-for="(item, index) in datosPaginados" :key="index" @click="detailInfo(item), $vuetify.goTo('footer', { duration:1000})" @keyup.enter="detailInfo(item)">
											<td scope="row">{{ item.REGION_DIV_NM }}</td>
											<td>{{ item.PARKING_DIV_NM }}</td>
											<td>{{ item.PARKING_NM }}</td>
											<td>{{ item.CELL_ADDR_JIBUN == null ? '정보없음' : item.CELL_ADDR_JIBUN }}</td>
											<!--
											<td>{{ amountRegex(item.DFLT_AMT) }}</td>
											<td>{{ amountRegex(item.INTVL_AMT) }}</td>
											-->
											<td>{{ item.FREE_YN == "Y" ? '무료' : '유료' }}</td>
										</tr>
									</tbody>
								</table>
							</section>
						</article>
						<article>
							<nav>
								<ul class="pagination">
									<li class="first" @click="getDataPagina(1)" :style="isFirst()"><a class="" href="#" title="처음">&lt;&lt;</a></li>
									<li class="prev" @click="getPreviousBlock()" :style="isFirst()"><a class="" href="#" title="이전">&lt;</a></li>
									<li v-for="pagina in pageNum" :key="pagina" @click="getDataPagina(pagina)" :class="isActive(pagina)"><a href="#">{{ pagina }}</a></li>
									<li class="next" @click="getNextBlock()" :style="isLast()"><a class="" href="#" title="다음">&gt;</a></li>
									<li class="last" @click="getDataPagina(totalPage())" :style="isLast()"><a class="" href="#" title="마지막">&gt;&gt;</a></li>
								</ul>
							</nav>
						</article>
						<article v-show="parkingDetailFlag">
							<h3 id="parkingName">주차장 상세보기</h3>
							<table class="default-table-view">
								<caption class="sr-only">공영주차장 리스트</caption>
								<colgroup>
									<col style="width:20%" />
									<col style="width:80%" />
								</colgroup>
								<tbody>
									<tr>
										<th scope="row">권역</th>
										<td>
											{{ regionDivNm }}
										</td>
									</tr>
									<tr>
										<th scope="row">주차형태</th>
										<td>
											<ul class="parking-type">
												<li>{{ parkingDivCd }}</li>
											</ul>
										</td>
									</tr>
									<tr>
										<th>공영주차장명</th>
										<td>{{ parkingNm }}</td>
									</tr>
									<tr>
										<th>공영주차장 주소</th>
										<td class="parking-addr" >
											{{ parkingCellAddr }}
											<button type="button"  class="table-in-btn black map" style="margin-left: 15px;" @click="[kakaoMap(parkingDetailItem), popupShow(), popupFocus()]" ref="focus">지도 확인</button>
										</td>
									</tr>
									<tr v-if="freeYn == 'N'">
										<th>운영 시간</th>
										<td>{{ parkingUseTm }}</td>
									</tr>
									<tr>
										<th>주차 요금</th>
										<!-- <td v-if="freeYn == 'N'">{{ parkingAmt }}</td> -->
										<td v-if="freeYn == 'N'">주차장 요금안내 참고</td>
										<td v-else>무료</td>
									</tr>
									<tr v-if="parkingPeriodAmt !== '0'">
										<th>월 정기권 금액</th>
										<td>{{ parkingPeriodAmt }}</td>
									</tr>
									<tr>
										<th>주차정보</th>
										<td>
											<ul>
												<li>
													<span>주차면수</span><u>{{ parkingCellCnt }}</u>
												</li>
												<li>
													<span>장애전용면수</span><u>{{ hndcapCellCnt }}</u>
												</li>
											</ul>
										</td>
									</tr>
								</tbody>
							</table>
						</article>
					</div>
				</div>
			</article>	
		</section>

		<!-- 모달 지도 -->
		<template>
			<div v-show="mapModal" class="kakaoMap" id="kakao">
				<section class="modal-kakaoMap">
					<article class="title">
						<ul>
							<li id="modalTit">
								지도 확인
								<span class="hide">팝업창 (스페이스 키를 누르면 닫기 버튼으로 이동이 가능합니다. )</span>	
							</li>
							<li id="modalMap" class="popup-close" @click="mapController" @keyup.space.enter.esc="mapController($event)">
								<span class="hide">닫기 아이콘 (엔터키를 누르시면 팝업창이 닫아집니다.)</span>
							</li>
						</ul>
					</article>
					<article class="content parking-inner">
						<div class="content-inner" id="container">

							<!-- Map 화면 들어갈 곳 -->
							<div id="mapWrapper">

								<div id="map" class="mapWH100"></div>

							</div>

						</div>
					</article>
				</section>
				<section class="dim"></section>
			</div>
		</template>

	</main>
</template>

<script>
import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			nodata                : false,
			selectboxParkingDivCd : "",	// 주차장 구분
			selectboxRegionDivCd  : "", // 주차장 권역
			ifParkingDivCd        : "",	// 주차장 구분
			selectbocParkingDong  : "",	// 주차장 명, 도로 명
			inputParking          : "",	// 주차장 이름, 동, 주소
			parkingDetailFlag     : false,

			parkingList       : [],
			parkingDivCd      : "",
			parkingDivNm      : "",
			parkingNm         : "",
			parkingCellAddr   : "",
			parkingUseTm      : "",
			parkingAmt        : "",
			parkingPeriodAmt  : "",
			parkingCellCnt    : "",
			parkingUseCnt     : "",
			parkingCurrentCnt : "",
			parkingDetailItem : "",
			regionDivNm       : "",
			freeYn            : "",
			hndcapCellCnt     : "",

			mapModal              : false,
			overlayOn             : false,
			container             : null,
			map                   : null,
			userLat               : 37.19952770562244,
			userLng               : 126.83145146431667,
			mapContainer          : null,
			imageSrc              : null,
			imageSize             : null,
			imageOption           : null,
			clickImageOption      : null,
			marker                : null,
			active                : false,
			parkingName           : null,
			defaultParkingMapList : [],	// 주차장저장리스트
			markers               : [],	// 마커최소 저장리스트
			positions             : [],
			customOverlay         : [],
			mapGeocoder           : null,
			clickCustomOverlay    : [],
			clickStatus           : -1,	// 1 누른상태 , -1 취소상태
			cMarkerIndex          : -1,
			geoLocationState      : -1,
			userCustomOverlay     : null,
			userLocCustomOverlay  : null,
			distance              : "",
			message               : "<div style='padding:5px;'>화성시청</div>",
			park                  : "",
			def                   : "",
			defFlag               : false,
			ckImg                 : null,
			//id                  : "active",
			/**
			 * Pagination
			 */
			pageSize        : 5,
			datosPaginados  : [],
			paginaActual    : 1,
			on              : false,
			blockSize		: 5,
			pageNum			: [], 
			startBlock		: 1,
			endBlock		: 1,
		}
	},

	mounted(){

		this.inputParking = this.$route.params.data;

		this.searchBtn();

		(window.kakao && window.kakao.maps) ? this.initMap() : this.createScript();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

		const layer = document.querySelector("#kakao"),
		layerArrow = layer.querySelectorAll("li#modalMap, li#modalTit"),
		layerFirst = layerArrow[0],
		layerLast = layerArrow[layerArrow.length -1];

		const firstKeydown = e => {
			if (e.shiftKey && e.key === "Tab") {
					e.preventDefault();
          layerLast.focus();
			}
		};

		const lastKeydown = e => {
				if (!e.shiftKey && e.key === "Tab") {
						e.preventDefault();
						layerFirst.focus();
				}
		};

		layerFirst.addEventListener("keydown", firstKeydown);
		layerLast.addEventListener("keydown", lastKeydown);
	},

	methods: {
		popupShow(){
			this.on = true;
		},

		mapController(){
			this.mapModal=!this.mapModal;
			this.$refs.focus.focus();
		},

		mapMarker(){
			return require("../../../assets/images/map/step2.png");
		},

		mapPcMarker(){
			return require("../../../assets/images/map/map_pc.png");
		},

		createScript(){
			const script  = document.createElement("script");
			script.onload = () => kakao.maps.load(this.initMap);
			script.src    = "//dapi.kakao.com/v2/maps/sdk.js?appkey=5130af43da731f05b3be59b4730e2676&libraries=services&autoload=false";
			document.head.appendChild(script);
		},

		initMap(){

			this.container = document.getElementById('container');		// 지도와 로드뷰를 감싸고 있는 div

			const mapContainer = document.getElementById('map');		// 지도를 표시할 div

			var mapCenter = new kakao.maps.LatLng(this.userLat, this.userLng);

			// 지도를 생성할 때 필요한 기본 옵션
			const mapOptions = {
				center : mapCenter,	// 지도의 중심좌표
				level  : 1			// 지도의 레벨(확대, 축소 정도)
			};

			// 지도를 표시할 div와 지도 옵션으로 지도를 생성
			this.map = new kakao.maps.Map(mapContainer, mapOptions);

			//일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
			var mapTypeControl = new kakao.maps.MapTypeControl();

			// kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
			this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);	// 지도에 컨트롤을 추가해야 지도위에 표시됩니다
			// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다

			this.imageSrc    = this.mapMarker();							// 마커이미지의 주소입니다
			this.imageSize   = new kakao.maps.Size(20, 20);					// 마커이미지의 크기입니다
			this.imageOption = { offset: new kakao.maps.Point(10, 10) };	// 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

			this.displayMarker(this.userLat, this.userLng);
			
		},

		// 현재 위치에 표시를 위한 마커 만들어준다
		displayMarker(userLat, userLng){

			var locPosition   = new kakao.maps.LatLng(userLat, userLng);
			this.map.setCenter(locPosition);
			this.map.relayout();

		},

		mapClickReset(){
			// 만약 클릭한 상태라면, 기존의 오버레이를 지운다.
			if(this.clickStatus == 1){

				for(var i = 0 ; i < this.positions.length ; i++){
					this.customOverlay[i].setMap(null);
				}

			}

			this.map.relayout();
		},

		// [지도 바로가기] 클릭
		kakaoMap(p){
			this.mapModal = true;

			this.mapClickReset();

			this.userLat   = p.LAT;
			this.userLng   = p.LNG;
			this.parkingNm = "[" + p.PARKING_DIV_NM + "] " + p.PARKING_NM;

			var parkingLot = {};
			parkingLot.title  = this.parkingNm;
			parkingLot.latlng = new kakao.maps.LatLng(this.userLat, this.userLng);

			this.positions = [];
			this.positions.push(parkingLot);

			this.setMapInit(this.positions);
		},

		setMapInit(positions){
			setTimeout(() => {
				this.map.relayout();
				this.setMarkers(positions);
				this.map.setLevel(1);
				this.map.setCenter(positions[0].latlng);
			}, 1);
		},

		// 각 주차장 정보를 받아 마커를 지도에 뿌려준다.
		setMarkers(positions, limitKm){
			this.controlhideMarkers();
			this.markers = [];

			// 초록색
			var markerImage = new kakao.maps.MarkerImage(this.imageSrc, this.imageSize, this.imageOption);

			if(positions != null) {
				for(var i = 0 ; i < positions.length ; i++){

					this.markers[i] = new kakao.maps.Marker({
						map       : this.map,				// 마커를 표시할 지도
						position  : positions[i].latlng,	// 마커의 위치
						title     : positions[i].title,
						clickable : true,					// 마커 클릭가능. (지도의 클릭이벤트가 발생하지않도록 함)
						id        : positions[i].id,
						image     : markerImage
					});

					var customContent	= '<div class="mapCustomOverlay-box" style="min-height:0; width:200px;">'
										+     '<dl style="margin-bottom: 0px;">'
										+         '<dt>' + positions[i].title + '</dt>'
										+     '</dl>'
										+ '</div>'
					;

					this.customOverlay[i] = new kakao.maps.CustomOverlay({
						position  : positions[i].latlng,
						content   : customContent,
						xAnchor   : 0.017,
						yAnchor   : 0.5
					});

					//kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeOverListener(this.markers[i], this.customOverlay[i], positions[i].title));	// 마커 호버 이벤트
					kakao.maps.event.addListener(this.markers[i], 'mouseover', this.makeClickListener(this.markers[i], i, this.customOverlay[i]));	// 마커 호버 이벤트
					//kakao.maps.event.addListener(this.markers[i], 'click'    , this.makeClickListener(this.markers[i], i, this.customOverlay[i]));						// 마커 클릭 이벤트
					kakao.maps.event.addListener(this.markers[i], 'mouseout' , this.makeOutListener(this.customOverlay[i], this.markers[i], i));						// 마커 호버아웃 이벤트
				}
			}

		},

		// "마커 감추기" 버튼을 클릭하면 호출되어 배열에 추가된 마커를 지도에서 삭제하는 함수입니다
		controlhideMarkers(){
			this.controlsetMarkers(null);
		},

		controlsetMarkers(map){
			for (var i = 0 ; i < this.markers.length ; i++) {
				this.markers[i].setMap(map);
			}
		},
		
		makeClickListener(marker, idx, customOverlay){
			return () => {

				// 로드뷰 오버레이 나왓을시 마커 클릭 안되게함.
				if(this.overlayOn) {
					return null;
				}

				this.def         = this.positions[idx];
				this.defFlag     = true;
				this.clickStatus = 1;

				if(this.cMarkerIndex != -1){
					customOverlay.setMap(null);
					if(this.clickCustomOverlay){
						this.clickCustomOverlay.setMap(null);
					}
				}

				for(var i = 0 ; i < this.markers.length ; i++){

					if(this.markers[i] == marker){
						this.cMarkerIndex = i;													// 클릭된 마커 id값 저장
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);	// 클릭된 마커 크기및 이미지 변경
						kakao.maps.event.removeListener(this.markers[i], 'mouseout', this.makeOutListener(customOverlay, this.markers[i]));
						customOverlay.setMap(this.map);
						this.clickCustomOverlay = customOverlay;
					}else{
						this.changeImage(this.markers[i], this.mapMarker(), this.imageOption);
					}

				}

			}
		},

		// 마크 에 마우스를 빼면 발생하는 이벤트(아웃)
		makeOutListener(customOverlay, marker, idx){
			return () => {

				if(idx != this.cMarkerIndex){

					var markerImage = new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption);
					marker.setImage(markerImage);
					customOverlay.setMap(null);

				}else{

					if(this.clickStatus == 1){
						marker.setImage(new kakao.maps.MarkerImage(this.mapMarker(), this.imageSize, this.imageOption));
						customOverlay.setMap(null);
					}

				}

			}
		},

		// 거리 및 확대레벨에 따른 마커 출력 함수
		changeImage(markers, imageSrc, imageOption){

			var markerImage = new kakao.maps.MarkerImage(imageSrc, this.imageSize, imageOption);

			markers.setImage(markerImage);
			markers.setMap(this.map);

		},

		// 권역
		getRegionDivCd(){
			var rdc = this.selectboxRegionDivCd;
			if(rdc == "1"){
				return "RGT-001";
			}else if(rdc == "2"){
				return "RGT-002";
			}else if(rdc == "3"){
				return "RGT-003";
			}else if(rdc == "4"){
				return "RGT-004";
			}else{
				return null;
			}
		},

		getParkingDivCd(){
			var pdc = this.selectboxParkingDivCd;
			if(pdc == "1"){
				return "PLT-003";
			}else if(pdc == "2"){
				return "PLT-002";
			}else{
				return null;
			}
		},

		totalPage(){
			return Math.ceil(this.parkingList.length / this.pageSize);
		},

		getDataPagina(noPagina){
			
			this.datosPaginados = [];
			this.paginaActual = noPagina;
			let ini = (noPagina * this.pageSize) - this.pageSize;
			let fin = (noPagina * this.pageSize);
			this.datosPaginados = this.parkingList.slice(ini, fin);

			var pageNumlist = [];
			var nowBlock = Math.ceil(this.paginaActual / this.blockSize);
			this.startBlock = ((nowBlock - 1) * this.blockSize) + 1;
			this.endBlock = this.startBlock + this.blockSize - 1
			var i = this.startBlock;

			if(this.endBlock > this.totalPage()) {
				this.endBlock = this.totalPage();
			}

			for (i; i <= this.endBlock; i++) {
				pageNumlist.push(i);
			}

			this.pageNum = pageNumlist;
		},

		getPreviousBlock(){
			this.getDataPagina(this.startBlock - 1);
		},

		getNextBlock(){
			this.getDataPagina(this.endBlock + 1);
		},

		isActive(noPagina){

			return noPagina == this.paginaActual ? 'active' : '';

		},

		isFirst(){
			return this.startBlock == 1 ? { display : 'none' } : "";
		},

		isLast(){
			return this.endBlock == this.totalPage() ? { display : 'none' } : "";
		},
		
		searchBtn : function(){

			this.parkingDetailFlag = false;
			var jsonList = [];

			if(this.selectbocParkingDong != "" && this.inputParking == ""){
				this.selectbocParkingDong = "";
			}

			instance.get('/api/parking/searchParkingList.json', {
				params: {
					searchParking : this.inputParking,
					parkingDong   : this.selectbocParkingDong,
				}
			}).then((response => {
				var data = response.data;

				if(data.parkingList && data.parkingList.length > 0){
					this.parkingList = data.parkingList;

					for(var i = 0 ; i < this.parkingList.length ; i++) {
						if(this.getParkingDivCd() != null){
							if(this.getParkingDivCd() == this.parkingList[i].PARKING_DIV_CD){

								if(this.getRegionDivCd() != null){
									if(this.getRegionDivCd() == this.parkingList[i].REGION_DIV_CD){
										// (서부권 / 동부권 / 동탄) 일때
										jsonList.push(this.parkingList[i]);
									}
								}else{
									// (노상 / 노외)일때
									jsonList.push(this.parkingList[i]);
								}

							}
						}else{
							
							if(this.getRegionDivCd() != null){
								if(this.getRegionDivCd() == this.parkingList[i].REGION_DIV_CD){
									// (서부권 / 동부권 / 동탄) 일때
									jsonList.push(this.parkingList[i]);
								}
							}else{
								// 전체일때
								jsonList.push(this.parkingList[i]);
							}

						}
					}

					this.parkingList    = jsonList;
					this.datosPaginados = jsonList.slice(0, 5);

					if(this.parkingList.length > 0){
						this.nodata = false;
					}else{
						this.nodata = true;
					}

				}else{
					this.parkingList    = [];
					this.datosPaginados = null;
					this.nodata         = true;
				}

				this.getDataPagina(1);

				//console.dir(this.parkingList);
			})).catch(function (error) {
				// console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});
		},
		
		detailInfo : function(item){
			
			this.regionDivNm   = item.REGION_DIV_NM;
			this.freeYn        = item.FREE_YN;
			this.hndcapCellCnt = item.HNDCAP_CELL_CNT;

			this.parkingDetailFlag = true;

			this.parkingDetailItem = item;
			var parkingDetail = item;
			
			if(parkingDetail.PARKING_DIV_CD == 'PLT-002'){
				this.parkingDivCd = "노상";
			}else if(parkingDetail.PARKING_DIV_CD == 'PLT-003'){
				this.parkingDivCd = "노외";
			}else if(parkingDetail.PARKING_DIV_CD == 'PLT-004'){
				this.parkingDivCd = "부설";
			}else if(parkingDetail.PARKING_DIV_CD == 'PLT-001'){
				this.parkingDivCd = "공사";
			}

			this.parkingDivNm    = parkingDetail.PARKING_DIV_NM;
			this.parkingNm       = parkingDetail.PARKING_NM;
			this.parkingCellAddr = parkingDetail.CELL_ADDR_LOAD;

			this.parkingUseTm	= "주중: " + parkingDetail.WDAYS_START_TM + " ~ " + parkingDetail.WDAYS_END_TM 
								+ " 주말: " + parkingDetail.WEND_START_TM + " ~ " +  parkingDetail.WEND_END_TM
			;
			this.parkingAmt	= "기본: " + this.amountRegex(parkingDetail.DFLT_AMT) + "원(" + parkingDetail.DFLT_TM + "분)"
							+ " 추가: " + this.amountRegex(parkingDetail.INTVL_AMT) + "원(" + parkingDetail.INTVL_TM + "분당)"
							+ " 전일: " + this.amountRegex(parkingDetail.DAY_PARKING_AMT) + "원"
			;
			this.parkingPeriodAmt = this.amountRegex(parkingDetail.PERIOD_TICKET_AMT);

			var params = new FormData();
			params.append("PARKING_ID", parkingDetail.PARKING_ID);

			instance.post('/api/parking/operating.json', params)
			.then((response => {
				var cnt = response.data.operating.result;

				this.parkingCellCnt    = cnt.CELL_CNT == null ? "-" : cnt.CELL_CNT;
				this.parkingUseCnt     = cnt.USE_CNT == null ? "-" : cnt.USE_CNT;
				this.parkingCurrentCnt = cnt.CURRENT_CNT == null ? "-" : cnt.CURRENT_CNT;
				
			})).catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.");
			});

			document.querySelector('#parkingName');
			document.querySelector('#parkingName').focus();
		},

		amountRegex: function(x){
			return x != null ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
		},

		popupFocus(){
			this.mapModal = true;
			setTimeout(function(){
				document.getElementById('modalTit').focus();
			});
		},
	},
}

</script>

<style lang="scss" scoped>
	#tabs{
		.tab-menu{
			display: flex;

			li{
				padding: 10px 0;
				width: 50%;
				text-align: center;
				border: 1px solid $color;

				a{
					color: $color;
				}

				&.active{
					@include gradient;

					a{
						color:$white;
					}
				}
			}
		}
	}

	.mapWH100{ width: 100%; height: 100%; }

	#container {
		overflow : hidden;
		height   : 590px;
		position : relative;
	}
	#mapWrapper {
		width   : 100%;
		height  : 550px;
		z-index : 5;
	}

	.modal-kakaoMap {
		position   : fixed;
		border     : 1px solid;
		display    : block;
		background : #FFFFFF;
		left       : 50%;
		top        : 50%;
		width      : 700px;
		height     : 675px;
		transform  : translate(-50%, -50%);
		z-index    : 2;
	}

	.modal-kakaoMap .title {
		background : #333333;
		height     : 50px;
	}

	.modal-kakaoMap .title ul {
		margin-top : 0 !important;
	}

	.modal-kakaoMap .title ul {
		display         : flex;
		justify-content : space-between;
		padding         : 10px 20px;
		box-sizing      : border-box;
		list-style      : none;
	}

	.modal-kakaoMap .title ul li:first-child {
		padding-left : 0;
		width        : 700px;
		text-align   : center;
		box-sizing   : border-box;
	}

	.modal-kakaoMap .title ul li {
		color       : #FFFFFF;
		font-size   : 1.25rem;
		font-weight : 400;
		list-style  : none;
	}

	.modal-kakaoMap .title ul li.popup-close {
		cursor : pointer;
	}

	.modal-kakaoMap .content {
		height     : 620px;
		padding    : 15px 15px 30px;
		box-sizing : border-box;
	}

	.modal-kakaoMap .content .content-inner {
		padding     : 20px;
		background  : #EFEFEF;
		height      : 100%;
		text-align  : center;
		line-height : 1.5;
		box-sizing  : border-box;
	}

	.modal-kakaoMap .btn-list {
		margin-top  : 45px;
		padding-top : 25px;
		border-top  : 1px solid #eeeeee;
	}
</style>