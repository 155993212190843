<template>
	<main id="subTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청</h4>
			</article>
			<article>
				<h3>03. 주민등록(재직증명)상의 정보</h3>
				<table class="vertical-table mo">
					<caption class="sr-only">주민등록(재직증명)상의 정보</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">신청자구분</th>
							<td>
								<ul class="input-radio">
									<li>
										<input type="radio" id="resident" v-model="applicant" :value="{id:'APT-001', text:'거주자'}">
										<label for="resident">거주자</label>
									</li>
									<li>
										<input type="radio" id="fullTime" v-model="applicant" :value="{id:'APT-002', text:'상근자'}">
										<label for="fullTime">상근자</label>
									</li>
									<div class="error" v-if="!$v.applicant.required">필수 입력란입니다.</div>
								</ul>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">주소</th>
							<td>
								<ul class="input-style button">
									<li>
										<label for="zipcode" class="hide">우편번호</label>
										<input type="text" placeholder="우편번호" class="place-hide" v-model="zipCode" id="zipcode">
										<button class="table-btn black" @click="daumPostcodeBtn">우편번호</button>
									</li>
									<li>
										<label for="address" class="hide">주소</label>
										<input type="text" placeholder="주소" class="place-hide" v-model="address" id="address">
									</li>
									<li>
										<label for="addressDetail" class="hide">상세주소</label>
										<input type="text" placeholder="상세주소" class="place-hide" v-model="addressDetail" ref="addressDetail" id="addressDetail">
									</li>
								</ul>
								<div class="error" v-if="!$v.zipCode.required || !$v.address.required || !$v.addressDetail.required">필수 입력란입니다.</div>
							</td>
						</tr>
						<tr>
							<th class="asterisk-after">전입 일자</th>
							<td>
								<date-picker @emitSetDate="setDate"></date-picker>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
      <article class="btn-list">
				<button class="btn" @click="previousBtn">이전</button>
				<button class="btn-border" @click="nextBtn">다음</button>
		  </article>
		</section>
		<pop-up v-if="showModal" @emitHideModal="showModal = false" @emitSearchedAddress="searchedAddress">
			<template #info>
				<li>우편번호</li>
			</template>
	
			<template #close>
				<li></li>
			</template>
		</pop-up>
	</main>
</template>

<script>
import DatePicker from '../../../components/DatePicker1.vue';
import { required } from 'vuelidate/lib/validators'
import PopUp from '../../../components/PopupType6.vue';

export default {
	data(){
		return {
			userNm: '',
			userAddress: '',
			useHpPhone: '',
			useTelPhone: '',
			carNumber: '',
			carOwnerNm: '',
			// registerNumber1: '',
			// registerNumber2: '',
			discountCd: '',
			renYnCheckBox: '',
			applicant: '',
			zipCode: '',
			address: '',
			addressDetail: '',
			movingDt: new Date().toISOString().substr(0, 10),
			submitStatus: '',
			showModal: false
		}
	},

	validations: {
		applicant: {
			required
		},
		zipCode: {
			required
		},
		address: {
			required
		},
		addressDetail: {
			required
		}
	},

	created() {
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("residentApplData"));
		}else{
			localStorage.setItem("residentApplData", JSON.stringify(data));
		}

		this.userNm = data.userNm;
		this.userAddress = data.userAddress;
		this.useHpPhone = data.useHpPhone;
		this.useTelPhone = data.useTelPhone;
		this.carNumber = data.carNo;
		this.carOwnerNm = data.carOwnerNm;
		// this.registerNumber1 = data.registerNumber1;
		// this.registerNumber2 = data.registerNumber2;
		this.discountCd = {id: data.discountCd, text: data.discountNm};
		// this.discountNm = data.discountNm;
		this.renYnCheckBox = data.renYnCheckBox;
		this.applicant = {id: data.applicant, text: data.applicantNm};
		// this.applicant = data.applicant;
		// this.applicantNm = data.applicantNm;
		// this.zipCode = data.zipCode;
		// this.address = data.address;
		// this.addressDetail = data.addressDetail;
		this.zipCode = this.$store.state.zipcode;
		this.address = this.$store.state.addr;
		this.addressDetail = this.$store.state.addrDetail;
		if(typeof data.movingDt === 'undefined'){
		}else {
			this.movingDt = data.movingDt;
		}
	},

	methods: {
		daumPostcodeBtn() {
			this.showModal = true;
		},

		setDate(date){
			this.movingDt = date;
		},

		previousBtn() {
			this.$router.push('vehicleinfo');
		},

		nextBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR"
			}else {
				this.submitStatus = "OK";
				let params = {
					userNm : this.userNm,
					userAddress : this.userAddress,
					useHpPhone : this.useHpPhone,
					useTelPhone : this.useTelPhone,
					carNo : this.carNumber,
					carOwnerNm : this.carOwnerNm,
					// registerNumber1 : this.registerNumber1,
					// registerNumber2 : this.registerNumber2,
					discountCd : this.discountCd.id,
					discountNm : this.discountCd.text,
					renYnCheckBox : this.renYnCheckBox,
					applicant : this.applicant.id,
					applicantNm : this.applicant.text,
					zipCode : this.zipCode,
					address : this.address,
					addressDetail : this.addressDetail,
					movingDt : this.movingDt
				}

				this.$router.push({
					name : 'parkinginfo',
					params: {data : params}
				})
			}
		},

		searchedAddress(data) {
			this.zipCode = data.zonecode;
			// 도로명을 선택하는 경우
			if(data.userSelectedType == 'R'){
				this.address = data.address;
			}else {
				this.address = data.jibunAddress;
			}
			this.$refs.addressDetail.focus();
			this.showModal = false;
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	components : {
		DatePicker,
		PopUp
	}
};
</script>

<style>

</style>