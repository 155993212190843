<template>
	<section>
		<article>
			<select v-model.number="dataPerPage" @change="emitPerChange" disabled>
				<option value="10">10개씩 보기</option>
				<option value="30">30개씩 보기</option>
				<option value="50">50개씩 보기</option>
			</select>
		</article>
		<article>
			<ul class="input-style">
				<slot name="searchUseYn"></slot>
				<li>
					<select v-model="searchCondition">
						<slot name="searchCondition"></slot>
					</select>
				</li>
				<li>
					<input type="text" v-model="searchKeyword" @keyup.enter="emitSearch"/>
				</li>
				<li><button class="table-btn" @click="emitSearch">조회</button></li>
			</ul>
		</article>
	</section>
</template>

<script>
export default {
	data(){
		return{
			dataPerPage: 10,
			searchCondition: 1,
			searchKeyword: null
		}
	},

	methods: {
		emitSearch() {
			this.$emit('emitSearch', this.searchCondition, this.searchKeyword);
		},

		emitPerChange() {
			this.$emit('emitPerChange', this.dataPerPage);
		}
	}
}
</script>

<style>

</style>