<template>
	<main id="subTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>주차장 신청</h4>
			</article>
			<article>
				<h3>01. 신청자 정보</h3>
				<table class="vertical-table mo">
					<caption class="sr-only">신청자 정보</caption>
					<colgroup>
						<col style="width:20%; min-width: 80px;"/>
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>{{userNm}}</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>{{userAddress}}</td>
						</tr>
						<tr>
							<th class="asterisk-after">휴대폰번호</th>
							<td>
                <ul class="input-style tel">
                <li>
									<label for="phoneNumber" class="hide">휴대폰 번호</label>
                  <input type="text" placeholder="'-' 제외하고 입력" class="place-hide" v-model="useHpPhone" id="phoneNumber">
									<div class="error" v-if="!$v.useHpPhone.required">필수 입력란입니다.</div>
									<div class="error" v-if="!$v.useHpPhone.numRegex">숫자만 입력 가능합니다.</div>
                </li>
              </ul>
							</td>
						</tr>
						<tr>
							<th>전화번호</th>
							<td>
                <ul class="input-style tel">
                <li>
									<label for="telNumber" class="hide">전화번호</label>
                  <input type="text" placeholder="'-' 제외하고 입력" class="place-hide" v-model="useTelPhone" id="telNumber">
									<div class="error" v-if="!$v.useTelPhone.numRegex">숫자만 입력 가능합니다.</div>
                </li>
              </ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<p class="typo__p" v-if="submitStatus === 'ERROR'">필수 입력란을 채워주세요.</p>
      <article class="btn-list right">
        <button class="btn" @click="nextBtn">다음</button>
      </article>		
		</section>
	</main>
</template>

<script>
import { required, helpers } from 'vuelidate/lib/validators'

const numRegex = helpers.regex('numRegex', /\d/g);

export default {
	data(){
		return{
			userNm: '',
			userAddress: '',
			useHpPhone: '',
			useTelPhone: '',
			submitStatus: '',
		}
	},

	validations: {
		useHpPhone: {
			required,
			numRegex
		},
		useTelPhone: {
			numRegex
		}
	},

	created(){
		var data = this.$route.params.data;

		if(data == null){
			data = JSON.parse(localStorage.getItem("residentApplData"));
		}else{
			localStorage.setItem("residentApplData", JSON.stringify(data));
		}

		this.userNm = this.$store.state.empNm;
		this.userAddress = this.$store.state.addr + ' ' + this.$store.state.addrDetail;
		this.useHpPhone = this.$store.state.empMobile,
		this.useTelPhone = data.useTelPhone
	},

	methods: {
		nextBtn() {
			if(this.$v.$invalid) {
				this.submitStatus = "ERROR"
			}else {
				this.submitStatus = "OK";
				let params = {
					userNm : this.userNm,
					userAddress : this.userAddress,
					useHpPhone : this.useHpPhone,
					useTelPhone : this.useTelPhone
				}

				this.$router.push({
					name : 'vehicleinfo',
					params: {data : params}
				})
			}
		}
	},

	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
};
</script>

<style>

</style>