<template>
  <div v-show="showModal" id="popup">
    <section
      class="popup-layer"
      data-focus-prev="prev"
      v-bind:style="{
        height: sizeX + 'px',
        width: sizeY + 'px',
        top: posX + 'px',
        left: posY + 'px',
      }"
    >
      <article class="title">
        <ul>
          <li>
            <slot name="info"></slot>
          </li>
          <li
            class="popup-close"
            tabindex="1001"
            @click="modalHide"
            @keydown.enter="modalHide"
            data-focus-next="next"
          >
            <slot name="close"></slot>
          </li>
        </ul>
      </article>
      <article class="content">
        <div class="content-inner" style="text-align: initial">
          <slot name="content"></slot>
          <a :href="linkUrl">
            ▶ 링크 이동하기 ◀
            <!-- <img :src="attachFile" alt="팝업이미지"/> -->
          </a>
        </div>
      </article>
      <article class="chk-list">
        <slot name="chkbox"></slot>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: true,
      posX: "",
      posY: "",
      sizeX: "",
      sizeY: "",
      linkUrl: "",
      repoId: "",
      filePath: "",
      saveNm: "",
      fileEx: "",
      attachFile: "",
    };
  },

  methods: {
    modalHide() {
      this.showModal = false;
    },

    setProps() {
      if (this.propsData != null) {
        this.posX = this.propsData.posX;
        this.posY = this.propsData.posY;
        this.sizeX = this.propsData.sizeX;
        this.sizeY = this.propsData.sizeY;
        this.linkUrl = this.propsData.linkUrl;
        this.repoId = this.propsData.repoId;
        this.filePath = this.propsData.filePath;
        this.saveNm = this.propsData.saveNm;
        this.fileExt = this.propsData.fileExt;

        this.attachFile =
          "/api/admin/popup/atchfile/" +
          this.repoId +
          "/" +
          this.filePath +
          "/" +
          this.saveNm +
          "." +
          this.fileExt;
      }
    },

    url() {
      location.href = this.linkUrl;
    },
  },

  props: ["propsData"],

  watch: {
    propsData: {
      handler() {
        this.setProps();
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (this.showModal === true) {
      document.getElementById("popup").querySelector(".popup-layer").tabindex =
        "5000";
      document.getElementById("popup").querySelector(".now").focus();
    }

    const layer = document.querySelector("#popup"),
      layerFirst = layer.querySelector(".now"),
      layerLast = layer.querySelector(".popup-close");

    const firstKeydown = (e) => {
      if (e.shiftKey && e.key === "Tab") {
        e.preventDefault();
        layerLast.focus();
      }
    };

    const lastKeydown = (e) => {
      if (!e.shiftKey && e.key === "Tab") {
        e.preventDefault();
        layerFirst.focus();
      }
    };

    layerFirst.addEventListener("keydown", firstKeydown);
    layerLast.addEventListener("keydown", lastKeydown);
  },
};
</script>

<style scoped>
.dialogdrag-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.popup-layer {
  position: absolute;
}

#popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
</style>
