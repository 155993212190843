<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">비대면 자격확인</div>
			<ul>
				<li><router-link to="reductioninfo" title="비대면 자격확인 안내">안내</router-link></li>
				<li><router-link to="reductionagreement" title="비대면 자격확인 조회" class="active">비대면 자격확인 조회</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>비대면 자격확인</h2>
				<h4>비대면 자격확인 조회</h4>
			</article>
			<article>
				<h3>비대면 자격확인</h3>
				<ul>
					<li>화성도시공사는 행정안전부 즉시감면 서비스를 활용한 정보제공을 위하여 아래와 같이 개인정보를 수집, 이용 및 제3자 제공하고자 합니다.</li>
					<li>내용을 읽으신 후 동의 여부를 결정하여 주십시오.</li>
				</ul>
			</article>
			<article id="fullConsent">
				<input type="checkbox" id="agreement" v-model="allCheck" @click="checkAll">
				<label for="agreement" id="checkYn" @keydown.space="all" @keydown.enter="all"><span role="checkbox" aria-checked="false" aria-labelledby="checkYn" class="hide"></span>전체동의</label>
			</article>
			<article>
				<h3 style="margin-top: 10px;">개인정보 이용 동의</h3>
													
				<privacy-policy></privacy-policy>

				<input type="checkbox" id="privacy" v-model="check1" @change="checkboxCkc()">
				<label for="privacy" id="privacyUse" @keydown.space="check" @keydown.enter="check">
				<span role="checkbox" aria-checked="false" aria-labelledby="privacyUse" class="hide"></span>
				개인정보 수집 및 이용안내를 숙지하였습니다.</label>
			</article>

			<article class="btn-list">
				<button class="btn"        @click="agreeBtn">동의</button>
				<button class="btn-border" @click="pageIntro">동의안함</button>
			</article>	
		</section>
	</main>
</template>

<script>

import PrivacyPolicy from '../../../components/PrivacyPolicy.vue';

export default {
	data(){
		return{
			allCheck  : false,
			check1    : false,
			showModal : false,
			msg       : '',
		}
	},
	components:{
		PrivacyPolicy
	},
	methods:{
		checkboxCkc(){
			if(this.check1 == true){
				this.allCheck = true;
			}else{
				this.allCheck = false;
			}
		},

		checkAll(){
			if(this.allCheck == false){
				this.allCheck = true;
				this.check1 = true;
			}else{
				this.check1 = false;
			}
		},

		agreeBtn(){
			if(this.check1){
				this.$router.push('/reductionconfirm');
			}else{
				alert('개인정보이용에 동의하지 않으셨습니다.');
			}
		},

		popupFocus(){
			//document.getElementById('closeBtn').focus();
		},

		pageIntro(){
			this.$router.push('/reductioninfo');
		},

		all(event){
			event.preventDefault();
			this.allCheck = !this.allCheck;
			this.check1 = true;

			if(this.allCheck == false){
				this.check1 = false;
			}
		},

		check(event){
			event.preventDefault();
			this.check1 = !this.check1;
			
			if(this.check1 == false){
				this.allCheck = false;
			}else{
				this.allCheck = true;
			}
		}
	},
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style scoped>

.sub_wrap {
    padding: 0 0 100px;
}

.privacy_wrap {
    padding: 40px 0 100px;
    color: #666;
    font-size: 16px;
    line-height: 2em;
    display: block;
    overflow: hidden;
    clear: both;
    min-height: 400px;
	max-height: 12.5rem;
}

.sub_title_box {
    margin: 40px auto 0;
}

.sub_title_box.bd {
    border: 1px solid #d5d5d5;
    padding: 20px;
}

.sub_title_box.bd .sub_title {
    font-size: 20px;
    line-height: 1.3em;
}

.contains b {
    font-weight: 700;
}

.sub_title_box .desc {
    font-size: 15px;
    line-height: 1.6em;
    font-weight: 100;
    color: #666;
    word-break: break-all;
}

.sub_wrap .sub_title_box .sub_title + .desc {
    margin-top: 10px;
}

.sub_title_box .sub_title span.point {
    color: #005923;
}

.sub_wrap p {
    color: #666;
    font-size: 15px;
    line-height: 26px;
}

.agree_box {
    width: 100%;
    max-height: 12.5rem;
    background-color: #fff;
    border: 1px solid #ccc;
    overflow-y: auto;
    padding: 0.9rem;
}

.agree_box dl dt {
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 500;
}

.agree_box dl dd {
    font-size: 0.7rem;
    line-height: 1.2rem;
    font-weight: 300;
    margin-top: 0.75rem;
    margin-bottom: 1.75rem;
}

</style>