<template>
  <v-row>
    <v-col class="calendar">
      <v-menu ref="menu1" v-model="menu1" :close-on-content-click="true" :return-value.sync="s_Date" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="s_Date" solo v-model="startDate" v-bind="attrs" v-on="on" title="시작일자" ></v-text-field>
        </template>
        <v-date-picker locale="ko-KR" v-model="startDate" @change="emitSetDate"></v-date-picker>
      </v-menu>
    </v-col>
    <span>-</span>
    <v-col class="calendar">
      <v-menu ref="menu2" v-model="menu2" :close-on-content-click="true" :return-value.sync="e_Date" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="e_Date" solo v-model="endDate" v-bind="attrs" v-on="on" title="종료일자" ></v-text-field>
        </template>
        <v-date-picker locale="ko-KR" v-model="endDate" :min="startDate" @change="emitSetDate"></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      date      : new Date().toISOString().substr(0, 10),
      startDate : new Date().toISOString().substr(0, 10),
      endDate   : new Date().toISOString().substr(0, 10),
      s_Date    : '',
      e_Date    : '',
      menu1     : false,
      menu2     : false,
    }
  },

  mounted() {
    if(this.propsDate != null){
      this.startDate = this.propsDate.startDate;
      this.endDate = this.propsDate.endDate;
    }
  },

  methods : {
    emitSetDate(){
      this.$emit('emitSetDate', this.startDate, this.endDate);
    }
  },

  props: ['propsDate']

}
</script>
