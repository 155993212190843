<template>
	<main id="oneTemplet" class="email">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>이메일무단수집거부</h2>
			</article>
			<section id="email-content">
				<article class="email-notice">
					본 공사는 어떠한 이메일 수집 프로그램이나 기타 그 밖의 기술적 장치를 이용하여 본 사이트에 게시된 이메일 주소를 무단으로 수집하는 것을 거부하며, 이를 위반시 정보통신망법에 의해 형사처벌 될 수 있음을 알립니다.
				</article>	
				<article class="email-content">
					<h3>정보통신망법 제 50조의 2<span>(전자우편주소의 무단 수집행위 등 금지)</span></h3> 
					<ul>
						<li class="hyphen">
							누구든지 전자우편 주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으로 전자우편 주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편 주소를 수집하여서는 안된다.
						</li>
						<li class="hyphen">
							누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매 유통하여서는 아니된다.
						</li>
						<li class="hyphen">
							누구든지 제1항 및 제2항의 규정에 의하여 수집, 판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보전송에 이용하여서는 아니된다.
						</li>
					</ul>
				</article>
				<article class="email-bg">

				</article>
			</section>
		</section>
	</main>
</template>

<script>
export default {
  mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	}
}
</script>

<style>

</style>