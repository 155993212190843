<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">주차요금 사전결제</div>
			<ul>
				<li><router-link to="prepaymentinfo" title="사전결제 안내" >안내</router-link></li>
				<li><router-link to="lookup" title="사전결제 조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>주차요금 사전결제</h2>
				<h4>조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>결제내역 확인</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">결제내역 확인 테이블</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">차량번호</th>
						<td>{{ r.carNo }}</td>
					</tr>
					<tr>
						<th>이용주차장</th>
						<td>{{ r.parkingName }}</td>
					</tr>
					<tr>
						<th>이용시간</th>
						<td>
							<span>{{ r.inDttm }} ~ {{ r.outDttm }}</span>
							<span class="emphasis">{{ r.useTm | comma }}분</span>
						</td>
					</tr>
					<tr>
						<th>이용금액</th>
						<td>{{ r.useAmt | comma }} 원</td>
					</tr>
					<tr>
						<th>할인금액</th>
						<td>
							<p>{{ r.discountAmt | comma }} 원</p>
							<span class="emphasis">{{ discountCd.discountNm }}</span>
						</td>
					</tr>
					<tr>
						<th>차량번호 할증금액</th>
						<td>
							<p>{{ r.extraAmt | comma }} 원</p>
							<span class="emphasis-gray">{{ extraCd.discountNm }}</span>
						</td>
					</tr>
					<tr>
						<th>미납금액</th>
						<td>{{ r.unpaidSumAmt | comma }}원</td>
					</tr>
					<tr>
						<th>미납가산금액</th>
						<td>{{ r.addSumAmt | comma }}원</td>
					</tr>
					<tr>
						<th>상세내역</th>
						<td>
							이용금액 {{ r.useAmt | comma }} 원 - 
							할인금액 {{ r.discountAmt | comma }} 원 + 
							할증금액 {{ r.extraAmt | comma }} 원 + 
							미납금액 {{ r.unpaidSumAmt | comma }} 원 + 
							가산금액 {{ r.addSumAmt | comma }} 원 = 
							청구금액 {{ r.reqAmt | comma }} 원
						</td>
					</tr>
					<tr>
						<th>청구금액</th>
						<td>
							<ul class="billing">
								<li>{{ r.reqAmt | comma }} 원</li>
								<li class="asterisk">결제 후 10분 안에 출차하지 않으면 추가요금이 부과됩니다.</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
			<article class="message">
				<p>결제 후 10분 안에 출차하지 않으면 추가요금이 부과됩니다.</p>
			</article>

			<article>
			</article>
			<table class="default-table-view" v-if="!isMobile">
				<caption class="sr-only">결제내역 응답메시지 테이블</caption>
				<colgroup>
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td colspan="3">{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>
			<table class="default-table-view" v-else>
				<caption class="sr-only">결제내역 응답 메세지 테이블</caption>
				<colgroup>
					<col style="width:30%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
					</tr>
					<tr>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat}}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td>{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>

			<article class="message">
				<p v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">결제가 완료 되었습니다.</p>
			</article>

			<article class="btn-list">
				<button class="one-btn" @click="cancelBtn(r.carNo)">확인</button>
			</article>

		</section>
	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return{
			fResCd     : '',
			resCd      : '',
			iNervPayId : '',
			carNo      : '',
			pgCno      : '',
			approvalNo : '',
			payResult  : '',
			r          : '',
			discountCd : '',
			extraCd    : '',
			isMobile   : this.$isMobile()
		}
	},
	filters : {
		comma : function(value){
			if(value != null){
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
		dateFormat : function(value){
			if(value != null){
				var year  = value.toString().substr(0, 4);
				var month = value.toString().substr(4, 2);
				var day   = value.toString().substr(6, 2);
				var time  = value.toString().substr(8, 2);
				var min   = value.toString().substr(10, 2);
				var sec   = value.toString().substr(12, 2);
				return year + "-" + month + "-" + day + " " + time + ":" + min + ":" + sec;
			}
		}
	},
	created(){
		var data = this.$route.query;
		this.fResCd     = data.a;
		this.resCd      = data.b;
		this.iNervPayId = data.c;
		this.carNo      = data.d;
		this.pgCno      = data.e;
		this.approvalNo = data.f;
	},
	mounted(){

		let apiParams = new Map();
		apiParams.set("fResCd"    , this.fResCd);
		apiParams.set("resCd"     , this.resCd);
		apiParams.set("iNervPayId", this.iNervPayId);
		apiParams.set("carNo"     , this.carNo);
		apiParams.set("pgCno"     , this.pgCno);
		apiParams.set("approvalNo", this.approvalNo);

		instance.get("/api/prepay/result.json", {
			params : Object.fromEntries(apiParams)
		})
		.then((res => {

			this.payResult  = res.data.payResult;
			this.r          = res.data.jsonObj.data;
			this.discountCd = res.data.jsonObj.data.discountCd;
			this.extraCd    = res.data.jsonObj.data.extraCd;

		}));
		
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		cancelBtn(carNo){
			this.$router.push({
				name : 'lookup',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},
	},
	
}
</script>

<style>

</style>