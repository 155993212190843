<template>
  <section id="sortList">
		<article>
			<select v-model.number="dataPerPage" @change="emitPerChange">
				<option value="10">10개씩 보기</option>
				<option value="30">30개씩 보기</option>
				<option value="50">50개씩 보기</option>
			</select>
		</article>
	</section>
</template>

<script>
export default {
	data(){
		return{
			dataPerPage: 10,
		}	
	},
	
	methods: {
		emitPerChange() {
			this.$emit('emitPerChange', this.dataPerPage);
		}
	}
}
</script>

<style>

</style>