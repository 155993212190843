<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">거주자 우선 주차장</div>
			<ul>
				<li><router-link to="residentinfo"        title="안내">안내</router-link></li>
				<li><router-link to="residentsearch"      title="거주자 우선 주차장 검색">거주자 우선 주차장 검색</router-link></li>
				<li><router-link to="residentagreement" title="거주자 우선 주차장 신청">거주자 우선 주차장 신청</router-link></li>
				<li><router-link to="residentlookup"      title="조회/결제" class="active">조회 &#47; 결제</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>거주자 우선 주차장</h2>
				<h4>거주자 우선 주차장 신청 조회 &#47; 결제</h4>
			</article>
			<article>
				<h3>거주자 우선 주차장 신청 결제내역 확인</h3>
			</article>
			<article>
				<table class="default-table-view">
					<caption class="sr-only">거주자 우선 주차장 신청 결제내역 리스트 테이블</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">성명</th>
							<td>{{ r.appcntNm }}</td>
						</tr>
						<tr>
							<th>주소</th>
							<td>({{ r.zipcode }}) {{ r.addr}} {{ r.addrDetail }}</td>
						</tr>
						<tr>
							<th>연락처</th>
							<td>{{ r.mobile | phoneFormat }}</td>
						</tr>
						<tr>
							<th>신청 주차장</th>
							<td>
								<span class="emphasis-view">{{ r.dongNm }}</span>
								{{ r.sectnNm }}
								<span class="emphasis" v-if="r.cellNm != '' && r.cellNm != null">
									{{ r.cellNm }}
								</span>
							</td>
						</tr>
						<tr>
							<th>차량 소유주</th>
							<td>{{ r.carOwnerNm }}</td>
						</tr>
						<tr>
							<th>차량번호</th>
							<td>{{ r.carNo }}</td>
						</tr>
						<tr>
							<th>할인</th>
							<td>{{ r.discountNm }}</td>
						</tr>
						<tr>
							<th>이용 기간</th>
							<td>
								<span class="emphasis-view">{{ r.useDivNm }}</span>
								{{ r.startDt | longToDate }} ~ {{ r.endDt | longToDate }}
							</td>
						</tr>
						<tr>
							<th>결제 기간</th>
							<td>
								<span class="orange bold" v-if="r.billDt == '' || r.limitDt == ''">
									결제 기간이 없습니다. 관리자에게 문의해주세요.
								</span>
								<span class="orange" v-else>{{ r.billDt | longToDate }} ~ {{ r.limitDt | longToDate }}</span>
							</td>
						</tr>
						<tr>
							<th>결제 금액</th>
							<td>{{ r.billAmt | comma }} 원</td>
						</tr>
					</tbody>
				</table>
			</article>

			<article>
			</article>
			<table class="default-table-view" v-if="!isMobile">
				<caption class="sr-only">거주자 우선 주차장 신청 결제내역 상세보기 테이블</caption>
				<colgroup>
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
					<col style="width:25%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td colspan="3">{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>
			<table class="default-table-view" v-else>
				<caption class="sr-only">거주자 우선 주차장 신청 결제내역 상세보기 테이블</caption>
				<colgroup>
					<col style="width:30%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>
					<tr>
						<th>결제 응답 메시지</th>
						<td>{{ payResult.fResMsg }}</td>
					</tr>
					<tr>
						<th>결과 메시지</th>
						<td>{{ payResult.resMsg }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래 상태 메시지</th>
						<td>{{ payResult.statusMessage }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인거래번호</th>
						<td>{{ payResult.pgCno }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>거래일시</th>
						<td>{{ payResult.transactionDate | dateFormat }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인일시</th>
						<td>{{ payResult.approvalDate | dateFormat}}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>카드번호</th>
						<td>{{ payResult.cardNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인번호</th>
						<td>{{ payResult.approvalNo }}</td>
					</tr>
					<tr v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">
						<th>승인금액</th>
						<td>{{ payResult.amount | comma }} 원</td>
					</tr>
				</tbody>
			</table>

			<article class="message">
				<p v-if="payResult.fResCd == '0000' && payResult.resCd == '0000'">결제가 완료 되었습니다.</p>
			</article>

			<article class="btn-list">
				<button class="one-btn" @click="cancelBtn(carNo)">확인</button>
			</article>
		</section>
	</main>
</template>

<script>

import axios from 'axios';

const instance = axios.create({
  timeout: 30000,
});


export default {
	data(){
		return{
			fResCd     : '',
			resCd      : '',
			iNervPayId : '',
			carNo      : '',
			pgCno      : '',
			approvalNo : '',
			payResult  : '',
			r          : '',
			isMobile   : this.$isMobile()
		}
	},
	filters : {
		// 숫자 #,### 포맷팅
		comma : function(x){
			if(x != null){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
		// 날짜 포맷팅
		dateFormat : function(x){
			if(x != null){
				var d = x.toString().replace(/[^0-9]/g, '');

				var year  = d.toString().substr(0, 4);
				var month = d.toString().substr(4, 2);
				var day   = d.toString().substr(6, 2);

				return year + "-" + month + "-" + day;
			}
		},
		longToDate : function(x){
			if(x != null){
				var d = new Date(x);

				var year  = d.getFullYear();
				var month = d.getMonth() + 1;
				month     = (month < 10) ? "0" + month.toString() : month.toString();
				var day   = d.getDate();
				day       = (day < 10) ? "0" + day.toString() : day.toString();

				return year + "-" + month + "-" + day;
			}
		},
		// 전화번호 포맷팅
		phoneFormat : function(x){
			if(x != null){
				var p = x.toString().replace(/[^0-9]/g, '');

				let tmp = "";

				if(p.length < 4){

					return p;

				}else if(p.length < 7){

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3);
					return tmp;

				}else if(p.length == 8){

					tmp += p.substr(0, 4);
					tmp += "-";
					tmp += p.substr(4);
					return tmp;

				}else if(p.length < 10){

					tmp += p.substr(0, 2);
					tmp += "-";
					tmp += p.substr(2, 3);
					tmp += "-";
					tmp += p.substr(5);
					return tmp;

				}else if(p.length < 11){

					if(p.substr(0, 2) == "02"){

						tmp += p.substr(0, 2);
						tmp += "-";
						tmp += p.substr(2, 4);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}else{

						tmp += p.substr(0, 3);
						tmp += "-";
						tmp += p.substr(3, 3);
						tmp += "-";
						tmp += p.substr(6);
						return tmp;

					}
					

				}else{

					tmp += p.substr(0, 3);
					tmp += "-";
					tmp += p.substr(3, 4);
					tmp += "-";
					tmp += p.substr(7);
					return tmp;

				}

			}
		}
	},
	created(){
		var data = this.$route.query;
		this.fResCd     = data.a;
		this.resCd      = data.b;
		this.iNervPayId = data.c;
		this.carNo      = data.d;
		this.pgCno      = data.e;
		this.approvalNo = data.f;
	},
	mounted(){
		let apiParams = new Map();
		apiParams.set("fResCd"    , this.fResCd);
		apiParams.set("resCd"     , this.resCd);
		apiParams.set("iNervPayId", this.iNervPayId);
		apiParams.set("carNo"     , this.carNo);
		apiParams.set("pgCno"     , this.pgCno);
		apiParams.set("approvalNo", this.approvalNo);

		instance.get("/api/resident/result.json", {
			params : Object.fromEntries(apiParams)
		})
		.then((res => {

			this.payResult  = res.data.payResult;
			this.r          = res.data.resident.result;

		}));
		
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},
	methods : {
		// 취소 버튼
		cancelBtn(carNo){
			this.$router.push({
				name  : 'residentlookup',
				query : { carNo : carNo }
			});
			localStorage.clear();
		},
	},
}

</script>

<style>

</style>