<template>
	<main id="subTemplet">
    <!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">마이페이지</div>
			<ul>
				<li><router-link to="/mypagemain" title="회원정보변경" class="active">회원정보 변경</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>마이페이지</h2>
        <h4>비밀번호 확인</h4>
			</article>
			<article class="figure">
				<figure class="img-find" title="마이페이지 이미지"></figure>
				<ul class="emphasis-font mypage">
					<li>회원정보 확인을 위해 <span class="bold">비밀번호</span>를 입력해주세요</li>
				</ul>
			</article>
			<article>
				<table class="lookup-table mainpage">
					<caption class="sr-only">비밀번호 확인</caption>
					<colgroup>
						<col style="width:20%" />
						<col style="width:80%" />
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">비밀번호</th>
							<td class="gray">
								<ul class="input-style">
									<li>
										<input type="password" placeholder="Password" class="place-hide" id="password" v-model="password" v-on:keyup.enter="pageMove">
										<label for="password"><span class="hide">Password</span></label>
									</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</article>
			<article class="btn-list">
				<button class="one-btn" @click="pageMove">비밀번호 확인</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators'

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
		password: "",
		}
	},

	validations: {
		password: {
			required
		}
	},

  methods: {
		pageMove(){
			if(!this.validationCheck()) return;

			instance.post('/api/mypage/main.json', null, {
				params: {
					password: this.password
				}
			})
			.then((response => {
				const data = response.data;

				if(!data.result){
					alert(data.msg);

					this.password = "";
					return;
				}else{
					
					this.$router.push({
						name   : 'mypageview',
						params : { 
						empNo : data.result }
					});
				}

			}))
			.catch(function (error) {
				alert("로그인 정보가 올바르지 않습니다.");
				console.dir(error);
			})

		},
		validationCheck(){
			if(!this.$v.password.required) {
				alert('비밀번호를 입력하여주십시오.');
				return false;
			}else{
				return true;
			}
		}
	},
	mounted() {
		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	
}
</script>

<style>

</style>