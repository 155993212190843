<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
      	<section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리" class="active">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>부서관라</h4>
			</article>
			<article>
				<div class="tab-content">
					<article>
						<h3>부서관리</h3>
						<!-- Sort Area -->
						<div class="search-box">
							<search-box @emitSearch="searchBtn" @emitPerChange="changePerPage">
								<template slot="searchCondition">
									<option value="1">부서명</option>
									<option value="2">부서설명</option>
									<option value="3">부서명 + 부서설명</option>
								</template>
							</search-box>
						</div>
					</article>
					<article>
						<h5 v-if="nodata">Total: 0건</h5>
						<h5 v-else>Total: {{listData.length}}건</h5>
						<table class="default-table">
							<caption class="sr-only">부서관리 리스트 테이블</caption>
							<colgroup>
								<col style="width:20%" />
								<col style="width:20%" />
								<col style="width:60%" />

							</colgroup>
							<thead>
								<tr>
									<th scope="col">부서ID</th>
									<th scope="col">부서명</th>
									<th scope="col">부서 설명</th>
								</tr>
							</thead>
							<tbody>
								<tr class="nodate" v-if="nodata">
									<td colspan="3" >조회된 데이터가 없습니다.</td>
								</tr>
								<tr v-for="(item, index) in calData" :key="index" @click="view(item.deptId)">
									<td>{{ item.deptId }}</td>
									<td>{{ item.deptNm }}</td>
									<td>{{ item.deptDc }}</td>
								</tr>
							</tbody>
						</table>
					</article>
					<v-pagination v-model="curPageNum" :length="numOfPages"></v-pagination>
					<article class="btn-list right">
						<button class="btn-border" @click="$router.push('departmentenrollment')">등록</button>
					</article>	
				</div>
			</article>	
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import SearchBox from '../../../components/SearchBox.vue';
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return{
			nodata: false,
			listData: [],
			curPageNum: 1,
			dataPerPage: 10
		}
	},

	mounted() {
		this.searchBtn();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		searchBtn(searchCondition, searchKeyword) {
			instance.get('/api/dept/list.json', {
				params: {
					searchCondition: searchCondition,
					searchKeyword: searchKeyword
				}
			})
			.then((response => {
				var data = response.data;

				if(data.resultList && data.resultList.length > 0){
					this.listData = data.resultList;
					this.nodata = false;
				} else {
					this.listData = [];
					this.nodata = true;
				}

				this.curPageNum = 1;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		changePerPage(dataPerPage) {
			this.dataPerPage = dataPerPage;
			this.curPageNum = 1;
		},

		view(deptId) {

			this.$router.push({
				path  : 'departmentview',
				query : { deptId : deptId }
			});
		}
	},

	computed: {
		startOffset() {
			return ((this.curPageNum - 1) * this.dataPerPage);
		},
		endOffset() {
			return (this.startOffset + this.dataPerPage);
		},
		numOfPages() {
			return Math.ceil(this.listData.length / this.dataPerPage);
		},
		calData() {
			if(this.listData.length) {
				return this.listData.slice(this.startOffset, this.endOffset);
			}
		}
	},
	
	components:{
		SearchBox,
	}
}
</script>

<style scope>
.default-table tr td{font-weight: 300;}
</style>