<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">관리자 메뉴</div>
			<ul>
				<li><router-link to="/freevehicle"      title="무료차량 관리">무료차량 관리</router-link></li>
				<li><router-link to="/prepaymentlist"   title="정기권 주차장 관리">정기권 주차장 관리</router-link></li>
				<li><router-link to="/noticemanagement" title="공지사항 관리">공지사항 관리</router-link></li>
				<li><router-link to="/faqmanagement"    title="자주하는 질문 관리"  class="active">자주하는 질문 관리</router-link></li>
				<li><router-link to="/popuplist"        title="팝업 관리">팝업 관리</router-link></li>
				<li><router-link to="/residentmanagement" title="거주자 주차장 관리">거주자 주차장 관리</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>관리자 메뉴</h2>
				<h4>자주하는 질문 관리</h4>
			</article>
			<article>
				<h3>자주하는 질문 관리</h3>
				<!-- Search Area -->
				<div class="search-box">
					<search-box @emitSearch="searchBtn" @emitPerChange="changePerPage">
						<template slot="searchCondition">
							<option value="1">제목</option>
							<option value="2">내용</option>
							<option value="3">제목+내용</option>
						</template>
					</search-box>
				</div>
			</article>
			<article>
				<!-- Table List Area -->
				<h5 v-if="nodata">Total: 0건</h5>
				<h5 v-else>Total: {{listData.length}}건</h5>
				<table class="default-table">
					<caption class="sr-only">자주하는 질문 리스트 테이블</caption>
					<colgroup>
						<col style="width:70px" />
						<col style="width:50%" />
						<col style="width:20%" />
						<col style="width:20%" />
						<col style="width:10%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">번호</th>
							<th scope="col">제목</th>
							<th scope="col">작성자</th>
							<th scope="col">작성일자</th>
							<th scope="col">조회수</th>
						</tr>
					</thead>
					<tbody>
					<tr class="nodate" v-if="nodata">
						<td colspan="5" >조회된 데이터가 없습니다.</td>
					</tr>
					<tr v-for="(item, index) in calData" :key="index" @click="view(item.atclId)">
						<td>{{ listData.length - index - startOffset }}</td>
						<td style="text-align: left;">
							{{ item.title }}
							<span v-if="item.atchCnt > 0" class="glyphicon glyphicon-paperclip" aria-hidden="true"> </span>
						</td>
						<td>{{ item.regNm }}</td>
						<td>{{ item.regDttm | ptDateFormat }}</td>
						<td>{{ item.hits }}</td>
					</tr>
					</tbody>
				</table>
			</article> 
			<!-- Pagenation Area -->
			<v-pagination v-model="curPageNum" :length="numOfPages"></v-pagination>

			<!-- Button Area -->
			<article class="btn-list right">
				<button class="btn-border" @click="$router.push('faqmanagementenrollment')">등록</button>
			</article>
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import SearchBox from '../../../components/SearchBox.vue';

const instance = axios.create({
  timeout: 30000,
});

export default {
	data(){
		return{
			nodata: false,
			listData: [],
			curPageNum: 1,
			dataPerPage: 10,
			bbsCd: 'faq'
		}
	},

	mounted() {
		this.searchBtn();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		searchBtn(searchCondition, searchKeyword) {
			instance.get('/api/admin/board/'+ this.bbsCd +'/list.json', {
				params: {
					searchCondition: searchCondition,
					searchKeyword: searchKeyword,
					bbsCd: this.bbsCd
				}
			})
			.then((response => {
				var data = response.data;

				if(data.boardList && data.boardList.length > 0){
					this.listData = data.boardList;
					this.nodata = false;
				} else {
					this.listData = [];
					this.nodata = true;
				}

				this.curPageNum = 1;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		changePerPage(dataPerPage) {
			this.dataPerPage = dataPerPage;
			this.curPageNum = 1;
		},

		view(atclId) {

			this.$router.push({
				path  : 'faqmanagementview',
				query : { atclId : atclId }
			});
		}
	},

	computed: {
		startOffset() {
			return ((this.curPageNum - 1) * this.dataPerPage);
		},
		endOffset() {
			return (this.startOffset + this.dataPerPage);
		},
		numOfPages() {
			return Math.ceil(this.listData.length / this.dataPerPage);
		},
		calData() {
			if(this.listData.length) {
				return this.listData.slice(this.startOffset, this.endOffset);
			}
		}
	},
	
	components:{
		SearchBox,
	}
}
</script>

<style>

</style>