<template>
	<main id="oneTemplet">
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>아이디 찾기</h2>
			</article>
			<article class="figure">
				<figure class="img-find"></figure>
				<ul class="emphasis-font">
					<li><span class="bold">아이디</span>를 잊어버리셨나요?</li>
					<!-- <li>포탈에 등록하신 차량번호를 입력해 주세요.</li> -->
					<li>포탈에 등록하신 휴대폰 번호로</li>
					<li>본인인증을 진행해 주세요.</li>
				</ul>
			</article>
			<!--
				<article>
					<table class="lookup-table">
						<caption class="sr-only">ID 찾기</caption>
						<colgroup>
							<col style="width:20%" />
							<col style="width:80%" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row">차량 번호</th>
								<td>
									<form v-on:submit="onSubmit">
										<ul class="input-style">
											<li>
												<input type="text" v-model="inputText" placeholder="차량번호" class="place-hide">
											</li>
										</ul>
									</form>
								</td>
							</tr>
						</tbody>
					</table>
				</article>
			-->
			<article class="btn-list">
				<button class="one-btn" @click="checkNiceID()" title="새창열림(아이디찾기)">아이디 찾기</button>
			</article>

			<article class="responsive-align" v-show="findIdChk">
				<div>
					<p>* 가입하신 ID 는 <span class="orange">{{ empNo }}</span> 입니다.</p>
				</div>
				<div class="dim" @click="findIdChk=!findIdChk"></div>
			</article>

		</section>

		<form name="form_chk" method="post" ref="form_chk">
			<input type="hidden" name="m"          value="checkplusService" >
			<input type="hidden" name="EncodeData" v-model="sEncData">
		</form>

		<form name="form_nice" ref="form_nice">
			<input type="hidden" id="nice_name"       name="nice_name" >
			<input type="hidden" id="nice_mobile"     name="nice_mobile" >
			<input type="hidden" id="nice_birthDate"  name="nice_birthDate" >
			<input type="hidden" id="nice_msg"        name="nice_msg" >
			<input type="hidden" id="memberDuplCheck" name="memberDuplCheck" >
		</form>

	</main>
</template>

<script>

import axios from 'axios';
const instance = axios.create({
  timeout: 30000,
});

export default {

	data(){
		return{
			sEncData        : "",
			nice_name       : "",
			nice_mobile     : "",
			nice_birthDate  : "",
			nice_msg        : "",
			memberDuplCheck : "",

			empNo     : "",
			empNm     : "",
			empMobile : "",
			findIdChk : false,
			intervals : [],
		}
	},

	mounted() {

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);

	},

	methods:{

		setValues(name, mobile, birth, msg, memberDuplCheck){

			this.nice_name       = name;
			this.nice_mobile     = mobile;
			this.nice_birthDate  = birth;
			this.nice_msg        = msg;
			this.memberDuplCheck = memberDuplCheck;

			if(memberDuplCheck > 0){
				
				let apiParams = new Map();
				apiParams.set("name"  , name);
				apiParams.set("mobile", mobile);

				instance.get("/api/nice/findId.json", {
					params : Object.fromEntries(apiParams)
				})
				.then((res => {
					var resData    = res.data.findId;
					this.empNo     = resData.EMP_NO;
					this.empNm     = resData.EMP_NM;
					this.empMobile = resData.EMP_MOBILE;
					this.findIdChk = true;
				}));

				return false;
			}else{
				alert("가입되어 있는 계정이 없습니다.");
				return false;
			}

		},

		// Nice 본인인증
		checkNiceID(){
			
			window.name = "Parent_window";
			var niceWindow = window.open("/api/nice/checkPlusSafe_main", "popupChk", "width=500, height=550, top=100, left=1000, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no");
			let interval = window.setInterval(() => {
				if(niceWindow.closed && document.form_nice.nice_mobile.value){

					this.intervals.forEach(element => {
						window.clearInterval(element);
					});

					var name            = document.form_nice.nice_name.value;
					var mobile          = document.form_nice.nice_mobile.value;
					var birth           = document.form_nice.nice_birthDate.value;
					var msg             = document.form_nice.nice_msg.value;
					var memberDuplCheck = document.form_nice.memberDuplCheck.value;

					this.setValues(name, mobile, birth, msg, memberDuplCheck);
				}
			}, 500);

			this.intervals.push(interval);

		},
	},

	beforeRouteLeave(to, from, next) {
		this.intervals.forEach(element => {
			window.clearInterval(element);
		});
		next();
	}

}
</script>

<style>

</style>