<template>
  <div v-show="showModal">
    <section class="modal-manager freecar">
      <article class="title">
        <ul>
          <li>
            <slot name="info"></slot>
          </li>
          <li class="popup-close" @click="modalHide" tabindex="2" @keydown.enter="modalHide">
            <slot name="close"></slot>
          </li>
        </ul>
      </article>
      <article class="content">
        <article>
          <table class="vertical-table">
            <caption class="sr-only">엑셀 업로드 테이블</caption>
            <colgroup>
              <col style="width:20%" />
              <col style="width:80%" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">차적파일</th>
                <td>
                  <ul class="input-style button">
                    <li>
                      <label for="excelUpload" class="table-btn">첨부파일</label>
                      <input type="file" accept=".xlsx" id="excelUpload" multiple @change="changeFile"/>
                      <p class="asterisk" v-if="eFile != ''">{{eFile.name}} {{eFile.size}}kb</p>
                    </li>
                  </ul>
                  <p class="asterisk">첨부파일(최대용량: 20M) <span class="asterisk">엑셀파일</span></p>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
        <article class="content-inner">
          <slot name="content"></slot>
        </article>
      </article>
      <p class="typo__p" v-if="submitStatus === 'ERROR'">파일을 선택해 주세요.</p>
      <article class="btn-list">
        <button class="popup-btn" @click="modalHide">닫기</button>
        <button class="popup-btn-border" @click="uploadFile">파일 업로드</button>
      </article>
    </section>
    <section class="dim"></section>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data(){
    return{
      eFile: '',
      showModal: false,
      submitStatus: ''
    }
  },

	validations: {
		eFile: {
			required
    }
	},

  methods:{
    modalHide(){
      this.$emit('emitChangeModal');
    },

    changeFile(e){
      this.file = e.target.files ? e.target.files[0] : null;
      this.eFile = this.file;
    },

    uploadFile(){
      if(this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
        this.submitStatus = "OK";
        this.$emit('emitUploadFile', this.eFile);
      }
    }
  }
}
</script>