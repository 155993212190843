<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
    <section id="lnb">
			<div id="lnb-title">시스템 관리</div>
			<ul>
				<li><router-link to="/usermanagement" title="사용자 관리">사용자 관리</router-link></li>
				<li><router-link to="/departmentlist" title="부서관리">부서관리</router-link></li>
				<li><router-link to="/teamlist" title="팀관리">팀관리</router-link></li>
				<li><router-link to="/codelist" title="공통코드">공통코드</router-link></li>
				<li><router-link to="/detailedcodelist" title="공통상세코드">공통상세코드</router-link></li>
				<li><router-link to="/menulist" title="메뉴관리">메뉴관리</router-link></li>
				<li><router-link to="/createmenu" title="메뉴생성 관리">메뉴생성 관리</router-link></li>
				<li><router-link to="/programlist" title="프로그램 관리">프로그램 관리</router-link></li>
				<li><router-link to="/authoritylist" title="권한 관리">권한 관리</router-link></li>
				<li><router-link to="/rolllist" title="롤 관리">롤 관리</router-link></li>
				<li><router-link to="/userauthoritylist" title="사용자 권한 관리">사용자 권한 관리</router-link></li>
				<li><router-link to="/grouplist" title="그룹 관리">그룹 관리</router-link></li>
				<li><router-link to="/accessinfo" title="개인별 접속정보" class="active">개인별 접속정보</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>시스템 관리</h2>
				<h4>개인별 접속정보</h4>
			</article>
			<article>
				<h3>개인별 접속정보</h3>
				<section id="connectInfo">
					<article>
						<div>
							<ul>
								<li>접속시작일</li>
								<li class="application">
									<date-picker @emitSetDate="setDate"></date-picker>
								</li>
							</ul>
						</div>
						<div>
							<ul class="input-style small">
								<li>사용자명</li>
								<li><input type="text" placeholder="사용자명" class="place-hide" v-model="userNm" @keyup.enter="searchBtn"></li>
							</ul>
						</div>
						<div>
							<ul class="input-style small">
								<li>접근 IP</li>
								<li> <input type="text" placeholder="접근 IP" class="place-hide" v-model="accessIp" @keyup.enter="searchBtn"></li>
							</ul>
						</div>
					</article>
					<article>
						<button class="table-btn" @click="searchBtn">조회</button>
					</article>
				</section>
				<!-- Search Area -->
				<div class="search-box">
					<exclusive-sort @emitPerChange="changePerPage"></exclusive-sort>
				</div>
			</article>
			<article>
				<h5 v-if="nodata">Total: 0건</h5>
				<h5 v-else>Total: {{listData.length}}건</h5>
				<table class="default-table">
					<caption class="sr-only">개인별 접속정보 테이블</caption>
					<colgroup>
						<col style="width:70px" />
						<col style="width:23%" />
						<col style="width:13%" />
						<col style="width:10%" />
						<col style="width:10%" />
						<col style="width:13%" />
						<col style="width:31%" />
					</colgroup>
					<thead>
						<tr>
							<th scope="col">번호</th>
							<th scope="col">로그ID</th>
							<th scope="col">로그인일자</th>
							<th scope="col">접속방식</th>
							<th scope="col">사용자명</th>
							<th scope="col">접속IP</th>
							<th scope="col">접속URL</th>
						</tr>
					</thead>
					<tbody>
						<tr class="nodate" v-if="nodata">
							<td colspan="7" >조회된 데이터가 없습니다.</td>
						</tr>
						<tr v-for="(item, index) in calData" :key="index">
							<td>{{ startOffset + index + 1 }}</td>
							<td>{{ item.logId }}</td>
							<td>{{ item.creatDt }}</td>
							<td v-if="item.loginMthd == 'I'">로그인</td>
							<td v-if="item.loginMthd == 'O'">로그아웃</td>
							<td v-if="item.loginMthd == 'P'">페이지</td>
							<td>{{ item.loginNm }}</td>
							<td>{{ item.loginIp }}</td>
							<td>{{ item.url }}</td>
						</tr>
					</tbody>
				</table>
			</article>
			<v-pagination v-model="curPageNum" :length="numOfPages"></v-pagination>
		</section>
	</main>
</template>

<script>
import axios from 'axios';
import DatePicker from '../../../components/DatePicker2.vue';
import ExclusiveSort from '../../../components/ExclusiveSort.vue';
const instance = axios.create({
  timeout: 30000,
});
export default {
	data(){
		return{
			nodata: false,
			listData: [],
			curPageNum: 1,
			dataPerPage: 10,
			checkedValues: [],
			allChecked: false,
			userNm: '',
			accessIp: '',
			startDate: new Date().toISOString().substr(0, 10),
			endDate: new Date().toISOString().substr(0, 10)
		}
	},

	mounted() {
		this.searchBtn();

		let category = this.$route.meta.category;
		EventBus.$emit('emitCategory', category);
	},

	methods: {
		searchBtn() {
			instance.get('/api/admin/loginHistory/list.json', {
				params: {
					logStartDt: this.startDate,
					logEndDt: this.endDate + ' 23:59:59',
					re: this.userNm,
					ip: this.accessIp
				}
			})
			.then((response => {
				var data = response.data;

				if(data.resultList && data.resultList.length > 0){
					this.listData = data.resultList;
					this.nodata = false;
				} else {
					this.listData = [];
					this.nodata = true;
				}

				this.curPageNum = 1;
			}))
			.catch(function (error) {
				//console.log(error);
				alert("서버와 통신중 오류가 발생하였습니다.")
			})
		},

		changePerPage(dataPerPage) {
			this.dataPerPage = dataPerPage;
			this.curPageNum = 1;
		},

		setDate(sd, ed){
			this.startDate = sd;
			this.endDate   = ed;
		},
	},

	computed: {
		startOffset() {
			return ((this.curPageNum - 1) * this.dataPerPage);
		},
		endOffset() {
			return (this.startOffset + this.dataPerPage);
		},
		numOfPages() {
			return Math.ceil(this.listData.length / this.dataPerPage);
		},
		calData() {
			if(this.listData.length) {
				return this.listData.slice(this.startOffset, this.endOffset);
			}
		}
	},

	components:{
		ExclusiveSort,
		DatePicker
	}
}
</script>

<style>

</style>