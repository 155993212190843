import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    })
  ],

  state: {
    isLogin: false,
    authorCode: undefined,
    empNo: undefined,
    empNm: undefined,
    empMobile: undefined,
    empCarNo: undefined,
    zipcode: undefined,
    addr: undefined,
    addrDetail: undefined,
  },

  getters: {
  },

  mutations: {
    authorCode: function(state, payload){
      state.authorCode = payload;
    },
    empNo: function(state, payload){
      state.empNo = payload;
    },
    empNm: function(state, payload){
      state.empNm = payload;
    },
    empMobile: function(state, payload){
      state.empMobile = payload;
    },
    empCarNo: function(state, payload){
      state.empCarNo = payload;
    },
    zipcode: function(state, payload){
      state.zipcode = payload;
    },
    addr: function(state, payload){
      state.addr = payload;
    },
    addrDetail: function(state, payload){
      state.addrDetail = payload;
    },
    isLogin: function(state, payload){
      state.isLogin = payload;
    },
    logout: function(state, payload){
      state.authorCode = undefined;
      state.empNo = undefined;
      state.empNm = undefined;
      state.empMobile = undefined;
      state.empCarNo = undefined;
      state.zipcode = undefined;
      state.addr = undefined;
      state.addrDetail = undefined;
      state.isLogin = false;
    },
  },

  actions: {
    authorCode: function(context, payload){
      context.commit("authorCode", payload);
    },
    empNo: function(context, payload){
      context.commit("empNo", payload);
    },
    empNm: function(context, payload){
      context.commit("empNm", payload);
    },
    empMobile: function(context, payload){
      context.commit("empMobile", payload);
    },
    empCarNo: function(context, payload){
      context.commit("empCarNo", payload);
    },
    zipcode: function(context, payload){
      context.commit("zipcode", payload);
    },
    addr: function(context, payload){
      context.commit("addr", payload);
    },
    addrDetail: function(context, payload){
      context.commit("addrDetail", payload);
    },
    isLogin: function(context, payload){
      context.commit("isLogin", payload);
    },
    logout: function(context, payload){
      context.commit("logout", payload);
    }
  }
});