<template>
	<main id="subTemplet">
		<!-- 좌측메뉴 (LNB) -->
		<section id="lnb">
			<div id="lnb-title">선불권(종이)</div>
			<ul>
				<li><router-link to="" title="안내" class="active">선불권(종이)</router-link></li>
			</ul>
		</section>
		<!-- 컨텐츠 영역 -->
		<section id="content">
			<article id="contentTitle">
				<h2>공영주차 선불권</h2>
				<h4>선불권 신청</h4>
			</article>
			<article>
				<h3>선불권 신청 내역</h3>
			</article>
			<table class="default-table-view">
				<caption class="sr-only">선불권 신청 내역</caption>
				<colgroup>
					<col style="width:20%" />
					<col style="width:80%" />
				</colgroup>
				<tbody>					
					<tr>
						<th scope="row">선택 주차장</th>
						<td>롯데백화점</td>
					</tr>
					<tr>
						<th>구매자 이름</th>
						<td>홍길동</td>
					</tr>
					<tr>
						<th>전화번호</th>
						<td>010-0000-0000</td>
					</tr>
					<tr>
						<th>사업장</th>
						<td>롯데벡화점</td>
					</tr>
					<tr>
						<th>내역</th>
						<td>
							<input type="text" class="disabled" placeholder="1시간권"/>장당 500원
							<input type="text" class="disabled" placeholder="매수"/>200 매
						</td>
					</tr>
					<tr>
						<th>구매 총 금액</th>
						<td>160,000원</td>
					</tr>
					<tr>
						<th>결제하실 금액</th>
						<td>80,000원</td>
					</tr>
				</tbody>
			</table>
			<article class="message">
				<p>고객님의 선불권 신청 내역은 위와 같습니다. 학인 후 결제버튼을 눌러주세요.</p>
			</article>
			<article class="btn-list">
				<button class="btn">결제</button>
				<button class="btn-border">취소</button>
			</article>	
		</section>
	</main>
</template>

<script>
export default {

}
</script>

<style>

</style>